<template lang="pug">
v-col.col-12.col-lg-11.pa-0.mt-lg-5.members__projects.projects(
  :style='projectsStyle')
  .projects__list(
    :id='`list-${id}`'
    :style='componentStyles')
    span.projects__name(
      v-for='project in sortedProjects'
      :class='{"projects__name--active": project.has_actual_sprint}') {{ project.name }}
  button.projects__button(
    v-if='isButtonVisible'
    type='button'
    @click='toggleListVisibility') {{ $lang.buttonSeeAll }}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserProjects',
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    isListVisible: true,
    isButtonVisible: false,
  }),
  computed: {
    ...mapGetters(['isDark', 'isMobile']),
    sortedProjects() {
      let activeArr = []
      let inactiveArr = []
      this.projects.map((project) =>
        project.has_actual_sprint
          ? activeArr.push(project)
          : inactiveArr.push(project)
      )
      activeArr.sort((a, b) =>
        a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1
      )
      inactiveArr.sort((a, b) =>
        a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1
      )
      return [...activeArr, ...inactiveArr]
    },
    componentStyles() {
      return {
        '--font-weight': this.isDark ? '300' : '400',
        '--letter-spacing': this.isDark ? '0.7px' : 'normal',
      }
    },
    projectsStyle() {
      return {
        '--list-height': this.isListVisible ? 'auto' : '24px',
      }
    },
  },
  mounted() {
    this.checkHeight()
  },
  methods: {
    toggleListVisibility() {
      this.isListVisible = !this.isListVisible
    },
    checkHeight() {
      const projectsEl = document.querySelector(`#list-${this.id}`)
      const elHeight = projectsEl.clientHeight
      let lineHeight = this.isMobile ? 48 : 24
      this.isButtonVisible = elHeight > lineHeight ? true : false
      this.isListVisible = elHeight > lineHeight ? false : true
    },
  },
}
</script>

<style lang="scss" scoped>
.projects {
  display: flex;
  justify-content: space-between;
  height: var(--list-height);

  @media (max-width: 850px) {
    height: calc(2 * var(--list-height));
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 0 12px;
    max-width: 90%;
    overflow: hidden;

    @media (max-width: 1500px) {
      max-width: 85%;
    }

    @media (max-width: 1100px) {
      max-width: 80%;
    }
  }

  &__name {
    font-size: 16px;
    color: var(--v-text-base);
    font-weight: var(--font-weight);
    letter-spacing: var(--letter-spacing);
    width: max-content;

    &--active {
      color: var(--v-title-color);
      font-weight: 500;
    }
  }

  &__button {
    align-self: flex-start;
    color: var(--v-backgroundLabel-base);
    font-size: 12px;
    margin-top: 4px;
  }
}
</style>
