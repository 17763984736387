const state = {
  endFullSyncTime: null,
  endRoughSyncTime: null,
}

const mutations = {
  setFullSyncTime: (state, payload) => {
    state.endFullSyncTime = payload.setSeconds(payload.getSeconds() + 30)
  },
  setRoughSyncTime: (state, payload) => {
    state.endRoughSyncTime = payload.setSeconds(payload.getSeconds() + 30)
  },
  setFullSyncTimeEmpty: (state) => {
    state.endFullSyncTime = null
  },
  setRoughSyncTimeEmpty: (state) => {
    state.endRoughSyncTime = null
  },
}

export default {
  state,
  mutations,
}
