<template lang="pug">
v-expansion-panels(
  focusable
  v-model='currentColumn'
  flat)
  v-expansion-panel.column__panel.backgroundMain(
    :disabled='boardClient'
    v-for='(column, index) in columns'
    :id='`column--${column.id}`'
    :key='`column--${column.id}`'
    @click='changeColumn(column.id, index)')
    v-expansion-panel-header.panel-header.px-0.py-6
      v-row.flex-wrap(no-gutters)
        v-col.column__left.pa-0.d-flex.flex-column.align-start.flex-md-row.align-md-center.justify-space-between.justify-md-start(
          :cols='12'
          :lg='9')
          .column__items.d-flex.flex-wrap.flex-md-nowrap.align-center.justify-space-between
            v-tooltip(
              bottom
              v-if='column.role === 1')
              template(v-slot:activator='{on}')
                div(
                  v-on='on'
                  v-if='!boardClient')
                  v-switch.column__switch-archive.mb-4(
                    @click.stop.prevent='toggleShowArchive(column.id)'
                    v-model='isShowArchive[column.id]'
                    hide-details
                    color='title'
                    :aria-label='$lang.showArchiveCards')
              span {{ $lang.showArchiveCards }}
            .column__switch-stub(v-else)
            .column__chip.base--text.d-flex.flex-column(
              :class='`role${column.role}`')
              span(v-if='column.role === 0') #simple
              span(v-if='column.role === 1') #relise
              span(v-if='column.role === 2') #backlog
              span(v-if='column.role === 3') #sprint
              span(v-if='column.role === 4') #coded
              span(v-if='column.role === 5') #production
              span(v-if='column.closed') #archive
          .column__items.d-flex.flex-wrap.flex-md-nowrap.align-center.justify-space-between
            h4.column__name.grey--text {{ column.name }}
            .column__label.primary.base--text {{ column.boardlist_cards }}
        v-col.py-0.pl-0.d-flex.justify-space-between.justify-lg-end.align-center(
          :cols='12'
          :lg='3'
          v-if='isAdmin')
          TimeTrackingSwitch(:column='column')
          v-btn(
            v-if='isAdmin'
            icon
            @click.stop='openSettings(column.id)'
            :aria-label='$lang.toColumnSettings')
            v-icon.setting-icon {{ "$gearIcon" }}
    v-expansion-panel-content.panel-content.mt-7.backgroundMain
      BoardColumn(
        :columnId='column.id'
        :isLoaded='isLoaded'
        :isShowArchive='isShowArchive[column.id]')
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
const { mapState: boardsMapState } = createNamespacedHelpers('boards')

const BoardColumn = () =>
  import(
    /* webpackChunkName: "BoardColumn" */ /* webpackMode: "eager" */ '@/views/Boards/BoardColumn.vue'
  )
const TimeTrackingSwitch = () =>
  import(
    /* webpackChunkName: "TimeTrackingSwitch" */ /* webpackMode: "eager" */ '@/views/Boards/components/TimeTrackingSwitch.vue'
  )

export default {
  name: 'BoardColumns',
  components: {
    BoardColumn,
    TimeTrackingSwitch,
  },
  props: {
    columns: { type: Array, default: () => [] },
    listAdmins: { type: Array, default: () => [] },
  },
  data() {
    return {
      currentColumn: undefined,
      isLoaded: false,
      isShowArchive: {},
      timeoutID: null,
    }
  },
  computed: {
    ...mapGetters(['userId', 'isDark', 'isClient', 'isMobile', 'isTablet']),
    ...boardsMapState(['lists', 'currentBoard']),
    isAdmin() {
      return this.listAdmins.map((a) => a.id).includes(this.userId)
    },
    boardClient() {
      return this.currentBoard.user_status.toLowerCase() === 'client'
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeoutID)
  },
  methods: {
    openSettings(columnId) {
      this.$emit('openSettingsModal', columnId)
    },
    changeColumn(id, index) {
      if (this.currentColumn !== index) {
        this.scrollTo(id)
        this.isLoaded = false
        this.$store.dispatch('boards/getBoardList', id).finally(() => {
          this.isLoaded = true
        })
      }
    },
    toggleShowArchive(column, show = this.isShowArchive[column]) {
      this.isShowArchive = { ...this.isShowArchive, [column]: show }
    },
    scrollTo(id) {
      this.timeoutID = setTimeout(() => {
        const element = document.querySelector(`#column--${id}`)

        const positions = {
          elementTop: window.pageYOffset + element.getBoundingClientRect().top,
          elementBottom:
            window.pageYOffset + element.getBoundingClientRect().bottom,
          windowTop: window.pageYOffset,
          windowBottom:
            window.pageYOffset + document.documentElement.clientHeight,
        }
        // checking - if element visible on the screen
        if (
          positions.elementBottom > positions.windowTop &&
          positions.elementTop < positions.windowBottom
        )
          return
        else {
          const y =
            element.getBoundingClientRect().top + window.pageYOffset - 65
          window.scrollTo({ top: y, behavior: 'smooth' })
        }
      }, 400)
    },
  },
}
</script>

<style lang="scss" scoped>
.noWrap {
  white-space: nowrap;
}

.column {
  &__items {
    & > * {
      margin-top: 10px;
    }
  }

  &__switch-archive {
    margin-right: 30px;
  }

  &__chip {
    @include chip(8px, 10px);
    margin-right: 20px;
  }

  &__name {
    @include card-title;
    margin-right: 20px;

    @media (max-width: 960px) {
      font-size: 20px;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 20px;
  }

  &__switch-stub {
    @media (min-width: 600px) {
      width: 76px;
    }
  }
}
</style>

<style lang="scss">
.column__panel .v-expansion-panel-content .v-expansion-panel-content__wrap {
  padding: 0;

  @media (max-width: 960px) {
    padding: 0;
  }
}

.panel-header.v-expansion-panel-header {
  border-bottom: 1px solid rgba(191, 191, 191, 0.45);
}

.panel-header.v-expansion-panel-header::before {
  color: rgba($color: #fff, $alpha: 1);
}

.v-expansion-panel--active
  > .v-expansion-panel-header.panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  color: var(--v-primary-base) !important;
}
</style>
