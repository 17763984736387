import app from '@/main'

const state = {
  isMobile: false,
  isTablet: false,
  isDark: false,
  isAutosetIsDark: true,
}

const getters = {
  isMobile: ({ isMobile }) => isMobile,
  isTablet: ({ isTablet }) => isTablet,
  isDark: ({ isDark }) => isDark,
  isAutosetIsDark: ({ isAutosetIsDark }) => isAutosetIsDark,
}

const mutations = {
  SET_IS_MOBILE: (state, value) => {
    state.isMobile = value
  },
  SET_IS_TABLE: (state, value) => {
    state.isTablet = value
  },
  SET_IS_DARK: (state, value) => {
    state.isDark = value
    localStorage.setItem('is_dark', value)
  },
  SET_AUTOSET_IS_DARK: (state, value) => {
    state.isAutosetIsDark = value
    localStorage.setItem('autoset_is_dark', value)
  },
}

const actions = {
  setIsDark: ({ commit, state, dispatch }, value) => {
    if (state.isAutosetIsDark) {
      dispatch('options/checkIsNightWithAutoSet')
    } else {
      app.$vuetify.theme.dark = value
      commit('SET_IS_DARK', value)
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
