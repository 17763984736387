<template lang="pug">
v-row.member-header(no-gutters)
  v-col.col-12.pa-0
    v-card.backgroundMain.mb-3(elevation='0')
      v-card-title.justify-space-between.pa-0
        .member-header__content.d-flex.align-center
          v-btn.mr-5(
            :to='{name: "members"}'
            icon
            :aria-label='$lang.linkGoBack')
            v-icon {{ mdiKeyboardBackspace }}

          h1.screen-subtitle.title--text {{ currentRoute }}
          InternalLink.member-header__link(
            v-if='currentMember && Object.keys(currentMember).length && userMemberId'
            :name='pageName'
            :params='pageParams'
            :text='$lang.toDashboard'
            :icon='mdiOpenInNew'
            :isTooltipDisabled='false'
            :showCaption='false')
</template>

<script>
import { mdiKeyboardBackspace, mdiOpenInNew } from '@mdi/js'
import { mapGetters } from 'vuex'
const InternalLink = () =>
  import(
    /* webpackChunkName: "InternalLink" */ /* webpackMode: "eager" */ '@/components/InternalLink.vue'
  )

export default {
  name: 'MemberHeader',
  components: { InternalLink },
  props: {
    currentMember: {
      type: Object,
      default: () => ({}),
    },
    currentRoute: {
      type: String,
    },
  },
  data: () => ({
    mdiKeyboardBackspace,
    mdiOpenInNew,
  }),
  computed: {
    ...mapGetters(['isMobile', 'userId', 'userMemberId']),
    isMemberUser() {
      return this.currentMemberId && this.userMemberId === this.currentMemberId
    },
    currentMemberId() {
      return this.currentMember?.id
    },
    pageName() {
      return this.isMemberUser ? 'dashboard' : 'memberDashboard'
    },
    pageParams() {
      return this.isMemberUser ? {} : { userId: this.currentMemberId }
    },
  },
}
</script>

<style lang="scss" scoped>
.member-header {
  margin-left: -72px;

  @media (max-width: 600px) {
    margin-left: -16px;
  }

  &__link {
    margin-left: auto;
    margin-top: 10px;

    @media (max-width: 600px) {
      margin-top: 0;
    }
  }
}

.trello {
  &header-__wrapper {
    margin-bottom: 17px;
  }

  &__link {
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  &__text {
    font-size: 20px;
    color: #0075ff;
    margin-right: 7px;
  }
}

.v-btn--active:before {
  opacity: 0;
}
</style>
