<template lang="pug">
v-switch.observer(
  v-model='isObserver'
  @change='$emit("changeObserverStatus", userId)')
  template(v-slot:label)
    span.observer__status.mr-4 {{ $lang.observerStatus }}
</template>

<script>
export default {
  name: 'ObserverStatusSwitch',
  props: {
    userId: {
      type: Number,
    },
    observerStatus: {
      type: Boolean,
    },
  },
  data() {
    return {
      isObserver: this.observerStatus,
    }
  },
}
</script>

<style lang="scss" scoped>
.observer {
  &__status {
    font-size: 12px;

    @media (max-width: 960px) {
      display: none;
    }
  }
}
</style>

<style lang="scss">
.observer .v-input__slot {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
}
</style>
