<template lang="pug">
.card__section-title.section-title
  h3.section-title__title {{ title }}
  v-divider
</template>

<script>
export default {
  name: 'CardSectionTitle',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.section-title {
  display: flex;
  align-items: center;
  margin: 24px 24px 16px 0;

  &__title {
    color: var(--v-title-base);
    font-weight: 500;
    margin-right: 16px;
  }
}
</style>
