<template lang="pug">
v-row.board__column.pt-9
  v-col.col-12.mt-0
    BoardColumns(
      v-if='currentBoard'
      :columns='sortBoardLists'
      :listAdmins='currentBoard.admins'
      @openSettingsModal='openSettingsModal')

  v-dialog(
    v-model='vIsOpenSettingsModal'
    max-width='500'
    overlay-color='base')
    v-card.base
      v-card-title.dialog__title-wrapper
        h4.dialog__title.title--text {{ $lang.settingsOfList }} "{{ listName }}"
      v-divider.mx-6.mb-5
      v-card-text(v-if='activeListId')
        h4.dialog__title.primary--text {{ $lang.main }}:
        .mb-3
          v-select.dialog__select.mt-0(
            v-model='role'
            :label='$lang.listRole'
            hide-details
            :items='listRoles'
            item-value='id'
            item-text='text')
        span.dialog__tip {{ listRoleTip }}

        v-checkbox.dialog__checkbox(
          v-model='showInProgress'
          :label='$lang.showProgress'
          hide-details)
      .btn-wrapper.d-flex.pb-6.pr-6.pl-6.flex-wrap.flex-sm-nowrap
        button.setting__button-white.mb-3.mb-sm-0.button(
          @click.stop='closeSettingsModal()'
          :block='isMobile') {{ $lang.cancel }}
        button.setting__button-blue.mb-3.mb-sm-0.button(
          @click.stop='setSettingsList(role, showInProgress)'
          :block='isMobile') {{ $lang.apply }}
</template>

<script>
import objectPath from 'object-path'
import { createNamespacedHelpers, mapGetters } from 'vuex'
const { mapState: boardsMapState } = createNamespacedHelpers('boards')

const BoardColumns = () =>
  import(
    /* webpackChunkName: "BoardColumns" */ /* webpackMode: "eager" */ '@/views/Boards/BoardColumns.vue'
  )

export default {
  name: 'Board',
  components: { BoardColumns },
  data() {
    return {
      checkProgressInterval: null,
      isLoadedBoard: true,
      activeListId: null,
      isOpenListSettingsModal: false,
      listRoles: [
        {
          id: 0,
          text: this.$lang.simpleList,
          description: this.$lang.simpleList,
        },
        {
          id: 1,
          text: this.$lang.reliseList,
          description: this.$lang.reliseListMeaning,
        },
        {
          id: 2,
          text: this.$lang.backlog,
          description: this.$lang.backlogListMeaning,
        },
        {
          id: 3,
          text: this.$lang.sprintList,
          description: this.$lang.autosprintListMeaning,
        },
        {
          id: 4,
          text: this.$lang.codedList,
          description: this.$lang.codedListMeaning,
        },
        {
          id: 5,
          text: this.$lang.productionList,
          description: this.$lang.productionListMeaning,
        },
      ],

      role: undefined,
      showInProgress: false,
    }
  },
  computed: {
    ...mapGetters(['isMobile', 'userId', 'isError']),
    ...boardsMapState(['currentBoard', 'boardProgress']),
    vIsOpenSettingsModal: {
      get() {
        return this.isOpenListSettingsModal
      },
      set(value) {
        !value && this.closeSettingsModal()
      },
    },
    vListRole() {
      return (this.activeList && this.activeList.role) || 0
    },
    vShowProgress() {
      return (this.activeList && this.activeList.show_in_progress) || false
    },
    listRoleTip() {
      const role = () => {
        if (this.role !== undefined) {
          return this.role
        } else {
          return (this.activeList && this.activeList.role) || 0
        }
      }

      return this.listRoles[role()].description
    },
    activeList() {
      return objectPath
        .get(this.currentBoard, 'board_lists', [])
        .find((l) => l.id === this.activeListId)
    },

    listName() {
      return this.activeList && this.activeList.name
    },
    isAdmin() {
      return this.currentBoard.admins.map((a) => a.id).includes(this.userId)
    },
    sortBoardLists() {
      return { ...this.currentBoard }.board_lists
        .sort(
          (a, b) =>
            a.closed &&
            a.role === 1 &&
            b.closed &&
            b.role === 1 &&
            b.sprint_number - a.sprint_number
        )
        .sort(
          (a, b) =>
            a.closed && a.role === 0 && b.closed && b.role === 0 && b.id - a.id
        )
    },
  },
  watch: {
    role(next, prev) {
      if ((prev || prev === 0) && next === 1) {
        this.showInProgress = true
      } else {
        this.showInProgress = this.vShowProgress
      }
    },
  },
  created() {
    this.$store
      .dispatch('boards/getBoard', this.$route.params.id)
      .then(() => {
        if (this.isAdmin) {
          this.$store.dispatch('boards/getBoardProgress', this.$route.params.id)

          this.checkProgressInterval = setInterval(this.requestProgress, 15000)
        }
      })
      .catch(() => clearInterval(this.checkProgressInterval))
      .finally(() => (this.isLoadedBoards = true))
  },
  destroyed() {
    this.$store.commit('boards/SET_TO_ZERO_BOARD')
    this.$store.commit('boards/SET_TO_ZERO_BOARD_LISTS')
    this.checkProgressInterval && clearInterval(this.checkProgressInterval)
  },
  methods: {
    openSettingsModal(listId) {
      this.activeListId = listId
      this.isOpenListSettingsModal = true
      this.role = this.vListRole
      this.showInProgress = this.vShowProgress
    },
    closeSettingsModal() {
      this.isOpenListSettingsModal = false
      this.activeListId = null
      this.role = undefined
      this.showInProgress = undefined
    },
    setSettingsList(role, showInProgress) {
      this.$store
        .dispatch('boards/setSettingsBoardList', {
          listId: this.activeList.id,
          role,
          showInProgress,
          boardId: this.$route.params.id,
        })
        .then(() => {
          this.closeSettingsModal()
        })
    },
    requestProgress() {
      !this.isError
        ? this.$store.dispatch('boards/getBoardProgress', this.$route.params.id)
        : clearInterval(this.checkProgressInterval)
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-wrapper {
  & .button {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.link-wrapper {
  margin-left: 4px;
  text-decoration: none;

  & .link-icon {
    font-size: 20px;
  }
}

.dialog {
  &__title,
  &__subtitle {
    font-family: var(--font-raleway);
    font-size: 18px;
  }
  &__title {
    font-weight: 600;
  }

  &__subtitle {
    font-weight: 400;
  }

  &__tip {
    font-size: 12px;
    font-weight: 500;
  }
}
</style>

<style lang="scss">
.dialog__select .v-select__selection,
.dialog__select .v-select__selection--comma,
.dialog__select.v-select.v-text-field input {
  font-family: var(--font-raleway);
  color: var(--v-darkGrey-base) !important;
  font-size: 20px;
}

.dialog__select .v-select__selection--comma {
  padding-bottom: 3px;
}

.dialog__checkbox.v-input--selection-controls .v-input__slot > .v-label {
  font-size: 12px;
  font-weight: 500;
  color: var(--v-title-base);
}
</style>
