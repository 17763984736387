<template lang="pug">
v-row.card-header
  v-col.col-12
    v-row
      v-col.card-header__card.col-12.col-md-10.title--text
        article.card-header__card-item.mr-md-4
          h2.card-header__board-name {{ card.board_name }}
          h3.card-header__card-title {{ card.name }}
          LinkBox(
            :task='card'
            :isCardLinkVisible='false')
      v-col.col-12.col-md-2(v-if='!isClient')
        .mt-2.mt-sm-0.mr-6.d-flex.align-center.justify-end
          AvatarGroup.justify-end(
            :isTooltip='true'
            :size='32'
            :margin='"0 0 6px 6px"'
            :users='users')
      v-col.card-header__labels.col-12
        CardLabels(:card='card')
</template>

<script>
import { mapGetters } from 'vuex'

const AvatarGroup = () =>
  import(
    /* webpackChunkName: "AvatarGroup" */ /* webpackMode: "eager" */ '@/components/AvatarGroup.vue'
  )
const LinkBox = () =>
  import(
    /* webpackChunkName: "LinkBox" */ /* webpackMode: "eager" */ '@/components/LinkBox.vue'
  )
const CardLabels = () =>
  import(
    /* webpackChunkName: "CardLabels" */ /* webpackMode: "eager" */ '@/components/CardLabels.vue'
  )

export default {
  name: 'CardHeader',
  components: {
    AvatarGroup,
    LinkBox,
    CardLabels,
  },
  props: {
    card: {
      type: Object,
      default: () => {},
    },
    users: {
      type: Array,
      default: () => {},
    },
    isClient: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isTooltipVisible: false,
  }),
  computed: {
    ...mapGetters(['isMobile', 'isTablet']),
  },
}
</script>

<style lang="scss" scoped>
.card-header {
  margin-bottom: 8px;

  &__card {
    @media (max-width: 960px) {
      order: 2;
    }
  }

  &__board-name {
    @include card-title;
    margin-bottom: 12px;
  }

  &__card-title {
    display: inline;
    @include item-title;
    line-height: 1.2;
    margin-right: 8px;

    @media (pointer: coarse) {
      display: inline-block;
    }
  }

  &__labels {
    @media (max-width: 960px) {
      order: 3;
    }
  }
}
</style>

<style lang="scss">
.main__header-container
  .custom-header
  .custom-header__button.custom-header__button {
  @media (max-width: 600px) {
    width: 0;
  }
}
</style>
