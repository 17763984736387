import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

import dom from '@/store/modules/dom'
import profile from '@/store/modules/profile'
import boards from '@/store/modules/boards'
import options from '@/store/modules/options'
import reports from '@/store/modules/reports'
import sprints from '@/store/modules/sprints'
import notifications from '@/store/modules/notifications'
import members from '@/store/modules/members'
import dashboard from '@/store/modules/dashboard'
import sync from '@/store/modules/sync'
import git from '@/store/modules/git'
import charts from '@/store/modules/charts'
import cards from '@/store/modules/cards'

const syncState = createPersistedState({
  key: 'sync',
  paths: ['sync'],
})

export default new Vuex.Store({
  modules: {
    dom,
    profile,
    boards,
    options,
    reports,
    sprints,
    notifications,
    members,
    dashboard,
    sync,
    git,
    charts,
    cards,
  },
  plugins: [syncState],
})
