<template lang="pug">
div(v-scroll='handleScroll')
  .home
    main.home__main
      section.home__section.hero
        .section__container
          .hero__header
            router-link.header__link.text-none(
              :to='{ name: "home" }'
              :aria-label='$lang.linkGoToHome')
              IconBase(
                icon-name='logo'
                :height='isMobile ? 13 : 24'
                :width='isMobile ? 53 : 95'
                icon-color='var(--grey-dark)')
                LogotypeUni
            router-link(
              v-if='!accessToken'
              :to='{ name: "login" }')
              button.hero__button.hero__button--strict.hero__button--top {{ $lang.login }}
            router-link(
              v-else-if='!isUserStatus'
              :to='{ name: "options" }')
              button.hero__button.hero__button--flexible.hero__button--top {{ $lang.goToSettings }}
            router-link(
              v-else
              :to='{ name: "boards" }')
              button.hero__button.hero__button--flexible.hero__button--top {{ $lang.gotoBoards }}
          .hero__content
            h1.hero__title
              div {{ $lang.heroHeaderOne }}
              span.hero__title--color {{ $lang.heroHeaderTwo }}
              br(v-if='isMobile')
              span {{ $lang.heroHeaderThree }}
              div {{ $lang.heroHeaderFour }}
            ul.hero__text
              li.hero__text-item(v-for='text in $lang.heroText') {{ text }}
            router-link(
              v-if='!accessToken'
              :to='{ name: "registration" }')
              button.hero__button.hero__button--strict.hero__button--blue {{ $lang.registration }}
            router-link(
              v-if='!accessToken'
              :to='{ name: "login" }')
              button.hero__button.hero__button--strict.hero__button--white {{ $lang.entrance }}
      section.home__section.data
        .section__container.section__container--data
          h2.data__title {{ $lang.dataTitle }}
          .data__text-wrapper
            .data__text-item
              LensIcon
              span.data__text {{ $lang.dataText[0] }}
            .data__text-item
              ReportIcon
              span.data__text {{ $lang.dataText[1] }}
            .data__text-item
              StatIcon
              span.data__text {{ $lang.dataText[2] }}
      section.home__section.functions
        .section__container.section__container--functions
          h2.functions__title {{ $lang.functionsTitle }}
          .functions__wrapper
            .functions__tooltip {{ $lang.functionsTooltip }}
            .functions__item.functions__item--active
              img.functions__icon(
                alt='bell'
                width='24'
                height='24'
                :src='require("@/assets/img/svg/bell.svg")')
              span.functions__text {{ $lang.functionsMenu[0] }}
            .functions__item
              img.functions__icon(
                alt='commit'
                width='24'
                height='24'
                :src='require("@/assets/img/svg/commit.svg")')
              span.functions__text {{ $lang.functionsMenu[1] }}
            .functions__item
              img.functions__icon(
                alt='percent'
                width='24'
                height='24'
                :src='require("@/assets/img/svg/percent.svg")')
              span.functions__text {{ $lang.functionsMenu[2] }}
            .functions__item
              img.functions__icon(
                alt='clock'
                width='24'
                height='24'
                :src='require("@/assets/img/svg/clock.svg")')
              span.functions__text {{ $lang.functionsMenu[3] }}
            .functions__item
              img.functions__icon(
                alt='history'
                width='21'
                height='24'
                :src='require("@/assets/img/svg/history.svg")')
              span.functions__text {{ $lang.functionsMenu[4] }}
            .functions__item
              img.functions__icon(
                alt='reports'
                width='19'
                height='24'
                :src='require("@/assets/img/svg/reports.svg")')
              span.functions__text {{ $lang.functionsMenu[5] }}
          .functions__content
            picture.functions__image
              source.lazy__image(
                v-for='image in imagesMapped.desktop'
                :key='image.src'
                :data-srcset='require(`@/assets/img/home/${image.src}`)'
                :type='image.type')
              img.lazy__image(
                alt='desktop'
                :data-src='require("@/assets/img/home/desktop.png")')
            h3.functions__subtitle {{ $lang.functionsSubtitle }}
            ul.functions__subtext.functions__text-wrapper
              li.functions__subtext-item(v-for='text in $lang.functionsText') {{ text }}
      section.home__section.features
        .section__container.section__container--features
          h2.features__title
            span {{ $lang.featuresTitle[0] }}
            br(v-if='isMobile')
            span {{ $lang.featuresTitle[1] }}
          picture
            source.lazy__image(
              v-for='image in imagesMapped.features'
              :key='image.src'
              media='(min-width: 600px)'
              :data-srcset='require(`@/assets/img/home/${image.src}`)')
            source.lazy__image(
              v-for='image in imagesMapped.featuresMb'
              :key='image.src'
              :data-srcset='require(`@/assets/img/home/${image.src}`)')
            img.features__image.lazy__image(
              alt='screen'
              :data-src='isMobile ? require("@/assets/img/home/features-mb.png") : require("@/assets/img/home/features.png")')
          .features__content
            .features__subtitle-wrapper
              h3.features__subtitle {{ $lang.featuresSubtitle[0] }}
              h3.features__subtitle.features__subtitle--blue {{ $lang.featuresSubtitle[1] }}
            .features__text {{ $lang.featuresText }}
      section.home__section.analytics
        .section__container.section__container--analytics
          h2.analytics__title {{ $lang.analyticsTitle }}
          span.analytics__text {{ $lang.analyticsText }}
      section.home__section.stat
        .section__container.section__container--stat
          h2.stat__title {{ $lang.statTitle }}
          span.stat__text {{ $lang.statText }}
      section.home__section.reports
        .section__container.section__container--reports
          h2.reports__title {{ $lang.reportsTitle }}
          span.reports__text {{ $lang.reportsText }}
      section.home__section.mobile
        .section__container.section__container--mobile
          h2.mobile__title {{ $lang.mobileTitle }}
          .mobile__text-wrapper
            span.mobile__text {{ $lang.mobileText[0] }}
            br(v-if='!isMobile')
            span.mobile__text {{ $lang.mobileText[1] }}
            br(v-if='!isMobile')
            span.mobile__text {{ $lang.mobileText[2] }}
          .mobile__qr-wrapper
            picture
              source.mobile__qr.lazy__image(
                v-for='image in imagesMapped.qrq'
                :key='image.src'
                :data-srcset='require(`@/assets/img/home/${image.src}`)')
              img.mobile__qr.lazy__image(
                alt='qr code'
                :data-src='require("@/assets/img/home/qrq.png")')
            img.mobile__google-play(
              alt='Google Play logo'
              :src='require("@/assets/img/svg/googlePlay.svg")')
          .mobile__screen-wrapper
            picture
              source.mobile__screen.lazy__image(
                v-for='image in imagesMapped.screenOne'
                :key='image.src'
                :data-srcset='require(`@/assets/img/home/${image.src}`)')
              img.mobile__screen.lazy__image(
                alt='screen'
                :data-src='require("@/assets/img/home/screen-1.png")'
                width='326px'
                height='585px')
            picture
              source.mobile__screen.lazy__image(
                v-for='image in imagesMapped.screenTwo'
                :key='image.src'
                :data-srcset='require(`@/assets/img/home/${image.src}`)')
              img.mobile__screen.lazy__image(
                alt='screen'
                :data-src='require("@/assets/img/home/screen-2.png")'
                width='326px'
                height='585px')
            picture
              source.mobile__screen.lazy__image(
                v-for='image in imagesMapped.screenThree'
                :key='image.src'
                :data-srcset='require(`@/assets/img/home/${image.src}`)')
              img.mobile__screen.lazy__image(
                alt='screen'
                :data-src='require("@/assets/img/home/screen-3.png")'
                width='326px'
                height='585px')
          a.mobile__button.hero__button.hero__button--blue(href='#') {{ $lang.mobileDownload }}
          img.mobile__google(
            alt='Google Play logo'
            :src='require("@/assets/img/svg/googlePlay.svg")'
            width='100px')
      section.home__section.pro
        .section__container.section__container--pro
          h2.pro__title
            span.pro__title--color {{ $lang.proTitle[0] }}
            span {{ $lang.proTitle[1] }}
          .pro__text-wrapper
            span.pro__text {{ $lang.proText[0] }}
            br(v-if='isMobile')
            span.pro__text {{ $lang.proText[1] }}
            br(v-if='!isMobile')
            span.pro__text {{ $lang.proText[2] }}
    footer.home__footer.footer
      .section__container.section__container--footer
        div
          span.footer__copyright.footer__text {{ new Date().getFullYear() }} —
          span.footer__copyright.footer__text TrStat
        img.footer__logo(
          :src='require("@/assets/img/logo-nk.svg")'
          alt='logo')

    ButtonToTop.button-to-top(
      :isShow='isShowToTop'
      v-if='!isMobile')
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import LensIcon from '@/assets/img/icons/LensIcon.vue'
import ReportIcon from '@/assets/img/icons/ReportSimpleIcon.vue'
import StatIcon from '@/assets/img/icons/StatIcon.vue'
import { imagesMapped } from '@/assets/data/homepage'

const ButtonToTop = () =>
  import(
    /* webpackChunkName: "ButtonToTop" */ /* webpackMode: "eager" */ '@/components/ButtonToTop.vue'
  )

export default {
  name: 'Home',
  components: {
    ButtonToTop,
    LensIcon,
    ReportIcon,
    StatIcon,
  },
  data() {
    return {
      isShowToTop: false,
      timerID: null,
      imagesMapped,
    }
  },
  computed: {
    ...mapGetters([
      'isUserStatus',
      'accessToken',
      'isClient',
      'isMobile',
      'isTablet',
    ]),
  },
  created() {
    this.getToken()
  },
  mounted() {
    const lazyImages = document.querySelectorAll('.lazy__image')
    this.setLazyImageLoading(lazyImages)
  },
  destroyed() {
    clearTimeout(this.timerID)
  },
  methods: {
    ...mapActions(['loginObtain']),
    ...mapMutations(['SET_ACCESS_TOKEN', 'SET_TOKENS']),
    getToken() {
      const data = JSON.parse(localStorage.getItem('token-data'))
      // this.SET_TOKENS(data)

      if (!data) {
        const token = this.getCookie('access_token')
        if (token) {
          this.SET_ACCESS_TOKEN(token)
          this.timerID = setTimeout(() => {
            this.loginObtain()
          }, 10)
        }
      } else {
        this.SET_TOKENS(data)
      }
    },
    handleScroll() {
      if (window.scrollY > 500) {
        this.isShowToTop = true
      } else if (window.scrollY < 50) {
        this.isShowToTop = false
      }
    },
    getCookie(name) {
      let matches = document.cookie.match(
        new RegExp(
          '(?:^|; )' +
            name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
            '=([^;]*)'
        )
      )
      return matches ? decodeURIComponent(matches[1]) : undefined
    },
    setLazyImageLoading(lazyImages) {
      const config = { root: null, rootMargin: '0px', threshold: 0 }

      if ('IntersectionObserver' in window) {
        let lazyImageObserver = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              let lazyImage = entry.target
              if (lazyImage.dataset.src) {
                lazyImage.src = lazyImage.dataset.src
              }
              if (lazyImage.dataset.srcset) {
                lazyImage.srcset = lazyImage.dataset.srcset
              }
              lazyImage.classList.remove('lazy__image')
              lazyImageObserver.unobserve(lazyImage)
            }
          }, config)
        })

        lazyImages.forEach((lazyImage) => lazyImageObserver.observe(lazyImage))
      } else {
        lazyImages.forEach((image) => {
          image.src = image.dataset.src
          image.classList.remove('lazy__image')
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.home {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  background: var(--base);
}

.home__main,
.home__footer {
  grid-column: 1 / 2;
}

.home__section {
  display: grid;
  grid-template-columns: 1fr minmax(320px, 1440px) 1fr;
  height: 1024px;
  margin-bottom: 44px;

  @media (max-width: $mobile) {
    margin-bottom: 40px;
  }
}

.functions,
.mobile {
  background: var(--blue);
}

.features,
.stat,
.pro {
  background: var(--grey-light);
}

.button-to-top {
  position: fixed;
  z-index: 4;
  bottom: 28px;
  right: 28px;
}

.section__container {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  padding-left: 24px;
  padding-right: 24px;

  @media (max-width: $mobile) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

[class*='__title'],
[class*='__text'],
[class*='__subtitle'],
[class*='__subtext'] {
  font-family: var(--font-raleway);
}

[class*='__title'] {
  font-weight: bold;
}

/* hero section styles */
.hero {
  background: var(--blue-light);

  @media (max-width: $mobile) {
    height: 801px;
  }
}

.hero__header {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;

  @media (max-width: $mobile) {
    padding-top: 16px;
  }
}

.hero__button {
  // width: 215px;
  width: var(--width);
  height: 40px;
  background: var(--bcg);
  color: var(--color);
  border: 2px solid var(--border);
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  transition: all 0.3s ease-out;

  &:hover {
    background: var(--color);
    color: var(--bcg);
    border-color: var(--border-hover);
  }

  &:disabled {
    color: var(--grey);
    border: 1px solid var(--grey);
    background: var(--bcg);
    cursor: auto;
  }

  @media (max-width: $mobile) {
    width: var(--width);
    font-size: 13px;
  }
}

.hero__button--strict {
  --width: 215px;

  @media (max-width: $mobile) {
    --width: 104px;
  }
}

.hero__button--flexible {
  padding-left: 16px;
  padding-right: 16px;
}

.hero__button--top {
  --bcg: var(--grey-lightest);
  --color: var(--main);
  --border: var(--main);
  --border-hover: var(--main);
}

.hero__button--blue {
  --bcg: var(--main);
  --color: var(--base);
  --border: var(--main);
  --border-hover: var(--main);

  margin-right: 20px;

  @media (max-width: $mobile) {
    --width: 156px;
    margin-right: 15px;
    margin-bottom: 4px;
  }
}

.hero__button--white {
  --bcg: var(--base);
  --color: var(--main);
  --border: var(--main);
  --border-hover: var(--main);

  @media (max-width: $mobile) {
    --width: 156px;
  }
}

.hero__content {
  width: 100%;
  height: calc(100% - 71px);
  padding: 237px 116px 190px 88px;
  background-image: url('../../assets/img/home/hero.png');
  background-position: top 50% right;
  background-repeat: no-repeat;

  @media (max-width: $tablet) {
    height: calc(100% - 57px);
    padding: 334px 0;
    background-image: url('../../assets/img/home/hero-mb.png');
    background-position: top 34px center;
  }
}

.hero__content > * {
  margin-bottom: 69px;

  @media (max-width: $mobile) {
    margin-bottom: 32px;
  }
}

.hero__title {
  font-weight: 800;
  font-size: 52px;
  line-height: 1.3;
  // letter-spacing: 0.38px;
  color: var(--title);

  @media (max-width: $mobile) {
    font-size: 28px;
  }
}

.hero__title--color {
  color: var(--main);
}

.hero__text {
  // font-family: var(--font-raleway);
  font-size: 20px;
  line-height: 0.65;
  color: var(--title);
  margin-left: -24px;

  @media (max-width: $mobile) {
    font-size: 16px;
    line-height: 0.8;
    margin-left: 0;
  }
}

.hero__text > li:not(:last-child) {
  margin-bottom: 12px;
}

/* data section styles */

.data {
  background-image: url('../../assets/img/home/data.png');
  background-position: top 50% left;
  background-repeat: no-repeat;

  @media (max-width: $tablet) {
    background-image: url('../../assets/img/home/data-mb.png');
    background-position: top 50% left;
  }

  @media (max-width: $mobile) {
    background-position: top left;
    height: 847px;
  }
}

.section__container--data {
  display: grid;
  grid-template-columns: repeat(12, calc((100% - 11 * 10px) / 12));
  grid-template-rows: 304px 205px 1fr;
  column-gap: 10px;
  color: var(--grey-dark);

  @media (max-width: $mobile) {
    grid-template-columns: repeat(4, calc((100% - 3 * 16px) / 4));
    grid-template-rows: 420px 172px 1fr;
    column-gap: 16px;
  }
}

.data__title {
  grid-column: 8 / 12;
  grid-row: 2 / 3;
  font-size: 40px;
  font-weight: 800;

  @media (max-width: $tablet) {
    grid-column: 8 / 12;
    font-size: 26px;
  }

  @media (max-width: $mobile) {
    grid-column: 1 / 5;
    font-size: 26px;
  }
}

.data__text-wrapper {
  grid-column: 8 / 11;
  grid-row: 3 / 4;

  @media (max-width: $mobile) {
    grid-column: 1 / 5;
  }
}

.data__text-item {
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  @media (max-width: $mobile) {
    margin-bottom: 20px;
  }
}

.data__text {
  font-size: 20px;
  margin-left: 28px;
  line-height: 1.2;
}

/* functions section styles */

.functions {
  background-image: url('../../assets/img/home/ellipse.png');
  background-position: bottom right;
  background-repeat: no-repeat;

  @media (max-width: $tablet) {
    background-image: none;
    height: auto;
  }
}

.section__container--functions {
  padding-top: 132px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--grey-lightest);

  @media (max-width: $tablet) {
    padding-top: 44px;
  }
}

.functions__title {
  font-size: 48px;
  margin-bottom: 45px;
  line-height: 1;

  @media (max-width: $tablet) {
    font-size: 30px;
    margin-bottom: 30px;
  }
}

.functions__wrapper {
  position: relative;
  display: flex;
}

.functions__item {
  display: flex;
  font-size: 20px;
  font-weight: 500;

  @media (max-width: $tablet) {
    padding: 5px;
    border: 1px solid var(--base);
    border-radius: 3px;
  }
}

.functions__item--active {
  @media (max-width: $tablet) {
    background: var(--main-light);
    border: 1px solid var(--main-light);
  }
}

.functions__item + .functions__item {
  margin-left: 24px;
}

.functions__text {
  margin-left: 8px;

  @media (max-width: $tablet) {
    display: none;
  }
}

.functions__item--active .functions__text {
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;
}

.functions__tooltip {
  position: absolute;
  top: 57px;
  left: -90px;
  color: var(--base);
  background: var(--main);
  border-radius: 10px;
  font-size: 17px;
  font-weight: 400;
  padding: 11px 16px;

  @media (max-width: 1380px) {
    left: -50px;
  }

  @media (max-width: $tablet) {
    font-size: 6px;
    left: 0;
    border-radius: 5px;
    padding: 8px 14px;
  }
}

.functions__tooltip::after {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  content: '';
  width: 0;
  height: 0;
  border-bottom: 25px solid var(--main);
  border-right: 24px solid transparent;

  @media (max-width: 1380px) {
    left: 39%;
  }

  @media (max-width: $tablet) {
    top: -10px;
    left: 20px;
    border-bottom: 10px solid var(--main);
    border-right: 12px solid transparent;
  }
}

.functions__content {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(12, calc((100% - 11 * 10px) / 12));
  grid-template-rows: 106px 83px 123px 1fr;
  column-gap: 10px;

  @media (max-width: $tablet) {
    display: flex;
    flex-direction: column;
    padding-bottom: 48px;
  }
}

.functions__image {
  grid-column: 2 / 8;
  grid-row: 2 / 4;

  @media (max-width: $tablet) {
    margin-top: 78px;
    margin-bottom: 40px;
    width: 50%;
    align-self: center;
  }

  @media (max-width: $mobile) {
    width: 100%;
    margin-top: 78px;
    margin-bottom: 40px;
  }
}

.functions__subtitle {
  grid-column: 9 / 11;
  grid-row: 3 / 4;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;

  @media (max-width: $tablet) {
    font-size: 20px;
    margin-bottom: 28px;
  }
}

.functions__subtext {
  grid-column: 9 / 12;
  grid-row: 4 / 5;
  margin-left: -24px;

  @media (max-width: $tablet) {
    margin-left: 0;
  }
}

.functions__subtext-item {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 24px;

  @media (max-width: $mobile) {
    font-size: 16px;
  }
}

/* stat section styles */

.features {
  background-image: url('../../assets/img/home/features.png');
  background-position: bottom 92px right;
  background-repeat: no-repeat;

  @media (max-width: $tablet) {
    background-image: none;
    height: auto;
  }

  @media (max-width: $mobile) {
    background-image: none;
    height: auto;
  }
}

.section__container--features {
  padding-top: 132px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: $tablet) {
    padding-top: 64px;
    position: relative;
  }
}

.features__title {
  font-size: 48px;
  font-weight: bold;
  color: var(--main-light);

  @media (max-width: $tablet) {
    font-size: 30px;
    text-align: center;
    margin-bottom: 25px;
  }
}

.features__content {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(12, calc((100% - 11 * 10px) / 12));
  grid-template-rows: 200px 225px 1fr;
  column-gap: 10px;

  @media (max-width: $tablet) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 48px;
    margin-top: 35vw;
  }

  @media (max-width: $mobile) {
    margin-top: 95vw;
    padding-bottom: 53px;
  }
}

.features__image {
  display: none;

  @media (max-width: $tablet) {
    display: block;
    position: absolute;
    top: 150px;
    right: 0;
    width: 50%;
  }

  @media (max-width: $mobile) {
    display: block;
    position: absolute;
    top: 200px;
    width: 100%;
  }
}

.features__subtitle-wrapper {
  grid-column: 2 / 5;
  grid-row: 2 / 3;
}

.features__subtitle {
  font-size: 48px;
  font-weight: bold;
  line-height: 1.3;
  color: var(--grey-dark);

  @media (max-width: $tablet) {
    font-size: 30px;
    max-width: 60%;
  }
}

.features__subtitle--blue {
  color: var(--main-light);
}

.features__text {
  grid-column: 2 / 5;
  grid-row: 3 / 4;
  font-size: 20px;
  line-height: 1.4;
  color: var(--text);
  letter-spacing: 0.38px;

  @media (max-width: $tablet) {
    margin-top: 24px;
    font-size: 16px;
  }
}

/* analitics section styles */

.analytics {
  @media (max-width: $mobile) {
    height: auto;
  }
}

.section__container--analytics {
  display: grid;
  grid-template-columns: repeat(12, calc((100% - 11 * 10px) / 12));
  grid-template-rows: 390px 105px 1fr;
  column-gap: 10px;
  color: var(--grey-dark);
  background-image: url('../../assets/img/home/analytics.png');
  background-position: top 50% left 24px;
  background-repeat: no-repeat;

  @media (max-width: $tablet) {
    background-image: url('../../assets/img/home/analytics-mb.png');
    background-position: top 50% left 24px;
  }

  @media (max-width: $mobile) {
    grid-template-columns: repeat(4, calc((100% - 3 * 16px) / 4));
    grid-template-rows: 460px 67px 1fr;
    column-gap: 16px;
    background-position: top 130px center;
    padding-bottom: 213px;
  }
}

.analytics__title {
  grid-column: 9/ 12;
  grid-row: 2 / 3;
  font-size: 48px;
  font-weight: bold;
  color: var(--grey-dark);

  @media (max-width: $tablet) {
    grid-column: 9 / 12;
    font-size: 30px;
  }

  @media (max-width: $mobile) {
    grid-column: 1 / 5;
    font-size: 30px;
  }
}

.analytics__text {
  grid-column: 9 / 12;
  grid-row: 3 / 4;
  color: var(--text);
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.38px;

  @media (max-width: $mobile) {
    grid-column: 1 / 5;
    font-size: 16px;
  }
}

/* stat section styles */

.stat {
  @media (max-width: $mobile) {
    height: auto;
  }
}

.section__container--stat {
  display: grid;
  grid-template-columns: repeat(12, calc((100% - 11 * 10px) / 12));
  grid-template-rows: 390px 105px 1fr;
  column-gap: 10px;
  color: var(--grey-dark);
  background-image: url('../../assets/img/home/stat.png');
  background-position: top 50% right 24px;
  background-repeat: no-repeat;

  @media (max-width: $tablet) {
    background-image: url('../../assets/img/home/stat-mb.png');
    background-position: top 50% right 24px;
  }

  @media (max-width: $mobile) {
    grid-template-columns: repeat(4, calc((100% - 3 * 16px) / 4));
    grid-template-rows: 472px 67px 1fr;
    column-gap: 16px;
    background-position: top 104px right;
    padding-bottom: 130px;
  }
}

.stat__title {
  grid-column: 2/ 5;
  grid-row: 2 / 3;
  font-size: 48px;
  font-weight: bold;
  color: var(--grey-dark);

  @media (max-width: $tablet) {
    grid-column: 2 / 5;
    font-size: 30px;
  }

  @media (max-width: $mobile) {
    grid-column: 1 / 5;
    font-size: 30px;
  }
}

.stat__text {
  grid-column: 2 / 5;
  grid-row: 3 / 4;
  color: var(--text);
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.38px;

  @media (max-width: $mobile) {
    grid-column: 1 / 5;
    font-size: 16px;
  }
}

/* reports section styles */

.reports {
  background-image: url('../../assets/img/home/reports.png');
  background-position: top 50% left;
  background-repeat: no-repeat;

  @media (max-width: $tablet) {
    background-image: url('../../assets/img/home/reports-mb.png');
    background-position: top 50% left;
  }

  @media (max-width: $mobile) {
    height: auto;
    background-position: top 130px center;
  }
}

.section__container--reports {
  display: grid;
  grid-template-columns: repeat(12, calc((100% - 11 * 10px) / 12));
  grid-template-rows: 390px 105px 1fr;
  column-gap: 10px;
  color: var(--grey-dark);

  @media (max-width: $mobile) {
    grid-template-columns: repeat(4, calc((100% - 3 * 16px) / 4));
    grid-template-rows: 470px 67px 1fr;
    column-gap: 16px;
    padding-bottom: 160px;
  }
}

.reports__title {
  grid-column: 9/ 12;
  grid-row: 2 / 3;
  font-size: 48px;
  font-weight: bold;

  @media (max-width: $tablet) {
    grid-column: 9 / 12;
    font-size: 30px;
  }

  @media (max-width: $mobile) {
    grid-column: 1 / 5;
    font-size: 30px;
  }
}

.reports__text {
  grid-column: 9 / 12;
  grid-row: 3 / 4;
  color: var(--text);
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.38px;

  @media (max-width: $mobile) {
    grid-column: 1 / 5;
    font-size: 16px;
  }
}

/* mobile section styles */

.mobile {
  background-image: url('../../assets/img/home/mobile-group.png');
  background-position: bottom right;
  background-repeat: no-repeat;

  @media (max-width: $tablet) {
    background-image: url('../../assets/img/home/mobile-group-tb.png');
    background-position: bottom right;
  }

  @media (max-width: 1000px) {
    background-image: none;
  }

  @media (max-width: $mobile) {
    height: auto;
  }
}

.section__container--mobile {
  display: grid;
  grid-template-columns: repeat(12, calc((100% - 11 * 10px) / 12));
  grid-template-rows: 270px 150px 150px 1fr;
  column-gap: 10px;
  color: var(--base);

  @media (max-width: $mobile) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 85px;
    padding-bottom: 85px;
  }
}

.mobile__title {
  grid-column: 2/ 5;
  grid-row: 2 / 3;
  font-size: 48px;
  font-weight: bold;
  line-height: 1.3;

  @media (max-width: $tablet) {
    grid-column: 2 / 5;
    font-size: 30px;
  }

  @media (max-width: 1000px) {
    grid-column: 2 / 7;
  }

  @media (max-width: $mobile) {
    margin-bottom: 24px;
  }
}

.mobile__text-wrapper {
  grid-column: 2 / 5;
  grid-row: 3 / 4;

  @media (max-width: 1000px) {
    grid-column: 2 / 8;
  }

  @media (max-width: $mobile) {
    margin-bottom: 54px;
  }
}

.mobile__text {
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.38px;

  @media (max-width: $mobile) {
    font-size: 16px;
  }
}

.mobile__qr-wrapper {
  grid-column: 2 / 4;
  grid-row: 4 / 5;
  max-width: 216px;

  @media (max-width: $mobile) {
    display: none;
  }
}

.mobile__google-play {
  margin: 14px auto 0;
}

.mobile__screen-wrapper {
  display: none;

  @media (max-width: $mobile) {
    display: block;
    margin-bottom: 62px;
  }
}

.mobile__screen + .mobile__screen {
  margin-top: 35px;
}

.mobile__button {
  display: none;

  @media (max-width: $mobile) {
    display: block;
    text-align: center;
    line-height: 36px;
    text-decoration: none;
    margin-right: 0;
    margin-bottom: 19px;
  }
}

.mobile__google {
  display: none;

  @media (max-width: $mobile) {
    display: block;
  }
}

/* pro section styles */

.pro {
  @media (max-width: $mobile) {
    height: auto;
    margin-bottom: 0;
  }
}

.section__container--pro {
  display: grid;
  grid-template-columns: repeat(12, calc((100% - 11 * 10px) / 12));
  grid-template-rows: 315px 91px 1fr;
  column-gap: 10px;
  color: var(--grey-dark);
  background-image: url('../../assets/img/home/pro.png');
  background-position: top 70% right;
  background-repeat: no-repeat;

  @media (max-width: $tablet) {
    background-image: url('../../assets/img/home/pro-mb.png');
    background-position: top 50% right 24px;
  }

  @media (max-width: $mobile) {
    display: flex;
    flex-direction: column;
    background-position: top 175px right;
    padding-bottom: 100px;
  }
}

.pro__title {
  grid-column: 2/ 5;
  grid-row: 2 / 3;
  font-size: 48px;
  font-weight: bold;

  @media (max-width: $tablet) {
    grid-column: 2 / 5;
    font-size: 30px;
  }

  @media (max-width: $mobile) {
    font-size: 30px;
    margin-top: 484px;
    margin-bottom: 24px;
  }
}

.pro__title--color {
  color: var(--main);
}

.pro__text-wrapper {
  grid-column: 2 / 5;
  grid-row: 3 / 4;
}

.pro__text {
  color: var(--text);
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.38px;

  @media (max-width: $mobile) {
    font-size: 16px;
  }
}

/* footer styles */

.footer {
  display: grid;
  grid-template-columns: 1fr minmax(320px, 1440px) 1fr;
  height: 264px;

  @media (max-width: $mobile) {
    background: var(--grey-light);
    height: 76px;
  }
}

.section__container--footer {
  padding-top: 136px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  @media (max-width: $mobile) {
    padding-top: 0;
  }
}

.footer__copyright {
  font-size: 24px;
  font-weight: bold;
  color: var(--grey-dark);

  @media (max-width: $mobile) {
    font-size: 14px;
  }
}

.footer__logo {
  // display: none;

  @media (max-width: $mobile) {
    display: block;
    width: 70px;
    height: 11px;
  }
}
</style>
