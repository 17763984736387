import request from '@/services/request'
import { YMD } from '@/helpers/dateFormatter'
import {
  apiPublicReports,
  apiTakeReports,
  apiAvailableBoards,
} from '@/helpers/urls'

const state = {
  reports: {},
  sinceDate: YMD(Date.now()),
  publicReports: {},
  publicReportsMain: null,
  publicReportsByTasks: {},
  availableBoards: [],
}

const getters = {
  reports: ({ reports }) => reports,
  sinceDate: ({ sinceDate }) => sinceDate,
  publicReports: ({ publicReports }) => publicReports,
  publicReportsMain: ({ publicReportsMain }) => publicReportsMain,
  publicReportsByTasks: ({ publicReportsByTasks }) => publicReportsByTasks,
  availableBoards: ({ availableBoards }) => availableBoards,
}

const actions = {
  getReports: async ({ commit, state }, memberId) => {
    try {
      const data = {
        since: state.sinceDate,
        member_uid: memberId,
      }

      const response = await request({
        url: apiTakeReports,
        method: 'POST',
        data,
      })
      commit('SET_REPORTS', { data: response, since: data.since })
      return response
    } catch (err) {
      console.log(err)
    }
  },
  getPublicReports: async ({ commit }, hash) => {
    try {
      const data = await request({
        url: `${apiPublicReports}${hash}/`,
        method: 'GET',
        isWithoutToken: true,
      })
      commit('SET_PUBLIC_REPORTS', data)
      return data
    } catch (err) {
      console.log(err)
    }
  },
  getPublicReport: async (
    { commit },
    { hash, since, only_completed = true }
  ) => {
    try {
      let params = {}
      if (only_completed) {
        params = {
          since,
          only_completed,
        }
      } else {
        params = {
          since,
        }
      }

      const data = await request({
        url: `${apiPublicReports}${hash}/`,
        params,
        method: 'GET',
        isWithoutToken: true,
      })
      commit('SET_PUBLIC_REPORT', { data, since })
      return data
    } catch (err) {
      console.log(err)
    }
  },
  getPublicReportByTasks: async (
    { commit },
    { hash, since, before, only_completed = true }
  ) => {
    try {
      let params = {}
      if (only_completed) {
        params = {
          since,
          before,
          only_completed,
        }
      } else {
        params = {
          since,
          before,
        }
      }

      const data = await request({
        url: `${apiPublicReports}${hash}/`,
        params,
        method: 'GET',
        isWithoutToken: true,
      })
      commit('SET_PUBLIC_REPORT_BY_TASKS', data.boards)
      return data
    } catch (err) {
      console.log(err)
    }
  },
  createPublicReports: async (ctx, { boards, since, before }) => {
    try {
      return await request({
        url: apiPublicReports,
        method: 'POST',
        data: { boards, since, before },
      })
    } catch (err) {
      console.log(err)
    }
  },
  getAvailableBoards: async ({ commit }) => {
    try {
      const data = await request({
        url: apiAvailableBoards,
        method: 'GET',
      })
      commit('SET_AVAILABLE_BOARDS', data)
      return data
    } catch (err) {
      console.log(err)
    }
  },
}

const mutations = {
  SET_REPORTS: (state, { data, since }) => {
    state.reports = { ...state.reports, [since]: data }
  },
  SET_TO_ZERO_REPORTS: (state) => {
    state.reports = {}
  },
  SET_SINCE_TODAY: (state) => {
    state.sinceDate = YMD(Date.now())
  },
  SET_SINCE_DATE: (state, value) => {
    state.sinceDate = value
  },
  SET_PUBLIC_REPORTS: (state, data) => {
    state.publicReportsMain = data
  },
  SET_PUBLIC_REPORT: (state, { data, since }) => {
    state.publicReports = { ...state.publicReports, [since]: data }
  },
  SET_PUBLIC_REPORT_BY_TASKS: (state, data) => {
    state.publicReportsByTasks = data
  },
  SET_TO_ZERO_PUBLIC_REPORTS: (state) => {
    state.publicReports = {}
    state.publicReportsMain = null
    state.publicReportsByTasks = {}
  },
  SET_TO_ZERO_ONLY_PUBLIC_REPORTS: (state) => {
    state.publicReports = {}
    state.publicReportsByTasks = {}
  },
  SET_AVAILABLE_BOARDS: (state, data) => {
    state.availableBoards = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
