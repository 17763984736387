<template lang="pug">
v-row#scroll-target.settings
  v-col.settings.col-12
    v-card.settings__wrapper(v-if='currentBoard')
      v-card-title.d-flex.flex-nowrap.backgroundMain
        .ml-auto(v-if='isMobile || isTablet')
          v-menu(offset-y)
            template(v-slot:activator='{ on }')
              .d-flex.align-center(v-on='on')
                v-btn(icon)
                  v-icon {{ mdiDotsVertical }}
            v-list(
              nav
              dense)
              v-list-item.settings__item(
                v-for='settings in boardSettingsMenu'
                :key='`settings-${settings.id}`'
                link
                @click='() => handleIsShow(settings.isShow)'
                :class='isShow === settings.isShow ? "v-list-item--active" : ""')
                v-list-item-title.settings__item-title.title--text {{ settings.title }}

      v-row.backgroundMain(no-gutters)
        v-navigation-drawer.settings__nav-drawer(
          v-if='!isMobile && !isTablet'
          permanent
          width='210'
          height='inherit'
          absolute
          color='backgroundMain')
          v-list.px-0.pt-6(
            nav
            dense)
            v-list-item.settings__item.mb-3.title--text(
              v-for='settings in boardSettingsMenu'
              :key='`settings-${settings.id}`'
              link
              @click='() => handleIsShow(settings.isShow)'
              :class='isShow === settings.isShow ? "v-list-item--active" : ""')
              v-list-item-title.settings__item-title.title--text {{ settings.title }}
            v-list-item.d-flex.align-center.pl-3
              //-   v-switch.settings__switch.mt-2(
              //-     @change='changeBoardUpdatable'
              //-     v-model='updatable'
              //-     color='grey')
              //-     template(v-slot:label)
              //-       span.settings__switch-label.text--text.ml-5 {{ currentBoard.updatable ? $lang.updatable : $lang.notUpdatable }}

              //- SynchronizeSwitch(
              //-   :updatable='currentBoard.updatable'
              //-   :boardId='+$route.params.id')
              SynchronizeSwitch(
                :updateIsPossible='currentBoard.update_is_possible'
                :updatable='currentBoard.updatable'
                :boardId='+$route.params.id')

        v-container.px-0.pl-sm-9.pt-0(
          fluid
          :style='{ marginLeft: isMobile || isTablet ? "0" : "210px" }')
          div(v-if='isShow === "usersOfBoard"')
            UsersSettings
          .pl-3(v-if='isShow === "gitRepositories"')
            SettingTitle(:title='isShow')
            GitRepositoriesSettings(:boardGits='boardGits')
          .pl-3(v-if='isShow === "specialLists"')
            SettingTitle(:title='isShow')
            SpecialListSettings(:currentBoard='currentBoard')
          .pl-3(v-if='isShow === "boardHerald"')
            SettingTitle(:title='isShow')
            BoardHeraldSettings(
              :boardActiveHerald='boardActiveHerald'
              :boardHeralds='boardHeralds')
          .pl-3(v-if='isShow === "notifications"')
            SettingTitle(:title='isShow')
            NotificationSettings
          .pl-3(v-if='isShow === "reportReceivers"')
            SettingTitle(:title='isShow')
            ReportReceiversSettings(
              :boardReportReceivers='boardReportReceivers'
              :boardUsers='boardUsers')
          .pl-3(v-if='isShow === "notificationUsers"')
            SettingTitle(:title='isShow')
            SubscribeSettings(
              :members='boardMembers'
              :users='boardUsers'
              :membersSubscribe='boardSubscribeMembers')
          .px-3(v-if='isShow === "boardPrefix"')
            SettingTitle(:title='isShow')
            PrefixSettings(
              :boardId='boardId'
              :currentPrefix='currentPrefix')
          .px-3(v-if='isShow === "commonSettings"')
            SettingTitle(:title='isShow')
            CommonSettings
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'
import objectPath from 'object-path'
import { createNamespacedHelpers, mapGetters } from 'vuex'
const { mapState: boardsMapState } = createNamespacedHelpers('boards')
import { boardSettingsItems } from '@/helpers/settingsItems'

const SettingTitle = () =>
  import(
    /* webpackChunkName: "SettingTitle" */ /* webpackMode: "eager" */ '@/views/Boards/components/SettingTitle.vue'
  )
const UsersSettings = () =>
  import(
    /* webpackChunkName: "UsersSettings" */ /* webpackMode: "eager" */ '@/views/Boards/SettingsTab/UsersSettings.vue'
  )
const GitRepositoriesSettings = () =>
  import(
    /* webpackChunkName: "GitRepositoriesSettings" */ /* webpackMode: "eager" */ '@/views/Boards/SettingsTab/GitRepositoriesSettings.vue'
  )
const SpecialListSettings = () =>
  import(
    /* webpackChunkName: "SpecialListSettings" */ /* webpackMode: "eager" */ '@/views/Boards/SettingsTab/SpecialListSettings.vue'
  )
const BoardHeraldSettings = () =>
  import(
    /* webpackChunkName: "BoardHeraldSettings" */ /* webpackMode: "eager" */ '@/views/Boards/SettingsTab/BoardHeraldSettings.vue'
  )
const NotificationSettings = () =>
  import(
    /* webpackChunkName: "NotificationSettings" */ /* webpackMode: "eager" */ '@/views/Boards/SettingsTab/NotificationSettings.vue'
  )
const ReportReceiversSettings = () =>
  import(
    /* webpackChunkName: "ReportReceiversSettings" */ /* webpackMode: "eager" */ '@/views/Boards/SettingsTab/ReportReceiversSettings.vue'
  )
const SubscribeSettings = () =>
  import(
    /* webpackChunkName: "SubscribeSettings" */ /* webpackMode: "eager" */ '@/views/Boards/SettingsTab/SubscribeSettings.vue'
  )
const PrefixSettings = () =>
  import(
    /* webpackChunkName: "PrefixSettings" */ /* webpackMode: "eager" */ '@/views/Boards/SettingsTab/PrefixSettings.vue'
  )
const CommonSettings = () =>
  import(
    /* webpackChunkName: "CommonSettings" */ /* webpackMode: "eager" */ '@/views/Boards/SettingsTab/CommonSettings.vue'
  )
const SynchronizeSwitch = () =>
  import(
    /* webpackChunkName: "SynchronizeSwitch" */ /* webpackMode: "eager" */ '@/views/Boards/components/SynchronizeSwitch.vue'
  )

export default {
  name: 'BoardSettings',
  components: {
    UsersSettings,
    GitRepositoriesSettings,
    SpecialListSettings,
    BoardHeraldSettings,
    NotificationSettings,
    ReportReceiversSettings,
    SubscribeSettings,
    PrefixSettings,
    CommonSettings,
    SettingTitle,
    SynchronizeSwitch,
  },
  data() {
    return {
      isLoadedBoard: true,
      isOpenBoardSettingsModal: false,

      activeListId: null,
      isOpenListSettingsModal: false,

      isShow: 'usersOfBoard',
      // updatable: null,
      mdiDotsVertical,
    }
  },
  created() {
    if (!this.currentBoard) {
      Promise.all([
        this.$store.dispatch('boards/getBoard', this.$route.params.id),
        this.$store.dispatch('boards/getBoardHeralds', this.$route.params.id),
        this.$store.dispatch('boards/getBoardGits', this.$route.params.id),
        this.$store.dispatch('boards/getBoardUsers', this.$route.params.id),
      ]).finally(() => {
        this.isLoadedBoard = true
        // this.updatable = this.currentBoard.updatable
      })
    } else {
      Promise.all([
        this.$store.dispatch('boards/getBoardHeralds', this.$route.params.id),
        this.$store.dispatch('boards/getBoardGits', this.$route.params.id),
        this.$store.dispatch('boards/getBoardUsers', this.$route.params.id),
      ]).finally(() => {
        this.isLoadedBoard = true
      })
    }
  },
  destroyed() {
    this.$store.commit('boards/SET_TO_ZERO_BOARD')
    this.$store.commit('boards/SET_TO_ZERO_REPORT_RECEIVERS')
    this.$store.commit('boards/SET_TO_ZERO_BOARD_HERALDS')
    this.$store.commit('boards/SET_TO_ZERO_BOARD_GITS')
    this.$store.commit('boards/SET_TO_ZERO_BOARD__SPECIAL_LIST')
    this.$store.commit('boards/SET_TO_ZERO_BOARD_USERS')
  },
  computed: {
    ...mapGetters(['isMobile', 'isTablet', 'userId']),
    ...boardsMapState([
      'boards',
      'currentBoard',
      'boardHeralds',
      'boardUsers',
      'boardAdmins',
      'boardClients',
      'boardActiveHerald',
      'boardGits',
      'boardReportReceivers',
      'boardsNotification',
      'boardSubscribeMembers',
      'boardSpecialLists',
      'boardObservers',
    ]),
    boardSettingsMenu() {
      return boardSettingsItems.map((item, idx) => {
        return {
          id: idx,
          title: this.$lang[item],
          isShow: item,
        }
      })
    },
    activeList() {
      return objectPath
        .get(this.currentBoard, 'board_lists', [])
        .find((l) => l.id === this.activeListId)
    },

    boardMembers() {
      return this.currentBoard.members
      // return this.currentBoard.actual_members;
    },
    boardId() {
      return this.currentBoard.id
    },
    currentPrefix() {
      return this.currentBoard.card_prefix ? this.currentBoard.card_prefix : ''
    },
  },
  methods: {
    handleIsShow(isShow = 'usersOfBoard') {
      this.isShow = isShow
    },
    // changeBoardUpdatable() {
    //   this.$store
    //     .dispatch('boards/patchSettingsBoard', {
    //       boardId: this.$route.params.id,
    //       updatable: !this.currentBoard.updatable,
    //     })
    //     .then(() => {
    //       if (this.updatable) {
    //         this.$store.commit('ADD_NOTIFICATION', {
    //           color: 'success',
    //           text: this.$lang.updatableNotification,
    //         })
    //       }
    //     })
    // },
  },
}
</script>

<style lang="scss" scoped>
.settings {
  background: var(--v-backgroundMain-base);
}

.settings-container {
  margin-left: 160px;
}

.settings {
  padding-top: 54px;

  @media (max-width: 600px) {
    padding-top: 16px;
  }
}

.settings__wrapper {
  box-shadow: none !important;
}

.settings__switch {
  padding-left: 4px;
}

.settings__item-title {
  font-size: 17px !important;
  font-weight: normal !important;
}

.settings__nav-drawer {
  padding-bottom: 32px;
}

.settings__switch-label {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
}
</style>

<style lang="scss">
.settings__item.v-list-item {
  color: var(--v-title-base) !important;
}

.settings__item.v-list-item.v-list-item--active > .v-list-item__title {
  font-weight: 700 !important;
}

.settings__switch > .v-input__control {
  min-height: 58px;
  height: 58px;
}
</style>
