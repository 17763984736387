<template lang="pug">
v-container.auth-wrapper.justify-center.pt-0
  AuthHeader
  v-row.align-end.align-sm-center.justify-sm-end
    v-col.col-12.col-sm-8.col-md-5.col-lg-5.pa-0.mt-0.mt-sm-14
      v-form.form.d-flex.flex-column(
        v-model='validForm'
        @submit.prevent='submit')
        v-text-field(
          :label='$lang.username'
          required
          v-model='username'
          :rules='usernameRules'
          :prepend-inner-icon='mdiAccountCircle')
        v-text-field(
          v-if='!getEmail'
          label='E-mail'
          type='email'
          required
          :rules='emailRules'
          v-model='email'
          :prepend-inner-icon='mdiAt')
        v-text-field(
          v-else
          label='E-mail'
          type='email'
          required
          v-model='getEmail'
          :readonly='!!getEmail'
          :disabled='!!getEmail'
          :prepend-inner-icon='mdiAt')
        .spacer
        v-text-field(
          type='password'
          :label='$lang.password'
          required
          v-model='password1'
          :rules='passwordRules'
          :prepend-inner-icon='mdiLock')
        v-text-field(
          type='password'
          :label='$lang.repeatPassword'
          required
          :rules='passwordConfirmRules'
          v-model='password2'
          :prepend-inner-icon='mdiLock'
          ref='passwordInput')
        small.form__error {{ error }}
        button.form__btn.primary(
          type='submit'
          :disabled='!validForm') {{ $lang.toRegister }}
        .d-flex.justify-center
          router-link.form__auth-btn.main--text(
            :to='{name: "termsLinkTrello"}') {{ $lang.loginTrello }}
        .invite.d-flex.justify-center(@click='sendInvite()')
          v-btn.form__auth-btn(
            :to='{name: "login"}'
            text
            color='title') {{ $lang.haveAccount }}
        .mt-8.mt-sm-14
          v-select.lang-select(
            v-model='vLang'
            :items='language'
            hide-details
            outlined)
</template>

<script>
import { mdiAccountCircle, mdiLock, mdiAt } from '@mdi/js'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { language } from '@/helpers/constants'
import { errorFormatter } from '@/helpers/errorFormatter'

const AuthHeader = () =>
  import(
    /* webpackChunkName: "AuthHeader" */ /* webpackMode: "eager" */ '@/views/Auth/components/AuthHeader.vue'
  )

export default {
  name: 'RegistrationComponent',
  components: {
    AuthHeader,
  },
  data() {
    return {
      validForm: false,
      usernameRules: [(v) => !!v || this.$lang.required],
      passwordRules: [(v) => (!!v && v.length >= 8) || this.$lang.required],
      passwordConfirmRules: [
        (v) =>
          (!!v && v === this.$refs.passwordInput.value) || this.$lang.required,
      ],
      emailRules: [
        (v) => !!v || this.$lang.required,
        (v) => /.+@.+/.test(v) || this.$lang.invalidEmail,
      ],
      nonFieldError: null,
      username: '',
      email: '',
      password1: '',
      password2: '',
      isLoading: false,
      language,
      mdiAccountCircle,
      mdiLock,
      mdiAt,
      error: '',
      errorFormatter,
    }
  },
  computed: {
    ...mapGetters(['lang', 'isMobile']),
    getEmail() {
      return this.$route.query.email
    },
    vLang: {
      get() {
        return this.lang
      },
      set(value) {
        this.$store.dispatch('setLang', value)
        this.$setLang(value)
        this.$vuetify.lang.current = value
      },
    },
  },
  methods: {
    ...mapMutations(['ADD_NOTIFICATION']),
    ...mapActions(['registration', 'login']),
    sendInvite() {
      if (this.$route.params && this.$route.params.token) {
        this.$store.dispatch('boards/postBoardInvite', {
          signingDumps: this.$route.params.token,
        })
      }
    },
    async submit() {
      this.isLoading = true
      this.nonFieldError = ''

      const data = await this.registration({
        username: this.username,
        email: this.getEmail ? this.getEmail : this.email,
        password1: this.password1,
        password2: this.password2,
        signing_dumps: this.$route.params.token,
        settings: { lang: this.vLang },
      })
      if (data?.access_token) {
        this.error = ''
        this.ADD_NOTIFICATION({
          color: 'success',
          text: this.$lang.sucessRegistration,
        })

        this.login({
          username: this.username,
          password: this.password1,
        }).then(() => {
          this.$router.push({ name: 'onboarding' })
        })
      }
      if (data?.error) {
        this.error = this.errorFormatter(data.error)
      }
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.auth-wrapper {
  max-width: 1170px;

  @media (max-width: 600px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.form {
  @include auth-form;

  &__btn-wrapper {
    padding: 0 42px;
  }

  &__btn,
  &__auth-btn {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: normal;
  }

  &__btn {
    @include btn-common(center);
    margin: 40px 0 8px;

    @media (max-width: 600px) {
      margin: 20px 0 8px;
    }
  }

  &__auth-btn {
    text-transform: lowercase !important;
    padding: 0 !important;
    text-decoration: none;
  }

  &__error {
    color: red;
  }
}

.spacer {
  height: 26px;

  @media (max-width: 600px) {
    height: 18px;
  }
}

.invite {
  margin-top: 8px;

  @media (max-width: 600px) {
    margin-top: 0;
  }
}
</style>
