<template lang="pug">
v-chart.chart(
  :option='option'
  :style='{"margin-bottom": marginBottom}'
  autoresize)
</template>

<script>
import { mapGetters } from 'vuex'
import { use } from 'echarts/core'
// import * as echarts from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { HeatmapChart } from 'echarts/charts'
import {
  TitleComponent,
  CalendarComponent,
  TooltipComponent,
  VisualMapComponent,
} from 'echarts/components'
import VChart, { INIT_OPTIONS_KEY } from 'vue-echarts'

use([
  TitleComponent,
  CalendarComponent,
  TooltipComponent,
  VisualMapComponent,
  HeatmapChart,
  CanvasRenderer,
])

export default {
  name: 'CalendarChart',
  components: {
    VChart,
  },
  provide: {
    [INIT_OPTIONS_KEY]: {
      height: '200',
    },
  },
  inject: ['marginBottom'],
  props: {
    activity: {
      type: Array,
      default: () => [],
    },
    member: {
      type: Object,
      default: () => {},
    },
    chartOptions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoaded: false,
      option: {
        backgroundColor: this.chartOptions.backgroundColor,
        tooltip: {
          trigger: 'item',
          renderMode: 'richText',
          formatter: function (params) {
            return params.value[0] + ': ' + params.value[1]
          },
        },
        visualMap: this.chartOptions.visualMapOptions,
        calendar: {
          top: this.chartOptions.calendarTop,
          left: 30,
          // right: this.chartOptions.calendarRight,
          width: this.chartOptions.calendarWidth,
          cellSize: [16, 16],
          range: this.chartOptions.calendarRange,
          splitLine: {
            lineStyle: {
              color: this.chartOptions.lineStyleColor,
            },
          },
          itemStyle: {
            borderWidth: 0.5,
            borderColor: this.chartOptions.itemStyleBorderColor,
            color: this.chartOptions.itemStyleColor,
          },
          dayLabel: {
            firstDay: 1,
            color: this.chartOptions.calendarLabelColor,
            nameMap: this.chartOptions.calendarDayLabel,
          },
          monthLabel: {
            show: this.chartOptions.calendarMonthLabelShow,
            color: this.chartOptions.calendarLabelColor,
            nameMap: this.chartOptions.calendarMonthLabel,
          },
          yearLabel: {
            show: false,
          },
        },
        series: {
          name: 'activity',
          type: 'heatmap',
          coordinateSystem: 'calendar',
          data: this.chartOptions.chartData,
          itemStyle: {},
        },
      },
    }
  },
  computed: {
    ...mapGetters(['isDark']),
  },
}
</script>

<style>
.chart {
  height: var(--chart-height);
}
</style>
