<template lang="pug">
.commits(v-if='commits.length')
  h4.commits__subtitle {{ $lang.totalNumberOfCommits }}: {{ commits.length }}
  template(v-for='commit in commits')
    .commits__commit.commit(:key='commit.id')
      component.commit__icon(
        :is='getServiceIcon(commit.repository.git_service)')
      div
        span.commit__item {{ getShortDateForCard(commit.created_at, lang) }}

        a.commit__item(
          :href='commit.git_commit_url'
          target='_blank')
          span.commit__link {{ commit.title || commit.git_id }}
span(v-else) {{ $lang.noData }}
</template>

<script>
import { mapGetters } from 'vuex'
import GitlabIcon from '@/components/GitlabIcon.vue'
import GithubIcon from '@/components/GithubIcon.vue'
import BitbucketIcon from '@/components/BitbucketIcon.vue'
import RepoIcon from '@/components/RepoIcon.vue'
import { getShortDateForCard } from '@/helpers/dateFormatter'

export default {
  name: 'CardCommits',
  components: {
    GitlabIcon,
    GithubIcon,
    BitbucketIcon,
    RepoIcon,
  },
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    getShortDateForCard,
  }),
  computed: {
    ...mapGetters(['lang']),
    commits() {
      return this.card.commits
    },
  },
  methods: {
    getServiceIcon(service) {
      switch (service) {
        case 'GITLAB':
          return GitlabIcon
        case 'GITHUB':
          return GithubIcon
        case 'BITBUCKET':
          return BitbucketIcon
        default:
          return RepoIcon
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.commits {
  &__subtitle {
    font-weight: 500;
    margin-bottom: 16px;
  }
}
.commit {
  display: flex;
  align-items: center;

  &__item {
    display: block;
  }

  &__icon {
    width: 36px;
    height: 36px;
    margin-right: 2rem;
  }

  &__link {
    word-break: break-all;
  }
}
.commit + .commit {
  margin-top: 1rem;
}
</style>
