import request from '@/services/request'
import {
  apiBoards,
  apiSprints,
  endGantt,
  apiDashboard,
  endSprints,
} from '@/helpers/urls'

const state = {
  sprints: [],
  sprintDetails: [],
  sprintsMembers: [],
  lastSprints: [],
  showLists: [],
  dashboard: [],
}

const getters = {
  sprints: ({ sprints }) => sprints,
  sprintDetails: ({ sprintDetails }) => sprintDetails,
  sprintsMembers: ({ sprintsMembers }) => sprintsMembers,
  lastSprints: ({ lastSprints }) => lastSprints,
  dashboard: ({ dashboard }) => dashboard,
}

const mutations = {
  SET_BOARD_SPRINTS: (state, data) => {
    state.sprints = data.sprints
    state.showLists = data.board_lists
    state.sprintsMembers = data.all_time_members
  },
  SET_BOARD_SPRINT: (state, data) => {
    state.sprintDetails = [...state.sprintDetails, data]
  },
  SET_TO_ZERO_BOARD_SPRINTS: (state) => {
    state.sprints = []
    state.sprintDetails = []
    state.sprintsMembers = []
  },
  SET_LAST_SPINTS: (state, data) => {
    state.lastSprints = data
  },
  SET_DASHBOARD: (state, data) => {
    state.dashboard = data
  },
}

const actions = {
  getLastSprints: async ({ commit }) => {
    try {
      const data = await request({ url: apiSprints, method: `GET` })
      commit('SET_LAST_SPINTS', data)
      return data
    } catch (err) {
      console.log(err)
    }
  },
  getBoardSprints: async ({ commit }, boardId) => {
    try {
      const response = await request({
        url: `${apiBoards}${boardId}${endSprints}`,
        method: `GET`,
      })
      commit('SET_BOARD_SPRINTS', response)
      return response
    } catch (err) {
      return { error: err }
    }
  },
  getBoardSprint: async ({ commit }, sprintId) => {
    try {
      const data = await request({
        url: `${apiSprints}${sprintId}/`,
        method: 'GET',
      })
      commit('SET_BOARD_SPRINT', data)
      return data
    } catch (err) {
      console.log(err)
    }
  },
  getSprintCardsGantt: async (_, sprintId) => {
    try {
      return await request({
        url: `${apiSprints}${sprintId}${endGantt}`,
        method: 'GET',
      })
    } catch (err) {
      console.log(err)
    }
  },
  getDashboard: async ({ commit }) => {
    try {
      const data = await request({ url: apiDashboard, method: 'GET' })
      commit('SET_DASHBOARD', data)
      return data
    } catch (err) {
      console.log(err)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
