<template lang="pug">
.tracking.d-flex.align-center
  //- .tracking.mr-3(:class='[tracking ? "primary--text" : "title--text"]') {{ $lang.timeTracking }}
  v-switch.tracking__switch.mb-4.pb-1(
    @click.stop='switchColumnTimeTracking()'
    v-model='tracking'
    hide-details
    color='primary')
    template(v-slot:label)
      .tracking__label.mr-3(
        :class='[tracking ? "primary--text" : "title--text"]') {{ $lang.timeTracking }}
</template>

<script>
export default {
  name: 'TimeTrackingSwitch',
  props: {
    column: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tracking: this.column.count_time,
    }
  },
  computed: {},
  methods: {
    switchColumnTimeTracking() {
      const data = {
        listId: this.column.id,
        count_time: this.tracking ? true : false,
      }
      this.$store.dispatch('boards/setTimeTracking', data)
    },
  },
}
</script>

<style lang="scss" scoped>
.tracking {
  &__label {
    font-size: 12px;
    font-weight: 500;
  }
}
</style>

<style lang="scss">
.tracking .v-input__slot {
  flex-direction: row-reverse !important;
}

.tracking__switch.v-input--switch:not(.v-input--switch--flat):not(.v-input--switch--inset)
  .v-input--switch__thumb {
  color: var(--v-title-base);
}
</style>
