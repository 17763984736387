export const light = {
  backgroundHome: '#EFF4FA',
  backgroundReport: '#EEEEEE',
  backgroundMain: '#FBFBFB',
  backgroundTable: '#EBE7E7',
  backgroundStar: '#777892',
  backgroundLabel: '#AEAEAE',
  backgroundInfo: '#f6f6f6',
  colorLabel: '#fff',
  base: '#fff',
  border: '#BFBFBF',
  darkGrey: '#333333',
  grey: '#4F4F4F', //gray2
  greySprint: '#302828',
  icon: '#72ADF3',
  inactive: '#AEAEAE', //middle
  lightGrey: '#BDBDBD',
  logo: '#2E2E30',
  main: '#0075FF', //main
  primary: '#098BEB', //main2
  text: '#7F8487',
  title: '#505155', //2dark
  titleTab: '#6A6A6A',
  warning: '#dd5227',
  warningActive: '#f58766',
  switch: '#505155',

  role0: '#ACD1C4', //simpleList
  role1: '#CAACD1', //reliseList
  role2: '#74B6F2', //backlog
  role3: '#D1CBAC', //sprintList
  role4: '#D1ACAC', //codedList
  role5: '#ACB2D1', //productionList

  /* chart colors */
  chartStart: '#fff',
  chartMin: '#daebf2',
  chartMid: '#82C3F3',
  chartMax: '#39A2FF',
  chartEnd: '#0075FF',

  /* trello labels */
  'label-green': '#60bf57',
  'label-yellow': '#f1d72e',
  'label-orange': '#fe9f2b',
  'label-purple': '#c473dd',
  'label-blue': '#0b78bd',
  'label-sky': '#0dc2df',
  'label-red': '#eb5847',
  'label-pink': '#ff74c8',
  'label-black': '#354462',
  'label-lime': '#51ea9c',
}

export const dark = {
  backgroundHome: '#EFF4FA',
  backgroundReport: '#161E29',
  backgroundMain: '#20344F',
  backgroundTable: '#161E29',
  backgroundStar: '#777892',
  backgroundLabel: '#161E29',
  backgroundInfo: '#161E29',
  colorLabel: '#fff',
  base: '#161E29',
  border: '#BFBFBF',
  darkGrey: '#D7E2E9',
  grey: '#D7E2E9',
  greySprint: '#FFF',
  icon: '#098BEB',
  inactive: '#AEAEAE',
  lightGrey: '#BDBDBD',
  logo: '#FFF',
  main: '#0075FF',
  primary: '#098BEB',
  text: '#D7E2E9',
  title: '#D7E2E9',
  titleTab: '#F2F5FC',
  warning: '#dd5227',
  warningActive: '#f58766',
  switch: '#098BEB',

  role0: '#ACD1C4', //simpleList member1
  role1: '#CAACD1', //reliseList member3
  role2: '#42A5F5', //backlog
  role3: '#D1CBAC', //sprintList member2 member4
  role4: '#D1ACAC', //codedList member0
  role5: '#ACB2D1', //productionList member5

  /* chart colors */
  chartStart: '#fff',
  chartMin: '#d7e2e9',
  chartMid: '#5c96c3',
  chartMax: '#008CFF',
  chartEnd: '#0075FF',

  /* trello labels */
  'label-green': '#60bf57',
  'label-yellow': '#f1d72e',
  'label-orange': '#fe9f2b',
  'label-purple': '#c473dd',
  'label-blue': '#0b78bd',
  'label-sky': '#0dc2df',
  'label-red': '#eb5847',
  'label-pink': '#ff74c8',
  'label-black': '#354462',
  'label-lime': '#51ea9c',
}
