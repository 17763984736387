<template lang="pug">
v-container.justify-center.fill-height(
  v-if='isLoaded'
  fluid)
  v-row(
    align='center'
    justify='center')
    v-col(
      cols='12'
      sm='8'
      md='4')
      .mb-4.text-center
        router-link.text-none.display-1.link-to-main-page(
          :to='{ name: "home" }') Trello Stat
      v-card
        v-card-title
          h3 {{ unSubscribeLang.header }}
        v-form
          v-card-text {{ unSubscribeLang.title }}
            div(
              v-for='board in subscribeBoards'
              :key='`subscribeBoards-${board.id}`')
              v-checkbox(
                v-model='selectedBoards'
                :label='board.name'
                color='primary'
                :value='board.id'
                hide-details)
          v-card-actions.d-block.pa-4
            v-btn.text-none(
              @click='submit'
              color='primary'
              block
              height='48'
              :loading='loading'
              :disabled='isDisabled') {{ unSubscribeLang.button }}
            .mt-2.mt-sm-4.text-center(v-if='successUnSubscribe')
              p {{ unSubscribeLang.notification }}
      .ma-5
        v-select(
          v-model='vLang'
          :items='language'
          hide-details
          outlined
          :label='$lang.selectLang')
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
const { mapGetters: boardsMapGetters } = createNamespacedHelpers('boards')
import { language } from '@/helpers/constants'

export default {
  name: 'UnSubscribe',
  data() {
    return {
      signingDumps: undefined,
      isLoaded: false,
      loading: false,
      successUnSubscribe: false,
      selectedBoards: [],
      language,
    }
  },
  created() {
    this.isLoaded = false
    this.getSubscribeBoards()
  },
  destroyed() {
    clearTimeout(this.timer)
    this.$store.commit('boards/SET_TO_ZERO_SUBSCRIBE_BOARDS')
  },
  computed: {
    ...mapGetters(['lang']),
    ...boardsMapGetters(['subscribeBoards']),
    vLang: {
      get() {
        return this.lang
      },
      set(value) {
        this.$store.dispatch('setLang', value)
        this.$setLang(value)
        this.$vuetify.lang.current = value
      },
    },
    isDisabled() {
      return this.loading || !this.selectedBoards.length
    },
    unSubscribeLang() {
      return this.$lang.unSubscribeObj
    },
  },
  methods: {
    submit() {
      this.loading = true
      this.$store
        .dispatch('boards/postSubscribeBoards', {
          signingDumps: this.signingDumps,
          boardId: this.selectedBoards,
        })
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          this.loading = false
          this.successUnSubscribe = true
          // this.timer = setTimeout(() => {
          //   this.$router.push({ name: 'Landing' })
          // }, this.time * 1000)
        })
    },
    getSubscribeBoards() {
      this.signingDumps = this.$route.params.signingDumps
      this.$store
        .dispatch('boards/getSubscribeBoards', this.signingDumps)
        .finally(() => {
          this.isLoaded = true
        })
    },
  },
}
</script>
>

<style lang="scss" scoped></style>
