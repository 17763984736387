<template lang="pug">
v-container(v-if='isLoaded && !isSuccess')
  v-col.invite__column.invite__column--desktop.col-12.py-0.my-12(
    v-if='!isMobile')
    router-link.text-none(:to='{ name: "home" }')
      IconBase(
        icon-name='logo'
        height='21'
        icon-color='var(--v-logo-base)')
        LogotypeUni
    EmailLink

  v-col.invite__column.invite__column--mobile.col-12(v-if='isMobile')
    EmailLink.align-self-end
    .invite__link-wrapper
      router-link.invite__link.text-none(:to='{ name: "home" }')
        IconBase(
          icon-name='logo'
          width='425'
          height='53'
          icon-color='var(--v-logo-base)')
          LogotypeUni

  v-row.invite__hero.my-12.pa-10
    v-col.col-12.my-12.d-flex.justify-center
      span {{ isSuccess ? $lang.subscribeBoard : $lang.noSubscribeBoard }}&nbsp;
        span.invite__email {{ !isSuccess ? supportEmail : "" }}
    v-col.col-12.d-flex.justify-center
      v-btn.invite__btn(
        :to='{ name: "home" }'
        elevation='0') {{ $lang.toMainPage }}
</template>

<script>
import { mapGetters } from 'vuex'
import config from '@/../config.json'

const EmailLink = () =>
  import(
    /* webpackChunkName: "EmailLink" */ /* webpackMode: "eager" */ '@/components/EmailLink.vue'
  )

export default {
  name: 'inviteBoard',
  components: {
    EmailLink,
  },
  data() {
    return {
      isLoaded: false,
      isSuccess: undefined,
      supportEmail: config.email,
    }
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
  created() {
    this.isLoaded = false
    this.$store
      .dispatch('boards/postBoardInvite', {
        signingDumps: this.$route.query.signing_dumps,
      })
      .then(() => {
        this.isSuccess = true
      })
      .then(() => {
        this.$router.push({ name: 'home' })
      })
      .catch(() => {
        this.isSuccess = false
      })
      .finally(() => {
        this.isLoaded = true
      })
  },
}
</script>

<style lang="scss" scoped>
.invite {
  &__wrapper {
    height: 100%;
    display: flex;
  }
  &__column {
    display: flex;

    &--desktop {
      justify-content: space-between;
      align-items: flex-end;
    }

    &--mobile {
      flex-direction: column;
      align-items: center;
    }
  }

  &__link-wrapper {
    flex-grow: 2;
    display: flex;
    align-items: center;
  }

  &__hero {
    background: var(--v-base-base);
    border-radius: 10px;
  }

  &__email {
    color: var(--v-main-base);
  }

  &__btn {
    @include btn-common(center);
  }
}
</style>
