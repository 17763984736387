<template lang="pug">
v-container
  v-row#reports.reports
    v-col.col-12
      v-progress-linear(
        v-show='!isLoaded'
        indeterminate)
      div(v-if='isLoaded && publicReportsMain')
        v-card.mb-16.backgroundMain(elevation='0')
          v-card-title.d-flex.justify-space-between.align-end
            router-link.header__link.text-none(
              :to='{name: "home"}'
              :aria-label='$lang.linkGoToHome')
              IconBase(
                icon-name='logo'
                height='36'
                icon-color='var(--v-logo-base)')
                LogotypeUni
            .d-flex.flex-column.align-end
              span.reports__date.text--text {{ $lang.since }}: {{ publicReportsMain.meta.since }}&nbsp;{{ $lang.before }}: {{ publicReportsMain.meta.before }}
          .d-flex.justify-md-end.align-center.reports__type
            v-switch.mr-4.mt-0.pt-0(
              v-model='completed'
              hide-details
              color='main')
              template(v-slot:label)
                span.reports__type.ml-4.text--text {{ $lang.completedTasks }}
          v-card-text.text--text.d-flex.flex-wrap.justify-md-end.align-center.mt-2.pb-2.px-0
            span.reports__type.mr-4.mb-2 {{ $lang.reportType }}
            v-radio-group.reports__radio.mt-0.pt-0(
              v-model='reportType'
              row
              hide-details)
              v-radio.mb-2(
                :label='$lang.byDays'
                value='1'
                :class='[reportType === "1" ? "active" : ""]')
              v-radio.mb-2(
                :label='$lang.byTasks'
                value='2'
                :class='[reportType === "2" ? "active" : ""]')
        PublicReportByDays(
          v-if='reportType === "1"'
          :reportDates='reportDates'
          :vSinceDate='sinceDate'
          :completed='completed'
          :isToggleCompleted='isToggleCompleted'
          :setToggleCompleted='setToggleCompleted'
          :isLoadedByDays='isLoadedByDays')
        PublicReportByTasks(
          v-else
          :isLoadedByTasks='isLoadedByTasks')
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
const { mapGetters: reportsMapGetters } = createNamespacedHelpers('reports')

const PublicReportByDays = () =>
  import(
    /* webpackChunkName: "PublicReportByDays" */ /* webpackMode: "eager" */ '@/views/PublicReport/components/PublicReportByDays.vue'
  )
const PublicReportByTasks = () =>
  import(
    /* webpackChunkName: "PublicReportByTasks" */ /* webpackMode: "eager" */ '@/views/PublicReport/components/PublicReportByTasks.vue'
  )

export default {
  name: 'PublicReport',
  components: {
    PublicReportByDays,
    PublicReportByTasks,
  },
  data() {
    return {
      isLoaded: false,
      sinceDate: null,
      reportType: '1',
      since: null,
      before: null,
      completed: false,
      isToggleCompleted: false,
      isLoadedByDays: false,
      isLoadedByTasks: false,
    }
  },
  computed: {
    ...mapGetters(['isMobile', 'isTablet']),
    ...reportsMapGetters(['publicReports', 'publicReportsMain']),
    reportDates() {
      return Object.keys(this.publicReports)
    },
  },
  watch: {
    completed(n, o) {
      if (n !== o) {
        this.isToggleCompleted = true
        this.$store.commit('reports/SET_TO_ZERO_ONLY_PUBLIC_REPORTS')
        this.getReports()
      }
    },
  },
  created() {
    this.$store
      .dispatch('reports/getPublicReports', this.$route.params.hash)
      .then((data) => {
        this.sinceDate = data.meta.before
        this.isLoaded = true
        this.since = data.meta.since
        this.before = data.meta.before
      })
      .then(() => this.getReports())
  },
  destroyed() {
    this.$store.commit('reports/SET_TO_ZERO_PUBLIC_REPORTS')
  },
  methods: {
    getReports() {
      this.isLoadedByDays = false
      this.isLoadedByTasks = false

      this.$store
        .dispatch('reports/getPublicReport', {
          hash: this.$route.params.hash,
          since: this.sinceDate,
          only_completed: this.completed,
        })
        .finally(() => (this.isLoadedByDays = true))

      this.$store
        .dispatch('reports/getPublicReportByTasks', {
          hash: this.$route.params.hash,
          since: this.since,
          before: this.before,
          only_completed: this.completed,
        })
        .finally(() => (this.isLoadedByTasks = true))
    },
    setToggleCompleted(val) {
      this.isToggleCompleted = val
    },
    getSpecialBoardListName(report, specialListId) {
      const list = report.board.board_lists.find(
        (list) => list.id === specialListId
      )
      const count = report.special_counts[specialListId] || 0
      return `${list.name}: ${count}`
    },
  },
}
</script>

<style lang="scss" scoped>
.reports {
  padding-top: 60px;

  &__date {
    font-family: var(--font-raleway);
    font-size: 24px;
    font-weight: 400;
    line-height: 1;
  }

  &__type,
  &__radio,
  &__radio label {
    font-family: var(--font-raleway);
    font-size: 18px !important;
    font-weight: 500;
    line-height: 1;
  }
}

.header {
  &__link {
    text-decoration: none;
  }

  &__logo {
    font-family: var(--font-raleway);
    font-size: 45px;

    &--bold {
      font-weight: 700;
    }

    &--thin {
      font-weight: 500;
    }
  }
}
</style>

<style lang="scss">
.reports__panel.v-expansion-panel::before {
  box-shadow: none;
}

.reports__card.v-sheet.v-card {
  background-color: var(--v-backgroundMain-base);
  border: 1px solid var(--v-backgroundReport-base);
  border-radius: 0px 10px 10px 10px;
}

.reports__radio label {
  font-size: 18px;
}

.reports__radio .v-radio label {
  color: var(--v-text-base) !important;
}

.reports__radio .v-radio.active label {
  color: var(--v-main-base) !important;
}

.v-input--selection-controls__input .v-icon {
  color: var(--v-inactive-base);
}
</style>
