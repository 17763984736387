<template lang="pug">
.my-3
  .report__title.title--text.d-flex.align-center.pl-0.flex-wrap
    .report__card-text.mr-4(v-if='info') {{ info.title }} - {{ new Date(action.created_at) | HMS }}
    .report__card-text.d-flex.align-center.mr-4(v-if='info && info.from')
      span {{ info.from }}
      v-icon.ml-2.mr-2(color='title') {{ mdiTrendingNeutral }}
      span {{ info.to }}
    span.report__card-text(v-if='info && info.actionAuthor') {{ !isPublic ? `(${$lang.byWho}${info.actionAuthor})` : "" }}
</template>

<script>
import { mdiTrendingNeutral } from '@mdi/js'
import actionsMixin from '@/mixins/actionsMixin'

export default {
  name: 'ReportSubtitle',
  mixins: [actionsMixin],
  props: {
    task: {
      type: Object,
      default: () => {},
    },
    action: {
      type: Object,
      default: () => {},
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mdiTrendingNeutral,
  }),
  computed: {
    info() {
      return this.action && this.task
        ? this.actionInfo(this.action, this.task)
        : null
    },
  },
}
</script>

<style lang="scss" scoped>
.report {
  &__title {
    font-family: var(--font-roboto);
    font-weight: 400;
  }

  &__card-text {
    font-size: 14px;
  }
}
</style>
