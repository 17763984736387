<template lang="pug">
v-container.justify-center.fill-height(fluid)
  v-row(
    align='center'
    justify='center')
    v-progress-circular(
      :size='70'
      :width='7'
      color='purple'
      indeterminate)
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FinalAuth',
  created() {
    this.$store.commit('SET_TOKENS', { access: this.$route.query.access })
    this.$store.dispatch('loginObtain').then(() => {
      this.$setLang(this.lang)
      this.$router.push({ name: 'boards' })
    })
  },
  computed: {
    ...mapGetters(['lang']),
  },
}
</script>
