<template lang="pug">
.d-flex.flex-column
  v-card.mb-3.backgroundMain(
    v-for='sprint in sprints'
    :key='sprint.id'
    flat
    hover
    :to='!sprint.error ? {name: "sprint", params: {sprintId: sprint.id, boardId}} : undefined')
    v-card-text.px-0
      .d-flex.mb-2
        h3.title--text
          span.sprint__number # {{ sprint.number }}
        v-spacer
        .d-flex.flex-wrap.d-sm-inline-block.main--text
          span.sprint__subtitle.mr-2(v-if='sprint.starts_at') {{ $lang.since }}: {{ sprint.starts_at | YMD }}
          span.sprint__subtitle(v-if='sprint.ends_at') {{ $lang.before }}: {{ sprint.ends_at | YMD }}
      .d-flex.flex-column(v-if='sprint.error')
        span.error--text {{ sprint.error }}
        router-link.ml-auto.text-none(:to='{name: "faq", hash: "#sprints"}') ({{ $lang.moreDetailsForDoc }})
      div(v-else)
        SprintProgressBars(
          :lists='lists'
          :sprint='sprint')
</template>

<script>
import { mapGetters } from 'vuex'

const SprintProgressBars = () =>
  import(
    /* webpackChunkName: "SprintProgressBars" */ /* webpackMode: "eager" */ '@/views/Sprints/components/SprintProgressBars.vue'
  )

export default {
  name: 'SprintsItem',
  components: {
    SprintProgressBars,
  },
  props: {
    sprints: {
      type: Array,
      default: () => [],
    },
    lists: {
      type: Array,
      default: () => [],
    },
    boardId: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters(['isDark']),
  },
}
</script>

<style lang="scss" scoped>
.sprint {
  &__number {
    @include subtitle-common;
    white-space: nowrap;
    margin-right: 8px;
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
