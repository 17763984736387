export const apiMembers = '/members/'
export const apiDashboardMembers = '/cards/dashboard_actors/'
export const apiBoards = '/boards/'
export const apiBoardsProgressList = '/boards/progress_list/'
export const apiBoardsIsAdmin = '/boards/is_admin/'
export const apiBoardsInvite = '/boards/invite/'
export const apiLists = '/lists/'
export const apiAlertsVariants = '/alerts/variants/'
export const apiAlerts = '/alerts/'
export const apiBoardsUnsubscribeNewsletter = '/boards/unsubscribe_newsletter/'
export const apiOptions = '/options/'
export const apiToken = '/token/'
export const apiRegistration = '/rest_auth/registration/'
export const apiTokenObtain = '/token/obtain/'
export const apiTokenRefresh = '/token/refresh/'
export const apiPasswordReset = '/password_reset/'
export const apiPasswordResetConfirm = '/password_reset/confirm/'
export const apiChangePassword = '/rest_auth/password/change/'
export const apiVerifyEmail = '/users/send_verification/'
export const apiConfirmEmail = '/accounts/confirm-email/'
export const apiPublicReports = '/publicreports/'
export const apiTakeReports = '/actions/take_report/'
// export const apiBoardsSprints = '/boards/sprints/'
export const apiSprints = '/sprints/'
export const apiDashboard = '/cards/dashboard/'
export const apiAvailableBoards = '/publicreports/available_boards/'
export const apiUserBoards = '/boards/on_boards_in_trello/'
export const apiOptionsOAuth = '/options/oauth_services/'
export const apiLogout = '/accounts/logout/'
export const apiRepoList = '/gits/get_user_accessible_repositories/'
export const apiSendBindRepo = '/gits/bind_repositories/'
export const apiGits = '/gits/'
export const apiAccount = '/accounts/'
export const apiStatCards = '/diagrams/timeline/'
export const apiCards = '/cards/'
export const apiAvailableTrelloBoards = '/boards/available_trello_boards/'

// end of endpoints
export const endGit = '/git/'
export const endProgress = '/progress/'
export const endHeralds = '/heralds/'
export const endUsers = '/users/'
export const endGantt = '/gantt/'
export const endBoardPrefix = '/change_board_prefixes/'
export const endTimeTracking = '/change_time_tracking/'
export const endObserverStatus = '/change_observer_status/'
export const endSyncBoards = '/synchronize_with_trello/'
export const endSyncBoardsRoughly = '/rough_sync/'
export const endActivityGraph = '/activity_graph/'
export const endBindGitToken = '/bind_git_token/'
export const endLogout = '/logout/'
export const endListOfBranches = '/get_list_of_repository_branches/'
export const endSprints = '/sprints/'
