<template lang="pug">
v-row(no-gutters)
  v-col.col-12
    v-progress-linear(
      indeterminate
      v-show='!isLoaded')
    EmptyEntities(v-if='isLoaded && !reports')
    v-card.reports__header.backgroundMain(
      v-else-if='isLoaded && reports'
      elevation='0')
      v-card-text.pa-0
        .reports__header-text.d-flex.justify-space-between.align-start.flex-wrap.flex-md-nowrap
          .date.d-flex.align-center
            v-icon.date__icon {{ "$calendarIcon" }}
            v-menu(
              ref='since'
              v-model='since'
              :close-on-content-click='false'
              :nudge-left='86'
              :nudge-bottom='16'
              origin='center top'
              transition='scale-transition'
              offset-y
              min-width='290px')
              template(v-slot:activator='{on}')
                .date__menu-item(v-on='on') {{ computedDateFormatted }}
              v-date-picker.reports__picker(
                v-model='vSince'
                no-title
                scrollable
                :min='endDate'
                :max='now'
                color='primary'
                :first-day-of-week='firstDayOfWeek')
                v-spacer
                v-btn.picker__close.primary.base--text(
                  @click='since = false'
                  depressed) {{ $lang.close }}
          .reports__dialog-wrapper.d-flex.flex-column(v-if='!memberId')
            v-dialog(
              v-if='availableBoards && availableBoards.length'
              v-model='popupPublicReport'
              max-width='630'
              overlay-color='base'
              content-class='dialog__inner')
              template(v-slot:activator='{on}')
                v-btn.button__common.base--text(
                  v-on='on'
                  depressed) {{ $lang.publicReport }}
              v-card.backgroundMain
                v-card-title.dialog__title.d-flex.flex-nowrap
                  span {{ $lang.createPublicReport }}
                  v-btn.ml-2.ml-sm-auto.grey--text(
                    text
                    @click='popupPublicReport = false'
                    fab
                    small)
                    v-icon(dark) {{ mdiClose }}
                v-card-text
                  v-autocomplete.reports__select(
                    v-model='reportsBoard'
                    :items='[...availableBoards]'
                    item-value='id'
                    item-text='name'
                    :label='$lang.chooseBoard'
                    multiple)
                  //- v-select(
                  //-   v-model="reportsSprint"
                  //-   v-if="reportsBoard && sprints.length"
                  //-   :items="[{id: null, number: $lang.chooseSprint}, ...filterSprints]"
                  //-   item-value="id"
                  //-   item-text="number"
                  //-   @change="setDates(reportsSprint)"
                  //- )
                .d-flex.justify-center.mb-3
                  v-date-picker.reports__picker(
                    v-model='dates'
                    :first-day-of-week='firstDayOfWeek'
                    range
                    width='272'
                    color='primary'
                    @input='() => changeDate()'
                    :min='endDate'
                    :max='now')
                v-card-text
                  div
                    .d-flex.align-baseline.flex-wrap
                      v-text-field#inputCopyUrl.mr-2(
                        :value='publicLink'
                        v-show='publicLink')
                      v-btn.dialog__button.primary(
                        v-if='publicLink'
                        @click='copyLink') {{ $lang.copyLink }}
                    .d-flex.align-baseline.mt-3
                      v-btn.dialog__button.primary(
                        depressed
                        @click='generateLink'
                        :disabled='disabledBtnGenLink') {{ $lang.generateLink }}

            span.reports__caution.my-2 {{ availableBoards && availableBoards.length ? $lang.aboutData : $lang.aboutAdmin }}

    div(
      v-if='isLoaded && Object.keys(reports).length'
      :class='memberId ? "reports__content-wrapper-member" : "reports__content-wrapper"')
      ReportsContents(:reports='reports')
      InfiniteLoading(
        @infinite='infiniteHandler'
        spinner='spiral')
</template>

<script>
import { mdiClose } from '@mdi/js'
import InfiniteLoading from 'vue-infinite-loading'
import { mapGetters, createNamespacedHelpers } from 'vuex'
const { mapGetters: reportsMapGetters } = createNamespacedHelpers('reports')
const { mapGetters: boardsMapGetters } = createNamespacedHelpers('boards')
const { mapGetters: sprintsMapGetters } = createNamespacedHelpers('sprints')
const { mapGetters: optionsMapGetters } = createNamespacedHelpers('options')
import config from '@/../config.json'
import { YMD, LLP } from '@/helpers/dateFormatter'

const EmptyEntities = () =>
  import(
    /* webpackChunkName: "EmptyEntities" */ '@/components/EmptyEntities.vue'
  )
const ReportsContents = () =>
  import(
    /* webpackChunkName: "ReportsContents" */ /* webpackMode: "eager" */ '@/views/Reports/components/ReportsContents.vue'
  )

export default {
  name: 'ReportsComponent',
  components: {
    ReportsContents,
    InfiniteLoading,
    EmptyEntities,
  },
  props: {
    memberId: { type: String },
    sinceReport: { type: Number },
  },
  data() {
    return {
      since: false,
      isLoaded: false,
      isPreLoad: false,

      reportsBoard: null,
      reportsSprint: null,

      publicLink: null,
      dates: [],
      startDateReport: YMD(Date.now()),

      reg: /https?:\/\/[\w*.]*.*(?=%[\w|\d]{2})/g,

      popupPublicReport: false,
      now: new Date(Date.now()).toISOString(),
      mdiClose,
    }
  },
  created() {
    this.vSince =
      this.memberId && this.sinceReport
        ? YMD(this.sinceReport)
        : YMD(Date.now())

    if (!this.memberId) {
      this.$store.dispatch('reports/getAvailableBoards')
    }
  },
  destroyed() {
    this.$store.commit('reports/SET_TO_ZERO_REPORTS')
    this.$store.commit('reports/SET_SINCE_TODAY')
    this.$store.commit('boards/SET_TO_ZERO_BOARDS')
    this.$store.commit('boards/SET_TO_ZERO_BOARDS_MEMBERS')
  },
  computed: {
    ...mapGetters(['isDark', 'userId', 'lang']),
    ...reportsMapGetters(['reports', 'sinceDate', 'availableBoards']),
    ...boardsMapGetters(['boards']),
    ...sprintsMapGetters(['sprints']),
    ...optionsMapGetters(['firstDayOfWeek']),

    vSince: {
      get() {
        return this.startDateReport
      },
      set(value) {
        this.startDateReport = value
        this.isLoaded = false
        this.since = false
        this.$store.commit('reports/SET_SINCE_DATE', value)
        this.$store.commit('reports/SET_TO_ZERO_REPORTS')
        this.$store
          .dispatch('reports/getReports', this.memberId)
          .finally(() => {
            this.isLoaded = true
          })
      },
    },
    disabledBtnGenLink() {
      return !(
        this.reportsBoard?.length &&
        this.dates.length === 2 &&
        this.dates[0] !== this.dates[1]
      )
    },
    filterSprints() {
      return this.sprints.filter((s) => !s.error)
    },
    computedDateFormatted() {
      return `${this.$lang.since} ${LLP(this.vSince, this.$lang)}`
    },
    endDate() {
      let date = new Date()
      let year = date.getFullYear() - 1
      let month = date.getMonth() + 1
      let day = date.getDate() + 1

      return `${year}-${month < 10 ? '0' + month : month}-${
        day < 10 ? '0' + day : day
      }`
    },
  },
  methods: {
    infiniteHandler($state) {
      if (!this.isPreLoad) {
        this.isPreLoad = true
        const yesterday = YMD(
          new Date(this.sinceDate).setDate(
            new Date(this.sinceDate).getDate() - 1
          )
        )
        this.$store.commit('reports/SET_SINCE_DATE', yesterday)
        this.$store.dispatch('reports/getReports', this.memberId).then(() => {
          this.isPreLoad = false
          $state.loaded()
        })
      }
    },
    generateLink() {
      const data = {
        boards: this.reportsBoard,
      }

      const d0 = new Date(this.dates[0])
      const d1 = new Date(this.dates[1])

      if (d0 < d1) {
        data.since = YMD(d0)
        data.before = YMD(d1)
      } else {
        data.since = YMD(d1)
        data.before = YMD(d0)
      }

      this.$store.dispatch('reports/createPublicReports', data).then((data) => {
        this.publicLink = `${config.baseURL}/reports/${data.signing_dumps}/`
      })
    },
    copyLink() {
      navigator.clipboard.writeText(this.publicLink).then(() => {
        try {
          if (this.publicLink) {
            this.$store.commit('ADD_NOTIFICATION', {
              color: 'success',
              text: this.$lang.copyLinkSuccessful,
            })
          } else {
            this.$store.commit('ADD_NOTIFICATION', {
              color: 'error',
              text: this.$lang.copyLinkUnsuccessful,
            })
          }
        } catch (err) {
          this.$store.commit('ADD_NOTIFICATION', {
            color: 'error',
            text: this.$lang.copyLinkError,
          })
        }
      })
    },
    decoder(text) {
      try {
        return text.length && this.reg.test(text) ? decodeURI(text) : text
      } catch (e) {
        return text
      }
    },
    changeDate() {
      this.publicLink = ''
    },
    setDates(sprintId) {
      const sprint = this.sprints.find((s) => s.id === sprintId)
      this.dates = [YMD(sprint.starts_at), YMD(sprint.ends_at)]
    },
  },
}
</script>

<style lang="scss" scoped>
.reports {
  &__header {
    position: fixed;
    top: 110px;
    left: 364px;
    right: 104px;
    z-index: 5;

    @media (max-width: 960px) {
      left: 328px;
      right: 68px;
    }

    @media (max-width: 600px) {
      top: 56px;
      left: 0;
      right: 0;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__header-text {
    padding-top: 6px;
  }

  &__dialog-wrapper {
    align-items: flex-end;
    margin-top: 16px;

    @media (max-width: 960px) {
      align-items: flex-start;
    }
  }

  &__caution {
    text-align: end;

    @media (max-width: 960px) {
      text-align: start;
    }
  }

  &__content-wrapper {
    padding-top: 98px;

    @media (max-width: 960px) {
      padding-top: 172px;
    }

    @media (max-width: 600px) {
      padding-top: 138px;
    }
  }

  &__content-wrapper-member {
    padding-top: 24px;

    @media (max-width: 960px) {
      padding-top: 98px;
    }

    @media (max-width: 600px) {
      padding-top: 64px;
    }
  }
}

.date {
  margin-top: 20px;

  &__icon {
    // margin-bottom: 0px;
    margin-right: 60px;
  }

  &__menu-item {
    @include subtitle-date;
    color: var(--v-title-base) !important;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
}

.button__common {
  @include btn-common;
}
</style>

<style lang="scss">
.date__input.v-text-field input {
  @include subtitle-date;
  color: var(--v-title-base) !important;
  text-decoration: underline;
  text-underline-offset: 2px;
  text-align: center;
  padding-left: 0;
}

.date__input .v-input__slot::before {
  border-color: transparent !important;
}

.date__icon.v-icon > svg > path {
  fill: var(--v-title-base);
}

.picker__close {
  @include btn-common;
}

.reports__picker .v-picker__body,
.reports__picker .v-picker__actions {
  background: var(--v-base-base) !important;
}

.dialog {
  &__title {
    font-family: var(--font-raleway);
  }

  &__button {
    @include btn-common;
  }
}

.reports__picker .v-date-picker-table thead th {
  color: var(--v-primary-base);
}

.reports__picker .v-date-picker-header__value button[type='button'],
.reports__picker
  .v-date-picker-header__value
  button[type='button']:not(:hover) {
  font-family: var(--font-raleway);
  color: var(--v-title-base) !important;
  font-size: 18px;
}

.reports__picker .v-date-picker-table .v-btn.v-btn--active::before {
  background-color: var(--v-primary-base) !important;
  opacity: 1;
  border-radius: 6px;
}

.reports__picker .v-date-picker-table .v-btn {
  border-radius: 6px;
}

.infinite-status-prompt {
  display: initial;
}

.reports__select .v-select__slot {
  max-height: initial;
}
</style>
