<template lang="pug">
div(v-if='publicReportsByTasks.length')
  template(v-for='board in publicReportsByTasks')
    PublicReportCard(
      :board='board'
      reportType='2'
      :isLoadedByTasks='isLoadedByTasks')
div(v-else)
  PublicReportCard(
    :board='{}'
    reportType='2'
    :isLoadedByTasks='isLoadedByTasks')
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters: reportsMapGetters } = createNamespacedHelpers('reports')

const PublicReportCard = () =>
  import(
    /* webpackChunkName: "PublicReportCard" */ /* webpackMode: "eager" */ '@/views/PublicReport/components/PublicReportCard.vue'
  )

export default {
  name: 'PublicReportByTasks',
  components: {
    PublicReportCard,
  },
  props: {
    isLoadedByTasks: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...reportsMapGetters(['publicReportsByTasks']),
  },
}
</script>

<style lang="scss" scoped></style>
