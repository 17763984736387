<template lang="pug">
a.email-link(:href='`mailto:${email}`') {{ email }}
</template>

<script>
import { email } from '@/../config.json'

export default {
  name: 'EmailLink',
  computed: {
    email() {
      return email
    },
  },
}
</script>

<style lang="scss" scoped>
.email-link {
  color: var(--v-main-base) !important;
  text-decoration: none;
}
</style>
