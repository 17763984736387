<template lang="pug">
v-card.reports__card(
  v-if='Object.keys(board).length && board.report_json && board.report_json.length'
  :key='board.id'
  elevation='0'
  :class='board.report_json ? "pt-3" : ""')
  v-expansion-panels.backgroundMain(
    flat
    v-if='board.report_json')
    h3.reports__title.text--text.pa-4(v-if='board.report_json') {{ board.board.name }}
    v-expansion-panel(
      v-for='(task, idx) in board.report_json'
      :key='task.idShort')
      v-expansion-panel-header.report__panel-header.py-sm-4.py-2.d-sm-flex(
        :class='idx % 2 === 0 ? "backgroundMain" : "backgroundReport"')
        ReportHeader(
          :task='task'
          :users='[]'
          :isPublic='true'
          :isActionDataForCopyToClipboard='true')
      v-expansion-panel-content(
        :class='idx % 2 === 0 ? "backgroundMain" : "backgroundReport"')
        v-list.px-3(
          dense
          :class='idx % 2 === 0 ? "backgroundMain" : "backgroundReport"'
          v-if='task.actions.length')
          ReportText(
            :task='task'
            v-for='action in [...task.actions].reverse()'
            :action='action'
            :key='action.id'
            :isPpublic='true')
        .reports__actions-description(v-else) {{ $lang.reportActionsDescription }}

  .reports__bottom(
    :style='{background: `${backgroundColor}`, height: `${backgroundHeight}`}')
.reports__empty-text.title--text(
  v-else-if='Object.keys(board).length && reportType === "2"')
  h3.reports__title.text--text.pa-4 {{ board.board.name }}
  span(v-if='!board.report_json || !board.report_json.length') {{ $lang.noTypeOfReport }}
.reports__empty-text.title--text(
  v-else-if='!Object.keys(board).length && reportType === "2" && isLoadedByTasks') {{ $lang.noTypeOfReport }}

.reports__loader(v-else-if='!isLoadedByDays')
  v-progress-linear(indeterminate)
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
const { mapGetters: reportsMapGetters } = createNamespacedHelpers('reports')

const ReportText = () =>
  import(
    /* webpackChunkName: "ReportText" */ /* webpackMode: "eager" */ '@/components/ReportText.vue'
  )
const ReportHeader = () =>
  import(
    /* webpackChunkName: "ReportHeader" */ /* webpackMode: "eager" */ '@/views/Reports/components/ReportHeader.vue'
  )

export default {
  name: 'PublicReportCard',
  components: {
    ReportText,
    ReportHeader,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    reportType: {
      type: String,
      default: '1',
    },
    isLoadedByTasks: {
      type: Boolean,
      default: false,
    },
    isLoadedByDays: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'isTablet']),
    ...reportsMapGetters(['publicReportsMain']),
    backgroundColor() {
      return this.board.report_json && this.board.report_json?.length % 2 === 0
        ? 'var(--v-backgroundReport-base)'
        : 'var(--v-backgroundMain-base)'
    },
    backgroundHeight() {
      return this.board.report_json && this.board.report_json?.length
        ? '10px'
        : '0'
    },
  },
}
</script>

<style lang="scss" scoped>
.reports {
  &__card {
    position: relative;
    margin-bottom: 32px;
  }

  &__title {
    font-family: var(--font-raleway);
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 8px;
    position: sticky;
    top: 60px;
    z-index: 9;
    background: var(--v-backgroundMain-base);
    border-radius: 40px !important;
  }

  &__bottom {
    width: 100%;
  }

  &__empty-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 4rem;
    font-family: var(--font-raleway);
    font-size: 20px;
  }

  &__actions-description {
    font-family: var(--font-raleway);
    font-style: italic;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
</style>
