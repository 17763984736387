<template lang="pug">
v-row
  v-col.col-12.col-md-9.col-lg-12.col-xl-8
    v-form.timezone__form(@submit.prevent='submitTimeZone')
      v-autocomplete.timezone__select(
        v-model='timeZone'
        :items='zones'
        item-value='value'
        item-text='label'
        :label='zones.label')
      button.setting__button-blue(
        type='submit'
        :disabled='!isActive') {{ $lang.save }}
</template>

<script>
import { timezones_en, timezones_ru } from '@/helpers/timezones.js'
import { createNamespacedHelpers, mapGetters, mapMutations } from 'vuex'
const { mapState: boardsMapState, mapActions: boardMapActions } =
  createNamespacedHelpers('boards')

export default {
  name: 'TimeZonePicker',
  data: () => ({
    timeZone: null,
    isActive: false,
    initTimeZone: null,
  }),
  computed: {
    ...boardsMapState(['currentBoard']),
    ...mapGetters(['lang']),
    zones() {
      const arr = []
      const t = this.lang === 'ru' ? timezones_ru : timezones_en
      for (const [label, value] of Object.entries(t)) {
        arr.push({ label, value })
      }
      return arr
    },
  },
  watch: {
    timeZone(n, o) {
      this.isActive = n !== o && n !== this.initTimeZone && n
    },
  },
  created() {
    const timeZoneObject = this.zones.filter(
      (el) => +el.value === this.currentBoard.time_zone
    )[0]
    this.timeZone = this.initTimeZone = timeZoneObject.value
  },
  methods: {
    ...mapMutations(['ADD_NOTIFICATION']),
    ...boardMapActions(['patchSettingsBoard']),
    submitTimeZone() {
      this.patchSettingsBoard({
        boardId: this.currentBoard.id,
        time_zone: +this.timeZone,
      }).then(() => {
        this.isActive = false
        this.initTimeZone = this.timeZone
        this.ADD_NOTIFICATION({
          color: 'success',
          text: this.$lang.settingsSaved,
        })
      })
    },
  },
}
</script>

<style lang="scss">
.timezone__select .v-select__selection,
.v-select__selection--comma {
  text-transform: none;
}
</style>
