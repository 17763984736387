<template lang="pug">
v-row(no-gutters)
  v-progress-linear(
    :size='50'
    :width='5'
    indeterminate
    v-show='!isLoadedMember')
  v-col.member.col-12(v-if='member && isLoadedMember')
    .member__activity-wrap
      UserServices(:services='member.users_services')
      UserProjects(:projects='member.member_boards')
      v-divider.my-8
      RadioButtonsGroup(
        @toggleChartFilterType='toggleChartFilterType'
        :radioFilterType='filterType')
      MemberActivityCalendar(
        v-if='isLoadedMember'
        :member='member'
        visualMapType='continuous'
        :filterType='filterType')
      MemberActivityBar(
        v-if='isLoadedMember'
        :member='member'
        :filterType='filterType')
      .member__select-wrapper
        v-select.member__select.mr-5.mr-lg-10(
          v-model='selectedYear'
          :label='$lang.selectYear'
          :items='yearsForSelect'
          clearable)
        v-select.member__select.mr-5.mr-lg-10(
          v-model='selectedMonth'
          :label='$lang.selectMonth'
          :items='monthForSelect'
          item-text='text'
          item-value='value'
          clearable)
        button.setting__button-blue(
          :disabled='!selectedYear || (!selectedMonth && selectedMonth !== 0)'
          @click='getReportData()') {{ $lang.selectReportPeriod }}
      v-divider.my-8
    Reports.pt-0(
      :memberId='member.trello_id'
      :sinceReport='sinceReport')
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const {
  mapState: membersMapState,
  mapMutations: membersMapMutations,
  mapActions: membersMapActions,
} = createNamespacedHelpers('members')

const Reports = () =>
  import(/* webpackChunkName: "Reports" */ '@/views/Reports/Reports.vue')
const MemberActivityCalendar = () =>
  import(
    /* webpackChunkName: "MemberActivityCalendar" */ /* webpackMode: "eager" */ '@/views/Members/components/MemberActivityCalendar.vue'
  )
const MemberActivityBar = () =>
  import(
    /* webpackChunkName: "MemberActivityBar" */ /* webpackMode: "eager" */ '@/views/Members/components/MemberActivityBar.vue'
  )
const RadioButtonsGroup = () =>
  import(
    /* webpackChunkName: "RadioButtonsGroup" */ /* webpackMode: "eager" */ '@/views/Members/components/RadioButtonsGroup.vue'
  )
const UserServices = () =>
  import(
    /* webpackChunkName: "UserServices" */ /* webpackMode: "eager" */ '@/views/Members/components/UserServices.vue'
  )
const UserProjects = () =>
  import(
    /* webpackChunkName: "UserProjects" */ /* webpackMode: "eager" */ '@/views/Members/components/UserProjects.vue'
  )

export default {
  name: 'MemberComponent',
  components: {
    Reports,
    MemberActivityCalendar,
    MemberActivityBar,
    RadioButtonsGroup,
    UserServices,
    UserProjects,
  },
  provide: {
    marginBottom: '30px',
  },
  data() {
    return {
      isLoadedMember: false,
      filterType: 'actions',
      selectedYear: null,
      selectedMonth: null,
    }
  },
  computed: {
    ...membersMapState(['member', 'chartFilterType']),
    sinceReport() {
      return this.member && this.member.actual
        ? 0
        : this.member.last_action_datetime
    },
    yearsForSelect() {
      return [
        new Date(
          new Date().setFullYear(new Date().getFullYear() - 1)
        ).getFullYear(),
        new Date().getFullYear(),
      ]
    },
    monthForSelect() {
      if (this.selectedYear === new Date().getFullYear()) {
        const current = new Date().getMonth()
        let currentMonthItems = []
        for (let i = 0; i <= current; i++) {
          currentMonthItems.push({
            value: i,
            text: this.$lang.monthsList[i],
          })
        }
        return currentMonthItems
      }
      let date = new Date()
      date.setDate(date.getDate() - 365)
      let lastMonthItems = []
      for (let i = date.getMonth(); i <= 11; i++) {
        lastMonthItems.push({ value: i, text: this.$lang.monthsList[i] })
      }
      return lastMonthItems
    },
  },
  created() {
    this.setToZeroActivity()
    this.setToZeroProjectActivity()
    Promise.all([
      this.getMember(this.$route.params.id),
      this.getActivity({
        id: this.$route.params.id,
        filter_type: this.chartFilterType,
      }),
    ]).finally(() => {
      this.isLoadedMember = true
      this.filterType = this.chartFilterType
    })
  },
  destroyed() {
    this.setToZeroMember()
  },
  methods: {
    ...membersMapMutations([
      'setToZeroMember',
      'setChartFilterType',
      'setToZeroActivity',
      'setToZeroProjectActivity',
    ]),
    ...membersMapActions(['getMember', 'getActivity']),
    toggleChartFilterType(val) {
      this.setChartFilterType(val)
      this.filterType = val
    },
    getReportData() {
      this.setToZeroProjectActivity()
      this.getActivity({
        id: this.$route.params.id,
        month: this.selectedMonth + 1,
        year: this.selectedYear,
        filter_type: this.chartFilterType,
      })
      // this.clearSelects()
    },
    clearSelects() {
      this.selectedMonth = null
      this.selectedYear = null
    },
  },
}
</script>

<style lang="scss" scoped>
.member {
  &__activity-wrap {
    // margin-top: 98px;
  }

  &__select-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }

  &__select {
    max-width: 200px;
  }
}
</style>

<style lang="scss">
.member__select .v-select__slot > label {
  color: var(--v-text-base);
  padding-left: 0;
}

.member .reports__header.reports__header {
  position: static;
  padding-left: 0;
}
</style>
