import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'
import { light, dark } from '@/helpers/themes'

import BoardsIcon from '@/assets/img/icons/BoardsIcon'
import FaqIcon from '@/assets/img/icons/FaqIcon'
import GanttIcon from '@/assets/img/icons/GanttIcon'
import MembersIcon from '@/assets/img/icons/MembersIcon'
import ReportsIcon from '@/assets/img/icons/ReportsIcon'
import SprintsIcon from '@/assets/img/icons/SprintsIcon'
import GearIcon from '@/assets/img/icons/GearIcon'
import LaunchIcon from '@/assets/img/icons/LaunchIcon'
import ListIcon from '@/assets/img/icons/ListIcon'
import ScheduleIcon from '@/assets/img/icons/ScheduleIcon'
import EventIcon from '@/assets/img/icons/EventIcon'
import CalendarIcon from '@/assets/img/icons/CalendarIcon'
import StringIcon from '@/assets/img/icons/StringIcon'
import TileIcon from '@/assets/img/icons/TileIcon'
import FlagRuIcon from '@/assets/img/icons/FlagRuIcon'
import FlagEnIcon from '@/assets/img/icons/FlagEnIcon'
import ExitIcon from '@/assets/img/icons/ExitIcon'
import UserIcon from '@/assets/img/icons/UserIcon'
import DashboardIcon from '@/assets/img/icons/DashboardIcon'
import OptionsIcon from '@/assets/img/icons/OptionsIcon'
import GithubIcon from '@/assets/img/icons/GithubIcon'
import GitlabIcon from '@/assets/img/icons/GitlabIcon'
import TrelloIcon from '@/assets/img/icons/TrelloIcon'
import BitbucketIcon from '@/assets/img/icons/BitbucketIcon'
import OnboardingIcon from '@/assets/img/icons/OnboardingIcon'
import GearBoardIcon from '@/assets/img/icons/GearBoardIcon'

Vue.use(Vuetify)
import ru from 'vuetify/es5/locale/ru'
import en from 'vuetify/es5/locale/en'

export default new Vuetify({
  theme: {
    themes: {
      light,
      dark,
    },
    options: {
      customProperties: true,
    },
  },
  lang: {
    locales: { ru, en },
    current: 'en',
  },
  icons: {
    iconfont: 'mdiSvg',
    values: {
      boardsIcon: {
        component: BoardsIcon,
      },
      faqIcon: {
        component: FaqIcon,
      },
      ganttIcon: {
        component: GanttIcon,
      },
      membersIcon: {
        component: MembersIcon,
      },
      reportsIcon: {
        component: ReportsIcon,
      },
      sprintsIcon: {
        component: SprintsIcon,
      },
      gearIcon: {
        component: GearIcon,
      },
      launchIcon: {
        component: LaunchIcon,
      },
      listIcon: {
        component: ListIcon,
      },
      scheduleIcon: {
        component: ScheduleIcon,
      },
      eventIcon: {
        component: EventIcon,
      },
      calendarIcon: {
        component: CalendarIcon,
      },
      stringIcon: {
        component: StringIcon,
      },
      tileIcon: {
        component: TileIcon,
      },
      flagRuIcon: {
        component: FlagRuIcon,
      },
      flagEnIcon: {
        component: FlagEnIcon,
      },
      exitIcon: {
        component: ExitIcon,
      },
      userIcon: {
        component: UserIcon,
      },
      dashboardIcon: {
        component: DashboardIcon,
      },
      optionsIcon: {
        component: OptionsIcon,
      },
      githubIcon: {
        component: GithubIcon,
      },
      gitlabIcon: {
        component: GitlabIcon,
      },
      trelloIcon: {
        component: TrelloIcon,
      },
      bitbucketIcon: {
        component: BitbucketIcon,
      },
      onboardingIcon: {
        component: OnboardingIcon,
      },
      gearBoardIcon: {
        component: GearBoardIcon,
      },
    },
  },
})
