const state = {
  isActiveNotification: false,
  notificationInfo: {},
  isConfirmEmail: true,
  isError: false,
}

const getters = {
  isActiveNotification: ({ isActiveNotification }) => isActiveNotification,
  notificationInfo: ({ notificationInfo }) => notificationInfo,
  isConfirmEmail: ({ isConfirmEmail }) => isConfirmEmail,
  isError: ({ isError }) => isError,
}

const mutations = {
  ADD_NOTIFICATION: (state, data) => {
    state.notificationInfo = data
    state.isActiveNotification = true
  },
  CLOSE_NOTIFICATION: (state) => {
    state.notificationInfo = {}
    state.isActiveNotification = false
  },
  CONFIRM_EMAIL: (state, data) => {
    state.isConfirmEmail = data
  },
  SET_IS_ERROR: (state) => {
    state.isError = true
  },
}

export default {
  state,
  getters,
  mutations,
}
