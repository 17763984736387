var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-divider',{staticClass:"mb-8"}),_c('div',{staticClass:"d-flex flex-column mb-8"},[_c('div',{staticClass:"options__subtitle title--text d-flex align-center"},[_vm._v(_vm._s(_vm.$lang.workingTimezone)),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"lightGrey--text ml-2 align-self-start",attrs:{"color":"lightGrey","dark":"","size":"14"}},on),[_vm._v(_vm._s(_vm.mdiInformation))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$lang.workingTimezoneInfo))])])],1),_c('TimeZonePicker')],1),_c('v-divider',{staticClass:"mb-8"}),_c('div',{staticClass:"d-flex flex-column mb-8"},[_c('div',{staticClass:"options__subtitle title--text d-flex align-center"},[_vm._v(_vm._s(_vm.$lang.fullForcedSync)),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"lightGrey--text ml-2 align-self-start",attrs:{"color":"lightGrey","dark":"","size":"14"}},on),[_vm._v(_vm._s(_vm.mdiInformation))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$lang.fullForcedSyncInfo))])])],1),_c('div',{staticClass:"options__button-sync-wrap d-flex"},[_c('ButtonSync',{attrs:{"initTime":_vm.initTime,"buttonStateName":"FullSyncTime","actionFunc":_vm.syncBoards,"buttonText":{syncText: _vm.$lang.synchronizeForcibly, noSyncText: _vm.$lang.synchronizationInProgress},"boardId":+_vm.$route.params.id,"disableSync":_vm.disableSync}})],1)]),_c('v-divider',{staticClass:"mb-8"}),_c('div',{staticClass:"d-flex flex-column mb-8"},[_c('div',{staticClass:"options__subtitle title--text d-flex align-center"},[_vm._v(_vm._s(_vm.$lang.roughSync)),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"lightGrey--text ml-2 align-self-start",attrs:{"color":"lightGrey","dark":"","size":"14"}},on),[_vm._v(_vm._s(_vm.mdiInformation))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$lang.roughSyncInfo))])])],1),_c('div',{staticClass:"options__button-sync-wrap d-flex"},[_c('ButtonSync',{attrs:{"initTime":_vm.initTime,"buttonStateName":"RoughSyncTime","actionFunc":_vm.syncBoardsRoughly,"buttonText":{syncText: _vm.$lang.synchronizeDirectly, noSyncText: _vm.$lang.synchronizationInProgress},"boardId":+_vm.$route.params.id,"disableSync":_vm.disableSync}})],1)]),_c('v-divider',{staticClass:"mb-8"}),_c('div',{staticClass:"d-flex flex-column mb-8"},[_c('div',{staticClass:"options__subtitle title--text d-flex align-center"},[_vm._v(_vm._s(_vm.$lang.commentAboutCommits)),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"lightGrey--text ml-2 align-self-start",attrs:{"color":"lightGrey","dark":"","size":"14"}},on),[_vm._v(_vm._s(_vm.mdiInformation))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$lang.commentAboutCommitsInfo))])])],1),_c('v-switch',{staticClass:"mt-2",on:{"change":_vm.changedTrackingOptions},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"switch__label text--text ml-5"},[_vm._v(_vm._s(_vm.$lang.isCommenting))])]},proxy:true}]),model:{value:(_vm.isCommenting),callback:function ($$v) {_vm.isCommenting=$$v},expression:"isCommenting"}}),(_vm.isCommenting)?_c('div',{staticClass:"commenting"},[_c('v-select',{staticClass:"commenting__commenting",attrs:{"items":_vm.trackingOptions,"item-value":"key","item-text":"text","label":_vm.$lang.trackingOption,"messages":_vm.getMessage()},model:{value:(_vm.choosenOption),callback:function ($$v) {_vm.choosenOption=$$v},expression:"choosenOption"}}),_c('button',{staticClass:"setting__button-blue commenting__button-start",attrs:{"disabled":!_vm.isActive},on:{"click":_vm.changedTrackingOptions}},[_vm._v(_vm._s(_vm.$lang.save))])],1):_vm._e()],1),_c('v-divider',{staticClass:"mb-8"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }