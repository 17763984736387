<template lang="pug">
v-row(no-gutters)
  v-progress-linear(
    indeterminate
    v-show='loading')
  EmptyEntities(
    v-if='!loading && !card'
    :message='$lang.cardErrorMessage')
  template(v-else-if='!loading && card')
    v-col.card__wrapper.col-12.px-0.d-flex.flex-column
      CardHeader(
        :card='card'
        :users='getMembersForReport(actualBoardMembers, card.members)'
        :isClient='card.is_client')
      SectionComponent(:card='card')
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapActions: cardMapActions, mapState: cardMapState } =
  createNamespacedHelpers('cards')
const { mapActions: boarddMapActions, mapState: boarddMapState } =
  createNamespacedHelpers('boards')

const EmptyEntities = () =>
  import(
    /* webpackChunkName: "EmptyEntities" */ '@/components/EmptyEntities.vue'
  )
const CardHeader = () =>
  import(
    /* webpackChunkName: "CardHeader" */ /* webpackMode: "eager" */ '@/views/Card/components/CardHeader.vue'
  )
const SectionComponent = () =>
  import(
    /* webpackChunkName: "SectionComponent" */ /* webpackMode: "eager" */ '@/views/Card/components/SectionComponent.vue'
  )

export default {
  name: 'CardComponent',
  components: {
    EmptyEntities,
    CardHeader,
    SectionComponent,
  },
  data: () => ({}),
  computed: {
    ...cardMapState(['card', 'loading']),
    ...boarddMapState(['actualBoardMembers']),
  },
  async created() {
    await this.getCardData(this.$route.params.cardId)
    this.card?.board_id ? this.getBoard(this.card.board_id) : null
  },
  methods: {
    ...cardMapActions(['getCardData']),
    ...boarddMapActions(['getBoard']),
    getMembersForReport(boardMembers, members) {
      return boardMembers
        ? boardMembers.filter(
            (member) => !!members.length && members.includes(member.trello_id)
          )
        : null
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  &__wrapper {
    color: var(--v-title-base);
  }
}
</style>
