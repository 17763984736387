<template lang="pug">
.burndown
  .burndown__select-wrapper
    v-select.burndown__select.col-12.col-md-6.col-lg-4(
      v-model='sprintNumber'
      hide-details
      :items='optionItems'
      :label='$lang.selectSprintNumber')
    ChartRangeDatePicker(
      :minCalendarAvailableDate='minCalendarAvailableDate'
      :maxCalendarAvailableDate='maxCalendarAvailableDate'
      :isError='isError'
      :ttype='ttype'
      @initChartData='initChartData')
  span.burndown__error {{ errorMessage }}
  v-chart.chart(
    :option='option'
    autoresize)
</template>

<script>
import { mdiCalendar } from '@mdi/js'
import { createNamespacedHelpers, mapGetters, mapMutations } from 'vuex'
const {
  mapState: chartsMapState,
  mapActions: chartsMapActions,
  mapMutations: chartMapMutations,
} = createNamespacedHelpers('charts')
const { mapActions: boardsMapActions } = createNamespacedHelpers('boards')

const { mapState: sprintsMapState, mapActions: sprintsMapActions } =
  createNamespacedHelpers('sprints')

import { light, dark } from '@/helpers/themes'
import { getArrayOfDatesFromRange, YMD } from '@/helpers/dateFormatter'

const ChartRangeDatePicker = () =>
  import(
    /* webpackChunkName: "ChartRangeDatePicker" */ '@/views/Charts/components/ChartRangeDatePicker.vue'
  )

import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { LineChart, BarChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from 'echarts/components'
import VChart, { INIT_OPTIONS_KEY } from 'vue-echarts'

use([
  TitleComponent,
  TooltipComponent,
  CanvasRenderer,
  LegendComponent,
  GridComponent,
  LineChart,
  BarChart,
])

export default {
  name: 'BurndownChart',
  components: {
    VChart,
    ChartRangeDatePicker,
  },
  provide: {
    [INIT_OPTIONS_KEY]: {
      // height: '600',
    },
  },
  props: {
    id: {
      type: [String, Number],
    },
  },
  data: () => ({
    option: {},
    xAxisData: [],
    seriesData: [],
    sprintNumber: undefined,
    lastSprintNumber: null,
    errorMessage: '',
    sprintStartDate: null,
    sprintEndDate: null,
    isError: false,
    mdiCalendar,
    menu: false,
    ttype: 'burndown_chart',
  }),
  computed: {
    ...mapGetters(['isDark', 'isMobile', 'isTablet', 'lang']),
    ...chartsMapState(['burndownData']),
    ...sprintsMapState(['sprints']),
    colorStyle() {
      return this.isDark ? dark.title : light.title
    },
    optionItems() {
      const items = []
      this.sprints.forEach((sprint) => items.push(sprint.number))
      return items.sort((a, b) => b - a)
    },
    minCalendarAvailableDate() {
      let date = new Date()
      let year = date.getFullYear() - 1
      let month = (date.getMonth() + 1).toString()
      let day = (date.getDate() + 1).toString()

      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    maxCalendarAvailableDate() {
      let date = new Date()
      date.setDate(date.getDate() + 30)
      return date.toISOString()
    },
  },
  watch: {
    sprintNumber(n, o) {
      if (!n) {
        return
      }
      if (n && o !== undefined) {
        this.getStatCards({
          board_id: this.$route.params.id,
          sprint_number: n,
          ttype: this.ttype,
        }).then(() => this.initChartData())
      }
    },
  },
  async created() {
    this.setBurndownData([])
    const res1 = await this.getBoard(this.$route.params.id)
    if (res1?.error) {
      this.ADD_NOTIFICATION({
        color: 'error',
        text: res1.error.detail,
      })
    }
    const res2 = await this.getBoardSprints(this.$route.params.id)
    if (res2?.error) {
      this.ADD_NOTIFICATION({
        color: 'error',
        text: res2.error.detail,
      })
    }
    await this.getLastSprintNumber()
    if (this.lastSprintNumber) {
      await this.getStatCards({
        board_id: this.$route.params.id,
        sprint_number: this.lastSprintNumber,
        ttype: this.ttype,
      })
      this.sprintNumber = this.lastSprintNumber
    } else {
      this.sprintNumber = null
    }

    this.initChartData()
  },
  methods: {
    ...mapMutations(['ADD_NOTIFICATION']),
    ...chartsMapActions(['getStatCards']),
    ...chartMapMutations(['setBurndownData']),
    ...boardsMapActions(['getBoard']),
    ...sprintsMapActions(['getBoardSprints']),
    initChartData(dates) {
      if (this.burndownData.length) {
        this.errorMessage = ''
        this.getDateRange(dates)
      } else if (!this.burndownData.length && this.sprintNumber) {
        this.errorMessage = this.$lang.burndownChartErrorMessage
      }
      this.getIdealBurndownSeries()
      this.getRemainingCards()
      this.setOption()
    },
    getDateRange(dates = []) {
      this.xAxisData = []
      if (!dates.length && this.sprints.length) {
        const sprint = this.sprints.find(
          (sprint) => sprint.number === this.sprintNumber
        )
        this.sprintStartDate = sprint.starts_at
        this.sprintEndDate = sprint.ends_at
      }
      if (dates.length) {
        this.sprintStartDate = dates[0]
        this.sprintEndDate = dates[1]
      }
      this.xAxisData = getArrayOfDatesFromRange(
        this.sprintStartDate,
        this.sprintEndDate
      )
    },
    getLastSprintNumber() {
      const reducer = (prev, curr) => {
        return prev.number > curr.number ? prev : curr
      }
      if (this.sprints.length) {
        this.lastSprintNumber = this.sprints.reduce(reducer).number
      }
    },
    getIdealBurndownSeries() {
      this.seriesData = []
      const data = []
      if (this.burndownData.length) {
        data[0] = [YMD(this.sprintStartDate), this.burndownData[0].card_count]
        data[1] = [YMD(this.sprintEndDate), 0]
      }
      const idealSeries = {
        name: this.$lang.idealBurndown,
        type: 'line',
        data,
        color: this.isDark ? dark.role0 : light.role0,
      }
      this.seriesData.push(idealSeries)
    },
    getRemainingCards() {
      const lineData = []
      const barData = []
      if (this.burndownData.length) {
        this.burndownData.forEach((item) => lineData.push(item.card_count))
        this.burndownData.forEach((item) => barData.push(item.closed_cards))
      }
      const cardsLineSeries = {
        name: this.$lang.remainingTasks,
        type: 'line',
        data: lineData,
        color: this.isDark ? dark.role1 : light.role1,
      }
      this.seriesData.push(cardsLineSeries)

      const cardsBarSeries = {
        name: this.$lang.conpletedTasks,
        type: 'bar',
        data: barData,
        color: this.isDark ? dark.role2 : light.role2,
      }
      this.seriesData.push(cardsBarSeries)
    },
    setOption() {
      this.option = {
        grid: {
          bottom: '15%',
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          textStyle: {
            color: this.colorStyle,
          },
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: this.xAxisData,
            axisLabel: {
              rotate: 90,
              color: this.colorStyle,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              color: this.colorStyle,
            },
          },
        ],
        series: this.seriesData,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.burndown {
  width: 100%;
  height: 100%;
  margin-top: 50px;

  @media (max-width: 600px) {
    margin-top: 0px;
  }

  &__select-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__error {
    display: inline-block;
    color: red;
    font-size: 14px;
    margin-bottom: 8px;
    height: 21px;
  }
}

.chart {
  width: 100%;
  height: 500px;

  @media (max-width: 600px) {
    height: 400px;
  }
}
</style>
