import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,

  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },

  routes: [
    {
      path: '',
      component: () =>
        import(
          /* webpackChunkName: "HomePageLayout" */ /* webpackMode: "eager" */ '@/layouts/HomePageLayout.vue'
        ),
      children: [
        {
          path: '/',
          name: 'home',
          component: () =>
            import(
              /* webpackChunkName: "home " */ /* webpackMode: "eager" */ '@/views/Home/Home.vue'
            ),
        },
      ],
    },
    {
      path: '',
      component: () =>
        import(
          /* webpackChunkName: "fullPage" */ /* webpackMode: "eager" */ '@/layouts/FullPage.vue'
        ),
      children: [
        // {
        //   path: '/',
        //   name: 'home',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "home" */ /* webpackMode: "eager" */ '@/views/Home/Home'
        //     ),
        // },
        {
          path: '/login',
          name: 'login',
          component: () =>
            import(
              /* webpackChunkName: "login" */ /* webpackMode: "eager" */ '@/views/Auth/Login'
            ),
        },
        {
          path: '/registration',
          name: 'registration',
          component: () =>
            import(
              /* webpackChunkName: "registration" */ /* webpackMode: "eager" */ '@/views/Auth/Registration'
            ),
        },
        {
          path: '/registration/:token',
          name: 'registrationForInvite',
          component: () =>
            import(
              /* webpackChunkName: "registrationForInvite" */ /* webpackMode: "eager" */ '@/views/Auth/Registration'
            ),
        },
        {
          path: '/reset-password',
          name: 'forgottPassword',
          component: () =>
            import(
              /* webpackChunkName: "forgottPassword" */ /* webpackMode: "eager" */ '@/views/Auth/ForgotPassword'
            ),
        },
        {
          path: '/confirm-reset/:token',
          name: 'confirmReset',
          component: () =>
            import(
              /* webpackChunkName: "confirmReset" */ /* webpackMode: "eager" */ '@/views/Auth/ConfirmReset'
            ),
        },
        {
          path: '/set_token',
          name: 'setToken',
          component: () =>
            import(
              /* webpackChunkName: "setToken" */ /* webpackMode: "eager" */ '@/views/Auth/FinalAuth'
            ),
        },
        {
          path: '/reports/:hash',
          name: 'publicReport',
          component: () =>
            import(
              /* webpackChunkName: "publicReport" */ /* webpackMode: "eager" */ '@/views/PublicReport/PublicReport'
            ),
        },
        {
          path: '/confirm-email/:token',
          name: 'confirmEmail',
          component: () =>
            import(
              /* webpackChunkName: "confirmEmail" */ /* webpackMode: "eager" */ '@/views/User/ConfirmEmail'
            ),
        },
        {
          path: '/unsubscribe/:signingDumps',
          name: 'unSubscribe',
          component: () =>
            import(
              /* webpackChunkName: "unSubscribe" */ /* webpackMode: "eager" */ '@/views/User/UnSubscribe'
            ),
        },
        {
          path: '/boards/invite',
          name: 'inviteBoard',
          component: () =>
            import(
              /* webpackChunkName: "inviteBoard" */ /* webpackMode: "eager" */ '@/views/Boards/InviteBoard.vue'
            ),
        },
        // {
        //   path: '/terms/linktrello',
        //   name: 'termsLinkTrello',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "termsLinkTrello" */ /* webpackMode: "eager" */ '@/views/Terms/TermsLinkTrello.vue'
        //     ),
        // },
      ],

      beforeEnter: (to, from, next) => {
        if (to.name === 'login' && localStorage.getItem('token-data')) {
          next({ name: 'boards' })
        } else {
          next()
        }
      },
    },
    {
      path: '',
      component: () =>
        import(
          /* webpackChunkName: "main" */ /* webpackMode: "eager" */ '@/layouts/Main.vue'
        ),
      children: [
        {
          path: '/',
          redirect: '/boards',
        },
        {
          path: '/boards',
          name: 'boards',
          component: () =>
            import(
              /* webpackChunkName: "boards" */ /* webpackMode: "eager" */ '@/views/Boards/Boards.vue'
            ),
        },
        {
          path: '/board/:id',
          name: 'board',
          component: () =>
            import(
              /* webpackChunkName: "board" */ /* webpackMode: "eager" */ '@/views/Boards/Board.vue'
            ),
        },
        {
          path: '/board/:id/settings',
          name: 'boardSettings',
          meta: { isAdmin: true },
          component: () =>
            import(
              /* webpackChunkName: "boardSettings" */ /* webpackMode: "eager" */ '@/views/Boards/BoardSettings.vue'
            ),
        },
        {
          path: '/reports',
          name: 'reports',
          component: () =>
            import(
              /* webpackChunkName: "reports" */ /* webpackMode: "eager" */ '@/views/Reports/Reports'
            ),
        },
        {
          path: '/options',
          name: 'options',
          component: () =>
            import(
              /* webpackChunkName: "options" */ /* webpackMode: "eager" */ '@/views/Options/Options'
            ),
        },
        {
          path: '/sprints',
          name: 'sprints',
          props: (route) => ({ query: route.query.q }),
          component: () =>
            import(
              /* webpackChunkName: "sprints" */ /* webpackMode: "eager" */ '@/views/Sprints/Sprints'
            ),
        },
        {
          path: '/sprints/:boardId',
          name: 'sprintsAtBoard',
          component: () =>
            import(
              /* webpackChunkName: "sprintsAtBoard" */ /* webpackMode: "eager" */ '@/views/Sprints/SprintsAtBoard'
            ),
        },
        {
          path: '/sprints/:boardId/:sprintId',
          name: 'sprint',
          component: () =>
            import(
              /* webpackChunkName: "sprint" */ /* webpackMode: "eager" */ '@/views/Sprints/Sprint'
            ),
        },
        {
          path: '/members/',
          name: 'members',
          component: () =>
            import(
              /* webpackChunkName: "members" */ /* webpackMode: "eager" */ '@/views/Members/Members'
            ),
          props: true,
        },
        {
          path: '/members/:id',
          name: 'member',
          component: () =>
            import(
              /* webpackChunkName: "member" */ /* webpackMode: "eager" */ '@/views/Members/Member'
            ),
        },
        {
          path: '/faq',
          name: 'faq',
          component: () =>
            import(
              /* webpackChunkName: "faq" */ /* webpackMode: "eager" */ '@/views/Documentation/DocumentationGeneral'
            ),
        },
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */ /* webpackMode: "eager" */ '@/views/Dashboard/Dashboard.vue'
            ),
          children: [
            {
              name: 'memberDashboard',
              props: true,
              path: '/dashboard/:userId',
            },
          ],
        },
        {
          path: '/charts',
          name: 'charts',
          component: () =>
            import(
              /* webpackChunkName: "charts" */ /* webpackMode: "eager" */ '@/views/Charts/Charts.vue'
            ),
        },
        {
          path: '/charts/:id/flow',
          name: 'flowchart',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "flowchart" */ /* webpackMode: "eager" */ '@/views/Charts/StackedAreaChart.vue'
            ),
        },
        {
          path: '/charts/:id/burndown',
          name: 'burndownchart',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "burndownchart" */ /* webpackMode: "eager" */ '@/views/Charts/BurndownChart.vue'
            ),
        },
        {
          path: '/onboarding',
          name: 'onboarding',
          component: () =>
            import(
              /* webpackChunkName: "onboarding" */ /* webpackMode: "eager" */ '@/views/Onboarding/Onboarding.vue'
            ),
          children: [{ props: true, path: '/dashboard/:userId' }],
        },
        {
          path: '/cards/:cardId',
          name: 'card',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "card" */ /* webpackMode: "eager" */ '@/views/Card/CardComponent.vue'
            ),
        },
      ],

      beforeEnter: (to, from, next) => {
        if (store.getters.userId) {
          if (to.matched.some((record) => record.meta.isAdmin)) {
            if (to.name === 'boardSettings') {
              store.dispatch('boards/getBoard', to.params.id).then((res) => {
                res.admins.map((a) => a.id).includes(store.getters.userId)
                  ? next()
                  : next({ name: 'pageNotFound', params: { 0: to.path } })
              })
            }
          } else {
            next()
          }
        } else {
          next({ name: 'login' })
        }
      },
    },
    {
      path: '/terms/linktrello',
      name: 'termsLinkTrello',
      component: () =>
        import(
          /* webpackChunkName: "termsLinkTrello" */ /* webpackMode: "eager" */ '@/views/Terms/TermsLinkTrello.vue'
        ),
    },
    {
      path: '/terms/text',
      name: 'termsPage',
      component: () =>
        import(
          /* webpackChunkName: "termsPage" */ /* webpackMode: "eager" */ '@/views/Terms/TermsPage.vue'
        ),
    },
    {
      path: '*',
      name: 'pageNotFound',
      component: () => import('@/layouts/PageNotFound.vue'),
    },
  ],
})
