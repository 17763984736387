<template lang="pug">
.terms
  header.terms__header
    router-link.header__link.text-none(:to='{name: "home"}')
      IconBase(
        icon-name='logo'
        width='142'
        height='36'
        icon-color='var(--v-logo-base)')
        LogotypeUni
  h1.terms__title {{ $lang.titleTrelloReg }}
    span.terms__inner-title Trello
  p.terms__subtitle {{ $lang.subtitleTrelloReg }}
  section.card-wrap
    .card(
      v-for='(card, idx) in cardsData'
      :key='card.src'
      :data-index='idx + 1')
      .card__circle
      p.card__text(v-html='$lang[card.text][idx]')
      img.card__image(:src='require(`@/assets/${card.src}`)')
  section.bottom
    .bottom__signin-wrap
      .bottom__signin-text {{ $lang.signinText }}
      .bottom__controls
        v-checkbox.bottom__checkbox(
          v-model='isAgree'
          hide-details)
          template(v-slot:label)
            div {{ $lang.acceptTerms }}
              a(
                target='_blank'
                href='/terms/text'
                @click.stop) {{ $lang.acceptTermsLink }}
        button.bottom__btn(
          :disabled='!isAgree'
          @click='login'
          text) {{ $lang.loginTrello }}
    .bottom__info
      .bottom__title-wrap
        v-icon.bottom__icon {{ mdiInformationOutline }}
        span.bottom__info-title {{ $lang.importantInfo }}
      ul.bottom__info-text
        li.bottom__info-item(v-for='(item, idx) in 2') {{ $lang.infoText[idx] }}
</template>

<script>
import { mdiInformationOutline } from '@mdi/js'
import { mapGetters } from 'vuex'
import config from '@/../config.json'
import { cardsData } from '@/helpers/termsData'

export default {
  name: 'TermsLinkTrello',
  data: () => ({
    isAgree: false,
    cardsData,
    mdiInformationOutline,
  }),
  computed: {
    ...mapGetters(['isMobile']),
  },
  methods: {
    sendInvite() {
      if (this.$route.params && this.$route.params.token) {
        this.$store.dispatch('boards/postBoardInvite', {
          signingDumps: this.$route.params.token,
        })
      }
    },
    login() {
      this.sendInvite()
      document.location.href = `${config.baseURL}/api/accounts/trello/login/?process=login&agreement=yes`
    },
  },
}
</script>

<style lang="scss" scoped>
.terms * {
  margin: 0;
  padding: 0;
}

.terms {
  --container-width: 1180px;

  max-width: var(--container-width);
  padding: 0 12px 60px;
  margin: 0 auto;
  color: var(--v-title-base);
  font-family: var(--font-raleway);
  font-weight: 500;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    height: 100%;
    display: flex;
  }

  &__header {
    padding: 50px 0;
  }

  &__title {
    display: inline-block;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 20px;
  }

  &__inner-title {
    color: var(--v-primary-base);
  }

  &__subtitle {
    font-size: 24px;
    line-height: 1.25;
    width: 50%;

    @media (max-width: 900px) {
      width: 100%;
    }
  }
}

.terms__subtitle,
.card-wrap {
  margin-bottom: 60px;
}

/* Cards styles */

.card-wrap {
  margin-bottom: 60px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(333px, 1fr));
  grid-gap: 78px;
}

.card {
  --circle-terms: 54px;
  --font-size: 36px;

  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 0;
  background: var(--v-base-base);
  padding: 48px 20px 20px;
  border-radius: 14px;
  position: relative;

  &::before {
    content: '';
    width: var(--circle-terms);
    height: var(--circle-terms);
    border-radius: 50%;
    background: var(--v-primary-base);
    display: flex;
    justify-content: center;
    align-items: baseline;
    top: calc(var(--circle-terms) * -1 / 2);
  }

  &::after {
    content: attr(data-index);
    font-size: var(--font-size);
    font-weight: 700;
    color: var(--v-colorLabel-base);
    top: calc((3 * var(--circle-terms) - var(--font-size)) * -1 / 4);
  }

  &::before,
  &::after {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &__text {
    height: 90px;
    font-size: 19px;
    line-height: 1.5;
    margin-bottom: 20px;
    overflow: hidden;
  }
}

/* Bottom block styles */

.bottom {
  display: grid;
  grid-template-columns: 50% 1fr 362px;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }

  &__signin-wrap {
    display: flex;
    flex-direction: column;
  }

  &__signin-text {
    font-size: 20px;
    margin-bottom: 30px;
  }

  &__controls {
    margin-bottom: 20px;
    font-family: var(--font-roboto);
  }

  &__checkbox {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 30px;
  }

  &__btn {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: normal;

    &:disabled {
      background-color: var(--v-backgroundMain-base) !important;
      border: 1px solid var(--v-inactive-base) !important;
    }

    @include btn-common(center);

    @media (max-width: 600px) {
      margin: 20px 0 8px;
    }
  }

  &__info {
    grid-column: 3 / 4;
    padding: 20px;
    background: #f6f6f6;
    background: var(--v-backgroundInfo-base);
    border-radius: 14px;
  }

  &__title-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  &__icon {
    margin-right: 12px;
  }

  &__info-title,
  &__info-text {
    font-family: var(--font-roboto);
    font-weight: 400;
  }

  &__info-title {
    font-size: 18px;
  }

  &__info-item {
    margin-left: 27px;
  }

  &__info-item + .bottom__info-item {
    margin-top: 12px;
  }
}
</style>
