import request from '@/services/request'
import { apiMembers, endActivityGraph } from '@/helpers/urls'

const state = {
  members: [],
  member: null,
  activity: [],
  projectActivity: [],
  chartFilterType: 'actions',
}

const getters = {
  members: ({ members }) => members,
  member: ({ member }) => member,
  activity: ({ activity }) => activity,
}

const actions = {
  getMembers: async ({ commit }) => {
    try {
      const data = await request({ url: apiMembers, method: 'GET' })
      commit('setMembers', data)
    } catch (err) {
      console.log(err)
    }
  },
  getMember: async ({ commit }, id) => {
    try {
      const data = await request({ url: `${apiMembers}${id}/`, method: 'GET' })
      commit('setMember', data)
    } catch (err) {
      console.log(err)
    }
  },
  getActivity: async (
    { commit },
    {
      id,
      filter_by_board = null,
      filter_type = null,
      month = null,
      year = null,
    }
  ) => {
    let data = { month, year }
    if (filter_by_board) {
      data = { ...data, filter_by_board }
    }
    if (filter_type) {
      data = { ...data, filter_type }
    }

    try {
      const activity = await request({
        url: `${apiMembers}${id}${endActivityGraph}`,
        method: 'POST',
        data,
      })

      if (month) {
        commit('setProjectActivity', { id, activity })
      } else {
        commit('setActivity', { id, activity })
        commit('setProjectActivity', { id, activity })
      }
    } catch (err) {
      console.log(err)
    }
  },
}

const mutations = {
  setMembers: (state, data) => {
    state.members = data
  },
  setMember: (state, data) => {
    state.member = data
  },
  setToZeroMembers: (state) => {
    state.members = []
  },
  setToZeroMember: (state) => {
    state.member = null
  },
  setActivity: (state, { id, activity }) => {
    let idx
    state.activity.filter((item, idx) => (idx = item.id === id ? idx : -1))
    if (idx) {
      state.activity = state.activity.map((item) =>
        item.id === id ? item.activity === activity : item.activity
      )
    } else {
      state.activity = [...state.activity, { id, activity }]
    }
  },
  setProjectActivity: (state, { id, activity }) => {
    state.projectActivity = [{ id, activity }]
  },
  setToZeroActivity: (state) => {
    state.activity = []
  },
  setToZeroProjectActivity: (state) => {
    state.projectActivity = []
  },
  setChartFilterType: (state, payload) => {
    state.chartFilterType = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
