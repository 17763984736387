<template lang="pug">
v-container.auth-wrapper.pt-0
  AuthHeader
  v-row.align-end.align-sm-center.justify-sm-end
    v-col.col-12.col-sm-8.col-md-5.col-lg-5.pa-0.mt-0.mt-sm-14
      v-form.form.d-flex.flex-column(
        v-model='validForm'
        @submit.prevent='submit')
        v-text-field(
          :label='$lang.username'
          required
          v-model='username'
          :rules='usernameRules'
          :prepend-inner-icon='mdiAccountCircle')
        v-text-field(
          type='password'
          :label='$lang.password'
          required
          v-model='password'
          :rules='passwordRules'
          :prepend-inner-icon='mdiLock')
        button.form__btn.primary(
          type='submit'
          :disabled='!validForm') {{ $lang.login }}
        .form__btn-wrapper.d-flex.flex-wrap.justify-space-between
          v-btn.form__auth-btn(
            :to='{ name: "registration" }'
            text
            color='main') {{ $lang.registration }}
          v-btn.form__auth-btn(
            :to='{ name: "forgottPassword" }'
            text
            color='text') {{ $lang.forgottPassword }}
        .mt-8.mt-sm-14
          v-select.lang-select(
            v-model='vLang'
            :items='language'
            hide-details
            outlined)
</template>

<script>
import { mdiAccountCircle, mdiLock } from '@mdi/js'
import { mapGetters } from 'vuex'
import config from '@/../config.json'
import { language } from '@/helpers/constants'

const AuthHeader = () =>
  import(
    /* webpackChunkName: "AuthHeader" */ /* webpackMode: "eager" */ '@/views/Auth/components/AuthHeader.vue'
  )

export default {
  name: 'Login',
  components: {
    AuthHeader,
  },
  data() {
    return {
      validForm: false,
      usernameRules: [(v) => !!v || this.$lang.required],
      passwordRules: [(v) => !!v || this.$lang.required],
      nonFieldError: null,
      username: '',
      password: '',
      isLoading: false,
      language,
      mdiAccountCircle,
      mdiLock,
    }
  },
  computed: {
    ...mapGetters(['lang']),
    vLang: {
      get() {
        return this.lang
      },
      set(value) {
        this.$store.dispatch('setLang', value)
        this.$setLang(value)
        this.$vuetify.lang.current = value
      },
    },
  },
  methods: {
    login() {
      document.location.href = `${config.baseURL}/api/accounts/trello/login/?process=login`
    },
    submit() {
      this.isLoading = true
      this.nonFieldError = ''
      this.$store
        .dispatch('login', {
          username: this.username,
          password: this.password,
        })
        .then((res) => {
          if (res.user_boards_status === 'not present') {
            this.$router.push({ name: 'options' })
          } else {
            this.$router.push({ name: 'boards' })
          }
        })
        // .catch(error => console.log(error.detail))
        .catch((error) => {
          if (error.detail) {
            this.nonFieldError = error.detail
          } else {
            // Handle backend errors
            for (let field in error) {
              this.nonFieldError += error[field].join(' ')
            }
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.auth-wrapper {
  max-width: 1170px;

  @media (max-width: 600px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.form {
  @include auth-form;

  &__btn-wrapper {
    padding: 0 42px;

    @media (max-width: 600px) {
      padding: 0;
    }
  }

  &__btn,
  &__auth-btn {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: normal;
  }

  &__btn {
    @include btn-common(center);
    margin: 31px 0 21px;
  }

  &__auth-btn {
    text-transform: lowercase !important;
    padding: 0 !important;
  }
}
</style>
