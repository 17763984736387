<template lang="pug">
v-list.backgroundMain.pt-0(dense)
  v-list-item.px-0(
    v-for='(member, index) in members'
    :key='`member--${member.id}`'
    :class='[index % 2 === 0 ? "setting__dark" : ""]')
    v-container.d-flex.flex-column.flex-md-row(fluid)
      .d-flex.flex-nowrap.align-center.mr-4
        AvatarGroup.mr-1.mr-sm-3(
          :user='member'
          :idx='index'
          :size='!isMobile ? 42 : 32')
        div
          div {{ member.full_name }}
          div ({{ member.username }})
      .justify-end.ml-auto
        v-autocomplete(
          :items='usersEmails'
          :placeholder='$lang.doNoNotify'
          item-text='userEmail'
          item-value='userId'
          persistent-hint
          return-object
          single-line
          dense
          v-model='select[index]'
          @change='changeSuscribeMembersList(index, member, usersEmails[index])')
  button.setting__button-blue.mt-3(@click='submitSubscribe()') {{ $lang.save }}
</template>

<script>
import { mapGetters } from 'vuex'

const AvatarGroup = () =>
  import(
    /* webpackChunkName: "AvatarGroup" */ /* webpackMode: "eager" */ '@/components/AvatarGroup.vue'
  )

export default {
  name: 'SubscribeSettings',
  components: {
    AvatarGroup,
  },
  props: {
    members: { type: Array, default: () => [] },
    users: { type: Array, default: () => [] },
    membersSubscribe: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      select: [],
    }
  },
  created() {
    this.initSubscribeMembers()
  },
  computed: {
    ...mapGetters(['isDark', 'isMobile']),
    usersEmails() {
      let email = []
      email[0] = { userId: -1, userEmail: this.$lang.doNoNotify }
      return email.concat(
        this.users.map((user) => ({ userId: user.id, userEmail: user.email }))
      )
    },
  },
  methods: {
    initSubscribeMembers() {
      this.select = this.members.map((member) => {
        const email =
          this.membersSubscribe && this.membersSubscribe[member.id]
            ? this.membersSubscribe[member.id]
            : this.$lang.doNoNotify
        const user = this.usersEmails.find((user) => user.userEmail === email)
        return {
          memberId: member.id,
          userEmail: user ? email : this.$lang.doNoNotify,
          userId: user ? user.userId : -1,
        }
      })
    },
    submitSubscribe() {
      let data = {}
      this.select
        .filter((item) => item.userId !== -1)
        .forEach((item) => (data[item.memberId] = item.userEmail))

      this.$store
        .dispatch('boards/patchSettingsBoard', {
          boardId: this.$route.params.id,
          members_emails: data,
        })
        .then(() => {
          this.$store.commit('ADD_NOTIFICATION', {
            color: 'success',
            text: this.$lang.notificationsConfigured,
          })
        })
    },
    changeSuscribeMembersList(index, member) {
      this.select[index]['memberId'] = member.id
    },
  },
}
</script>

<style lang="scss" scoped>
.setting {
  &__dark {
    background: var(--v-backgroundTable-base);
  }

  &__button-blue {
    @include btn-settings($color: true);
  }
}
</style>
