<template lang="pug">
.backgroundMain.pt-0
  v-row.row
    v-col
      .d-flex.flex-wrap(v-if='!isNewUser')
        button.setting__button-white.mr-4.mb-4(@click='addUserIsClient(false)') {{ $lang.addUser }}
        button.setting__button-blue.mb-4(@click='addUserIsClient(true)') {{ $lang.addClient }}
      div(v-else)
        v-text-field.mb-4(
          v-model='emailNewUser'
          :rules='[rulesUser.counter, rulesUser.email]'
          label='E-mail'
          counter
          :maxlength='maxlength')
        .setting__lang-wrapper.mb-8
          span.setting__lang-label {{ $lang.langToSend }}
          v-select.setting__lang-select(
            v-model='vMailLang'
            :items='langItems'
            hide-details)
        .d-flex.flex-wrap.justify-end
          button.setting__button-white.mb-4(
            @click='addNewUser(emailNewUser)'
            :disabled='isDisableBtn') {{ isClient ? $lang.sendInvite.client : $lang.sendInvite.user }}
          button.setting__button-blue.ml-4.mb-4(
            @click='() => (isNewUser = false)') {{ $lang.closeForm }}

  v-tabs.setting__tabs.pt-5(
    v-model='tab'
    hide-slider
    background-color='backgroundMain'
    active-class='tabs__active')
    v-tab.tabs__tab {{ $lang.usersOfBoard }}
    v-tab.tabs__tab(v-if='boardClients && boardClients.length') {{ $lang.clientOfBoard }}
  v-tabs-items.tabs-items.pt-10(v-model='tab')
    template(v-for='value in users')
      v-tab-item
        v-col.col-12.pa-0
          v-list.pa-0.backgroundMain
            v-list-item.settings__item.px-0.py-2.py-sm-4(
              v-for='(user, index) in boardUsers'
              :key='`user--${user.id}`')
              AvatarGroup.mr-1.mr-sm-3(
                :user='user'
                :idx='index'
                :size='!isMobile ? 42 : 32')
              span.setting__username.grey--text {{ user.username }} {{ checkUserIsBoardAdmin(user.id) ? "(Admin)" : "" }}
              v-spacer
              v-list-item-action.d-flex.align-start.my-0(
                v-if='!checkUserIsBoardAdmin(user.id)')
                ObserverStatusSwitch(
                  :observerStatus='checkUserIsObserver(user.id)'
                  :userId='user.id'
                  @changeObserverStatus='changeObserverStatus')
              v-list-item-action.my-0(v-if='!checkUserIsBoardAdmin(user.id)')
                v-btn(
                  icon
                  color='error'
                  @click='deleteUser(user.id)'
                  aria-label='delete button')
                  v-icon {{ mdiDeleteForever }}
          v-divider.my-4(v-if='observerUsers.length')
          h3.setting__observers-group-title.pt-3(v-if='observerUsers.length') {{ $lang.observersTitle }}
          v-list.pa-0.pt-7.backgroundMain
            v-list-item.settings__item.px-0.py-2.py-sm-4(
              v-for='(observer, index) in observerUsers'
              :key='`user--${observer.id}`')
              AvatarGroup.mr-1.mr-sm-3(
                :user='observer'
                :idx='index'
                :size='!isMobile ? 42 : 32')
              span.setting__username.grey--text {{ observer.username }}
              v-spacer
              v-list-item-action.my-0
                v-btn(
                  icon
                  color='error'
                  @click='changeObserverStatus(observer.id)'
                  aria-label='delete button')
                  v-icon {{ mdiDeleteForever }}

      v-tab-item
        v-col.col-12.pa-0
          v-list.pa-0.backgroundMain
            v-list-item.settings__item.pa-1.pa-sm-2.mb-3(
              v-for='(client, index) in boardClients'
              :key='`clients--${client.id}`')
              AvatarGroup.mr-1.mr-sm-3(
                :user='client'
                :idx='index'
                :size='!isMobile ? 42 : 32')
              span.setting__username.grey--text {{ client.username }}
              v-spacer
              v-list-item-action.my-0(v-if='!checkUserIsBoardAdmin(client.id)')
                v-btn(
                  icon
                  color='error'
                  @click='deleteUser(client.id)'
                  aria-label='delete button')
                  v-icon {{ mdiDeleteForever }}
</template>

<script>
import { mdiDeleteForever } from '@mdi/js'
import { createNamespacedHelpers, mapGetters } from 'vuex'
const { mapGetters: boardsMapGetters } = createNamespacedHelpers('boards')
import { langs } from '@/helpers/constants'

const AvatarGroup = () =>
  import(
    /* webpackChunkName: "AvatarGroup" */ /* webpackMode: "eager" */ '@/components/AvatarGroup.vue'
  )
const ObserverStatusSwitch = () =>
  import(
    /* webpackChunkName: "ObserverStatusSwitch" */ /* webpackMode: "eager" */ '@/views/Boards/components/ObserverStatusSwitch.vue'
  )

export default {
  name: 'UsersSettings',
  components: {
    AvatarGroup,
    ObserverStatusSwitch,
  },
  data() {
    return {
      isNewUser: false,
      isClient: false,
      emailNewUser: '',
      maxlength: 50,
      rulesUser: {
        counter: (value) =>
          (value || '').length <= this.maxlength ||
          `Max ${this.maxlength} characters`,
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$lang.invalidEmail
        },
      },
      tab: null,
      langs,
      mailLang: '',
      mdiDeleteForever,
      // langItems: [
      //   { text: 'en', value: 'EN' },
      //   { text: 'ru', value: 'RU' },
      // ],
    }
  },
  computed: {
    ...mapGetters(['isMobile', 'isDark', 'userId', 'lang']),
    ...boardsMapGetters([
      'boardUsers',
      'boardAdmins',
      'boardClients',
      'boardObservers',
      'boardsNotification',
    ]),
    isDisableBtn() {
      return !(
        this.rulesUser.email(this.emailNewUser) !== this.$lang.invalidEmail &&
        this.emailNewUser.length < this.maxlength
      )
    },
    users() {
      return [this.boardUsers, this.boardClients]
    },
    observerUsers() {
      return this.boardObservers.filter((observerUser) => {
        return !this.boardUsers.some((user) => user.id === observerUser.id)
      })
    },
    vMailLang: {
      get() {
        return this.mailLang
      },
      set(val) {
        this.mailLang = val.toUpperCase()
      },
    },
    langItems() {
      const arr = []
      langs.map((lang) => {
        const obj = {}
        obj['text'] = lang
        obj['value'] = lang.toUpperCase()
        arr.push(obj)
      })
      return arr
    },
  },
  created() {
    this.mailLang = this.lang.toUpperCase() || 'EN'
  },
  methods: {
    // addUser(user) {
    //   this.$store.dispatch('boards/addBoardUser', {
    //     boardId: this.$route.params.id,
    //     userEmail: user.email,
    //     isClient: this.isClient
    //   })
    //     .then(() => {
    //       this.$store.commit('ADD_NOTIFICATION', { color: 'success', text: this.$lang.userHasBeenAdded })
    //     })
    //     .finally(() => {
    //       this.isClient = false
    //     })
    // },
    deleteUser(userId) {
      this.$store
        .dispatch('boards/deleteBoardUser', {
          boardId: this.$route.params.id,
          userId,
        })
        .then(() =>
          this.$store.commit('ADD_NOTIFICATION', {
            color: 'success',
            text: this.$lang.userHasBeenRemoved,
          })
        )
    },
    addNewUser(email) {
      this.$store
        .dispatch('boards/addBoardUser', {
          boardId: this.$route.params.id,
          userEmail: email,
          isClient: this.isClient,
          mail_lang: this.mailLang,
        })
        .then(() => {
          this.$store.commit('ADD_NOTIFICATION', {
            color: 'success',
            text: this.boardsNotification,
          })
          this.isNewUser = false
          this.emailNewUser = ''
          this.isClient = false
        })
    },
    addUserIsClient(client = false) {
      this.isNewUser = true
      this.isClient = client
    },
    changeObserverStatus(user_id) {
      this.$store.dispatch('boards/changeObserverStatus', {
        boardId: this.$route.params.id,
        user_id,
      })
    },
    checkUserIsBoardAdmin(id) {
      return this.boardAdmins.some((u) => u.id === id)
    },
    checkUserIsObserver(id) {
      return !!this.boardObservers.filter((observer) => observer.id === id)
        .length
    },
  },
}
</script>

<style lang="scss" scoped>
.height60 {
  height: 60px;
}

.setting__tabs {
  margin-left: -16px;
}

.setting__title {
  font-size: 28px;
  font-weight: bold;
}

.setting__username {
  @include subtitle-common;

  @media (max-width: 1200px) {
    font-size: 18px;
  }
}

.setting__lang-select {
  max-width: 50%;
}

.setting__lang-label {
  color: var(--v-title-base);
}

.setting__observers-group-title {
  font-weight: 600;
  font-family: var(--font-raleway);
  color: var(--v-title-base);
  font-size: 18px;
  letter-spacing: 2px;
}
</style>

<style lang="scss">
.settings__item.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  &:hover {
    background: var(--v-backgroundTable-base) !important;
  }
}

.setting__tabs > .v-slide-group > .v-slide-group__prev {
  display: none !important;
}

.setting__lang-wrapper .setting__lang-select .v-label.v-label--active {
  font-size: 16px !important;
}
</style>
