<template lang="pug">
v-form.mb-3(@submit.prevent='saveSpecialLists')
  v-checkbox.d-flex.align-center.ma-0.py-5.px-3(
    v-model='boardSpecialLists'
    :class='[index % 2 === 0 ? "setting__dark" : ""]'
    v-for='(list, index) in listWithoutRel'
    :key='`specialList-${list.id}`'
    multiple
    hide-details
    :value='list.id'
    :label='list.name')
  //- v-checkbox.d-flex.align-center.ma-0.py-5.px-3(
  //-   v-model="boardSpecialLists"
  //-   v-for="(list, index) in listWithoutRel"
  //-   :key="`specialList-${list.id}`"
  //-   multiple
  //-   hide-details
  //-   :value="list.id"
  //-   :label="list.name"

  //-   :class="[index % 2 === 0 && isDark ? 'grey darken-3' : '', index % 2 === 0 && !isDark? 'grey grey lighten-4' : '', 'checkbox-wrapper']"
  //- )
  button.setting__button-blue.mt-3(type='submit') {{ $lang.save }}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SpecialListSettings',
  props: {
    currentBoard: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      boardSpecialLists: this.currentBoard.special_lists,
    }
  },
  computed: {
    ...mapGetters(['isDark']),
    listWithoutRel() {
      return this.currentBoard.board_lists.filter(
        (list) => !list.name.includes('REL')
      )
    },
  },
  methods: {
    saveSpecialLists() {
      this.$store
        .dispatch('boards/patchSettingsBoard', {
          boardId: this.$route.params.id,
          special_lists: this.boardSpecialLists,
        })
        .then((data) => {
          this.boardSpecialLists = data.special_lists
        })
        .then(() =>
          this.$store.commit('ADD_NOTIFICATION', {
            color: 'success',
            text: this.$lang.specialColumnsChanged,
          })
        )
    },
  },
}
</script>

<style lang="scss">
// .checkbox-wrapper {
//   & .v-input__control {
//     // align-items: center;
//     // justify-content: center;

//     & .v-input__slot {
//       margin: 0;
//     }

//     & .v-messages {
//       display: none;
//     }
//   }
// }

.setting {
  &__dark {
    background: var(--v-backgroundTable-base);
  }
}
</style>
