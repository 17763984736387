<template lang="pug">
v-row.report(:style='componentStyles')
  v-col.col-12
    v-row(:class='{"flex-column-reverse": isMobile || isTablet}')
      v-col.report__card.col-12.col-md-10.title--text
        article.report__card-item.mr-md-4.mb-2.mb-sm-0
          h3.report__card-title {{ task.name }}
          LinkBox(
            :task='task'
            :actionData='actionDataForCopyToClipboard')
        CardLabels.pb-2.pt-sm-2(:card='task')
        ReportSubtitle(
          :task='task'
          :action='task.actions[task.actions.length - 1]'
          :isPublic='isPublic')
      v-col.col-12.col-md-2(v-if='!isPublic && !isClient')
        .mt-2.mt-sm-0.mr-6.d-flex.align-center.justify-end
          AvatarGroup.justify-end(
            :isTooltip='true'
            :size='32'
            :margin='"0 0 6px 6px"'
            :users='users'
            :membersHidden='membersHidden')
</template>

<script>
import { mapGetters } from 'vuex'

const AvatarGroup = () =>
  import(
    /* webpackChunkName: "AvatarGroup" */ /* webpackMode: "eager" */ '@/components/AvatarGroup.vue'
  )
const ReportSubtitle = () =>
  import(
    /* webpackChunkName: "ReportSubtitle" */ /* webpackMode: "eager" */ '@/views/Reports/components/ReportSubtitle.vue'
  )
const LinkBox = () =>
  import(
    /* webpackChunkName: "LinkBox" */ /* webpackMode: "eager" */ '@/components/LinkBox.vue'
  )
const CardLabels = () =>
  import(
    /* webpackChunkName: "CardLabels" */ /* webpackMode: "eager" */ '@/components/CardLabels.vue'
  )

export default {
  name: 'ReportHeader',
  components: {
    AvatarGroup,
    ReportSubtitle,
    LinkBox,
    CardLabels,
  },
  props: {
    task: {
      type: Object,
      default: () => {},
    },
    users: {
      type: Array,
      default: () => [],
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
    isClient: {
      type: Boolean,
      default: false,
    },
    membersHidden: {
      type: Boolean,
      default: false,
    },
    board: {
      type: Object,
      default: () => {},
    },
    isActionDataForCopyToClipboard: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isTooltipVisible: false,
    copied: false,
    timeoutID: null,
  }),
  computed: {
    ...mapGetters(['isMobile', 'isTablet']),
    componentStyles() {
      return {
        '--max-width': this.isMobile
          ? 'calc(100vw - 24px - 2 * 16px)' /* 24px - ширина иконки v-expansion-panel, 16px - левый и правый паддинги на мобильных */
          : 'initial',
      }
    },
    actionDataForCopyToClipboard() {
      return this.isActionDataForCopyToClipboard
        ? this.task.actions[this.task.actions.length - 1].data
        : {}
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeoutID)
  },
  methods: {
    copyText(text) {
      const cb = navigator.clipboard
      cb.writeText(text).then(() => {
        this.isTooltipVisible = false
        this.copied = true

        this.timeoutID = setTimeout(() => {
          this.copied = false
        }, 600)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.report {
  &__card-item {
    position: relative;
    max-width: var(--max-width);
  }

  &__card-title {
    display: inline;
    @include item-title;
    line-height: 1.2;
    margin-right: 8px;
  }
}
</style>
