import request from '@/services/request'
import {
  apiRepoList,
  apiSendBindRepo,
  apiGits,
  endBindGitToken,
  endListOfBranches,
} from '@/helpers/urls'

const state = {
  accessibleRepoList: [],
  // bindRepoList: [],
  bindedRepoStatus: false,
  listOfReposBranches: [],
  listOfBranchesERR: false,
}

const mutations = {
  setRepoList: (state, payload) => {
    let serviceName
    let groupName

    Object.entries(payload).map((service) => {
      serviceName = service[0]
      return Object.entries(service[1]).map((group) => {
        groupName = group[0]
        group[1].map((el) => {
          state.accessibleRepoList.push({
            ...el,
            group: groupName,
            git_service: serviceName,
          })
        })
      })
    })
  },
  // setBindRepoList: (state, payload) => {
  //   state.bindRepoList = payload
  // },
  setBindedRepoStatus: (state, payload) => {
    state.bindedRepoStatus = payload
  },
  setEmptyRepoList: (state) => {
    state.accessibleRepoList = []
  },
  setListOfBranches: (state, payload) => {
    state.listOfReposBranches = payload
  },
  setListOfBranchesERR: (state, payload) => {
    state.listOfBranchesERR = payload
  },
}

const actions = {
  getRepoList: async ({ commit }) => {
    try {
      const data = await request({ url: apiRepoList, method: 'GET' })
      commit('setRepoList', data)
      commit('setBindedRepoStatus', true)
    } catch (err) {
      if (err.detail.includes('social token')) {
        commit('setBindedRepoStatus', false)
        commit('CLOSE_NOTIFICATION', {}, { root: true })
      }
    }
  },
  bindRepositories: async ({ dispatch }, data) => {
    const board_id = data.repositories[0].board_id
    try {
      await request({
        url: apiSendBindRepo,
        method: 'POST',
        data,
      })

      // commit('setBindRepoList', res)
      dispatch('boards/getBoardGits', board_id, { root: true })
      return 'ok'
    } catch (err) {
      return { error: err }
    }
  },
  syncRepoToken: async ({ commit }, git) => {
    const { id, sync_available } = git
    const method = sync_available ? 'DELETE' : 'POST'
    let data
    try {
      const res = await request({
        url: `${apiGits}${id}${endBindGitToken}`,
        method,
      })

      if (res === 204) {
        git.sync_available = false
        data = git
      } else {
        data = res
      }

      commit('boards/REPLACED_SYNCED_REPO', data, { root: true })
    } catch (err) {
      console.log(err)
    }
  },
  getListOfBranches: async ({ commit }, data) => {
    const id = data.gitRepoId

    try {
      const data = await request({
        url: `${apiGits}${id}${endListOfBranches}`,
        method: 'GET',
      })

      commit('setListOfBranches', data.branches)
      commit('setListOfBranchesERR', false)
    } catch (err) {
      console.log(err)
      commit('setListOfBranches', [])
      commit('setListOfBranchesERR', true)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
