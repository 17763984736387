<template lang="pug">
v-row.pb-11(no-gutters)
  v-progress-linear(
    indeterminate
    v-show='!isLoaded')
  EmptyEntities(v-if='isLoaded && !options')
  v-col.col-12.px-0
    v-card.backgroundMain(
      v-if='isLoaded && options'
      elevation='0')
      h2.options__title.mb-4.title--text {{ $lang.general }}
      v-divider.mb-8
      .d-flex.flex-column.mb-5
        h3.options__subtitle.title--text.mb-4 {{ $lang.language }}
        v-row.px-3
          v-col.d-flex.col-6.col-sm-4.col-md-3.col-lg-2.col-xl-1.px-0
            v-icon.options__flag.mr-5 {{ vLang === "en" ? "$flagEnIcon" : "$flagRuIcon" }}
            v-select.options__lang-select.mt-0.pt-0(
              v-model='vLang'
              :items='selectItems'
              hide-details
              aria-label='language selection')
      .d-flex.flex-column.mb-5
        h3.options__subtitle.title--text.mb-4 {{ $lang.firstDayOfWeek }}
        v-row.px-3
          v-col.d-flex.col-6.col-sm-4.col-md-3.col-lg-2.px-0
            v-select.options__first-day.mt-0.pt-0(
              v-model='vFirstDayOfWeek'
              :items='selectDayItems'
              hide-details
              aria-label='selection of the first day of the week')
      .d-flex.flex-column.mb-5
        h3.options__subtitle.title--text.mb-4 {{ $lang.theme }}
        .d-flex.align-center.mb-5
          v-switch.mt-0.pt-0(
            v-model='vIsAutosetIsDark'
            hide-details
            @change.native.prevent='checkIsNightWithAutoSet()'
            color='title')
            template(v-slot:label)
              span.options__text.title--text.d-flex.ml-4 {{ $lang.timeAdapting }}
              v-tooltip(bottom)
                template(v-slot:activator='{on}')
                  v-icon.lightGrey--text.ml-2.align-self-start(
                    color='lightGrey'
                    dark
                    size='14'
                    v-on='on') {{ mdiInformation }}
                span {{ $lang.timeAdaptingInfo }}
        .d-flex.align-center.mb-5
          v-switch.mt-0.pt-0(
            v-model='vIsDark'
            hide-details
            :disabled='isAutosetIsDark'
            color='title')
            template(v-slot:label)
              span.options__text.title--text.ml-4 {{ $lang.turnOnTheme }}
      v-row
        v-col.col-12.col-md-6.col-lg-4.px-0
          v-form(
            v-model='valid'
            lazy-validation
            @submit.prevent='patchOptions')
            .d-flex.flex-column.align-start.mb-5
              v-card-actions.d-flex.flex-column.align-start.px-2
                button.setting__button-blue.mb-4(
                  type='submit'
                  :disabled='!valid'
                  :loading='isLoading'
                  persistent-hint) {{ $lang.save }}
                span.mt-1.caption.main--text(v-show='isOptionsChanged') {{ $lang.settingsArentSaved }}
      h2.options__title.mb-4.title--text {{ $lang.applications }}
      v-divider.mb-8
      .mb-10.col-lg-10.col-xl-8.px-0
        v-list.backgroundMain
          .options__provider-list-item.px-0(
            v-for='(service, idx) in services'
            :key='service.id')
            .options__provider-wrapper
              v-list-item-icon.my-0.ml-0.mr-2
                v-icon(size='28') {{ getProviderIcon(service.provider) }}
              span.options__provider(
                :class='!service.active || checkSocialTokenSet(service.provider) ? "inactive-access" : ""') {{ capitalizFirstLetter(service.provider) }}
            .options__button-wrapper
              button.setting__button-blue(
                @click='conectProvider(service.provider)') {{ !service.active ? $lang.connectAccount : $lang.refreshToken }}
              button.setting__button-white(
                v-if='service.active && !checkSocialTokenSet(service.provider)'
                @click='unlinkProvider(service)') {{ $lang.unlinkAccount }}
      h2.options__title.mb-4.title--text {{ $lang.password }}
      v-divider.mb-8
      v-row
        v-col.col-12.col-md-6.col-lg-4.px-0
          v-form(
            v-model='validPasswords'
            @submit.prevent='changeUserPassword')
            v-card-text.py-0.px-3
              v-text-field.options__text-field(
                :label='$lang.oldPassword'
                required
                :type='showOldPassword ? "text" : "password"'
                :rules='[rules.password, rules.minLengthPassword]'
                v-model='oldPassword'
                ref='oldPasswordInput'
                :append-icon='showOldPassword ? mdiEye : mdiEyeOff'
                :hint='$lang.min8characters'
                counter
                @click:append='showOldPassword = !showOldPassword'
                validate-on-blur
                @focus='(e) => checkCapsWarning("isCapsOnOldPass", "isRusLangOldPass")'
                @blur='() => removeCapsWarning()'
                @keyup='(e) => checkCapsWarning("isCapsOnOldPass", "isRusLangOldPass")')
              .mt-3(v-if='isCapsOnOldPass')
                v-icon.mr-2.yellow--text {{ mdiAlert }}
                span {{ $lang.capsLockOn }}
              .mt-3(v-if='isRusLangOldPass')
                v-icon.mr-2.yellow--text {{ mdiAlert }}
                span {{ $lang.rusLangOn }}
              v-text-field.options__text-field(
                :label='$lang.password'
                required
                :type='showNewPassword ? "text" : "password"'
                :rules='[rules.password, rules.minLengthPassword]'
                v-model='newPassword'
                ref='passwordInput'
                :append-icon='showNewPassword ? mdiEye : mdiEyeOff'
                :hint='$lang.min8characters'
                counter
                @click:append='showNewPassword = !showNewPassword'
                validate-on-blur
                @focus='(e) => checkCapsWarning("isCapsOnNewPass", "isRusLangNewPass")'
                @blur='() => removeCapsWarning()'
                @keyup='(e) => checkCapsWarning("isCapsOnNewPass", "isRusLangNewPass")'
                @input='() => checkPassword()')
              .mt-3(v-if='isCapsOnNewPass')
                v-icon.mr-2.yellow--text {{ mdiAlert }}
                span {{ $lang.capsLockOn }}
              .mt-3(v-if='isRusLangNewPass')
                v-icon.mr-2.yellow--text {{ mdiAlert }}
                span {{ $lang.rusLangOn }}
              v-text-field.options__text-field(
                :label='$lang.repeatPassword'
                required
                :type='showConfirmNewPassword ? "text" : "password"'
                :rules='[rules.passwordConfirmRules, rules.minLengthPassword, passwordConfirmationRule]'
                v-model='confirmNewPassword'
                :append-icon='showConfirmNewPassword ? mdiEye : mdiEyeOff'
                :hint='$lang.min8characters'
                counter
                @click:append='showConfirmNewPassword = !showConfirmNewPassword'
                validate-on-blur
                @focus='(e) => checkCapsWarning("isCapsOnConfirmNewPass", "isRusLangConfirmNewPass")'
                @blur='() => removeCapsWarning()'
                @keyup='(e) => checkCapsWarning("isCapsOnConfirmNewPass", "isRusLangConfirmNewPass")'
                @input='() => checkPassword()')
              .mt-3(v-if='isCapsOnConfirmNewPass')
                v-icon.mr-2.yellow--text {{ mdiAlert }}
                span {{ $lang.capsLockOn }}
              .mt-3(v-if='isRusLangConfirmNewPass')
                v-icon.mr-2.yellow--text {{ mdiAlert }}
                span {{ $lang.rusLangOn }}

            v-card-actions
              button.setting__button-blue.mb-4(
                type='submit'
                :disabled='!validPasswords'
                color='primary') {{ $lang.save }}
</template>

<script>
import { mdiEye, mdiEyeOff, mdiAlert, mdiInformation } from '@mdi/js'
import {
  createNamespacedHelpers,
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex'
const {
  mapState: optionsMapState,
  mapMutations: optionsMapMutations,
  mapActions: optionsMapActions,
} = createNamespacedHelpers('options')
import config from '@/../config.json'
import { langs } from '@/helpers/constants'

const EmptyEntities = () =>
  import(
    /* webpackChunkName: "EmptyEntities" */ '@/components/EmptyEntities.vue'
  )

let capsLockEnabled = null
let rusLangEnabled = null
const reg = /[а-яА-Я]/

document.onkeydown = function (e) {
  e = e || window.event

  if (reg.test(e.key) && rusLangEnabled !== null) {
    rusLangEnabled = !rusLangEnabled
  }

  if (e.keyCode == 20 && capsLockEnabled !== null) {
    capsLockEnabled = !capsLockEnabled
  }
}

document.onkeypress = function (e) {
  e = e || window.event

  var chr = getChar(e)
  if (!chr) return // special key

  if (chr.toLowerCase() == chr.toUpperCase()) {
    // caseless symbol, like whitespace
    // can't use it to detect Caps Lock
    return
  }

  capsLockEnabled =
    (chr.toLowerCase() == chr && e.shiftKey) ||
    (chr.toUpperCase() == chr && !e.shiftKey)
  rusLangEnabled = reg.test(e.key)
}

function getChar(e) {
  if (e.which == null) {
    return String.fromCharCode(e.keyCode) // IE
  }
  if (e.which != 0 && e.charCode != 0) {
    return String.fromCharCode(e.which) // rest
  }

  return null
}

export default {
  name: 'OptionsComponent',
  components: {
    EmptyEntities,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$lang.required,
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$lang.invalidEmail
        },
        password: (v) => !!v || this.$lang.required,
        minLengthPassword: (v) => v.length >= 8 || this.$lang.min8characters,
        passwordConfirmRules: (v) =>
          (!!v && v === this.$refs.passwordInput.value) ||
          this.$lang.passwordNotMatch,
      },
      passwordConfirmationRule: '',
      valid: true,
      validPasswords: true,
      isLoading: false,
      newPassword: '',
      confirmNewPassword: '',
      oldPassword: '',
      showOldPassword: false,
      showNewPassword: false,
      showConfirmNewPassword: false,
      isNewPassword: false,

      isCapsOnOldPass: false,
      isCapsOnNewPass: false,
      isCapsOnConfirmNewPass: false,

      isRusLangOldPass: false,
      isRusLangNewPass: false,
      isRusLangConfirmNewPass: false,

      isLoaded: false,

      newLang: this.$store.state.profile.lang,

      // initial settings
      initLang: '',
      initIsDark: '',
      initIsDarkAuto: '',
      initFirstDayOfWeek: '',

      langs,
      // status: false,
      firstDayOfWeekEn: '',
      firstDayOfWeekRu: '',
      mdiAlert,
      mdiEye,
      mdiEyeOff,
      mdiInformation,
    }
  },
  computed: {
    ...optionsMapState([
      'options',
      'firstDayOfWeek',
      'allServices',
      'usersServices',
    ]),
    ...mapGetters(['isMobile', 'isDark', 'isAutosetIsDark', 'lang']),
    vIsDark: {
      get() {
        return this.isDark
      },
      set(value) {
        this.$vuetify.theme.dark = value
        this.$store.dispatch('setIsDark', value)
      },
    },
    vIsAutosetIsDark: {
      get() {
        return this.isAutosetIsDark
      },
      set(value) {
        this.SET_AUTOSET_IS_DARK(value)
      },
    },
    vLang: {
      get() {
        return this.newLang
      },
      set(value) {
        this.newLang = value
      },
    },
    vFirstDayOfWeek: {
      get() {
        return this.firstDayOfWeek
      },
      set(value) {
        this.SET_FIRST_DAY_OF_WEEK(value)
      },
    },
    isOptionsChanged() {
      if (
        this.vIsDark === this.initIsDark &&
        this.vIsAutosetIsDark === this.initIsDarkAuto &&
        this.vLang === this.initLang &&
        this.vFirstDayOfWeek === this.initFirstDayOfWeek
      )
        return false
      else return true
    },
    isPremium() {
      return this.options.is_premium
    },
    selectItems() {
      const arr = []
      langs.map((lang) => {
        const obj = {}
        obj['text'] = lang.toUpperCase()
        obj['value'] = lang
        arr.push(obj)
      })
      return arr
    },
    services() {
      const arr = []
      this.allServices.map((service) => {
        const obj = {}

        obj['provider'] = service.provider
        obj['id'] = service.id
        let userService = this.usersServices.find(
          (item) => item.provider === service.provider
        )
        if (
          userService
          // this.usersServices.find((item) => item.provider === service.provider)
        ) {
          obj['active'] = true
          obj['token_id'] = userService.socialtoken_set[0]
        } else {
          obj['active'] = false
        }

        arr.push(obj)
      })

      return arr
    },
  },
  created() {
    this.firstDayOfWeekEn = this.$lang.sunday
    this.firstDayOfWeekRu = this.$lang.monday
    this.selectDayItems = [
      { text: this.firstDayOfWeekRu, value: 1 },
      { text: this.firstDayOfWeekEn, value: 0 },
    ]

    Promise.all([this.getOptions(), this.getOAuthServices()])
      .then(() => {
        this.isLoaded = true

        this.initLang = this.vLang
        this.initIsDark = this.vIsDark
        this.initIsDarkAuto = this.vIsAutosetIsDark
        this.initFirstDayOfWeek = this.vFirstDayOfWeek
      })
      .catch((e) => {
        this.isLoaded = false
        console.log(e)
      })
  },
  updated() {
    this.firstDayOfWeekEn = this.$lang.sunday
    this.firstDayOfWeekRu = this.$lang.monday
    this.selectDayItems = [
      { text: this.firstDayOfWeekRu, value: 1 },
      { text: this.firstDayOfWeekEn, value: 0 },
    ]
  },
  methods: {
    ...mapMutations(['ADD_NOTIFICATION', 'SET_AUTOSET_IS_DARK']),
    ...optionsMapMutations(['SET_FIRST_DAY_OF_WEEK']),
    ...mapActions(['changePassword', 'setLang']),
    ...optionsMapActions([
      'checkIsNightWithAutoSet',
      'unlinkProvider',
      'postOptions',
      'getOptions',
      'getOAuthServices',
    ]),
    checkPassword() {
      this.newPassword === this.confirmNewPassword
        ? (this.passwordConfirmationRule = true)
        : (this.passwordConfirmationRule = '')
    },
    patchOptions() {
      this.initLang = this.vLang
      this.initIsDark = this.vIsDark
      this.initIsDarkAuto = this.vIsAutosetIsDark
      this.initFirstDayOfWeek = this.vFirstDayOfWeek
      this.firstDayOfWeekEn = ''
      this.firstDayOfWeekRu = ''

      this.isLoading = true
      this.setLang(this.newLang)
      this.postOptions().finally(() => {
        this.isLoading = false
      })
    },
    async changeUserPassword() {
      const res = await this.changePassword({
        password1: this.newPassword,
        password2: this.confirmNewPassword,
        oldPassword: this.oldPassword,
      })

      if (!res?.error) {
        this.ADD_NOTIFICATION({
          color: 'success',
          text: this.$lang.confirmResetSuccess,
        })
        this.newPassword = ''
        this.confirmNewPassword = ''
        this.oldPassword = ''
      }
      if (res?.error) {
        if (res.error.old_password) {
          this.ADD_NOTIFICATION({
            color: 'error',
            text: this.$lang.oldPasswordWrong,
          })
        }
      }
    },

    checkCapsWarning(isCaps, isRusLang) {
      this[isCaps] = capsLockEnabled
      this[isRusLang] = rusLangEnabled
    },
    removeCapsWarning() {
      this.isCapsOnOldPass = false
      this.isCapsOnNewPass = false
      this.isCapsOnConfirmNewPass = false

      this.isRusLangOldPass = false
      this.isRusLangNewPass = false
      this.isRusLangConfirmNewPass = false
    },
    conectProvider(provider) {
      if (provider === 'trello') {
        this.$router.push('/terms/linktrello')
      } else {
        document.location.href = `${config.baseURL}/api/accounts/${provider}/login/`
      }
    },
    capitalizFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    checkSocialTokenSet(provider) {
      return this.usersServices
        .filter((service) => service.provider === provider)
        .find((item) => item.socialtoken_set.length === 0)
    },
    getProviderIcon(provider) {
      return this.$vuetify.icons.values[`${provider}Icon`]
        ? `$${provider}Icon`
        : '$userIcon'
    },
  },
}
</script>

<style lang="scss" scoped>
.options__title {
  font-size: 30px;
  font-weight: 400;
}

.options__subtitle {
  font-size: 13px;
  font-weight: 300;
}

.options__flag {
  padding-bottom: 6px;
}

.options__lang-select {
  width: 80px;
}

.options__text {
  font-size: 17px;
}

.options__link {
  text-decoration: none;
}

.options__switch-label {
  color: var(--v-title-text);
  transition: color 0.3s ease-in-out;
}

.options__provider-list-item {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  padding: 16px 0;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.options__provider-wrapper {
  display: flex;
  align-items: center;
  flex-basis: 128px;
}

.options__provider {
  margin-right: 20px;
}

.options__button-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 4;
  gap: 16px;
}

.inactive-access {
  color: var(--v-inactive-base);
}
</style>

<style lang="scss">
.options__first-day .v-input__control > .v-input__slot::before,
.options__lang-select .v-input__control > .v-input__slot::before {
  border-color: transparent !important;
}

.options__lang-select .v-select__selection,
.v-select__selection--comma {
  text-transform: uppercase;
  font-size: 17px;
}

.options__first-day .v-select__selection,
.v-select__selection--comma {
  text-transform: none;
  font-size: 17px;
}

.options__lang-select .v-input__icon {
  position: absolute;
  left: 30px;
  top: 0;
}

.options__first-day .v-input__icon {
  position: absolute;
  left: 150px;
  top: 0;
}

.options__text-field .v-text-field__slot > input {
  padding-left: 0;
  color: var(--v-title-base) !important;
  font-size: 17px;
}

.options__text-field .v-input__slot::before {
  border-color: var(--v-border-base) !important;
}

.options__text-field .v-input__icon > button::before {
  color: var(--v-title-base);
}

.options__text-field .v-text-field__slot > .v-label {
  padding-left: 0;
}

.options__text-field--git .v-text-field__slot > .v-label {
  opacity: 0;
}
</style>
