<template lang="pug">
v-card-text.pb-0.px-0
  v-simple-table.backgroundMain
    thead
      tr
        th.pl-1.text-left
          v-icon.mr-2 {{ "$listIcon" }}
          span.card__subtext.text--text {{ $lang.list }}
        th.pl-1.text-left
          v-icon.mr-2 {{ "$scheduleIcon" }}
          span.card__subtext.text--text {{ $lang.workTimeRange }}
        th.pl-1.text-left
          v-icon.mr-2 {{ "$eventIcon" }}
          span.card__subtext.text--text {{ $lang.totalTime }}
    tbody
      template(v-if='card.locations.length')
        tr(
          v-for='location in card.locations'
          :key='location.id')
          template(v-if='location.archive')
            td(colspan='3')
              span.d-inline-block.mr-1.subheading-1.strong {{ $lang.cardInArchive }}
          template(v-else)
            td.pl-1
              span.card__subtitle.text--text {{ location.board_list_name }}
            td.pl-1
              span.card__subtitle.text--text {{ location.starts_at | LLL($lang) }} -&nbsp;
              span.card__subtitle.text--text(v-if='location.ends_at') {{ location.ends_at | LLL($lang) }}
              span.card__subtitle.text--text(v-else) {{ $lang.now }}
            td.pl-1
              span.card__subtitle.text--text {{ getTotalWorkTime(location) | dateTimeShort($lang) }}

      span.card__subtitle.text--text(v-else) {{ $lang.noData }}
</template>

<script>
export default {
  name: 'BoardColumnTable',
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  },
  computed: {},
  methods: {
    getTotalWorkTime(location) {
      let end = location.ends_at ? +new Date(location.ends_at) : +new Date()
      let start = location.starts_at
      return (end - start) / 1000
    },
  },
}
</script>
