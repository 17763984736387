<template lang="pug">
.avatar-group.flex-wrap
  template(v-if='users && users.length')
    AvatarComponent(
      v-for='(user, idx) in users'
      :key='`avatar-${user.id}`'
      :user='user'
      :size='size'
      :color='getAvatarColor(idx)'
      :fontSize='fontSize'
      :margin='margin'
      :active='true')
  template(v-else-if='user')
    AvatarComponent(
      :user='user'
      :size='size'
      :color='getAvatarColor(idx)'
      :fontSize='fontSize'
      :active='true')
  template(v-if='removedUsers && removedUsers.length')
    AvatarComponent(
      v-for='(user, idx) in removedUsers'
      :key='`avatar-${user.id}`'
      :user='user'
      :size='size'
      :color='getAvatarColor(idx)'
      :fontSize='fontSize'
      :margin='margin'
      :active='false')
  span.avatar-text.text--text(
    v-if='!(users && users.length) && !user && !removedUsers.length') {{ membersHidden ? $lang.membersHidden : $lang.notMembers }}
</template>

<script>
import { mapGetters } from 'vuex'

const AvatarComponent = () =>
  import(
    /* webpackChunkName: "AvatarComponent" */ /* webpackMode: "eager" */ '@/components/AvatarComponent.vue'
  )

export default {
  name: 'AvatarGroup',
  components: { AvatarComponent },
  props: {
    users: { type: Array, default: () => [] },
    isTooltip: { type: Boolean, default: false },
    size: { type: Number, default: 40 },
    fontSize: { type: String, default: '10px' },
    user: { type: Object, default: () => {} },
    idx: { type: Number, default: 0 },
    margin: { type: String, default: '0' },
    removedUsers: { type: Array, default: () => [] },
    membersHidden: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
  methods: {
    getAvatarColor(idx) {
      const a = idx % 6
      switch (a) {
        case 0:
          return `role4`
        case 1:
          return `role0`
        case 2:
          return `role3`
        case 3:
          return `role1`
        case 4:
          return `role3`
        case 5:
          return `role5`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.avatar-group {
  display: flex;
  align-items: center;
}

.avatar-text {
  font-size: 12px;
  line-height: 1.3;
}
</style>
