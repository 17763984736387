import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import {
  YMD,
  LLL,
  LL,
  DMY_HMS,
  HMS,
  dateTime,
  DDMMYY,
  ddMMYY,
  dateTimeShort,
} from '@/helpers/dateFormatter'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import lang from 'vue-lang'
import IconBase from '@/components/IconBase'
import LogotypeUni from '@/assets/img/icons/LogotypeUni'

const locales = {
  en: require('./locale/en.json'),
  ru: require('./locale/ru.json'),
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://25263aa6e3874b3693aa94c09c186eb7@sentry.wisesoft.tech//5',
    integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
    environment: 'develop',
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
  const currentVersion = process.env.version
  console.log('version: ' + currentVersion)
  const savedVersion = localStorage.getItem('version')
  if (currentVersion !== savedVersion) {
    //localStorage.clear()
    localStorage.setItem('version', currentVersion)
    //location.reload(true)
  }
}

Vue.component('IconBase', IconBase)
Vue.component('LogotypeUni', LogotypeUni)

Vue.filter('YMD', YMD)
Vue.filter('LLL', LLL)
Vue.filter('LL', LL)
Vue.filter('DMY_HMS', DMY_HMS)
Vue.filter('HMS', HMS)
Vue.filter('dateTime', dateTime)
Vue.filter('DDMMYY', DDMMYY)
Vue.filter('ddMMYY', ddMMYY)
Vue.filter('dateTimeShort', dateTimeShort)

Vue.config.productionTip = false

localStorage.getItem('token-data') &&
  store.commit('SET_TOKENS', JSON.parse(localStorage.getItem('token-data')))
localStorage.getItem('is_dark') &&
  store.commit('SET_IS_DARK', JSON.parse(localStorage.getItem('is_dark')))
localStorage.getItem('autoset_is_dark') &&
  store.commit(
    'SET_AUTOSET_IS_DARK',
    JSON.parse(localStorage.getItem('autoset_is_dark'))
  )
store.commit('SET_LANG', localStorage.getItem('lang') || 'en')
Vue.use(lang, { lang: store.getters['lang'], locales })
const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')

export default app
