<template lang="pug">
v-container.auth-wrapper.justify-center.pt-0
  AuthHeader
  v-row.align-end.align-sm-center.justify-sm-end
    v-col.col-12.col-sm-8.col-md-5.col-lg-5.pa-0.mt-14
      v-form.form.d-flex.flex-column(
        v-model='validForm'
        @submit.prevent='submit')
        v-card-text
          v-text-field(
            type='password'
            :label='$lang.password'
            required
            :rules='passwordRules'
            ref='passwordInput'
            v-model='password1')
          v-text-field(
            type='password'
            :label='$lang.repeatPassword'
            required
            :rules='passwordConfirmRules'
            v-model='password2')
        v-card-actions.justify-center
          button.form__btn.primary(
            type='submit'
            :disabled='!validForm') {{ $lang.save }}
</template>

<script>
const AuthHeader = () =>
  import(
    /* webpackChunkName: "AuthHeader" */ /* webpackMode: "eager" */ '@/views/Auth/components/AuthHeader.vue'
  )

export default {
  name: 'ConfirmReset',
  components: {
    AuthHeader,
  },
  data() {
    return {
      validForm: false,
      passwordRules: [(v) => (!!v && v.length >= 8) || this.$lang.required],
      passwordConfirmRules: [
        (v) =>
          (!!v && v === this.$refs.passwordInput.value) || this.$lang.required,
      ],

      nonFieldError: null,
      password1: '',
      password2: '',
      isLoading: false,
    }
  },
  methods: {
    submit() {
      this.isLoading = true
      this.nonFieldError = ''
      this.$store
        .dispatch('confirmChangePassword', {
          password: this.password1,
          token: this.$route.params.token,
        })
        .then(() => {
          this.$store.commit('ADD_NOTIFICATION', {
            color: 'success',
            text: this.$lang.confirmResetSuccess,
          })
          this.$router.push({ name: 'login' })
        })
        // .catch(error => {
        //   if (error.detail) {
        //     this.nonFieldError = error.detail
        //   } else {
        //     // Handle backend errors
        //     for (let field in error) {
        //       this.nonFieldError += error[field].join(' ')
        //     }
        //   }
        // })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.auth-wrapper {
  max-width: 1170px;

  @media (max-width: 600px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.form {
  @include auth-form;

  &__btn-wrapper {
    padding: 0 42px;
  }

  &__btn {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: normal;
    @include btn-common(center);
    margin: 21px 0 11px;
  }
}
</style>
