<template>
  <transition name="scroll-button">
    <div class="root" v-if="isShow">
      <v-btn color="primary" fab dark @click="scrollToTop()">
        <v-icon class="icon-size">{{ mdiChevronUp }}</v-icon>
      </v-btn>
    </div>
  </transition>
</template>

<script>
import { mdiChevronUp } from '@mdi/js'

export default {
  name: 'ButtonToTop',
  props: {
    isShow: { type: Boolean, default: () => false },
  },
  data: () => ({
    mdiChevronUp,
  }),
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.root {
  & .icon-size {
    font-size: 40px;
  }
}

.scroll-button-enter-active,
.scroll-button-leave-active {
  transition: opacity 0.5s;
}

.scroll-button-enter,
.scroll-button-leave-to {
  opacity: 0;
}
</style>
