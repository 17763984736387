<template lang="pug">
v-container(fluid)
  v-progress-linear(
    indeterminate
    v-show='!isLoaded')
  v-row
    InternalLink.mr-4(
      v-if='isLoaded'
      name='burndownchart'
      :params='{id: currentBoard.id}'
      :text='$lang.burndownChart'
      :icon='mdiOpenInNew'
      color='var(--v-main-base)')
    InternalLink(
      v-if='isLoaded'
      name='members'
      :query='{"init-project": currentBoard.id}'
      :text='$lang.toProjectMembers'
      :icon='mdiOpenInNew')
    v-col.px-3(cols='12')
      SprintsItem(
        :sprints='sprints'
        :boardId='+this.$route.params.boardId'
        :lists='showLists')
</template>

<script>
import { mdiOpenInNew } from '@mdi/js'
import { createNamespacedHelpers, mapGetters } from 'vuex'
const { mapState: sprintsMapState } = createNamespacedHelpers('sprints')
const { mapState: boardsMapState } = createNamespacedHelpers('boards')

const SprintsItem = () =>
  import(
    /* webpackChunkName: "SprintsItem" */ /* webpackMode: "eager" */ '@/views/Sprints/SprintsItem.vue'
  )
const InternalLink = () =>
  import(
    /* webpackChunkName: "InternalLink" */ /* webpackMode: "eager" */ '@/components/InternalLink.vue'
  )

export default {
  name: 'SprintsAtBoard',
  components: {
    SprintsItem,
    InternalLink,
  },
  data: () => ({
    isLoaded: false,
    mdiOpenInNew,
  }),
  created() {
    Promise.all([
      this.$store.dispatch(
        'sprints/getBoardSprints',
        this.$route.params.boardId
      ),
      this.$store.dispatch('boards/getBoard', this.$route.params.boardId),
    ]).finally(() => {
      this.isLoaded = true
    })
  },
  computed: {
    ...mapGetters(['isDark']),
    ...sprintsMapState(['sprints', 'showLists']),
    ...boardsMapState(['currentBoard']),
  },
}
</script>

<style lang="scss" scoped></style>
