import { pluralize } from './pluralize'
import { localeValues } from './constants'

// YY-MM-DD
export const YMD = (value) => {
  let date = new Date(value)
  let year = date.getFullYear()
  let month = (date.getMonth() + 1).toString()
  let day = date.getDate().toString()

  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
}

// LLL
export function LLL(value, $lang) {
  const months = $lang.monthsList
  const date = new Date(value)
  const hours = date.getHours()
  const minutes = date.getMinutes()
  return `${
    months[date.getMonth()]
  }, ${date.getDate()}, ${date.getFullYear()} ${
    hours < 10 ? '0' + hours : hours
  }:${minutes < 10 ? '0' + minutes : minutes}`
}

export function LL(value, $lang) {
  const date = new Date(value)
  const months = $lang.monthsList
  return `${months[date.getMonth()]}, ${date.getDate()}, ${date.getFullYear()}`
}

// DD.MM.YYYY - HH:mm:ss
export function DMY_HMS(value) {
  const date = new Date(value)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()
  return `${day < 10 ? '0' + day : day}.${
    month < 10 ? '0' + month : month
  }.${date.getFullYear()} - ${hours < 10 ? '0' + hours : hours}:${
    minutes < 10 ? '0' + minutes : minutes
  }:${seconds < 10 ? '0' + seconds : seconds}`
}

// HH:mm:ss
export function HMS(value) {
  const date = new Date(value)
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()
  return `${hours < 10 ? '0' + hours : hours}:${
    minutes < 10 ? '0' + minutes : minutes
  }:${seconds < 10 ? '0' + seconds : seconds}`
}

export function dateTime(value, $lang) {
  if (value === 1) {
    return `${$lang.daily} (8:00)`
  }
  let min = value / 60
  let hour = min / 60

  const getDays = Math.floor(hour / 24)
  const getHours = Math.floor(hour % 24)
  const getMinutes = Math.floor(min % 60)

  const pluralizeDay = pluralize(
    {
      many: $lang.daysPluralize[3],
      few: $lang.daysPluralize[2],
      one: $lang.daysPluralize[1],
      zero: $lang.daysPluralize[0],
    },
    getDays
  )

  const pluralizeHours = pluralize(
    {
      many: $lang.hoursPluralize[3],
      few: $lang.hoursPluralize[2],
      one: $lang.hoursPluralize[1],
      zero: $lang.hoursPluralize[0],
    },
    getHours
  )

  const pluralizeMinutes = pluralize(
    {
      many: $lang.minutesPluralize[3],
      few: $lang.minutesPluralize[2],
      one: $lang.minutesPluralize[1],
      zero: $lang.minutesPluralize[0],
    },
    getMinutes
  )

  return `${pluralizeDay}, ${pluralizeHours}, ${pluralizeMinutes}`
}

export function DDMMYY(value, $lang) {
  const date = new Date(value)
  const months = $lang.monthsList
  const days = $lang.daysList
  return `${days[date.getDay()]}, ${date.getDate()} ${
    months[date.getMonth()]
  } ${date.getFullYear()}`
}

// for BoardColumns.vue
export function ddMMYY(value, $lang) {
  const date = new Date(value)
  const months = $lang.monthsList
  const days = $lang.daysList
  return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}, ${
    days[date.getDay()]
  }`
}

export function dateTimeShort(value, $lang) {
  let min = value / 60
  let hour = min / 60

  const getDays = Math.floor(hour / 24)
  const getHours = Math.floor(hour % 24)
  const getMinutes = Math.floor(min % 60)

  return `${getDays} ${$lang.shortDays}; ${getHours} ${$lang.shortHours}; ${getMinutes} ${$lang.shortMinutes}`
}

// for date-picker output
export function LLP(value, $lang) {
  const date = new Date(value)
  const months = $lang.monthsList
  return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`
}

//for active sprint period on Boards
export function getSprintPeriod(start, end, lang) {
  const locale = localeValues[lang]
  const startYear = new Date(start).getYear()
  const endYear = new Date(end).getYear()
  const startMonth = new Date(start).getMonth()
  const endMonth = new Date(end).getMonth()

  if (startYear !== endYear) {
    return `${new Date(start).toLocaleDateString(locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })} - ${new Date(end).toLocaleDateString(locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })}`
  }
  if (startYear === endYear && startMonth !== endMonth) {
    return `${new Date(start).toLocaleDateString(locale, {
      day: 'numeric',
      month: 'long',
    })} - ${new Date(end).toLocaleDateString(locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })}`
  }
  return locale === 'ru'
    ? `${new Date(start).toLocaleDateString(locale, {
        day: 'numeric',
      })} - ${new Date(end).toLocaleDateString(locale, {
        day: 'numeric',
        month: 'long',
      })}`
    : `${new Date(start).toLocaleDateString(locale, {
        month: 'long',
        day: 'numeric',
      })} - ${new Date(end).toLocaleDateString(locale, {
        day: 'numeric',
      })}`
}

export function getRemovalTime(date, lang) {
  const locale = localeValues[lang]
  return new Date(date).toLocaleDateString(locale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })
}

// for sprints section in CardComponent -> ru - "15 июля 2022г.", en - "July 15, 2022"
export function getDateForCard(date, lang) {
  const locale = localeValues[lang]

  return `${new Date(date).toLocaleDateString(locale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })}`
}

// -> ru - "15.07.2022", en - "7/6/2022"
export function getShortDateForCard(date, lang) {
  const locale = localeValues[lang]

  return `${new Date(date).toLocaleDateString(locale)}`
}

export function getArrayOfDatesFromRange(start, end) {
  const startDate = new Date(start)
  const endDate = new Date(end)
  const dates = []

  let currentDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate()
  )

  while (currentDate <= endDate) {
    const item = YMD(currentDate)
    dates.push(item)

    currentDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1
    )
  }

  return dates
}
