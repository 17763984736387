<template lang="pug">
section
  template(v-for='component in components')
    v-expansion-panels(
      flat
      :key='component')
      v-expansion-panel.backgroundMain
        v-expansion-panel-header.pa-0
          CardSectionTitle(:title='getTitle(component)')
        v-expansion-panel-content.section__component.backgroundMain
          component(
            :is='component'
            :card='card')
</template>

<script>
const CardSectionTitle = () =>
  import(
    /* webpackChunkName: "CardSectionTitle" */ /* webpackMode: "eager" */ '@/views/Card/components/CardSectionTitle.vue'
  )
const Chronology = () =>
  import(
    /* webpackChunkName: "SectionCardChronology" */ /* webpackMode: "eager" */ '@/views/Card/components/CardChronology.vue'
  )
const Actions = () =>
  import(
    /* webpackChunkName: "SectionCardActions" */ /* webpackMode: "eager" */ '@/views/Card/components/CardActions.vue'
  )
const Commits = () =>
  import(
    /* webpackChunkName: "SectionCardCommits" */ /* webpackMode: "eager" */ '@/views/Card/components/CardCommits.vue'
  )
const Sprints = () =>
  import(
    /* webpackChunkName: "SectionCardSprints" */ /* webpackMode: "eager" */ '@/views/Card/components/CardSprints.vue'
  )

export default {
  name: 'SectionComponent',
  components: {
    CardSectionTitle,
    Chronology,
    Actions,
    Commits,
    Sprints,
  },
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    components: ['Chronology', 'Actions', 'Commits', 'Sprints'],
  }),
  methods: {
    getTitle(component) {
      const name = `card${component}Title`
      return this.$lang[name]
    },
  },
}
</script>

<style lang="scss">
.section__component .v-expansion-panel-content__wrap {
  padding-left: 0;
  padding-right: 0;
}
</style>
