<template lang="pug">
.services
  template(v-for='service in sortedServices')
    v-tooltip(bottom)
      template(v-slot:activator='{on}')
        v-icon.services__icon(
          v-on='on'
          size='28'
          :key='service.id'
          :class='{"services__icon--inactive": !service.with_token}') {{ getProviderIcon(service.provider) }}
      span.services__tooltip-text {{ service.provider }}
      span.services__tooltip-text {{ service.with_token ? $lang.activeServiceToken : $lang.inactiveServiceToken }}
</template>

<script>
export default {
  name: 'UserServices',
  props: {
    services: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    sortedServices() {
      return [...this.services].sort((a, b) => {
        return a.provider.toUpperCase() < b.provider.toUpperCase() ? -1 : 1
      })
    },
  },
  methods: {
    getProviderIcon(provider) {
      return this.$vuetify.icons.values[`${provider}Icon`]
        ? `$${provider}Icon`
        : '$userIcon'
    },
  },
}
</script>

<style lang="scss" scoped>
.services {
  &__icon {
    margin-right: 16px;

    &--inactive {
      filter: opacity(40%);
    }
  }

  &__tooltip-text {
    display: block;
  }
}
</style>
