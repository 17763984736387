<template>
  <v-app :dark="isDark" class="app">
    <router-view />
    <v-snackbar
      :value="isActiveNotification"
      @input="closeNotification"
      bottom
      right
      :color="notificationInfo.color"
    >
      <span>
        {{ notification }}
      </span>
      <v-btn color="white" icon @click="closeNotification">
        <v-icon>{{ mdiClose }}</v-icon>
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mdiClose } from '@mdi/js'
import { mapGetters, createNamespacedHelpers } from 'vuex'
const { mapActions: optionsMapActions } = createNamespacedHelpers('options')

export default {
  name: 'App',
  data() {
    return {
      interval: null,
      mdiClose,
    }
  },
  mounted() {
    window.addEventListener('resize', this.checkDeviceType, false)
    this.checkDeviceType()
    this.$vuetify.theme.dark = this.isDark
    this.checkIsNight()
    this.interval = setInterval(this.checkIsNight, 1000 * 60)

    const { dispatch } = this.$store

    if (!localStorage.getItem('lang')) {
      let language = window.navigator
        ? window.navigator.language ||
          window.navigator.systemLanguage ||
          window.navigator.userLanguage
        : 'en'
      const value = language.substr(0, 2).toLowerCase() === 'ru' ? 'ru' : 'en'
      dispatch('setLang', value)
      this.$setLang(value)
      this.$vuetify.lang.current = value
    }
  },
  destroyed() {
    clearInterval(this.interval)
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'isTablet',
      'isDark',
      'isAutosetIsDark',
      'isActiveNotification',
      'notificationInfo',
      'isError',
    ]),
    notification() {
      if (typeof this.notificationInfo.text === 'object') {
        let nonFieldError = ''
        for (const field in this.notificationInfo.text) {
          nonFieldError += Array.isArray(this.notificationInfo.text[field])
            ? this.notificationInfo.text[field].join(' ')
            : this.notificationInfo.text[field]
        }
        return nonFieldError
      }
      return this.notificationInfo.text
    },
  },
  methods: {
    ...optionsMapActions(['checkIsNightWithAutoSet']),
    closeNotification() {
      this.$store.commit('CLOSE_NOTIFICATION')
    },
    checkDeviceType() {
      if (
        window.innerWidth < 600 &&
        !(this.isMobile === true && this.isTablet === false)
      ) {
        this.$store.commit('SET_IS_MOBILE', true)
        this.$store.commit('SET_IS_TABLE', false)
      } else if (
        window.innerWidth >= 600 &&
        window.innerWidth < 960 &&
        !(this.isMobile === false && this.isTablet === true)
      ) {
        this.$store.commit('SET_IS_TABLE', true)
        this.$store.commit('SET_IS_MOBILE', false)
      } else if (
        window.innerWidth >= 960 &&
        !(this.isMobile === false && this.isTablet === false)
      ) {
        this.$store.commit('SET_IS_MOBILE', false)
        this.$store.commit('SET_IS_TABLE', false)
      }
    },
    checkIsNight() {
      if (this.isError) {
        clearInterval(this.interval)
        return
      }

      this.checkIsNightWithAutoSet()
    },
  },
}
</script>

<style lang="scss">
.v-snack__content {
  display: flex;
  align-items: center;

  & button.v-btn {
    margin-left: auto;
  }
}
</style>
