<template lang="pug">
v-row.members(no-gutters)
  v-progress-linear(
    indeterminate
    v-show='!isLoadedMembers')
  EmptyEntities(
    v-if='isLoadedMembers && !members.length'
    :message='$lang.empty.members')
  template(v-if='isLoadedMembers && members.length')
    v-col.members__outer.col-12.px-0.d-flex(v-if='tab === 0')
      .members__wrapper
        .members__switchers-wrapper
          RadioButtonsGroup(@toggleChartFilterType='toggleChartFilterType')
          v-switch.members__switch(
            v-model='isChartsVisible'
            color='main'
            hide-details
            @change='setVisibilityCharts()')
            template(v-slot:label)
              span.members__switch-label.main--text {{ $lang.displayActivity }}
        .members__filters(v-if='isChartsVisible')
          v-autocomplete.members__select(
            v-model='selectedMember'
            :items='[...actualMembers]'
            item-value='id'
            item-text='full_name'
            :label='$lang.filterByMembers'
            clearable)
          v-autocomplete.members__select(
            v-model='selectedBoard'
            :items='[...filteredBoards]'
            item-value='id'
            item-text='name'
            :label='$lang.filterByProjects'
            clearable)
    v-col.col-12.px-sm-0
      v-tabs.tabs(
        v-model='tab'
        hide-slider
        background-color='backgroundMain'
        active-class='tabs__active')
        v-tab.tabs__tab {{ $lang.activeMembers }}
        v-tab.tabs__tab {{ $lang.archived }}
      v-tabs-items.tabs-items.backgroundMain(v-model='tab')
        v-tab-item.backgroundMain(
          v-for='item in sorting'
          :key='item.title')
          v-col.col-12.col-lg-11.pa-0
            v-list.pa-0.backgroundMain
              v-list-item.members__member-item.d-flex.flex-wrap.px-0.py-2.align-start.backgroundMain(
                v-for='(member, idx) in item.members'
                :key='member.id')
                v-col.col-12.pa-0.mt-lg-5
                  v-list-item.pl-0.flex-wrap(
                    :to='{name: "member", params: {id: member.id}}')
                    v-list-item-avatar.mr-4.mr-sm-6(:size='42')
                      AvatarGroup(
                        :user='member'
                        :size='42'
                        :fontSize='"16px"'
                        :idx='idx')
                    v-list-item-action.ml-0
                      h2.members__member-name(
                        :class='!member.actual ? "lightGrey--text" : "grey--text"')
                        span(v-if='!member.actual') ({{ $lang.notActive }})
                        |
                        | {{ member.full_name }}
                    UserServices(:services='member.users_services')
                UserProjects.members__user-projects(
                  :projects='member.member_boards'
                  :id='member.id')
                v-col.col-12.pa-0(v-if='isChartsVisible')
                  v-lazy
                    MemberActivityCalendar.members__calendar(
                      v-intersect='onIntersect'
                      v-if='member.actual'
                      :member='member'
                      visualMapType='piecewise'
                      :filterType='filterType'
                      :selectedBoard='selectedBoard')
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const {
  mapState: membersMapState,
  mapActions: membersMapActions,
  mapMutations: memberMapMutations,
} = createNamespacedHelpers('members')
const { mapGetters: optionsMapGetters, mapActions: optionsMapActions } =
  createNamespacedHelpers('options')
const { mapState: boardsMapState, mapActions: boardsMapActions } =
  createNamespacedHelpers('boards')

const EmptyEntities = () =>
  import(
    /* webpackChunkName: "EmptyEntities" */ '@/components/EmptyEntities.vue'
  )
const AvatarGroup = () =>
  import(
    /* webpackChunkName: "AvatarGroup" */ /* webpackMode: "eager" */ '@/components/AvatarGroup.vue'
  )
const MemberActivityCalendar = () =>
  import(
    /* webpackChunkName: "MemberActivityCalendar" */ /* webpackMode: "eager" */ '@/views/Members/components/MemberActivityCalendar.vue'
  )
const RadioButtonsGroup = () =>
  import(
    /* webpackChunkName: "RadioButtonsGroup" */ /* webpackMode: "eager" */ '@/views/Members/components/RadioButtonsGroup.vue'
  )
const UserServices = () =>
  import(
    /* webpackChunkName: "UserServices" */ /* webpackMode: "eager" */ '@/views/Members/components/UserServices.vue'
  )
const UserProjects = () =>
  import(
    /* webpackChunkName: "UserProjects" */ /* webpackMode: "eager" */ '@/views/Members/components/UserProjects.vue'
  )

export default {
  name: 'MembersComponent',
  components: {
    EmptyEntities,
    AvatarGroup,
    MemberActivityCalendar,
    RadioButtonsGroup,
    UserServices,
    UserProjects,
  },
  provide: {
    chartHeight: '160',
    marginBottom: '0',
  },
  data() {
    return {
      isLoadedMembers: false,
      tab: null,
      selectedMember: null,
      selectedBoard: null,
      isChartsVisible: false,
      filterType: 'actions',
      isListVisible: false,
    }
  },
  computed: {
    ...membersMapState(['members', 'chartFilterType', 'activity']),
    ...optionsMapGetters(['settings']),
    ...boardsMapState(['userBoards']),
    sortMembers() {
      return this.members.slice().sort((a, b) => {
        return b.actual - a.actual
      })
    },
    actualMembers() {
      let arr = this.members.filter((member) => member.actual)
      if (this.selectedBoard) {
        return arr.filter((member) =>
          member.member_boards.some((el) => el.id === this.selectedBoard)
        )
      }
      return arr
    },
    archiveMembers() {
      return this.members.filter((member) => !member.actual)
    },
    sorting() {
      return [
        { members: this.filteredMembers, title: 'actual' },
        { members: this.archiveMembers, title: 'archive' },
      ]
    },
    filteredMembers() {
      return this.selectedMember
        ? this.actualMembers.filter(
            (member) => member.id === this.selectedMember
          )
        : this.actualMembers
    },
    filteredBoards() {
      if (this.selectedMember) {
        const memberBoards = this.members.filter(
          (member) => member.id === this.selectedMember
        )[0].member_boards
        return this.userBoards.filter((board) =>
          memberBoards.map((b) => b.id).includes(board.id)
        )
      }
      return this.userBoards
    },
  },
  watch: {
    chartFilterType(n, o) {
      if (n !== o) {
        this.filterType = n
        this.setToZeroActivity()
      }
    },
    selectedBoard(n) {
      this.setToZeroActivity()
      this.filteredMembers.map((member) => {
        this.getActivity({
          id: member.id,
          filter_by_board: n,
          filter_type: this.filterType,
        })
      })

      this.$router.replace({ query: { project: n } })
    },
  },
  created() {
    Promise.all([this.getMembers(), this.getUserBoards()])
      .then(() => {
        this.setToZeroActivity()
      })
      .finally(() => {
        this.isLoadedMembers = true
        this.isChartsVisible = this.settings.isChartsVisible
      })
    this.selectedBoard = this.$route.query['init-project'] ?? null
  },
  destroyed() {
    this.$store.commit('members/setToZeroMembers')
  },
  methods: {
    ...membersMapActions(['getMembers', 'getActivity']),
    ...optionsMapActions(['postOptions']),
    ...boardsMapActions(['getUserBoards']),
    ...memberMapMutations(['setChartFilterType', 'setToZeroActivity']),
    setVisibilityCharts() {
      this.selectedMember = null
      this.selectedBoard = null
      this.postOptions({ isChartsVisible: this.isChartsVisible })
    },
    toggleChartFilterType(val) {
      this.setChartFilterType(val)
      this.filterType = val
    },
    onIntersect(entries, observer, isIntersecting) {
      const id = entries[0].target.id
      if (isIntersecting && !this.activity.some((item) => item.id === id)) {
        this.getActivity({
          id: id,
          filter_by_board: this.selectedBoard,
          filter_type: this.filterType,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.members {
  @media (max-width: 600px) {
    padding: 0 12px;
  }

  &__outer {
    justify-content: flex-end;

    @media (max-width: 1500px) {
      justify-content: flex-start;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 32px;
    z-index: 6;
    font-size: 12px;

    @media (max-width: 1500px) {
      position: static;
      z-index: auto;
      align-items: flex-start;
      margin-top: 57px;
    }

    @media (max-width: 600px) {
      margin-top: 30px;
    }
  }

  &__switchers-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 12px;

    @media (max-width: 1500px) {
      flex-direction: column-reverse;
    }
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
  }

  &__switch-label {
    font-size: 12px;
    margin-right: 12px;
  }

  &__select {
    margin-right: 20px;

    @media (max-width: 600px) {
      margin-right: 0;
    }
  }

  &__member-item {
    min-height: 0;
  }

  &__member-name {
    @include subtitle-common;
    margin-right: 24px;

    @media (max-width: 600px) {
      font-size: 20px;
    }
  }

  &__user-projects {
    margin-left: 8px;
  }
}

.tabs-items {
  padding-top: 64px;
}
</style>

<style lang="scss">
.members__select .v-input__control {
  width: 360px;
  max-width: 360px;
}

.members__radio .v-radio label {
  color: var(--v-text-base) !important;
}

.members__radio .v-radio.active label {
  color: var(--v-main-base) !important;
}

.members__select .v-select__slot > label {
  color: var(--v-text-base);
  padding-left: 0;
}
</style>
