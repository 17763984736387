<template>
  <transition name="copy">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      viewBox="0 0 16 16"
      aria-labelledby="iconCopyAnimate"
      role="presentation"
    >
      <g
        class="copy"
        ref="copy"
        fill="none"
        stroke="var(--v-title-base)"
        :stroke-dasharray="50"
        :stroke-dashoffset="copied ? -50 : 0"
      >
        <path d="M5.75 4.75H10.25V1.75H5.75V4.75Z" />
        <path
          d="M3.25 2.88379C2.9511 3.05669 2.75 3.37987 2.75 3.75001V13.25C2.75 13.8023 3.19772 14.25 3.75 14.25H12.25C12.8023 14.25 13.25 13.8023 13.25 13.25V3.75001C13.25 3.37987 13.0489 3.05669 12.75 2.88379"
        />
      </g>

      <g
        class="success"
        ref="success"
        fill="none"
        stroke="var(--v-main-base)"
        :stroke-dasharray="50"
        :stroke-dashoffset="!copied ? -50 : 0"
      >
        <path d="M13.25 4.75L6 12L2.75 8.75" />
      </g>
    </svg>
  </transition>
</template>

<script>
export default {
  name: 'IconCopyAnimate',
  props: {
    copied: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '20',
    },
    height: {
      type: String,
      default: '20',
    },
  },
}
</script>
