<template lang="pug">
div(v-if='isLoaded')
  .d-flex.align-center.my-3
    //- span.setting__title.grey--text.mr-3 {{ $lang.addNotification }}
    button(
      @click='toggleCreateShow()'
      :class='!isCreateShow ? "setting__button-blue" : "setting__button-white"') {{ !isCreateShow ? $lang.addNotification : $lang.closeForm }}
    //- v-btn(
    //-   @click='toggleCreateShow()'
    //-   icon)
    //-   v-icon(
    //-     v-if='!isCreateShow'
    //-     color='success') {{ mdiPlus }}
    //-   v-icon(
    //-     v-if='isCreateShow'
    //-     color='error') {{ mdiMinus }}
  transition(name='createNotification')
    CreateNotificationSettings(
      v-if='isCreateShow'
      @toggleAddedNotification='toggleCreateShow(false)'
      :currentBoard='currentBoard'
      :alerts='alerts'
      :alertsParameters='alertsParameters')
  div(v-if='alertsBoard && alertsBoard.length')
    v-list.backgroundMain(dense)
      v-list-item.d-flex.flex-column(
        v-for='(alert, index) in [...alertsBoard].reverse()'
        :key='`notification-${alert.id}`'
        :class='[index % 2 === 0 ? "setting__dark" : ""]')
        v-list-item-content
          .title-2.d-flex.justify-space-between.align-center
            | {{ `#${alert.id}` }} {{ getAlertTypeName(alert) }}
            div
              v-tooltip(bottom)
                template(v-slot:activator='{on}')
                  v-btn(
                    icon
                    v-on='on'
                    @click='editAlert(alert)'
                    color='main')
                    v-icon {{ mdiPencil }}
                span.white--text.title-1 {{ $lang.edit }}
              v-tooltip(bottom)
                template(v-slot:activator='{on}')
                  v-btn(
                    icon
                    v-on='on'
                    @click='copyAlert(alert)')
                    v-icon {{ mdiContentCopy }}
                span.white--text.title-1 {{ $lang.copy }}
              v-tooltip(bottom)
                template(v-slot:activator='{on}')
                  v-btn(
                    v-on='on'
                    @click='deleteAlert(alert.id)'
                    icon
                    color='error')
                    v-icon {{ mdiClose }}
                span.white--text.title-1 {{ $lang.delete }}
          .mb-2(v-if='getAlertEventTypeName(alert.create_type)')
            span.grey--text {{ $lang.addByEvent }}:&nbsp;
            span.setting__text {{ getAlertEventTypeName(alert.create_type) }}
          div(
            v-for='(param, name) in alert.create_parameters'
            :key='`createParams-${name}`')
            .mb-2(v-if='name !== "compare"')
              span.grey--text {{ getAlertParamName(name) }}:&nbsp;
              span.setting__text(v-if='name === "cards_count"') {{ getAlertCompareValue(alert.create_parameters["compare"]) }}&nbsp;
              span.setting__text {{ getAlertParamValue(param, name) }}

          .mb-2.mt-3(v-if='getAlertEventTypeName(alert.delete_type)')
            span.grey--text {{ $lang.resetByEvent }}:&nbsp;
            span.setting__text {{ getAlertEventTypeName(alert.delete_type) }}
          .mb-2(
            v-for='(param, name) in alert.delete_parameters'
            :key='`deleteParams-${name}`')
            span.grey--text {{ getAlertParamName(name) }}:&nbsp;
            span.setting__text {{ getAlertParamValue(param, name) }}

          .mb-2.mt-3
            span.grey--text {{ $lang.notificationText }}:&nbsp;
            span.setting__text {{ alert.text }}
          .mb-2
            span.grey--text {{ $lang.repeat }}:&nbsp;
            span.setting__text {{ alert.repeat ? $lang.yes : $lang.no }}
          .mb-2
            span.grey--text {{ $lang.timer }}:&nbsp;
            span.setting__text {{ alert.delta_time | dateTime($lang) }}

  div(v-if='isEditingShow')
    v-dialog(
      v-model='isEditingShow'
      max-width='630'
      persistent)
      v-card
        v-card-title.d-flex.flex-nowrap.align-start(v-if='titleAlert')
          span {{ titleAlert }}
          v-btn.ml-2.ml-sm-auto(
            color='blue darken-1'
            text
            @click='closeDialog()'
            fab
            small)
            v-icon(dark) {{ mdiClose }}
        v-card-text.px-0.px-sm-3
          CreateNotificationSettings(
            @clearAlert='copyAlert()'
            @closeFormAlert='closeDialog()'
            :alert='copyAlertItem'
            :isEditing='isEditing'
            :currentBoard='currentBoard'
            :alerts='alerts'
            :alertsParameters='alertsParameters')
</template>

<script>
import { mdiPlus, mdiMinus, mdiContentCopy, mdiClose, mdiPencil } from '@mdi/js'
import { mapGetters, mapMutations, createNamespacedHelpers } from 'vuex'
const { mapGetters: boardsMapGetters, mapActions: boardsMapActions } =
  createNamespacedHelpers('boards')

const CreateNotificationSettings = () =>
  import(
    /* webpackChunkName: "CreateNotificationSettings" */ /* webpackMode: "eager" */ '@/views/Boards/SettingsTab/CreateNotificationSettings.vue'
  )

export default {
  name: 'NotificationSettings',
  components: { CreateNotificationSettings },
  data() {
    return {
      isCreateShow: false,
      isLoaded: false,

      copyAlertItem: {},
      timer: undefined,

      isEditingShow: false,
      isEditing: false,

      titleAlert: '',
      mdiPlus,
      mdiMinus,
      mdiContentCopy,
      mdiClose,
      mdiPencil,
    }
  },
  created() {
    Promise.all([
      this.getAlertsVariants(this.$route.params.id),
      this.getAlertsBoard(this.$route.params.id),
    ]).finally(() => {
      this.isLoaded = true
    })
  },
  destroyed() {
    clearTimeout(this.timer)
  },
  computed: {
    ...mapGetters(['isDark']),
    ...boardsMapGetters(['alertsVariants', 'alertsBoard', 'currentBoard']),

    alerts() {
      if (!this.isLoaded) return []
      return this.alertsVariants.alerts
    },

    alertsParameters() {
      return this.alertsVariants.parameters
    },
  },
  methods: {
    ...mapMutations(['ADD_NOTIFICATION']),
    ...boardsMapActions([
      'getAlertsVariants',
      'getAlertsBoard',
      'deleteListAlert',
    ]),
    async deleteAlert(alertId) {
      const res = await this.deleteListAlert({
        id: alertId,
        boardId: this.$route.params.id,
      })
      if (res === 'ok') {
        this.ADD_NOTIFICATION({
          color: 'success',
          text: this.$lang.notificationRemoved,
        })
      }
    },
    toggleCreateShow(isCreateShow = !this.isCreateShow) {
      this.isCreateShow = isCreateShow
      if (!this.isCreateShow) {
        this.copyAlertItem = {}
      }
    },
    copyAlert(alert) {
      this.copyAlertItem = alert ? JSON.parse(JSON.stringify(alert)) : {}
      this.isEditingShow = true
      this.isEditing = false
      this.titleAlert = this.$lang.copyNotification
    },
    editAlert(alert) {
      this.copyAlertItem = alert ? JSON.parse(JSON.stringify(alert)) : {}
      this.isEditingShow = true
      this.isEditing = true
      this.titleAlert = this.$lang.editNotification
    },
    closeDialog() {
      this.isEditingShow = false
    },
    getAlertTypeName(alert) {
      return this.alertsParameters.alert_type?.values[alert.alert_type]
    },
    getAlertEventTypeName(eventTypeId) {
      return (
        this.alerts.find((alert) => alert.alert_id === eventTypeId)?.label ||
        null
      )
    },
    getAlertParamName(name) {
      return this.alertsVariants.parameters[name]?.label
    },
    getAlertParamValue(param, name) {
      return Array.isArray(param)
        ? param
            .map((item) => this.alertsVariants.parameters[name].values[item])
            .join(', ')
        : this.alertsVariants.parameters[name].values[param]
        ? this.alertsVariants.parameters[name].values[param]
        : param
    },
    getAlertCompareValue(value) {
      return this.alertsVariants.parameters['compare'].values[value]
    },
  },
}
</script>

<style lang="scss" scoped>
.createNotification-enter-active,
.createNotification-leave-active {
  transition: opacity 0.5s;
}

.createNotification-enter, .fade-leave-to /* .createNotification-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.setting {
  &__title {
    @include subtitle-common;
  }

  &__dark {
    background: var(--v-backgroundTable-base);
  }

  &__text {
    line-height: 1.4;
  }
}
</style>
