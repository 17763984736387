<template lang="pug">
v-row.header-wrapper.justify-space-between(
  no-gutters
  v-if='currentBoard')
  v-col.d-flex.flex-column.justify-space-between(
    :class='isSettings ? "col-12" : "col-12 col-md-8"')
    .justify-space-between.pa-0
      .d-flex.align-center
        v-btn.mr-5(
          :to='{name: "boards"}'
          icon
          :aria-label='$lang.linkGoBack')
          v-icon {{ mdiKeyboardBackspace }}

        h1.screen-subtitle.title--text(v-if='!isSettings') {{ currentRoute }}
        h1.screen-subtitle.title--text(v-else) {{ $lang.settingsOfBoard }} "{{ currentRoute }}"
    span.board__progress.title--text.d-none.d-md-inline(
      v-if='isAdmin && !isSettings') {{ $lang.loadedData }}: {{ boardProgress }}% &nbsp;
    .caption(
      v-else-if='isAdminsKey && !this.currentBoard.admins.length && !isSettings')
      v-icon.yellow--text {{ mdiAlert }}
      span {{ $lang.requiredAdmin }},&nbsp;
        router-link.text-none(:to='{name: "faq", hash: "#needAdmin"}') {{ $lang.moreDetailsForDoc }}
  v-col.trello.col-12.col-md-4.d-flex.justify-space-between.align-start(
    v-if='!isSettings')
    .trello__wrapper.d-flex.align-center.mt-5(@click.stop)
      a.trello__link(
        :href='currentBoard.shortUrl'
        target='_blank'
        rel='noopener')
        span.trello__text Trello
        v-icon {{ "$launchIcon" }}
    v-btn.d-flex.mt-3(
      v-if='isAdminsKey && !isSettings && currentBoard.admins.map((a) => a.id).includes(userId)'
      :to='{name: "boardSettings", params: {id: currentBoard.id}}'
      :aria-label='$lang.linkToBoardSettings'
      icon)
      v-icon.board__gear-icon {{ "$gearBoardIcon" }}
</template>

<script>
import { mdiKeyboardBackspace, mdiAlert } from '@mdi/js'
import { createNamespacedHelpers, mapGetters } from 'vuex'
const { mapState: boardsMapState } = createNamespacedHelpers('boards')

export default {
  name: 'BoardHeader',
  props: {
    currentBoard: {
      type: Object,
    },
    currentRoute: {
      type: String,
    },
    isSettings: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mdiKeyboardBackspace,
    mdiAlert,
  }),
  computed: {
    ...mapGetters(['isMobile', 'userId']),
    ...boardsMapState(['boardProgress']),
    isAdmin() {
      return this.currentBoard?.admins
        ? this.currentBoard.admins.map((a) => a.id).includes(this.userId)
        : false
    },
    isAdminsKey() {
      return this.currentBoard?.admins
    },
  },
}
</script>

<style lang="scss" scoped>
.header-wrapper {
  margin-left: -72px;

  @media (max-width: 600px) {
    margin-left: -16px;
  }
}

.board {
  &__card {
    height: 100%;
  }

  &__progress {
    font-size: 17px;
    margin-left: 72px;
  }
}

.caption {
  margin-left: 72px;
}

.trello {
  @media (max-width: 960px) {
    padding-left: 68px !important;
  }

  @media (max-width: 600px) {
    padding-left: 16px !important;
  }

  &__wrapper {
    margin-bottom: 17px;
  }

  &__link {
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  &__text {
    font-size: 20px;
    font-weight: 400;
    color: var(--v-main-base);
    margin-right: 7px;
  }
}
</style>
