<template lang="pug">
v-row.custom-header(no-gutters)
  v-col.col-12.pa-0
    v-card.backgroundMain.mb-3(
      v-if='name'
      elevation='0')
      v-card-title.board__card-title.justify-space-between.pa-0
        .d-flex.align-center
          v-btn.custom-header__button.mr-5(
            :disabled='this.link === "cards"'
            @click='goToBack'
            icon
            :aria-label='$lang.linkGoBack')
            v-icon(v-if='this.link !== "cards"') {{ mdiKeyboardBackspace }}
          h1.screen-subtitle.title--text {{ name }}
          .header-trello__wrapper.d-none.d-md-flex.align-center.ml-4.ml-lg-14(
            @click.stop)
</template>

<script>
import { mdiKeyboardBackspace } from '@mdi/js'

export default {
  name: 'CustomHeader',
  props: {
    name: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    mdiKeyboardBackspace,
  }),
  computed: {
    boardName() {
      return this.sprint[0] ? this.sprint[0].board.name : ''
    },
  },
  methods: {
    goToBack() {
      this.link === 'charts'
        ? this.$router.back()
        : this.$router.push({ name: this.link })
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-header {
  margin-left: -72px;

  @media (max-width: 600px) {
    margin-left: -16px;
  }
}

.v-btn--active:before {
  opacity: 0;
}
</style>
