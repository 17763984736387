import request from '@/services/request'
import { apiStatCards } from '@/helpers/urls'

const state = {
  statCards: [],
  burndownData: [],
}

const mutations = {
  setStatCards: (state, payload) => {
    payload.sort((a, b) => a.column_id - b.column_id)
    state.statCards = payload
  },
  setBurndownData: (state, payload) => {
    state.burndownData = payload
  },
}

const actions = {
  getStatCards: async (
    { commit },
    { board_id, ttype, range = {}, sprint_number = null }
  ) => {
    let params
    if (sprint_number) {
      params = {
        board_id,
        sprint_number,
        ttype,
      }
    } else {
      const { start, end } = range
      params = {
        start,
        end,
        board_id,
        ttype,
      }
    }

    try {
      const res = await request({
        url: `${apiStatCards}`,
        method: 'GET',
        params,
      })

      ttype === 'burndown_chart'
        ? commit('setBurndownData', res)
        : commit('setStatCards', res)
    } catch (err) {
      console.log(err)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
