<template lang="pug">
div(v-if='actions.length')
  ReportText(
    v-for='action in [...actions].reverse()'
    :key='action.id'
    :action='action'
    :task='card')
span(v-else) {{ $lang.noData }}
</template>

<script>
const ReportText = () =>
  import(
    /* webpackChunkName: "ReportText" */ /* webpackMode: "eager" */ '@/components/ReportText.vue'
  )
export default {
  name: 'CardActions',
  components: {
    ReportText,
  },
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    actions() {
      return this.card.actions
    },
  },
}
</script>

<style lang="scss" scoped></style>
