export const timezones_en = {
  '(GMT-12:00) Eniwetok, Kwajalein': '-12',
  '(GMT-11:00) Midway Island, Samoa': '-11',
  '(GMT-10:00) Hawaii': '-10',
  '(GMT-09:00) Alaska': '-9',
  '(GMT-08:00) Los_Angeles, Tijuana': '-8',
  '(GMT-07:00) Denver, Arizona, Chihuahua, Mazatlan': '-7',
  '(GMT-06:00) Chicago, Mexico City, Regina, Guatemala': '-6',
  '(GMT-05:00) Bogota, New York, Lima': '-5',
  '(GMT-04:00) Halifax, Guyana, La Paz': '-4',
  '(GMT-03:00) Buenos Aires, Godthab, Montevideo': '-3',
  '(GMT-02:00) South Georgia': '-2',
  '(GMT-01:00) Azores, Cape Verde': '-1',
  '(GMT+00:00) Casablanca, Dublin, Lisbon, London': '0',
  '(GMT+01:00) Algiers, Madrid, Paris, Rome, Stockholm': '1',
  '(GMT+02:00) Kaliningrad, Kiev, Athens, Helsinki, Jerusalem': '2',
  '(GMT+03:00) Moscow, Minsk, Baghdad, Istanbul, Tehran': '3',
  '(GMT+04:00) Samara, Baku, Tbilisi, Yerevan': '4',
  '(GMT+05:00) Yekaterinburg, Tashkent, Karachi': '5',
  '(GMT+06:00) Omsk, Almaty, Dhaka, Colombo': '6',
  '(GMT+07:00) Krasnoyarsk, Bangkok, Hanoi, Jakarta': '7',
  '(GMT+08:00) Irkutsk, Beijing, Shanghai, Hong Kong, Singapore': '8',
  '(GMT+09:00) Yakutsk, Seoul, Tokyo': '9',
  '(GMT+10:00) Vladivostok, Brisbane, Guam': '10',
  '(GMT+11:00) Magadan, Melbourne, Sydney': '11',
  '(GMT+12:00) Petropavlovsk-Kamchatskiy, Wellington, Fiji': '12',
}

export const timezones_ru = {
  '(GMT-12:00) Эниветок, Хауленд': '-12',
  '(GMT-11:00) Мидуэй, Самоа': '-11',
  '(GMT-10:00) Гавайи': '-10',
  '(GMT-09:00) Аляска': '-9',
  '(GMT-08:00) Лос-Анжелес, Тихуана': '-8',
  '(GMT-07:00) Денвер, Аризона, Чихуахуа, Масатлан': '-7',
  '(GMT-06:00) Чикаго, Мехико, Реджайна, Гватемала': '-6',
  '(GMT-05:00) Богота, Нью-Йорк, Лима': '-5',
  '(GMT-04:00) Галифакс, Гайана, Ла-Пас': '-4',
  '(GMT-03:00) Буэнос-Айрес, Нуук, Монтевидео': '-3',
  '(GMT-02:00) Южная Георгия': '-2',
  '(GMT-01:00) Азорские острова, Кабо-Верде': '-1',
  '(GMT+00:00) Касабланка, Дублин, Лиссабон, Лондон': '0',
  '(GMT+01:00) Алжир, Мадрид, Париж, Рим, Стокгольм': '1',
  '(GMT+02:00) Калининград, Киев, Афины, Хельсинки, Иерусалим': '2',
  '(GMT+03:00) Москва, Минск, Багдад, Стамбул, Тегеран': '3',
  '(GMT+04:00) Самара, Баку, Тбилиси, Ереван': '4',
  '(GMT+05:00) Екатеринбург, Ташкент, Карачи': '5',
  '(GMT+06:00) Омск, Алматы, Дхака, Коломбо': '6',
  '(GMT+07:00) Красноярск, Бангкок, Ханой, Джакарта': '7',
  '(GMT+08:00) Иркутск, Пекин, Шанхай, Гонконг, Сингапур': '8',
  '(GMT+09:00) Якутск, Сеул, Токио': '9',
  '(GMT+10:00) Владивосток, Брисбен, Гуам': '10',
  '(GMT+11:00) Магадан, Мельбурн, Сидней': '11',
  '(GMT+12:00) Петропавловск-Камчатский, Веллингтон, Фиджи': '12',
}
