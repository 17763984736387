<template lang="pug">
v-card-text.pa-0(v-if='sprints.length')
  v-simple-table.backgroundMain
    thead
      tr
        th.pl-1.text-left
          span.card__subtext.text--text {{ $lang.sprintNumber }}
        th.pl-1.text-left
          span.card__subtext.text--text {{ $lang.workTimeSprint }}
        th.pl-1.text-left
          span.card__subtext.text--text {{ $lang.workTimeCard }}
    tbody
      template(v-for='sprint in sortedSprints')
        CardSprintTable(
          :sprint='sprint'
          :key='sprint.id'
          :lastSprintNumber='lastSprintNumber')
span(v-else) {{ $lang.noData }}
</template>

<script>
const CardSprintTable = () =>
  import(
    /* webpackChunkName: "CardSprintTable" */ /* webpackMode: "eager" */ '@/views/Card/components/CardSprintTable.vue'
  )

export default {
  name: 'CardSprints',
  components: { CardSprintTable },
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    sprints() {
      return this.card.sprintcards
    },
    sortedSprints() {
      return [...this.sprints].sort((a, b) => a.sprint_number - b.sprint_number)
    },
    lastSprintNumber() {
      return this.sortedSprints.at(-1).sprint_number
    },
  },
}
</script>

<style lang="scss" scoped></style>
