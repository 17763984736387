<template lang="pug">
.reports__box
  template(v-for='reportDate in reportDates')
    .reports__wrap
      .reports__label-wrap
        .reports__label {{ reportDate | DDMMYY($lang) }}
      .reports__card-wrap
        .reports__border
        template(v-for='(board, idx) in publicReports[reportDate].boards')
          PublicReportCard.reports__edge(
            :board='board || {}'
            :isLoadedByDays='isLoadedByDays')
    InfiniteLoading(
      @infinite='infiniteHandler'
      spinner='spiral')
      div(slot='no-more')
      div(slot='no-results')
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters: reportsMapGetters } = createNamespacedHelpers('reports')
import { YMD } from '@/helpers/dateFormatter'

const PublicReportCard = () =>
  import(
    /* webpackChunkName: "PublicReportCard" */ /* webpackMode: "eager" */ '@/views/PublicReport/components/PublicReportCard.vue'
  )

export default {
  name: 'PublicReportByDays',
  components: {
    InfiniteLoading,
    PublicReportCard,
  },
  props: {
    reportDates: {
      type: Array,
      default: () => [],
    },
    vSinceDate: {
      type: String,
      default: '',
    },
    completed: {
      type: Boolean,
      default: true,
    },
    isToggleCompleted: {
      type: Boolean,
      default: false,
    },
    setToggleCompleted: {
      type: Function,
    },
    isLoadedByDays: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPreLoad: false,
      sinceDate: null,
    }
  },
  computed: {
    ...reportsMapGetters(['publicReports', 'publicReportsMain']),
  },
  created() {
    this.sinceDate = this.vSinceDate
  },
  methods: {
    infiniteHandler($state) {
      if (this.isToggleCompleted) {
        this.sinceDate = this.vSinceDate
        this.setToggleCompleted(false)
      }

      if (!this.isPreLoad) {
        if (this.sinceDate !== YMD(this.publicReportsMain.meta.since)) {
          this.isPreLoad = true
          const yesterday = YMD(
            new Date(this.sinceDate).setDate(
              new Date(this.sinceDate).getDate() - 1
            )
          )
          this.sinceDate = yesterday

          this.$store
            .dispatch('reports/getPublicReport', {
              hash: this.$route.params.hash,
              since: yesterday,
              only_completed: this.completed,
            })
            .then(() => {
              this.isPreLoad = false
              $state.loaded()
            })
        } else {
          $state.complete()
        }
      }

      $state.complete()
    },
  },
}
</script>

<style lang="scss" scoped>
.reports {
  &__box {
    position: relative;
  }

  &__card-wrap {
    margin-bottom: 60px;
  }

  &__label-wrap {
    position: sticky;
    top: 0px;
    padding-top: 10px;
    z-index: 10;
    background: var(--v-backgroundMain-base);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__label {
    padding: 8px 20px;
    border-radius: 10px 10px 0px 0px;
    font-family: var(--font-raleway);
    font-weight: 700;
    font-size: 20px;
    color: var(--v-colorLabel-base);
    background: var(--v-backgroundLabel-base);
  }

  &__border {
    width: calc(100% - 10px);
    height: 1px;
    background: var(--v-backgroundReport-base);
  }

  &__edge {
    margin-top: -1px;
  }
}
</style>
