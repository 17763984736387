// Меню для обычного пользователя
export const menuUserOptions = [
  { title: 'dashboard', params: 'userId' },
  { title: 'reports' },
  { title: 'boards' },
  { title: 'members' },
  { title: 'sprints', query: 'board' },
  { title: 'faq' },
  { title: 'onboarding' },
  { title: 'options' },
]

// Меню для пользователя с правами наблюдателя и клиента
export const menuObserverAndClientOptions = [
  { title: 'dashboard', params: 'userId' },
  { title: 'reports' },
  { title: 'boards' },
  { title: 'sprints', query: 'board' },
  { title: 'faq' },
  { title: 'onboarding' },
  { title: 'options' },
]

// Меню для пользователя с правами наблюдателя
export const menuObserverOptions = [
  { title: 'dashboard', params: 'userId' },
  { title: 'boards' },
  { title: 'sprints', query: 'board' },
  { title: 'faq' },
  { title: 'onboarding' },
  { title: 'options' },
]

// Меню для пользователя с правами клиента
export const menuClientOptions = [
  { title: 'reports' },
  { title: 'boards' },
  { title: 'sprints', query: 'board' },
  { title: 'faq' },
  { title: 'onboarding' },
  { title: 'options' },
]

// Меню для пользователя, у которого нет ролей
export const menuOptions = [
  { title: 'boards' },
  { title: 'faq' },
  { title: 'onboarding' },
  { title: 'options' },
]
