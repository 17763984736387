<template lang="pug">
div
</template>

<script>
export default {
  name: 'ChartComponent',
}
</script>

<style lang="scss" scoped></style>
