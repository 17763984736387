<template lang="pug">
.wrapper
  v-progress-linear.progress.mb-3(
    v-if='isRel'
    :buffer-value='totalProgress'
    :value='progress'
    :height='heightLinear'
    :color='color'
    :aria-label='`Total progress of the column ${listName} is ${totalProgress}`')
  v-progress-linear.mb-3(
    :value='progress'
    :height='heightLinear'
    :color='color'
    :aria-label='`Progress of the column ${listName} is ${progress}`')
    template(v-slot='{ value }')
      .px-2(
        v-if='isRel'
        ref='progressTitle')
        span.progress__description.progress__description--list-name.mr-2 {{ listName }}
        span.progress__description.mr-2 {{ allCountCard ? `${$lang.completed}: ${countCard} / ${allCountCard} (${progress}%)` : `${progress}%` }}
        span.progress__description {{ allCountCard ? `${$lang.totalProgress}: ${inProgressCards + doneCards} / ${allCountCard} (${workingProgess}%)` : `${workingProgess}%` }}

      .progress__text-wrapper.px-2(
        v-else
        ref='progressTitle')
        span.progress__description.progress__description--progress {{ listName }}:&nbsp;
          | {{ allCountCard ? `${listCardsCount} / ${allCountCard} (${progress}%)` : `${progress}%` }}
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    isRel: { type: Boolean, default: false },
    isShowCount: { type: Boolean, default: () => false },
    list: { type: Object, default: () => {} },
    role: { type: Number, default: () => -1 },
    isDark: { type: Boolean, default: false },
    height: { type: Number, default: () => 20 },
    countCard: { type: Number, default: () => 0 },
    allCountCard: { type: Number, default: () => 0 },
    inProgressCards: { type: Number, default: () => 0 },
    doneCards: { type: Number, default: () => 0 },
  },
  data() {
    return {
      heightLinear: null,
    }
  },
  mounted() {
    this.listener()
    window.addEventListener('resize', this.listener, true)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.listener)
  },
  computed: {
    progress() {
      return this.allCountCard
        ? ((this.countCard / this.allCountCard) * 100).toFixed(1)
        : 0
    },
    listName() {
      return this.list && this.list.name
    },
    listCardsCount() {
      return (this.list && this.list.cards_count) || this.countCard
    },
    color() {
      return `role${this.role}`
    },
    totalProgress() {
      return this.allCountCard
        ? (
            ((this.inProgressCards + this.countCard) / this.allCountCard) *
            100
          ).toFixed(1)
        : 0
    },
    workingProgess() {
      return this.allCountCard
        ? (
            ((this.inProgressCards + this.doneCards) / this.allCountCard) *
            100
          ).toFixed(1)
        : 0
    },
  },
  methods: {
    listener() {
      this.$nextTick(() => {
        this.heightLinear =
          this.$refs.progressTitle &&
          this.$refs.progressTitle.offsetHeight > this.height
            ? this.$refs.progressTitle.offsetHeight
            : this.height
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
}
.progress {
  position: absolute;

  &__description {
    font-size: 12px;
    font-weight: 500;
    color: var(--v-greySprint-base);
  }

  &__text-wrapper {
    text-align: center;
  }
}
</style>
