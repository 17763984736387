import request from '@/services/request'
import objectPath from 'object-path'
import app from '@/main'
import {
  apiOptions,
  apiOptionsOAuth,
  apiAccount,
  endLogout,
} from '@/helpers/urls'

const state = {
  options: null,
  gitEmail: '',
  firstDayOfWeek: 1,
  allServices: [],
  usersServices: [],
}

const getters = {
  options: ({ options }) => options,
  gitEmail: ({ gitEmail }) => gitEmail,
  settings: ({ options }) =>
    options && options.settings ? options.settings : {},
  firstDayOfWeek: ({ firstDayOfWeek }) => firstDayOfWeek,
}

const actions = {
  getOptions: async ({ commit, dispatch }) => {
    try {
      const data = await request({ url: apiOptions, method: 'GET' })
      commit('SET_OPTIONS', data)
      dispatch('setIsDark', objectPath.get(data, 'settings.is_dark', false), {
        root: true,
      })
      commit(
        'SET_AUTOSET_IS_DARK',
        objectPath.get(data, 'settings.autoset_is_dark', true),
        {
          root: true,
        }
      )
      dispatch('setLang', objectPath.get(data, 'settings.lang', 'en'), {
        root: true,
      })
      commit('SET_FIRST_DAY_OF_WEEK', data.settings.firstDayOfWeek)

      if (app) {
        app.$setLang(objectPath.get(data, 'settings.lang', 'en'))
        app.$vuetify.lang.current = objectPath.get(data, 'settings.lang', 'en')
      }
      return data
    } catch (err) {
      // console.log(err)
      return err
    }
  },
  postOptions: async ({ dispatch, commit, getters, rootGetters }, settings) => {
    try {
      const data = {
        git_email: getters.gitEmail,
        settings: {
          ...getters.options.settings,
          ...settings,
          is_dark: rootGetters.isDark,
          autoset_is_dark: rootGetters.isAutosetIsDark,
          lang: rootGetters.lang,
          firstDayOfWeek: getters.firstDayOfWeek,
        },
      }

      const response = await request({ url: apiOptions, method: 'POST', data })
      commit('SET_TO_ZERO_OPTIONS')
      commit('SET_OPTIONS', response)
      dispatch(
        'setIsDark',
        objectPath.get(response, 'settings.is_dark', false),
        { root: true }
      )
      commit(
        'SET_AUTOSET_IS_DARK',
        objectPath.get(response, 'settings.autoset_is_dark', true),
        {
          root: true,
        }
      )
      dispatch('setLang', objectPath.get(response, 'settings.lang', 'en'), {
        root: true,
      })

      if (app) {
        app.$setLang(objectPath.get(response, 'settings.lang', 'en'))
        app.$vuetify.lang.current = objectPath.get(
          response,
          'settings.lang',
          'en'
        )
      }
      return response
    } catch (err) {
      console.log(err)
    }
  },
  checkIsNightWithAutoSet: ({ commit, rootGetters }) => {
    if (rootGetters.isAutosetIsDark && app) {
      const hours = new Date().getHours()
      if (hours >= 19 || hours <= 7) {
        commit('SET_IS_DARK', true, { root: true })
        app.$vuetify.theme.dark = true
      } else {
        commit('SET_IS_DARK', false, { root: true })
        app.$vuetify.theme.dark = false
      }
    }
  },
  getOAuthServices: async ({ commit }) => {
    try {
      const data = await request({ url: apiOptionsOAuth, method: 'GET' })
      commit('SET_ALL_SERVICES', data.all_services)
      commit('SET_USERS_SERVICES', data.users_services)
      return data
    } catch (err) {
      console.log(err)
    }
  },
  unlinkProvider: async ({ commit, dispatch }, service) => {
    const { provider, token_id } = service
    try {
      const data = await request({
        url: `${apiAccount}${provider}${endLogout}`,
        method: 'POST',
        data: { token_id },
      })

      commit(
        'ADD_NOTIFICATION',
        {
          color: 'success',
          text: data.detail,
        },
        { root: true }
      )
      dispatch('getOAuthServices')
    } catch (err) {
      console.log(err)
      commit(
        'ADD_NOTIFICATION',
        {
          color: 'error',
          text: app.$lang.unknownError,
        },
        { root: true }
      )
    }
  },
}

const mutations = {
  SET_OPTIONS: (state, data) => {
    // app.$store.state.profile.isClient = data.is_pure_client
    state.options = data
  },
  SET_TO_ZERO_OPTIONS: (state) => {
    state.options = null
    state.gitEmail = ''
  },
  SET_GIT_EMAIL: (state, data) => {
    state.gitEmail = data
  },
  SET_FIRST_DAY_OF_WEEK: (state, data = 1) => {
    state.firstDayOfWeek = data
  },
  SET_ALL_SERVICES: (state, data) => {
    state.allServices = data
  },
  SET_USERS_SERVICES: (state, data) => {
    state.usersServices = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
