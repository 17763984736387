<template lang="pug">
div
  v-list-item.px-0.flex-wrap.flex-lg-nowrap(
    v-if='action.type !== "commentCard" && action.type !== "updateCard"'
    :key='`action-${action.id}`'
    dense)
    v-list-item-action.title--text.ma-0
      span {{ info.title }} - {{ new Date(action.created_at) | DMY_HMS }} {{ !isPublic ? `(${info.actionAuthor})` : "" }}
    v-list-item-action.title--text.ma-0
      .px-2.px-sm-0(v-if='info.text') {{ info.text }}
      .d-flex.align-center.flex-wrap.pr-2.pl-0.px-sm-0(v-if='info.from')
        span {{ info.from }}
        v-icon.mx-2(color='title') {{ mdiTrendingNeutral }}
        span.mr-2 {{ info.to }}
        span(v-if='!!info.actionAuthor') ({{ info.actionAuthor }})
  v-list-item.px-0.flex-wrap.flex-lg-nowrap(
    v-if='action.type === "commentCard" || action.type === "updateCard"'
    :key='`action-${action.id}`'
    dense)
    v-list-item-action.title--text.my-0
      span {{ info.title }} - {{ new Date(action.created_at) | DMY_HMS }} {{ !isPublic ? `(${info.actionAuthor})` : "" }}
    v-list-item-action.title--text.ma-0(v-if='action.type === "updateCard"')
      .px-2.px-sm-0(v-if='info.text') {{ info.text }}
      .d-flex.align-center.flex-wrap.pr-2.pl-0.px-sm-0(v-if='info.from')
        span {{ info.from }}
        v-icon.mx-2(color='title') {{ mdiTrendingNeutral }}
        span.mr-2 {{ info.to }}
        span(v-if='!!info.actionAuthor && action.type !== "updateCard"') {{ !isPublic ? `(${info.actionAuthor})` : "" }}
</template>

<script>
import { mdiTrendingNeutral } from '@mdi/js'
import actionsMixin from '@/mixins/actionsMixin'

export default {
  name: 'ReportText',
  mixins: [actionsMixin],
  props: {
    task: {
      type: Object,
      default: () => {},
    },
    action: {
      type: Object,
      default: () => {},
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mdiTrendingNeutral,
  }),
  computed: {
    info() {
      return this.action && this.task
        ? this.actionInfo(this.action, this.task)
        : null
    },
  },
}
</script>

<style lang="scss" scoped>
.report {
  &__card-item {
    @include item-title;
    line-height: 1.2;
  }

  &__card-text {
    font-size: 14px;
  }
}
</style>
