<template lang="pug">
tr
  td.pl-1
    span.card__subtitle.text--text # {{ sprint.sprint_number }}
  td.pl-1
    span.card__subtitle.text--text {{ getSprintDateRange() }}
  td.pl-1
    span.card__subtitle.text--text {{ getCardDataRange() }}
</template>

<script>
import { mapGetters } from 'vuex'
import { getDateForCard } from '@/helpers/dateFormatter'

export default {
  name: 'CardSprintTable',
  props: {
    sprint: {
      type: Object,
      default: () => {},
    },
    lastSprintNumber: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    getDateForCard,
  }),
  computed: {
    ...mapGetters(['lang']),
  },
  methods: {
    getSprintDateRange() {
      const start = getDateForCard(this.sprint.starts_at_plan, this.lang)
      const end = this.sprint.ends_at_plan
        ? getDateForCard(this.sprint.ends_at_plan, this.lang)
        : this.$lang.now
      return `${start} - ${end}`
    },
    getCardDataRange() {
      if (this.sprint.is_active) {
        const start = this.sprint.starts_at
          ? getDateForCard(this.sprint.starts_at, this.lang)
          : getDateForCard(this.sprint.starts_at_plan, this.lang)
        const end = this.sprint.ends_at
          ? getDateForCard(this.sprint.ends_at, this.lang)
          : this.sprint.sprint_number !== this.lastSprintNumber
          ? getDateForCard(this.sprint.ends_at_plan, this.lang)
          : this.$lang.now
        return `${start} - ${end}`
      }
      return this.$lang.cardNotActive
    },
  },
}
</script>

<style lang="scss" scoped></style>
