<template lang="pug">
.header__wrapper
  v-col.header__column.header__column--desktop.col-12.py-0.my-12(
    v-if='!isMobile')
    router-link.text-none(
      :to='{ name: "home" }'
      :aria-label='$lang.linkGoToHome')
      IconBase(
        icon-name='logo'
        height='21'
        icon-color='var(--v-logo-base)')
        LogotypeUni
    EmailLink

  v-col.header__column.header__column--mobile.col-12(v-if='isMobile')
    EmailLink.align-self-end
    .header__link-wrapper
      router-link.header__link.text-none(:to='{ name: "home" }')
        IconBase(
          icon-name='logo'
          width='425'
          height='53'
          icon-color='var(--v-logo-base)')
          LogotypeUni
</template>

<script>
import { mapGetters } from 'vuex'

const EmailLink = () =>
  import(
    /* webpackChunkName: "EmailLink" */ /* webpackMode: "eager" */ '@/components/EmailLink.vue'
  )

export default {
  name: 'AuthHeader',
  components: {
    EmailLink,
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
}
</script>

<style lang="scss" scoped>
.header {
  &__wrapper {
    height: 100%;
    display: flex;
  }
  &__column {
    display: flex;

    &--desktop {
      justify-content: space-between;
      align-items: flex-end;
    }

    &--mobile {
      flex-direction: column;
      align-items: center;
    }
  }

  &__link-wrapper {
    flex-grow: 2;
    display: flex;
    align-items: center;
  }
}
</style>
