<template lang="pug">
div
  v-divider.mb-8
  .d-flex.flex-column.mb-8
    .options__subtitle.title--text.d-flex.align-center {{ $lang.workingTimezone }}
      v-tooltip(bottom)
        template(v-slot:activator='{on}')
          v-icon.lightGrey--text.ml-2.align-self-start(
            color='lightGrey'
            dark
            size='14'
            v-on='on') {{ mdiInformation }}
        span {{ $lang.workingTimezoneInfo }}
    TimeZonePicker
  v-divider.mb-8
  .d-flex.flex-column.mb-8
    .options__subtitle.title--text.d-flex.align-center {{ $lang.fullForcedSync }}
      v-tooltip(bottom)
        template(v-slot:activator='{on}')
          v-icon.lightGrey--text.ml-2.align-self-start(
            color='lightGrey'
            dark
            size='14'
            v-on='on') {{ mdiInformation }}
        span {{ $lang.fullForcedSyncInfo }}
    .options__button-sync-wrap.d-flex
      ButtonSync(
        :initTime='initTime'
        buttonStateName='FullSyncTime'
        :actionFunc='syncBoards'
        :buttonText='{syncText: $lang.synchronizeForcibly, noSyncText: $lang.synchronizationInProgress}'
        :boardId='+$route.params.id'
        :disableSync='disableSync')
  v-divider.mb-8
  .d-flex.flex-column.mb-8
    .options__subtitle.title--text.d-flex.align-center {{ $lang.roughSync }}
      v-tooltip(bottom)
        template(v-slot:activator='{on}')
          v-icon.lightGrey--text.ml-2.align-self-start(
            color='lightGrey'
            dark
            size='14'
            v-on='on') {{ mdiInformation }}
        span {{ $lang.roughSyncInfo }}
    .options__button-sync-wrap.d-flex
      ButtonSync(
        :initTime='initTime'
        buttonStateName='RoughSyncTime'
        :actionFunc='syncBoardsRoughly'
        :buttonText='{syncText: $lang.synchronizeDirectly, noSyncText: $lang.synchronizationInProgress}'
        :boardId='+$route.params.id'
        :disableSync='disableSync')
  v-divider.mb-8
  .d-flex.flex-column.mb-8
    .options__subtitle.title--text.d-flex.align-center {{ $lang.commentAboutCommits }}
      v-tooltip(bottom)
        template(v-slot:activator='{on}')
          v-icon.lightGrey--text.ml-2.align-self-start(
            color='lightGrey'
            dark
            size='14'
            v-on='on') {{ mdiInformation }}
        span {{ $lang.commentAboutCommitsInfo }}

    v-switch.mt-2(
      @change='changedTrackingOptions'
      v-model='isCommenting')
      template(v-slot:label)
        span.switch__label.text--text.ml-5 {{ $lang.isCommenting }}

    .commenting(v-if='isCommenting')
      v-select.commenting__commenting(
        :items='trackingOptions'
        item-value='key'
        item-text='text'
        v-model='choosenOption'
        :label='$lang.trackingOption'
        :messages='getMessage()')
      button.setting__button-blue.commenting__button-start(
        @click='changedTrackingOptions'
        :disabled='!isActive') {{ $lang.save }}

  v-divider.mb-8
</template>

<script>
import { mdiInformation } from '@mdi/js'
import { createNamespacedHelpers } from 'vuex'
const { mapActions: boardMapActions, mapState: boardMapState } =
  createNamespacedHelpers('boards')

const { mapState: optionsMapState, mapActions: optionsMapActions } =
  createNamespacedHelpers('options')

const TimeZonePicker = () =>
  import(
    /* webpackChunkName: "TimeZonePicker" */ /* webpackMode: "eager" */ '@/components/TimeZonePicker.vue'
  )
const ButtonSync = () =>
  import(
    /* webpackChunkName: "ButtonSync" */ '@/views/Boards/components/ButtonSync.vue'
  )

export default {
  name: 'CommonSettings',
  components: {
    TimeZonePicker,
    ButtonSync,
  },
  data() {
    return {
      mdiInformation,
      initTime: null,
      disableSync: false,
      trackingValues: ['commits', 'MR/PR'],
      isActive: false,
      isCommenting: null,
      choosenOption: null,
      messages: new Map(),
    }
  },
  computed: {
    ...optionsMapState(['usersServices']),
    ...boardMapState(['gitTracking']),
    trackingOptions() {
      const items = []
      this.trackingValues.forEach((key, idx) => {
        items.push({
          key,
          text: this.$lang.trackingOptionData[idx],
        })
        this.messages.set(key, this.$lang.trackingHint[idx])
      })
      return items
    },
  },
  watch: {
    usersServices() {
      this.disableSync = !this.checkSocialTokenSet('trello')
    },
    choosenOption(n) {
      if (n) {
        this.isActive = this.gitTracking !== n
      }
    },
    isCommenting(n) {
      if (!n) {
        this.choosenOption = null
        this.isActive = false
      }
    },
    gitTracking() {
      this.isActive = false
    },
  },
  created() {
    this.initTime = Date.now()
    this.getOAuthServices()
    this.isCommenting = !!this.gitTracking
    this.choosenOption = this.gitTracking
  },
  methods: {
    ...boardMapActions([
      'syncBoards',
      'syncBoardsRoughly',
      'patchSettingsBoard',
    ]),
    ...optionsMapActions(['getOAuthServices']),
    changedTrackingOptions() {
      const settings = {}

      if (this.choosenOption) {
        if (this.isCommenting) {
          settings['git_tracking'] = this.choosenOption
          this.toggleBoardSettings(settings)
        } else if (!this.isCommenting && this.gitTracking) {
          settings['git_tracking'] = null
          this.toggleBoardSettings(settings)
        }
      }
    },
    checkSocialTokenSet(provider) {
      return this.usersServices.find((service) => service.provider === provider)
        .socialtoken_set.length === 0
        ? false
        : true
    },
    toggleBoardSettings(settings) {
      this.patchSettingsBoard({
        boardId: this.$route.params.id,
        settings,
      })
    },
    getMessage() {
      return this.messages.get(this.choosenOption)
    },
  },
}
</script>

<style lang="scss" scoped>
.options__subtitle {
  font-size: 16px;
}

.options__button-sync-wrap {
  margin-top: 22px;
}

.commenting__button-start {
  align-self: flex-start;
  margin-top: 22px;
}
</style>

<style lang="scss">
.commenting__commenting .v-select__selection.v-select__selection--comma {
  text-transform: none;
}

.commenting .v-messages__message {
  font-size: 14px;
  line-height: 1;
}
</style>
