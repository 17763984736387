import axios from 'axios'
import app from '../main'
import store from '@/store'
import router from '@/router'
import config from '@/../config.json'

const baseURL = config.baseURL + '/api'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
const instance = axios.create({ baseURL })

const waitQueue = []
let isRefreshDispatched = false

const request = ({
  url,
  method,
  data,
  headers = {},
  params,
  isRefresh,
  isWithoutToken,
}) =>
  new Promise((resolve, reject) => {
    // request handler
    const requestFunc = ({ url, method, headers, data, resolve, reject }) => {
      if (!isWithoutToken && !isRefresh) {
        store.getters.accessToken &&
          (headers['Authorization'] = 'Bearer ' + store.getters.accessToken)
      }

      return instance({ url, method, headers, data, params })
        .then((response) => {
          switch (response.status) {
            case 200:
              if (
                response.request.responseURL.includes('accounts/confirm-email')
              ) {
                store.commit('CONFIRM_EMAIL', true)
              }
              break
            default:
              return resolve(response.data)
          }
          resolve(response.data)
        })
        .catch((error) => {
          if (error.response) {
            let status = error.response.status || 404
            const errorResponse = error.response.data
            //const errorRequest = error.response.request
            if (process.env.NODE_ENV !== 'production') {
              console.group('Error from: ' + url)
              console.info('Status: ' + status)
              // console.dir(errorResponse)
              console.groupEnd()
            }

            status = Math.floor(status) / 100 === 5 ? 5 : error.response.status
            errorResponse &&
              store.commit('ADD_NOTIFICATION', {
                color: 'error',
                text: errorResponse,
              })
            switch (status) {
              case 5:
                store.commit('ADD_NOTIFICATION', {
                  color: 'error',
                  text: app.$lang.breakError,
                })
                store.commit('SET_IS_ERROR')
                return reject(error.message)
              case 401:
                store.commit('SET_TOKENS', null)
                router.resolve(location).href !== '/login'
                  ? router.push({ name: 'login' })
                  : null
                return reject(errorResponse)
              case 400:
                if (errorResponse.email) {
                  store.commit('CONFIRM_EMAIL', false)
                }
                // if (errorResponse.old_password) {
                //   store.commit('ADD_NOTIFICATION', {
                //     color: 'error',
                //     text: app.$lang.oldPasswordWrong,
                //   })
                // }
                // if (errorResponse.git_url) {
                //   store.commit('ADD_NOTIFICATION', {
                //     color: 'error',
                //     text: app.$lang.repoIsAlreadyLinked,
                //   })
                // }
                // if (
                //   errorResponse.detail &&
                //   errorResponse.detail.includes('social token')
                // ) {
                //   // не показывать popup alert для страницы "git репозитории" в настройках доски
                //   store.commit('CLOSE_NOTIFICATION')
                // }
                // if (url === '/alerts/') {
                //   // не показывать popup alert для страницы "создать уведомление"
                //   store.commit('CLOSE_NOTIFICATION')
                // }
                break
              default:
                store.commit('ADD_NOTIFICATION', {
                  color: 'error',
                  text: app.$lang.unknownError,
                })
                store.commit('SET_IS_ERROR')
                return reject(errorResponse)
            }
            return reject(errorResponse)
          } else {
            store.commit('ADD_NOTIFICATION', {
              color: 'error',
              text: app.$lang.unknownError,
            })
            store.commit('SET_IS_ERROR')
            return reject(error.message)
          }
        })
    }

    const accessToken = store.state.profile.accessToken
    const isTokenExpired = store.state.profile.accessExpirationAt <= Date.now()
    const isRefreshExpired =
      store.state.profile.refreshExpirationAt <= Date.now()

    // If should refresh token
    if (accessToken && isTokenExpired && !isRefreshExpired && !isWithoutToken) {
      // push original request to waiting stack
      !isRefresh &&
        waitQueue.push({
          url,
          method,
          data,
          headers,
          isRefresh,
          resolve,
          reject,
        })

      // dispatch refresh token (first-time only!)
      if (!isRefresh && !isRefreshDispatched) {
        store
          .dispatch('refreshTokens')
          .then(() => {
            isRefreshDispatched = true
            // and after refresh - execute requests from waiting stack
            waitQueue.forEach((config) => requestFunc(config))
          })
          .catch(() => {
            // by default in error - logout user
            store.commit('SET_TOKENS', null)
            router.push({ name: 'login' })
            reject({ detail: 'Refresh token error' })
          })
      } else if (isRefresh) {
        // if has been called refresh method
        requestFunc({ url, method, data, headers, resolve, reject })
      }
    }
    // if refresh is expired - just logout
    else if (isRefreshExpired && !isWithoutToken) {
      store.commit('SET_TOKENS', null)
      router.push({ name: 'login' })
      reject({ detail: 'Refresh token is expired' })
    }
    // by default
    else {
      requestFunc({ url, method, data, headers, resolve, reject })
    }
  })

export default request
