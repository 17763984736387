<template lang="pug">
BoardColumnTable(
  v-if='card.locations.length'
  :card='card')
span(v-else) {{ $lang.noData }}
</template>

<script>
const BoardColumnTable = () =>
  import(
    /* webpackChunkName: "BoardColumnTable" */ /* webpackMode: "eager" */ '@/views/Boards/BoardColumnTable.vue'
  )

export default {
  name: 'CardChronology',
  components: {
    BoardColumnTable,
  },
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="scss" scoped></style>
