<template lang="pug">
.label(:style='labelStyles')
  span.label__name {{ label.name }}
</template>

<script>
export default {
  name: 'CardLabel',
  props: {
    label: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    bcgColor() {
      return this.label.color
        ? `var(--v-label-${this.label.color}-base)`
        : `var(--v-backgroundReport-base)`
    },
    labelStyles() {
      return {
        '--bcg-color': this.bcgColor,
        '--text-color': this.label.color ? '#fff' : 'var(--v-title-color)',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.label {
  padding: 0 12px;
  border-radius: 3px;
  background: var(--bcg-color, var(--v-backgroundReport-base));
  min-height: 28px;
  min-width: 54px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__name {
    font-family: var(--font-raleway);
    color: var(--text-color, #fff);
    font-size: 14px;
    font-weight: 700;
    white-space: nowrap;
  }
}
</style>
