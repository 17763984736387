export const cardsData = [
  {
    text: 'termsCardText',
    src: 'img/terms/terms_0.svg',
  },
  {
    text: 'termsCardText',
    src: 'img/terms/terms_1.svg',
  },
  {
    text: 'termsCardText',
    src: 'img/terms/terms_2.svg',
  },
]
