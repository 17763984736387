<template lang="pug">
a.link(
  :href='href'
  :target='target'
  :aria-label='text'
  rel='noopener'
  :style='componentStyle')
  v-tooltip.link__tooltip(bottom)
    template(v-slot:activator='{on, attrs}')
      v-icon.link__icon.link__icon--desktop(v-on='on') {{ icon }}
    span {{ text }}
  .link__content
    span.link__content-text {{ text }}
    v-icon.link__icon.link__icon--mobile {{ icon }}
</template>

<script>
export default {
  name: 'ExternalLink',
  props: {
    href: {
      type: String,
    },
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
    },
    iconColor: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_blank',
    },
  },
  computed: {
    componentStyle() {
      return {
        '--link-color': this.iconColor.length ? this.iconColor : null,
        '--desktop-text-visibility': '',
        '--mobile-text-visibility': '',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.link {
  /* тач-устройства c hover */
  --desktop-text-visibility: none;
  --mobile-text-visibility: inline-block;

  text-decoration: none;

  /* тач-устройства смартфоны, планшеты */
  @media (hover: none) and (pointer: coarse) {
    --desktop-text-visibility: none;
    --mobile-text-visibility: inline;
  }

  /* устройствас мышью и тач-падом */
  @media (hover: hover) and (pointer: fine) {
    --desktop-text-visibility: inline;
    --mobile-text-visibility: none;

    margin-right: 8px;
  }

  &__tooltip {
    display: var(--desktop-text-visibility);
  }

  &__content {
    display: var(--mobile-text-visibility);
    color: var(--link-color, 'currentColor');
  }

  &__content-text {
    word-break: normal;
    font-family: var(--font-roboto);
    font-size: 16px;
    font-weight: normal;
  }

  &__icon {
    color: var(--link-color, 'currentColor');

    &--desktop {
      display: var(--desktop-text-visibility);
    }

    &--mobile {
      margin-left: 8px;
      margin-bottom: 3px;
    }
  }
}
</style>
