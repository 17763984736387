import { render, staticRenderFns } from "./CreateNotificationSettings.vue?vue&type=template&id=5f718ba3&scoped=true&lang=pug&"
import script from "./CreateNotificationSettings.vue?vue&type=script&lang=js&"
export * from "./CreateNotificationSettings.vue?vue&type=script&lang=js&"
import style0 from "./CreateNotificationSettings.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./CreateNotificationSettings.vue?vue&type=style&index=1&id=5f718ba3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f718ba3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAlert,VCheckbox,VChip,VDivider,VForm,VIcon,VListItem,VListItemAction,VListItemContent,VListItemTitle,VSelect,VSlider,VSwitch,VTextField,VTextarea})
