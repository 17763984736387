<template lang="pug">
h2.setting__title.lightGrey--text.pb-9 {{ $lang[title] }}
  v-tooltip(
    bottom
    v-if='$lang[`${title}_text`]')
    template(v-slot:activator='{ on }')
      v-icon.setting__icon.lightGrey--text.ml-2.align-self-start(
        color='lightGrey'
        dark
        size='20'
        v-on='on') {{ mdiInformation }}
    span.setting__text {{ $lang[`${title}_text`] }}
</template>

<script>
import { mdiInformation } from '@mdi/js'

export default {
  name: 'SettingTitle',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    mdiInformation,
  }),
}
</script>

<style lang="scss" scoped>
.setting {
  &__title {
    font-size: 28px;
    font-weight: 700;
  }

  &__icon {
    margin-bottom: 1rem;
  }

  &__text {
    max-width: 100%;
  }
}
</style>
