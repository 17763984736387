<template lang="pug">
BarChart(
  v-if='Object.keys(this.memberActivity).length'
  :activity='memberActivity'
  :member='member'
  :chartOptions='createChartOptions')
.chart__stub(v-else)
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
const {
  mapState: membersMapState,
  mapMutations: memberMapMutations,
  mapActions: membersMapActions,
} = createNamespacedHelpers('members')
import { light, dark } from '@/helpers/themes'
import BarChart from '@/views/Members/components/BarChart.vue'

export default {
  name: 'MemberActivity',
  components: { BarChart },
  props: {
    member: {
      type: Object,
      default: () => {},
    },
    visualMapType: {
      type: String,
      default: 'piecewise',
    },
    filterType: {
      type: String,
      default: 'actions',
    },
  },
  data: () => ({
    activeBoards: new Set(),
    roughData: [],
    seriesData: [],
    chartSeries: [],
  }),
  computed: {
    ...mapGetters(['isDark', 'isMobile', 'isTablet', 'lang']),
    ...membersMapState(['projectActivity']),
    memberActivity() {
      const arr = this.projectActivity
        .filter((item) => +item.id === this.member.id)
        .map((el) => el.activity)

      if (arr.length && Object.keys(arr[0]).length > 31) {
        return Object.entries(arr[0])
          .sort((a, b) => b[0].localeCompare(a[0]))
          .slice(0, 30)
          .reverse()
      }
      if (arr.length && Object.keys(arr[0])?.length <= 31) {
        return Object.entries(arr[0])
          .sort((a, b) => b[0].localeCompare(a[0]))
          .reverse()
      }
      return []
    },
    rangeDate() {
      return Object.keys(Object.fromEntries(this.memberActivity))
    },
    createChartOptions() {
      return {
        backgroundColor: this.isDark
          ? dark.backgroundMain
          : light.backgroundMain,
        legend: {
          show: this.isMobile ? false : this.isTablet ? false : true,
          textStyle: {
            color: this.isDark ? dark.title : light.title,
            fontSize: '16px',
          },
          left: '10',
          width: '80%',
        },
        rangeDate: this.rangeDate,
        axisLabel: {
          color: this.isDark ? dark.title : light.title,
        },
        color: this.isDark
          ? [
              dark.role0,
              dark.role1,
              dark.role2,
              dark.role3,
              dark.role4,
              dark.role5,
            ]
          : [
              light.role0,
              light.role1,
              light.role2,
              light.role3,
              light.role4,
              light.role5,
            ],
        series: this.chartSeries,
      }
    },
  },
  watch: {
    projectActivity(n, o) {
      if (n !== o) {
        this.seriesData = []
        this.chartSeries = []
        this.getChartData()
      }
    },
    filterType(n, o) {
      if (n !== o) {
        this.setToZeroProjectActivity()
      }
    },
    isDark(n, o) {
      if (n !== o) {
        this.setToZeroProjectActivity()
      }
    },
  },
  created() {
    this.getChartData()
  },
  methods: {
    ...memberMapMutations(['setToZeroProjectActivity']),
    ...membersMapActions(['getActivity']),
    getRoughData() {
      this.roughData = this.memberActivity.map((item) => {
        let a = []
        a[0] = item[0]
        a[1] = item[1].detail_on_boards
        a[1].map((a) => this.activeBoards.add(a.board_name))
        return a
      })
    },
    getSeriesData() {
      this.activeBoards.forEach((board) => {
        let obj = {}
        obj['board'] = board
        let arr = []
        this.roughData.map((item) => {
          if (item[1].length) {
            const i = item[1].filter((el) => el.board_name === board)
            if (i.length) {
              arr.push(i[0].action_objects)
            } else {
              arr.push(0)
            }
          } else {
            arr.push(0)
          }
        })
        obj['data'] = arr
        this.seriesData.push(obj)
      })
    },
    getChartSeries() {
      this.seriesData.forEach((item) => {
        const el = {
          name: item.board,
          type: 'bar',
          stack: 'total',
          label: {
            show: false,
            // color: this.isDark ? dark.colorLabel : light.colorLabel,
          },
          emphasis: {
            focus: 'series',
          },
          data: item.data,
        }
        this.chartSeries.push(el)
      })
    },
    getChartData() {
      this.getRoughData()
      this.getSeriesData()
      this.getChartSeries()
    },
  },
}
</script>

<style lang="scss" scoped>
.chart__stub {
  height: var(--bar-chart-height);
}
</style>
