<template lang="pug">
v-list.git.backgroundMain(dense)
  v-form.mb-8(@submit.prevent='submitGitUrls')
    v-autocomplete.git__select.mb-8(
      v-model='repos'
      :items='accessibleRepoListWithDisabledItems'
      :item-value='(item) => item'
      :item-text='(item) => `${item.repo_url}`'
      multiple
      hide-details
      clearable
      loading='main'
      :disabled='!bindedRepoStatus')
    v-checkbox(
      v-model='sync'
      :label='$lang.syncImmediately'
      :disabled='!bindedRepoStatus')
    button.setting__button-blue.mr-4(
      type='submit'
      :disabled='!repos.length') {{ $lang.addGit }}
  v-list-item(v-if='!boardGits.length')
    span(:class='[bindedRepoStatus ? "title--text" : "main--text"]') {{ bindedRepoStatus ? $lang.noGits : $lang.bindGitInfo }}
  v-expansion-panels.mb-10(
    flat
    v-model='openedPanel')
    v-expansion-panel.git__panel(
      v-for='(git, index) in boardGits'
      :key='git.id')
      v-expansion-panel-header.pa-0(
        :class='[index % 2 === 0 ? "setting__dark" : "setting__light"]'
        hide-actions)
        v-list-item.git__panel-header
          v-list-item-title.git__panel-header-title(@click.stop)
            div {{ git.git_url }}
          v-list-item-action.flex-row
            v-btn(
              color='main'
              icon
              @click='setGitRepoBranches(git.id, index)'
              @click.native.stop)
              v-icon {{ mdiPencil }}
            v-btn(
              :color='git.sync_available ? "main" : "text"'
              icon
              @click.native.stop='syncRepoToken(git)')
              v-icon {{ mdiAutorenew }}
            v-btn(
              color='error'
              icon
              @click.native.stop='deleteGitUrl(git.git_url)')
              v-icon {{ mdiDeleteForever }}
      v-expansion-panel-content.git__panel-content(color='backgroundMain')
        GitForm(
          :git='git'
          :addGitRegexp='addGitRegexp'
          :isCleanOut='isCleanOut'
          :branches='listOfReposBranches')
</template>

<script>
import {
  mdiPencil,
  mdiDeleteForever,
  mdiPlus,
  mdiClose,
  mdiAutorenew,
} from '@mdi/js'
import { mapGetters, mapMutations, createNamespacedHelpers } from 'vuex'
const {
  mapActions: gitMapActions,
  mapState: gitMapState,
  mapMutations: gitMapMutations,
} = createNamespacedHelpers('git')
const { mapActions: boardGitsMapActions } = createNamespacedHelpers('boards')

const GitForm = () => import('@/views/Boards/components/GitForm.vue')

export default {
  name: 'GitRepoSettings',
  components: {
    GitForm,
  },
  props: {
    boardGits: { type: Array, default: () => [] },
  },
  data() {
    return {
      isEditing: false,
      mdiPencil,
      mdiDeleteForever,
      mdiPlus,
      mdiClose,
      mdiAutorenew,
      isCleanOut: false,
      repos: [],
      sync: false,
      openedPanel: undefined,
      index: null,
    }
  },
  watch: {
    listOfReposBranches() {
      this.openedPanel = this.index
    },
  },
  computed: {
    ...mapGetters(['isDark']),
    ...gitMapState([
      'accessibleRepoList',
      'bindedRepoStatus',
      'listOfReposBranches',
    ]),
    accessibleRepoListWithDisabledItems() {
      return this.accessibleRepoList.map((repo) => {
        if (
          this.boardGits.find(
            (item) => item.git_project_id === repo.git_project_id.toString()
          ) ||
          repo.already_linked
        ) {
          repo['disabled'] = true
          return repo
        } else {
          repo['disabled'] = false
          return repo
        }
      })
    },
  },
  created() {
    this.getRepoList()
  },
  destroyed() {
    this.setEmptyRepoList()
  },
  methods: {
    ...mapMutations(['ADD_NOTIFICATION']),
    ...gitMapMutations(['setEmptyRepoList']),
    ...gitMapActions([
      'getRepoList',
      'bindRepositories',
      'syncRepoToken',
      'getListOfBranches',
    ]),
    ...boardGitsMapActions(['getBoardGits', 'patchBoardGit', 'deleteBoardGit']),
    addGitRegexp(data) {
      this.patchBoardGit(data).then(() => {
        this.openedPanel = undefined
      })
    },
    deleteGitUrl(gitURL) {
      this.deleteBoardGit({
        gitURL,
        boardId: this.$route.params.id,
      })
    },
    submitGitUrls() {
      if (this.repos.length) {
        this.isRepoButtonActive = true
        const repoArr = []
        this.repos.map((repo) => {
          let item = {
            git_project_id: repo.git_project_id,
            board_id: this.$route.params.id,
            git_service: repo.git_service,
            git_url: repo.repo_url,
            extra_data: repo.extra_data || {},
          }
          repoArr.push(item)
        })

        const body = { repositories: repoArr, start_immediately: this.sync }
        this.bindRepositories(body).then((res) => {
          if (res === 'ok') {
            this.repos = []
            this.sync = false
          }
          if (res?.error.git_url) {
            this.ADD_NOTIFICATION({
              color: 'error',
              text: this.$lang.repoIsAlreadyLinked,
            })
          }
        })
      }
    },
    setGitRepoBranches(id, index) {
      this.getListOfBranches({ gitRepoId: id })
      this.index = index
    },
  },
}
</script>

<style lang="scss" scoped>
.setting__dark {
  background: var(--v-backgroundTable-base);
}

.setting__light {
  background: var(--v-backgroundMain-base);
}
</style>

<style lang="scss">
.git__input .v-messages__message {
  color: var(--v-main-base);
}

.git__panel-content > .v-expansion-panel-content__wrap {
  border-bottom: 1px solid var(--v-backgroundTable-base);
}

.git__panel-header {
  flex-wrap: wrap;
  min-width: 0;
}

.git__panel-header-title {
  flex-grow: 2;
  padding: 12px 20px 8px;
  font-size: 13px;
  font-weight: 500;
  white-space: normal;
}

.git__search {
  width: min(100%, 400px);
  margin-left: auto;
}

.git__select.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(0deg) !important;
}

.v-list-item.v-list-item--disabled .v-icon__svg {
  fill: transparent !important;
}
</style>
