<template lang="pug">
v-row.boards(no-gutters)
  v-progress-linear(
    indeterminate
    v-show='!isLoadedBoards')
  //- EmptyEntities(v-if='isLoadedBoards && !boards.length')
  template(v-if='isLoadedBoards')
    BoardsConnectionDialog
    template(v-if='boards.length')
      v-tabs.tabs(
        v-model='tab'
        hide-slider
        background-color='backgroundMain'
        active-class='tabs__active')
        v-tab.tabs__tab {{ $lang.recent }}
        v-tab.tabs__tab {{ $lang.old }}
        v-tab.tabs__tab {{ $lang.all }}
      v-tabs-items.tabs-items(v-model='tab')
        v-tab-item(
          v-for='(value, name) in sorting'
          :key='name')
          v-col.boards__column-wrapper.col-12.pa-0
            v-list.pa-0.backgroundMain
              v-list-item.boards__column-card.base.mb-10(
                v-for='board in value'
                :key='`board-${board.id}`'
                :to='{name: "board", params: {id: board.id}}')
                v-list-item-content.py-0
                  ToolBarPanel.toolbar.ml-auto(
                    :board='board'
                    :boardProgress='boardProgress(board.id)')
    template(v-else)
      EmptyEntities(:message='$lang.connectYourBoard')
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
const {
  mapState: boardsMapState,
  mapMutations: boardsMapMutations,
  mapActions: boardsMapActions,
} = createNamespacedHelpers('boards')
// const { mapActions: sprintsMapActions } = createNamespacedHelpers('sprints')

const ToolBarPanel = () =>
  import(
    /* webpackChunkName: "ToolBarPanel" */ /* webpackMode: "eager" */ '@/views/Boards/ToolBarPanel.vue'
  )
const EmptyEntities = () =>
  import(
    /* webpackChunkName: "EmptyEntities" */ '@/components/EmptyEntities.vue'
  )
const BoardsConnectionDialog = () =>
  import(
    /* webpackChunkName: "BoardsConnectionDialog" */ '@/views/Boards/components/BoardsConnectionDialog.vue'
  )

export default {
  name: 'BoardsComponent',
  components: {
    EmptyEntities,
    ToolBarPanel,
    BoardsConnectionDialog,
  },
  data() {
    return {
      isLoadedBoards: false,
      timerInterval: undefined,
      tab: null,
    }
  },
  created() {
    this.initBoardData()
  },
  destroyed() {
    this.SET_TO_ZERO_BOARD()
    this.SET_TO_ZERO_BOARDS()
    clearInterval(this.timerInterval)
  },
  computed: {
    ...mapGetters(['isMobile', 'isDark', 'userId', 'isError']),
    ...boardsMapState(['boards', 'boardsProgress']),
    isAdminAtBoards() {
      const adminAtBoards = this.boards
        .filter((board) => board.admins && board.admins.length)
        .filter((a) => a.admins.find((a) => a.id === this.userId))
      return !!adminAtBoards.length
    },
    sortingAllBoards() {
      let arrAdminUpdatable = []
      let arrAdminNoUpdatable = []
      let arrNoAdmins = []
      this.boards.map((board) => {
        if (board?.admins?.length) {
          const admin = board.admins.find((a) => a.id === this.userId)
          if (admin) {
            board.updatable
              ? arrAdminUpdatable.push(board)
              : arrAdminNoUpdatable.push(board)
          } else {
            arrNoAdmins.push(board)
          }
        } else {
          arrNoAdmins.push(board)
        }
      })
      return [...arrAdminUpdatable, ...arrAdminNoUpdatable, ...arrNoAdmins]
    },
    sortingRecentBoards() {
      return this.boards
        .slice()
        .sort((a, b) => b.last_activity - a.last_activity)
    },
    sortingOldBoards() {
      return this.boards.slice().sort((a, b) => a.created_at - b.created_at)
    },
    sorting() {
      return {
        recent: this.sortingRecentBoards,
        old: this.sortingOldBoards,
        all: this.sortingAllBoards,
      }
    },
  },
  methods: {
    ...boardsMapMutations(['SET_TO_ZERO_BOARD', 'SET_TO_ZERO_BOARDS']),
    ...boardsMapActions([
      'getBoards',
      'getBoardsProgress',
      'connectAvailableTrelloBoards',
    ]),
    // ...sprintsMapActions(['getLastSprints']),
    initBoardData() {
      this.getBoards()
        .then(() => {
          if (this.isAdminAtBoards) {
            this.timerInterval = setInterval(this.requestProgress, 15000)
          }

          // this.getLastSprints()
        })
        .catch(() => clearInterval(this.timerInterval))
        .finally(() => (this.isLoadedBoards = true))
    },
    requestProgress() {
      !this.isError
        ? this.getBoardsProgress()
        : clearInterval(this.timerInterval)
    },
    boardProgress(boardId) {
      const board =
        this.boardsProgress.length &&
        this.boardsProgress.find(
          (board) => board.id === boardId && board.progress
        )
      return board && board.progress
    },
  },
}
</script>

<style lang="scss" scoped>
.boards {
  position: relative;

  &__column-card {
    border-radius: 10px;
    padding: 30px;

    &::before {
      border-radius: 10px;
    }
  }
}

.toolbar {
  min-height: 64px;
}

.tabs-items {
  padding-top: 82px;
}
</style>
