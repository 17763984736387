<template lang="pug">
v-list.backgroundMain(dense)
  v-list-item(
    v-for='reportReceiver in boardReportReceivers'
    :key='reportReceiver')
    v-list-item-content
      span.setting__username.grey--text {{ getUsername(reportReceiver) }}
    v-list-item-action
      v-btn(
        color='error'
        icon
        @click='deleteReportReceiver(reportReceiver)'
        aria-label='delete button')
        v-icon {{ mdiDeleteForever }}
  v-form(@submit.prevent='addReportReceiver')
    v-row(
      align='center'
      no-gutters)
      v-autocomplete(
        v-model='reportReceiver'
        :items='filteredBoardUsers'
        :placeholder='$lang.chooseReceiver'
        item-value='id'
        item-text='username')
      v-btn(
        type='submit'
        icon
        color='success'
        :disabled='!reportReceiver')
        v-icon {{ mdiPlus }}
</template>

<script>
import { mdiDeleteForever, mdiPlus } from '@mdi/js'
import objectPath from 'object-path'

export default {
  name: 'ReportReceiversSettings',
  props: {
    boardReportReceivers: { type: Array, default: () => [] },
    boardUsers: { type: Array, default: () => [] },
  },
  data() {
    return {
      reportReceiver: null,
      mdiDeleteForever,
      mdiPlus,
    }
  },
  computed: {
    filteredBoardUsers() {
      return [
        ...this.boardUsers.filter(
          (u) => !this.boardReportReceivers.includes(u.id)
        ),
      ]
    },
  },
  methods: {
    addReportReceiver() {
      this.$store
        .dispatch('boards/patchSettingsBoard', {
          boardId: this.$route.params.id,
          reports_receivers: [
            ...this.boardReportReceivers,
            this.reportReceiver,
          ],
        })
        .then(() => (this.reportReceiver = null))
    },
    deleteReportReceiver(userId) {
      const reports_receivers = this.boardReportReceivers.filter(
        (el) => el !== userId
      )
      this.$store.dispatch('boards/patchSettingsBoard', {
        boardId: this.$route.params.id,
        reports_receivers,
      })
    },
    getUsername(id) {
      return objectPath.get(
        this.boardUsers.find((u) => u.id === id),
        'username',
        ''
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.setting {
  &__username {
    @include subtitle-common;

    @media (max-width: 960px) {
      font-size: 18px;
    }
  }
}
</style>
