<template lang="pug">
v-row.flex-column(no-gutters)
  v-progress-linear(
    indeterminate
    v-show='!isLoadedBoards')
  EmptyEntities(v-if='isLoadedBoards && !lastSprints.length')
  template(v-if='isLoadedBoards')
    v-row(no-gutters)
      v-col.sprints__controls-wrapper.col-12
        v-card.sprints__controls.justify-lg-end.backgroundMain(
          v-if='isLoadedBoards && lastSprints.length'
          elevation='0')
          v-card-title.px-0.justify-end
            .sprints__switch-wrapper.d-flex
              v-switch.mt-0(
                v-model='vIsShowOnlyActiveSprint'
                hide-details
                @click.stop='checkIsShowActiveSprint(vIsShowOnlyActiveSprint)'
                color='switch')
                template(v-slot:label)
                  span.sprints__switch-text.title--text.mr-4 {{ $lang.showActiveSprints }}
            .btn-container.ml-13(v-if='!isMobile && !isTablet')
              v-btn.btn-item(
                icon
                @click='viewModuleLayout(true)'
                :aria-label='$lang.toggleTiledView')
                v-icon(:class='viewModuleSprints ? "view-active" : ""') {{ "$tileIcon" }}
              v-btn.btn-item(
                icon
                @click='viewModuleLayout(false)'
                :aria-label='$lang.toggleLineView')
                v-icon(:class='!viewModuleSprints ? "view-active" : ""') {{ "$stringIcon" }}
    v-row.sprints__content.pt-3(no-gutters)
      v-col.col-12
        .d-flex(
          :class='viewModuleSprints ? "flex-row flex-wrap" : "flex-column"')
          v-card.backgroundMain(
            v-for='(board, index) in filterSprint'
            :key='`board-${board.id}`'
            flat
            outlined
            :class='["mb-3", viewModuleSprints ? "module" : ""]')
            v-card-text(
              :class='[!viewModuleSprints ? "px-0" : "px-0 px-sm-4"]')
              .d-flex.flex-column.flex-wrap.align-start.mb-3(
                :class='[viewModuleSprints ? "flex-column" : "flex-sm-row align-sm-center"]')
                .d-flex.flex-column.mb-3
                  h2.sprints__board-title.grey--text {{ board.name }}
                  InternalLink.sprints__link(
                    v-if='board.sprints.length'
                    name='sprintsAtBoard'
                    :params='{boardId: board.id}'
                    :text='$lang.watchAllSprints'
                    color='var(--v-main-base)')
                  InternalLink(
                    name='members'
                    :query='{"init-project": board.id}'
                    :text='$lang.toProjectMembers'
                    :icon='mdiOpenInNew')
                AvatarGroup.ml-sm-auto.justify-start.justify-sm-end(
                  v-if='!viewModuleSprints && board.members'
                  :users='board.members'
                  :size='32'
                  :margin='isMobile ? "0 6px 6px 0" : "0 0 6px 6px"')
              SprintsItem(
                v-if='board.sprints.length'
                :sprints='board.sprints'
                :boardId='board.id'
                :lists='board.board_lists')
              AvatarGroup.ml-sm-auto(
                v-if='viewModuleSprints && board.members'
                :users='board.members'
                :size='32'
                :margin='"0 6px 6px 0"')
</template>

<script>
import { mdiOpenInNew } from '@mdi/js'
import { createNamespacedHelpers, mapGetters } from 'vuex'
const {
  mapState: sprintsMapState,
  mapMutations: sprintsMapMutations,
  mapActions: sprintsMapActions,
} = createNamespacedHelpers('sprints')
const { mapGetters: optionsMapGetters, mapActions: optionsMapActions } =
  createNamespacedHelpers('options')
const { mapMutations: boardssMapMutations, mapActions: boardssMapActions } =
  createNamespacedHelpers('boards')

const EmptyEntities = () =>
  import(
    /* webpackChunkName: "EmptyEntities" */ '@/components/EmptyEntities.vue'
  )
const AvatarGroup = () =>
  import(
    /* webpackChunkName: "AvatarGroup" */ /* webpackMode: "eager" */ '@/components/AvatarGroup.vue'
  )
const SprintsItem = () =>
  import(
    /* webpackChunkName: "SprintsItem" */ /* webpackMode: "eager" */ '@/views/Sprints/SprintsItem.vue'
  )
const InternalLink = () =>
  import(
    /* webpackChunkName: "InternalLink" */ /* webpackMode: "eager" */ '@/components/InternalLink.vue'
  )

export default {
  name: 'SprintsComponent',
  components: {
    EmptyEntities,
    SprintsItem,
    AvatarGroup,
    InternalLink,
  },
  data: () => ({
    isLoadedBoards: false,
    isLoadedSprints: true,
    currentBoard: null,
    activeBoard: null,
    currentPanel: undefined,
    viewModuleSprints: false,
    vIsShowOnlyActiveSprint: true,
    mdiOpenInNew,
  }),
  created() {
    this.getLastSprints().finally(() => {
      this.vIsShowOnlyActiveSprint = this.settings.showOnlyActiveSprint
      if (this.isMobile || this.isTablet) {
        this.viewModuleSprints = false
      } else {
        this.viewModuleSprints =
          this.settings && this.settings.viewModuleSprints
      }
      this.isLoadedBoards = true
    })
  },
  destroyed() {
    this.SET_TO_ZERO_BOARD_SPRINTS()
    this.SET_TO_ZERO_BOARDS()
  },

  watch: {
    isMobile(value) {
      if (value) {
        this.viewModuleSprints = false
      }
    },
    isTablet(value) {
      if (value) {
        this.viewModuleSprints = false
      } else {
        this.viewModuleSprints =
          !this.isMobile && this.settings.viewModuleSprints
      }
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'isTablet']),
    ...sprintsMapState(['sprints', 'sprintDetails', 'lastSprints']),
    ...optionsMapGetters(['settings']),
    filterSprint() {
      if (this.vIsShowOnlyActiveSprint) {
        return this.lastSprints.filter(
          (board) => board.sprints.length && board.sprints[0].active
        )
      }
      return this.lastSprints
    },
  },
  methods: {
    ...boardssMapMutations(['SET_TO_ZERO_BOARDS']),
    ...boardssMapActions(['updateBoardActions']),
    ...sprintsMapMutations(['SET_TO_ZERO_BOARD_SPRINTS']),
    ...sprintsMapActions(['getLastSprints']),
    ...optionsMapActions(['postOptions']),
    closeBoard() {
      this.currentBoard = null
      this.SET_TO_ZERO_BOARD_SPRINTS()
    },
    updateAll() {
      this.updateBoardActions()
    },
    viewModuleLayout(module = this.viewModuleSprints) {
      this.viewModuleSprints = module
      this.postOptions({
        viewModuleSprints: this.viewModuleSprints,
      })
    },
    checkIsShowActiveSprint(value = !this.vIsShowOnlyActiveSprint) {
      this.vIsShowOnlyActiveSprint = value
      this.postOptions({
        showOnlyActiveSprint: this.vIsShowOnlyActiveSprint,
      })
    },
    getSprintPercent(sprint) {
      return (sprint.done / sprint.total) * 100
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-link {
  transition: all ease-in-out 0.5s;
}

.btn-container {
  & .btn-item {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 1200px) {
    display: none;
  }
}

.module {
  flex: 0 1 49%;

  &:nth-child(2n) {
    margin-left: 12px;
  }
}

@media (max-width: 768px) {
  .avatar-group {
    margin-top: 4px;
    transition: margin-top ease-in-out 0.5s;
  }
}

.sprints {
  position: relative;
  overflow: hidden;

  &__controls {
    position: fixed;
    z-index: 6;
    top: 38px;
    right: 105px;
    display: flex;

    @media (max-width: 1200px) {
      top: 100px;
      left: 365px;
      right: 105px;
    }

    @media (max-width: 960px) {
      top: 100px;
      left: 329px;
      right: 69px;
    }

    @media (max-width: 600px) {
      top: 64px;
      left: 16px;
      right: 16px;
    }
  }

  &__content {
    @media (max-width: 1200px) {
      margin-top: 46px;
    }
  }

  &__switch-text {
    font-size: 12px;
    font-weight: 500;
  }

  &__board-title {
    @include subtitle-common;
  }

  &__link {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.033em;
  }
}
</style>

<style lang="scss">
.view-active svg > path {
  fill: var(--v-icon-base);
}

.sprints__switch-wrapper .v-input__slot {
  flex-direction: row-reverse;
}
</style>
