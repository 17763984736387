<template lang="pug">
v-row
  v-col.col-12.col-md-6.pl-0.d-flex.align-center.justify-space-between
    .d-flex.flex-column
      .btn-wrapper.d-flex
        .board__chip.base--text(:class='[isActive ? "primary" : "inactive"]') {{ isActive ? $lang.active : $lang.noActive }}
        v-btn.board__gear(
          :to='{name: "boardSettings", params: {id: board.id}}'
          :aria-label='$lang.linkToBoardSettings'
          icon
          v-if='isAdmin'
          @click.native.prevent
          height='inherit'
          max-width='15')
          v-icon.board__gear-icon {{'$gearBoardIcon'}}
        span.board__user-status(
          :class='[isActive ? "board__user-status--active" : "board__user-status--inactive"]') {{ getUserStatus() }}
      .board__name(:class='[isActive ? "title--text" : "inactive--text"]') {{ board.name }}
      span.board__period.title--text(v-if='period || !board.closed') {{ period }}
      span.board__period.inactive--text(v-if='board.closed') {{ $lang.boardArchived }}

      .d-flex.flex-wrap.align-center
        span.board__text.inactive--text.pr-3(v-if='isAdmin') {{ progress }}%&nbsp;{{ $lang.loadedData }}
        .d-flex.align-center.mr-sm-4.py-1(v-if='isAdmin')
          SynchronizeSwitch(
            :updateIsPossible='board.update_is_possible'
            :updatable='board.updatable'
            :boardId='board.id')

  v-col.col-12.col-md-6.pl-0.pt-4
    .trello.d-flex.flex-column-reverse.flex-md-column
      .trello__wrapper.mb-4(@click.stop)
        .trello__chart-wrapper.d-flex.flex-wrap
          router-link.trello__chart-link(
            v-if='board.id'
            :to='{name: "flowchart", params: {id: board.id}}') {{ $lang.toFlowChart }}
            v-icon.trello__link-icon {{'$launchIcon'}}
          router-link.trello__chart-link.trello__chart-link--indent(
            v-if='board.id'
            :to='{name: "burndownchart", params: {id: board.id}}') {{ $lang.burndownChart }}
            v-icon.trello__link-icon {{'$launchIcon'}}

        a.trello__link(
          :href='board.shortUrl'
          target='_blank'
          rel='noopener')
          span.trello__text Trello
          v-icon.trello__icon {{'$launchIcon'}}
      .trello__members.d-flex.flex-wrap.mb-4.mb-md-0(v-if='!boardClient')
        AvatarGroup.justify-start.justify-sm-end(
          :users='members'
          :size='25'
          :margin='isMobile ? "0 6px 6px 0" : "0 0 6px 6px"')
</template>

<script>
import { mapGetters } from 'vuex'
// const { mapState: boardsMapState } = createNamespacedHelpers('boards')
// const { mapState: sprintsMapState } = createNamespacedHelpers('sprints')

import { getSprintPeriod } from '@/helpers/dateFormatter'

const AvatarGroup = () =>
  import(
    /* webpackChunkName: "AvatarGroup" */ /* webpackMode: "eager" */ '@/components/AvatarGroup.vue'
  )
const SynchronizeSwitch = () =>
  import(
    /* webpackChunkName: "SynchronizeSwitch" */ /* webpackMode: "eager" */ '@/views/Boards/components/SynchronizeSwitch.vue'
  )

export default {
  name: 'ToolBarPanel',
  components: {
    AvatarGroup,
    SynchronizeSwitch,
  },
  props: {
    board: {
      type: Object,
    },
    boardProgress: {
      type: Number,
    },
  },
  data() {
    return {
      // updatable: this.board.updatable,
      // members: null,
      isShow: true,
    }
  },
  computed: {
    ...mapGetters(['userId', 'lang', 'isMobile']),
    // ...boardsMapState(['currentBoard']),
    // ...sprintsMapState(['lastSprints']),
    isAdmin() {
      return this.board?.admins
        ? this.board.admins.map((a) => a.id).includes(this.userId)
        : false
    },
    progress() {
      return this.boardProgress || this.board.progress
    },
    isActive() {
      return this.board.sprints.length && this.board.sprints[0].active
    },
    period() {
      if (this.isActive) {
        const start = this.board?.sprints[0].starts_at
        const end = this.board?.sprints[0].ends_at
        return getSprintPeriod(start, end, this.lang)
      }
      return ''
    },
    members() {
      return this.board?.members ? this.board.members : []
    },
    boardClient() {
      return this.board.user_status.toLowerCase() === 'client'
    },
  },
  created() {},
  methods: {
    getShortName(user) {
      return user.full_name
        ? user.full_name
            .split(' ')
            .map((w) => w[0].toUpperCase())
            .join('')
        : user.username.slice(0, 1).toUpperCase()
    },
    getUserStatus() {
      const status = this.board.user_status
      switch (status) {
        case 'USER':
          return this.$lang.statusIsParticipant
        case 'OBSERVER':
          return this.$lang.statusIsObserver
        case 'CLIENT':
          return this.$lang.statusIsClient
        default:
          return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-wrapper {
  margin-bottom: 16px;
  flex-wrap: wrap;
}

.switchBtn {
  width: 160px;
}

@media (max-width: 380px) {
  div.d-flex.board-trello-btn {
    margin-top: 16px;
  }
}

.board {
  &__name {
    @include subtitle-common;
    margin-bottom: 5px;
  }

  &__text {
    font-size: 12px;
    font-weight: 500;
    margin-right: 84px;

    @media (max-width: 690px) {
      margin-right: 0;
    }
  }

  &__chip {
    @include chip(5px, 14px);
    margin-right: 14px;
  }

  &__period {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  &__gear {
    margin-right: 14px;
  }

  &__user-status {
    font-family: var(--font-raleway);
    font-weight: 600;
    color: var(--v-title-base);
    align-self: flex-end;
    margin-top: 8px;

    &--active {
      color: var(--v-title-base);
    }

    &--inactive {
      color: var(--v-inactive-base);
    }
  }
}

.trello {
  align-items: flex-end;

  @media (max-width: 960px) {
    align-items: flex-start;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: 900px) {
      align-items: flex-start;
    }
  }

  &__chart-wrapper {
    justify-content: flex-end;

    @media (max-width: 900px) {
      justify-content: flex-start;
    }
  }

  &__link,
  &__chart-link {
    text-decoration: none;

    @media (max-width: 600px) {
      min-height: 40px;
    }
  }

  &__chart-link {
    display: flex;
    align-items: center;
    color: var(--v-main-base);

    &--indent {
      margin-left: 8px;

      @media (max-width: 1400px) {
        margin-left: 0;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
  }

  &__link-icon {
    margin-left: 8px;
  }

  &__user-avatar {
    margin: 0 0 8px 8px;

    @media (max-width: 960px) {
      margin: 0 8px 8px 0;
    }
  }

  &__text {
    font-size: 20px;
    color: var(--v-main-base);
    margin-right: 7px;
  }

  &__members {
    max-width: 100%;
  }

  &__avatar-name {
    font-size: 10px;
    line-height: 2.8;
  }
}
</style>
