<template lang="pug">
v-row
  v-col.col-12.col-md-2.col-lg-2.grey--text.d-flex.justify-end.align-baseline
  v-col.col-12.pr-0
    v-card.dashboard__card-inner(
      tile
      :key='card.id'
      elevation='0')
      v-row.dashboard__card-title.align-baseline.flex-column-reverse.flex-md-row
        v-col.col-12.col-md-9.col-lg-10
          h3.dashboard__card-item.grey--text {{ card.name || $lang.noTitle }}
          CopyAnimateComponent(:name='card.name')
        v-col.col-md-3.col-lg-2
          .link__box.pr-3.d-flex.flex-row.flex-md-column.align-center.justify-end(
            @click.stop)
            a.link__link.link__link--trello(
              :href='card.shortUrl'
              target='_blank')
              span.link__text.link__text--trello Trello
              v-icon.link__icon {{ "$launchIcon" }}
            a.link__link.mt-md-2.ml-3.ml-md-0(
              :href='`/cards/${card.id}`'
              target='_blank'
              :aria-label='$lang.goToCard'
              rel='noopener')
              span.link__text TrStat
              v-tooltip.link__tooltip(bottom)
                template(v-slot:activator='{on, attrs}')
                  v-icon.link__icon.link__icon--desktop(v-on='on') {{ mdiOpenInNew }}
                span {{ $lang.goToCard}}
      v-card-title.pa-0
        span.dashboard__card-item.title--text {{ card.board_name }}
      v-card-text.pa-0
        .dashboard__card-location.primary--text {{ lastLocation }}
        .dashboard__card-time.title--text.mb-7 {{ $lang.totalWorkTime }}: {{ totalWorkTime | dateTime($lang) }}
        .d-flex.mt-4
          .dashboard__subtitle.title--text.py-1.pr-3 {{ $lang.assignee }}
          v-divider
        v-container.mt-4.pl-sm-3(fluid)
          v-row
            AvatarGroup(
              :users='card.members'
              :removedUsers='card.former_card_members'
              :size='25'
              :margin='"0 8px 8px 0"')
</template>

<script>
import { mdiOpenInNew } from '@mdi/js'
import { createNamespacedHelpers, mapGetters } from 'vuex'
const { mapState: membersMapState } = createNamespacedHelpers('members')

const AvatarGroup = () =>
  import(
    /* webpackChunkName: "AvatarGroup" */ /* webpackMode: "eager" */ '@/components/AvatarGroup.vue'
  )
const CopyAnimateComponent = () =>
  import(
    /* webpackChunkName: "CopyAnimateComponent" */ /* webpackMode: "eager" */ '@/components/CopyAnimateComponent.vue'
  )

export default {
  name: 'DashboardColumn',
  components: {
    AvatarGroup,
    CopyAnimateComponent,
  },
  props: {
    card: {
      type: Object,
    },
  },
  data: () => ({
    mdiOpenInNew,
  }),
  computed: {
    ...membersMapState(['members', 'dashboardActors']),
    ...mapGetters(['isMobile', 'isTablet']),
    totalWorkTime() {
      if (this.card.locations.length) {
        const first = this.card.locations[0]
        const last = this.card.locations[this.card.locations.length - 1]
        let end = last.ends_at ? +new Date(last.ends_at) : +new Date()
        let start = first.starts_at
        return (end - start) / 1000
      } else return 0
    },
    lastLocation() {
      return this.card?.locations[this.card.locations.length - 1]
        ?.board_list_name
    },
    boardId() {
      return this.card?.board_id || 0
    },
  },
}
</script>

<style lang="scss" scoped>
.dashboard {
  &__card-inner {
    background: transparent !important;
  }

  &__card-item {
    display: inline;
    @include item-title;
    line-height: 1.2;
    margin-right: 8px;
    white-space: break-spaces;
  }

  &__card-text {
    font-size: 14px;
  }

  &__card-location {
    font-size: 12px;
    font-weight: 500;
  }

  &__card-time {
    font-size: 12px;
    font-weight: bold;
  }

  &__subtitle-bcg {
    display: flex;
  }

  &__subtitle {
    font-size: 12px;
    font-weight: 400;
    margin-top: -16px;
  }
}

.link {
  &__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;

    &--trello {
      color: var(--v-main-base);
    }
  }

  &__text {
    font-size: 20px;
    font-weight: 400;
    margin-right: 7px;
    color: var(--v-grey-base);

    &--trello {
      color: var(--v-main-base);
    }
  }
}
</style>
