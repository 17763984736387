<template lang="pug">
router-link.link(
  :to='{name, params, query}'
  :style='componentStyle'
  :aria-label='text')
  span.link__text(v-if='showCaption') {{ text }}
  v-tooltip.link__tooltip(
    bottom
    :disabled='isTooltipDisabled')
    template(v-slot:activator='{on, attrs}')
      v-icon.link__icon.link__icon--desktop(v-on='on') {{ icon }}
    span {{text}}
</template>

<script>
export default {
  name: 'InternalLink',
  props: {
    name: {
      type: String,
      default: '',
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    query: {
      type: Object,
      default: () => ({}),
    },
    color: {
      type: String,
      default: 'var(--v-title-base)',
    },
    iconColor: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    isTooltipDisabled: {
      type: Boolean,
      default: true,
    },
    showCaption: {
      type: Boolean,
      default: true,
    },
    displayStyle: {
      type: String,
      default: 'flex',
    },
    paddingStyle: {
      type: String,
      default: '8px 0',
    },
  },
  data: () => ({}),
  computed: {
    componentStyle() {
      return {
        '--color': this.color,
        '--icon-color': this.iconColor ?? this.color,
        '--display': this.displayStyle,
        '--padding': this.paddingStyle,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.link {
  text-decoration: none;
  display: var(--display);
  align-items: center;
  color: var(--color);

  @media (max-width: 600px) {
    padding: var(--padding);
  }

  &__icon {
    margin-left: 8px;
    color: var(--icon-color, 'currentColor');
  }
}
</style>
