<template lang="pug">
.main-wrapper
  .verification-container(
    v-if='!isVerification'
    @click='confirmUserEmail()')
    div {{ !isEmailSent ? $lang.emailVerification : $lang.repeatSending }}
  v-app-bar(
    app
    color='backgroundMain'
    flat
    :height='isMobile ? "88" : "122"')
    v-container.main__header-subcontainer.backgroundMain.d-flex(
      v-if='(!upperLevel && $route.name === "board") || $route.name === "boardSettings"')
      BoardHeader(
        :currentBoard='currentBoard'
        :currentRoute='getCurrentRoute()'
        :isSettings='isSettings')
    v-container.main__header-container.backgroundMain.d-flex(v-else)
      h1.screen__title.title--text(
        v-if='upperLevel || (!upperLevel && $route.name === "memberDashboard") || (!upperLevel && $route.name === "boardsSelection")') {{ getCurrentRoute() }}
      MemberHeader(
        v-else-if='!upperLevel && $route.name === "member"'
        :currentMember='member'
        :currentRoute='getCurrentRoute()')
      CustomHeader(
        v-else-if='!upperLevel'
        :name='getCurrentRoute()'
        :link='getLink($route.path)')
      v-spacer(v-if='isMobile')
      v-btn(
        @click='isOpenDrawer = !isOpenDrawer'
        icon
        v-if='isMobile')
        v-icon {{ mdiMenu }}
  v-navigation-drawer.navigation.pt-11(
    v-model='isOpenDrawer'
    :permanent='!isMobile'
    app
    :style='{"margin-top": isVerification ? "0" : "32px"}'
    :width='!isMobile ? "var(--nav-drawer-width)" : "100%"'
    color='base'
    floating)
    .close-wrapper.d-flex.d-sm-none
      v-btn(
        @click='isOpenDrawer = !isOpenDrawer'
        icon
        v-if='isMobile')
        v-icon {{ mdiClose }}
    v-spacer.navigation__spacer
    router-link.logo-link.text-none.d-block(
      :to='{name: "home"}'
      :aria-label='$lang.linkGoToHome')
      IconBase(
        icon-name='logo'
        height='20'
        icon-color='var(--v-title-base)'
        width='83')
        LogotypeUni

    .avatar-group.d-none.d-sm-flex.align-center(v-if='options')
      v-avatar.user-avatar(size='20')
        v-img(
          :src='options.avatar_url'
          :alt='options.username'
          v-if='options.avatar_url')
        v-icon.user-avatar__icon(v-else) {{ "$userIcon" }}
      span.title--text {{ options.username }}
    v-list.pa-0
      v-list-item.navigation__menu.pl-7(
        v-for='link in links'
        :key='link.to'
        :exact='link.exact'
        :to='{name: link.to, query: link.query, params: link.params}'
        :class='getActiveClass(link.to)')
        v-icon.navigation__menu-icon.mr-5(size='20') {{ `${link.icon}` }}
        v-list-item-title {{ link.title }}
      v-list-item.pl-7
        v-icon.mr-5 {{ "$exitIcon" }}
        button.title--text(
          @click='userLogout'
          text) {{ $lang.logout }}
    v-list-item.navigation__email.title--text.pl-7.pa-sm-7.align-sm-end
      EmailLink
  v-main.main__component
    v-container.main__main-container.mt-0.mt-sm-0.justify-center.align-start.backgroundMain(
      fluid
      fill-height)
      transition(
        name='fade'
        mode='out-in')
        router-view
</template>

<script>
import { mdiMenu, mdiClose } from '@mdi/js'
import {
  mapGetters,
  mapMutations,
  mapActions,
  createNamespacedHelpers,
} from 'vuex'
const { mapGetters: optionsMapGetters, mapActions: optionsMapActions } =
  createNamespacedHelpers('options')
const { mapState: boardsMapState } = createNamespacedHelpers('boards')
const { mapState: membersMapState } = createNamespacedHelpers('members')
const { mapState: sprintsMapState } = createNamespacedHelpers('sprints')

import config from '@/../config.json'
import {
  menuUserOptions,
  menuObserverAndClientOptions,
  menuObserverOptions,
  menuClientOptions,
  menuOptions,
} from '@/helpers/mainMenuItems'

const BoardHeader = () =>
  import(
    /* webpackChunkName: "BoardHeader" */ /* webpackMode: "eager" */ '@/layouts/components/BoardHeader.vue'
  )
const MemberHeader = () =>
  import(
    /* webpackChunkName: "MemberHeader" */ /* webpackMode: "eager" */ '@/layouts/components/MemberHeader.vue'
  )
const CustomHeader = () =>
  import(
    /* webpackChunkName: "CustomHeader" */ /* webpackMode: "eager" */ '@/layouts/components/CustomHeader.vue'
  )
const EmailLink = () =>
  import(
    /* webpackChunkName: "EmailLink" */ /* webpackMode: "eager" */ '@/components/EmailLink.vue'
  )

export default {
  name: 'MainComponent',
  components: {
    BoardHeader,
    MemberHeader,
    CustomHeader,
    EmailLink,
  },
  data() {
    return {
      isOpenDrawer: false,
      isVerification: true,
      upperLevel: true,
      isSettings: false,
      activeBoardLink: false,
      isEmailSent: false,
      mdiMenu,
      mdiClose,
      menuUserOptions,
      menuObserverAndClientOptions,
      menuObserverOptions,
      menuClientOptions,
      menuOptions,
    }
  },
  computed: {
    ...mapGetters(['isMobile', 'isDark', 'isTablet', 'userId']),
    ...optionsMapGetters(['options']),
    ...boardsMapState(['currentBoard']),
    ...membersMapState(['member']),
    ...sprintsMapState(['sprintDetails']),
    avatarName() {
      return this.options.username
        .split(' ')
        .map((w) => w[0])
        .join('')
    },
    links() {
      if (this.options && this.options.user_boards_status) {
        const status = this.options.user_boards_status
        let menu

        switch (status) {
          case 'normal':
            menu = this.menuUserOptions
            break
          case 'observer and client':
            menu = this.menuObserverAndClientOptions
            break
          case 'observer':
            menu = this.menuObserverOptions
            break
          case 'client':
            menu = this.menuClientOptions
            break
          case 'not present':
            menu = this.menuOptions
            break
          default:
            menu = this.menuOptions
        }

        return this.getMenu(menu)
      }
      return this.getMenu(this.menuOptions)
    },
  },
  created() {
    this.getOptions().finally(() => {
      this.isVerification = this.options && this.options.verified_email
    })

    window.addEventListener('storage', this.eventHandler)
  },
  updated() {
    this.upperLevel =
      this.$route.params && Object.keys(this.$route.params).length === 0
  },
  beforeDestroy() {
    window.removeEventListener('storage', this.eventHandler)
  },
  methods: {
    ...mapMutations(['ADD_NOTIFICATION']),
    ...mapActions(['logout', 'confirmEmail']),
    ...optionsMapActions(['getOptions']),
    userLogout() {
      this.logout().then(() => (document.location.href = `${config.baseURL}`))
    },
    async confirmUserEmail() {
      const data = await this.confirmEmail()
      this.ADD_NOTIFICATION({
        color: 'success',
        text: data.detail,
      })
      this.isEmailSent = true
    },
    getActiveClass(name) {
      if (
        name === 'boards' &&
        (this.$route.name === 'board' || this.$route.name === 'boardSettings')
      ) {
        return 'active-link'
      }
      if (name === 'members' && this.$route.name === 'member') {
        return 'active-link'
      }
      if (
        name === 'sprints' &&
        (this.$route.name === 'sprintsAtBoard' ||
          this.$route.name === 'sprint' ||
          this.$route.name === 'sprints')
      ) {
        return 'active-link'
      }
    },
    eventHandler() {
      if (localStorage.getItem('token-data') === null) {
        localStorage.clear()
        document.location.href = config.baseURL
      }
    },
    getCurrentRoute() {
      if (Object.keys(this.$route.params).length === 0) {
        return this.$route.name === 'boardsSelection'
          ? this.$lang.boardsSelection
          : this.getTitle(this.$route.name)
      }

      if (this.$route.params && Object.keys(this.$route.params).length !== 0) {
        this.upperLevel = false

        if (this.$route.name === 'board' && this.currentBoard) {
          this.isSettings = false
          return this.currentBoard.name
        }
        if (this.$route.name === 'boardSettings' && this.currentBoard) {
          this.isSettings = true
          return this.currentBoard.name
        }
        if (this.$route.name === 'member' && this.member) {
          return this.member?.full_name
            ? this.member.full_name
            : this.member.username
        }
        if (this.$route.name === 'sprint' && this.sprintDetails[0]) {
          return this.sprintDetails[0] ? this.sprintDetails[0].board.name : ''
        }
        if (this.$route.name === 'sprintsAtBoard' && this.currentBoard) {
          return this.currentBoard.name
        }
        if (this.$route.path.includes('dashboard')) {
          return this.getTitle('dashboard')
        }
        if (this.$route.path.includes('cards')) {
          return this.getTitle(
            'cards',
            `${this.$lang.cardHeaderTitle} id: ${this.$route.params.cardId}`
          )
        }
        if (this.$route?.path.includes('chart')) {
          const title = `${this.$route.name}Title`
          const name = this.currentBoard?.name
            ? `"${this.currentBoard.name}"`
            : ''
          return `${this.$lang[title]} ${name}`
        }
      }
    },
    getMenu(items) {
      let menu = []
      items.map((el) => {
        const item = {}
        item.query = {}
        item.params = {}
        item.title = this.$lang[el.title]
        item.to = el.title
        item.icon = `$${el.title}Icon`
        if (el.query) {
          item.query[`${el.query}`] = this.$route.query[`${el.query}`]
        }
        // if (el.params) {
        //   console.log('params', this[el.params])
        //   item.params[`${el.params}`] = this[el.params]
        // }

        menu = [...menu, item]
      })
      return menu
    },
    getTitle(name, str = '') {
      const current = this.links?.find((item) => item.to === name)
      return current?.title ? current.title : str
    },
    getLink(path) {
      return path.split('/')[1]
    },
  },
}
</script>

<style lang="scss" scoped>
.main-wrapper {
  height: 100%;
}

.verification-container {
  position: fixed;
  z-index: 6;
  left: 0;
  right: 0;
  background: var(--v-warning-base);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  height: 32px;
  color: var(--v-base-base);
  cursor: pointer;

  &:active {
    background: var(--v-warningActive-base);
    transition: background-color 0.2s ease 0.1s;
  }
}

.logo-version {
  font-size: 1rem;
}

.main {
  &__header-container {
    min-width: 100%;
    padding-left: 88px;
    padding-right: 88px;
    height: var(--header-height);

    @media (max-width: 960px) {
      padding-left: 52px;
      padding-right: 52px;
    }

    @media (max-width: 600px) {
      padding: 0;
      height: var(--header-height-mobile);
    }
  }
  &__header-subcontainer {
    min-width: 100%;
    padding-left: 88px;
    padding-right: 88px;
    height: var(--subheader-height);
    margin-top: 60px;

    @media (max-width: 960px) {
      padding-left: 52px;
      padding-right: 52px;
    }

    @media (max-width: 600px) {
      padding: 0;
      height: var(--header-height-mobile);
    }
  }

  &__component {
    height: 100%;
  }

  &__main-container {
    // padding: 0 104px 0;
    padding-top: 0;
    padding-left: 104px;
    padding-right: 104px;

    @media (max-width: 960px) {
      // padding: 0 68px 0;
      padding-left: 68px;
      padding-right: 68px;
    }

    @media (max-width: 600px) {
      // padding: 16px 16px 0;
      padding-top: 16px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

.navigation {
  z-index: 10;

  &__menu-icon {
    margin-top: 1px;
  }
}

.navigation__spacer {
  flex-grow: 0 !important;
}

.navigation__email {
  width: var(--nav-drawer-width);
}

.avatar-group {
  margin-bottom: 28px;
}

.user-avatar {
  margin: 0 18px 0 26px;
}

.close-wrapper {
  justify-content: flex-end;
  padding: 0 24px;
  height: 40px;
  margin-top: -10px;
  position: absolute;
  z-index: 100;
  width: 100%;

  @media (max-width: 600px) {
    margin-bottom: 70px;
  }
}

.logo-link {
  margin: 14px 0 54px 20px;

  @media (max-width: 600px) {
    margin: 26px 0 26px 26px;
  }
}
</style>

<style lang="scss">
.navigation .v-navigation-drawer__content {
  @media (min-width: 600px) {
    display: flex;
    flex-direction: column;
  }
}

.navigation__menu.v-list-item:not(.v-list-item--active) > .v-list-item__title {
  color: var(--v-title-base) !important;
}

.navigation__menu.v-list-item:not(.v-list-item--active) svg > path {
  fill: var(--v-title-base);
}

.v-list-item--active {
  color: var(--v-main-base) !important;
}

.v-list-item--active svg > path {
  fill: var(--v-main-base);
}

.active-link.navigation__menu.v-list-item:not(.v-list-item--active)
  > .v-list-item__title {
  color: var(--v-main-base) !important;
}

.active-link.navigation__menu.v-list-item:not(.v-list-item--active) svg > path {
  fill: var(--v-main-base);
}

.v-list-item--active::before {
  background: transparent;
}

.v-icon.user-avatar__icon > svg > path {
  fill: var(--v-title-base);
}

.main__component > .v-main__wrap {
  min-height: 100%;
}
</style>
