<template lang="pug">
v-container.pa-0(fluid)
  template(v-for='list in filteredLists')
    ProgressBar(
      :key='list.id'
      :isRel='false'
      :isDark='isDark'
      :list='list'
      :role='list.role'
      :countCard='sprint.lists_cards_count[list.id]'
      :allCountCard='allCountCard')
  //- div.px-2()
  //-   span.progress__description.greySprint--text.mr-2 {{ relList.name }}
  //-   span.progress__description.mr-2 {{ allCountCard ? `${$lang.completed}: ${doneCards} / ${allCountCard} (${ progress }%)`: `${ progress }%`}}
  //-   span.progress__description {{ allCountCard ? `Всего: ${inProgressCards + doneCards} / ${sprint.cards_count} (${ workingProgess }%)`: `${ workingProgess }%`}}
  template(v-for='list in filteredRelList')
    ProgressBar(
      :key='"rel"-list.id'
      :isRel='true'
      :isDark='isDark'
      :list='list'
      :role='list.role'
      :countCard='sprint.lists_cards_count[list.id]'
      :allCountCard='allCountCard'
      :inProgressCards='inProgressCards'
      :doneCards='doneCards')
  p.sprint__summary.mb-0.d-flex.flex-column.flex-sm-row.flex-sm-wrap.title--text
    span.mr-1 {{ $lang.tasksInSprint }}: {{ totalCards }}.
    span.mr-1 {{ $lang.completed }}: {{ doneCards }}.
    span.mr-1 {{ $lang.opened }}: {{ totalCards - doneCards }}.
    span.mr-1 {{ $lang.inProgress }}: {{ inProgressCards }}.
</template>

<script>
import { mapState } from 'vuex'

const ProgressBar = () =>
  import(
    /* webpackChunkName: "ProgressBar" */ /* webpackMode: "eager" */ '@/views/Sprints/components/ProgressBar.vue'
  )

export default {
  name: 'SprintProgressBars',
  components: {
    ProgressBar,
  },
  props: {
    sprint: {
      type: Object,
      default: () => {},
    },
    lists: { type: Array, default: () => [] },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['isDark']),
    totalCards() {
      return this.sprint.cards_count
    },
    doneCards() {
      return this.sprint.lists_cards_count[this.sprint.rel_list_id] || 0
    },
    filteredLists() {
      return this.lists.filter((list) => list.role !== 1)
    },
    // filteredLists() {
    //   return (sprint) => {
    //     return this.lists.filter(list => {
    //       return list.role !== 1 || list.id === sprint.rel_list_id
    //     })
    //   }
    // },
    filteredRelList() {
      return this.lists.filter((list) => list.id === this.sprint.rel_list_id)
    },
    allCountCard() {
      return this.sprint.cards_count || 0
    },
    inProgressCards() {
      return this.sprint.cards_started_work_count || 0
    },
    // progress() {
    //   return this.sprint.cards_count
    //     ? (this.relCountCard / this.sprint.cards_count * 100).toFixed(1)
    //     : 0
    // },
    // workingProgess() {
    //   return this.sprint.cards_count
    //     ? ((this.inProgressCards + this.doneCards) / this.sprint.cards_count * 100).toFixed(1)
    //     : 0
    // },
  },
}
</script>

<style lang="scss" scoped>
.sprint {
  &__summary {
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
