<template lang="pug">
v-row.my-0.mx-md-0
  v-col.col-12.py-0.px-md-0.py-sm-2
    v-progress-linear(
      indeterminate
      v-show='!isLoaded')
    template(v-if='isLoaded')
      v-card.mb-3.backgroundMain(elevation='0')
        InternalLink(
          v-if='isLoaded'
          name='members'
          :query='{"init-project": sprint.board.id}'
          :text='$lang.toProjectMembers'
          :icon='mdiOpenInNew')
        v-card-title.px-0
          h3.sprint__title.grey--text {{ $lang.sprint }} # {{ sprint.number }}
        v-card-text.px-0(v-if='isLoaded')
          SprintProgressBars(
            :lists='sprint.board_lists'
            :sprint='sprint')
      v-expansion-panels(flat)
        v-expansion-panel.sprint__panel.backgroundMain(
          v-for='card in sprint.cards'
          :key='`card-${card.id}`')
          v-expansion-panel-header.sprint__panel-header.px-0
            SprintCard.backgroundMain(
              :card='card'
              :sprint='sprint.board_lists')
          v-expansion-panel-content.sprint__panel-content
            v-row
              v-col.col-12
                ul(v-if='card.work_lists.length')
                  li(
                    v-for='list in card.work_lists'
                    :key='list.id')
                    h5.subheading-2.font-weight-bold {{ list.name }}
                    p.caption.mb-1(v-if='!isRelColumn(list)') {{ $lang.totalTimeInList }}: {{ list.duration | dateTime($lang) }}
                    p.caption.mb-1 {{ $lang.iterationsInList }}: {{ list.times }}
                span(v-else) {{ $lang.noLists }}
</template>

<script>
import { mdiOpenInNew } from '@mdi/js'
import { createNamespacedHelpers, mapGetters } from 'vuex'
const {
  mapState: sprintsMapState,
  mapMutations: sprintsMapMutations,
  mapActions: sprintsMapActions,
} = createNamespacedHelpers('sprints')
const { mapMutations: boardssMapMutations, mapActions: boardssMapActions } =
  createNamespacedHelpers('boards')

const SprintCard = () =>
  import(
    /* webpackChunkName: "SprintCard" */ /* webpackMode: "eager" */ '@/views/Sprints/components/Card.vue'
  )
const SprintProgressBars = () =>
  import(
    /* webpackChunkName: "SprintProgressBars" */ /* webpackMode: "eager" */ '@/views/Sprints/components/SprintProgressBars.vue'
  )
const InternalLink = () =>
  import(
    /* webpackChunkName: "InternalLink" */ /* webpackMode: "eager" */ '@/components/InternalLink.vue'
  )

export default {
  name: 'SprintComponent',
  components: {
    SprintProgressBars,
    SprintCard,
    InternalLink,
  },
  data: () => ({
    isLoaded: false,
    mdiOpenInNew,
  }),
  created() {
    Promise.all([
      this.getBoardSprint(this.sprintId),
      this.getBoard(this.boardId),
    ]).finally(() => {
      this.isLoaded = true
    })
  },
  destroyed() {
    this.SET_TO_ZERO_BOARD_SPRINTS()
    this.SET_TO_ZERO_BOARDS_MEMBERS()
  },
  computed: {
    ...mapGetters(['isDark', 'isMobile', 'isTablet']),
    ...sprintsMapState(['sprintDetails', 'sprintsMembers']),
    sprintId() {
      return +this.$route.params.sprintId
    },
    boardId() {
      return +this.$route.params.boardId
    },
    sprint() {
      return this.sprintDetails.find((sprint) => sprint.id === this.sprintId)
    },
    sprintCards() {
      return this.sprint.cards
    },
  },
  methods: {
    ...sprintsMapMutations(['SET_TO_ZERO_BOARD_SPRINTS']),
    ...sprintsMapActions(['getBoardSprint']),
    ...boardssMapMutations(['SET_TO_ZERO_BOARDS_MEMBERS']),
    ...boardssMapActions(['getBoard']),
    isRelColumn(list) {
      // TODO: название релизной колонки не обязательно может быть с REL
      return list.name.includes('REL')
    },
    goToBack() {
      this.$router.back()
    },
    getTotalWorkTime(location) {
      let end = location.ends_at ? +new Date(location.ends_at) : +new Date()
      let start = location.starts_at
      return (end - start) / 1000
    },
  },
}
</script>

<style lang="scss" scoped>
.sprint {
  &__title {
    @include subtitle-common;
  }
}

.card {
  &__time {
    font-size: 12px;
    font-weight: 700;
  }
}
</style>

<style lang="scss">
.sprint__panel-header.v-expansion-panel-header .v-expansion-panel-header__icon {
  display: none;
}

.sprint__panel-content.v-expansion-panel-content
  .v-expansion-panel-content__wrap {
  padding-left: 12px;
  padding-right: 12px;
}
</style>
