<template lang="pug">
.labels
  CardLabel.labels__label(
    v-for='(label, idx) in card.labels'
    :key='`${label.name}-${idx}`'
    :label='label')
</template>

<script>
const CardLabel = () =>
  import(
    /* webpackChunkName: "CardLabel" */ /* webpackMode: "eager" */ '@/components/CardLabel.vue'
  )

export default {
  name: 'CardLabels',
  components: {
    CardLabel,
  },
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="scss" scoped>
.labels {
  display: flex;
  flex-wrap: wrap;

  &__label {
    margin-top: 4px;
    margin-right: 4px;
  }
}
</style>
