<template lang="pug">
v-tooltip(bottom)
  template(v-slot:activator='{on}')
    v-avatar.avatar__item(
      :color='avatarColor'
      :size='size'
      v-on='on'
      :style='margins')
      img(
        :src='user.avatar_url'
        :alt='user.username'
        v-if='user.avatar_url && !isLoadingError'
        @error='imgLoadingError')
      span.avatar__name(
        v-else
        :style='[{fontSize}, nameColor]') {{ shortName }}
  span.avatar__name.base--text {{ userName }}{{ !active ? `, ${$lang.removedFromBoard} ` : null }}{{ removalTime }}
</template>

<script>
import { mapGetters } from 'vuex'

import { getRemovalTime } from '@/helpers/dateFormatter'

export default {
  name: 'AvatarComponent',
  props: {
    user: { type: Object, default: () => {} },
    isTooltip: { type: Boolean, default: () => false },
    size: { type: Number, default: () => 50 },
    isGroup: { type: Boolean, default: () => false },
    color: { type: String, default: 'role4' },
    fontSize: { type: String, default: '10px' },
    margin: { type: String, default: '0' },
    active: { type: Boolean, default: true },
  },
  data: () => ({
    isLoadingError: false,
  }),
  computed: {
    ...mapGetters(['lang']),
    shortName() {
      return this.user.full_name
        ? this.user.full_name
            .split(' ')
            .map((w) => w[0].toUpperCase())
            .join('')
        : this.user.username.slice(0, 1).toUpperCase()
    },
    margins() {
      return {
        margin: this.margin,
      }
    },
    avatarColor() {
      return this.active
        ? `var(--v-${this.color}-base)`
        : `var(--v-${this.color}-lighten2)`
    },
    nameColor() {
      return {
        color: this.active ? 'var(--v-base-base)' : 'var(--v-inactive-base)',
      }
    },
    removalTime() {
      if (!this.active && this.user.removal_time) {
        return getRemovalTime(this.user.removal_time, this.lang)
      }
      return null
    },
    userName() {
      return this.user.full_name || this.user.username
    },
  },
  methods: {
    imgLoadingError() {
      this.isLoadingError = true
    },
  },
}
</script>

<style lang="scss" scoped></style>
