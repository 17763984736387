import request from '@/services/request'
import { apiDashboard, apiDashboardMembers } from '@/helpers/urls'

const state = {
  dashboard: [],
  dashboardActors: [],
}

const getters = {
  dashboard: ({ dashboard }) => dashboard,
  dashboardActors: ({ dashboardActors }) => dashboardActors,
}

const mutations = {
  SET_DASHBOARD: (state, data) => {
    state.dashboard = Array.isArray(data) ? data : []
  },
  SET_DASHBOARD_ACTORS: (state, data) => {
    state.dashboardActors = data
  },
  SET_TO_ZERO_DASHBOARD_ACTORS: (state) => {
    state.dashboardActors = []
  },
}

const actions = {
  getDashboard: async ({ commit }, userId) => {
    const params = {
      viewed_user: userId,
    }
    try {
      const data = await request({ url: apiDashboard, method: 'GET', params })
      commit('SET_DASHBOARD', data)
      return { data }
    } catch (err) {
      return { error: err }
    }
  },
  getDashboardActors: async ({ commit }) => {
    try {
      const data = await request({
        url: `${apiDashboardMembers}`,
        method: 'GET',
      })
      commit('SET_DASHBOARD_ACTORS', data)
      return data
    } catch (err) {
      return { error: err }
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
