<template lang="pug">
v-list.backgroundMain
  v-list-item.pa-1.pa-sm-2.mb-3(@click='changeBoardHerald(null)')
    v-list-item-title.setting__username.grey--text {{ $lang.notSet }}
    v-list-item-icon
      v-icon(:color='boardActiveHerald === null ? "main" : "lightGrey"') {{ mdiStar }}
  v-list-item.pa-1.pa-sm-2.mb-3(
    v-for='(boardHerald, index) in boardHeralds'
    :key='boardHerald.id'
    @click='changeBoardHerald(boardHerald.id)'
    :class='[index % 2 === 0 ? "setting__dark" : ""]')
    AvatarGroup.mr-1.mr-sm-3(
      :user='boardHerald'
      :idx='index'
      :size='!isMobile ? 42 : 32')
    v-list-item-title.setting__username.grey--text {{ boardHerald.username }}
    v-list-item-icon
      v-icon(
        :color='boardActiveHerald === boardHerald.id ? "main" : "lightGrey"') {{ mdiStar }}
</template>

<script>
import { mdiStar } from '@mdi/js'
import { mapGetters } from 'vuex'

const AvatarGroup = () =>
  import(
    /* webpackChunkName: "AvatarGroup" */ /* webpackMode: "eager" */ '@/components/AvatarGroup.vue'
  )

export default {
  name: 'BoardHeraldSettings',
  components: {
    AvatarGroup,
  },
  props: {
    boardHeralds: {
      type: Array,
      default: () => {},
    },
    boardActiveHerald: {
      type: Number,
      default: () => null,
    },
  },
  data: () => ({
    mdiStar,
  }),
  created() {
    // this.$store.dispatch('boards/getBoardHeralds', this.$route.params.id)
  },

  computed: {
    ...mapGetters(['isMobile', 'isDark', 'userId']),
  },
  methods: {
    changeBoardHerald(heraldId) {
      this.$store
        .dispatch('boards/patchSettingsBoard', {
          boardId: this.$route.params.id,
          herald_id: heraldId,
        })
        .then((data) => {
          const text = data.herald_id
            ? this.$lang.heraldAssigned
            : this.$lang.heraldNotSet
          this.$store.commit('ADD_NOTIFICATION', {
            color: 'success',
            text,
          })
          this.$store.dispatch('boards/getBoardHeralds', this.$route.params.id)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.setting {
  &__username {
    @include subtitle-common;

    @media (max-width: 960px) {
      font-size: 18px;
    }
  }

  &__dark {
    background: var(--v-backgroundTable-base);
  }
}
</style>
