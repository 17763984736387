<template lang="pug">
v-tooltip(
  bottom
  :z-index='10'
  :disabled='updateIsPossible')
  template(v-slot:activator='{ on }')
    div(v-on='on')
      v-switch.my-0.pb-1(
        @click.native.prevent='changeBoardUpdatable'
        v-model='vUpdatable'
        :disabled='!updateIsPossible'
        hide-details
        color='title')
        template(v-slot:label)
          span.settings__switch-label.board__text.inactive--text {{ vUpdatable ? $lang.updatable : $lang.notUpdatable }}
  span {{ !updateIsPossible ? $lang.hintSyncSwitchVisibility : null }}
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapActions: boardsMapActions } = createNamespacedHelpers('boards')

export default {
  name: 'SynchronizeSwitch',
  props: {
    updateIsPossible: {
      type: Boolean,
      default: true,
    },
    updatable: {
      type: Boolean,
      default: false,
    },
    boardId: {
      type: Number,
    },
  },
  data: () => ({
    vUpdatable: false,
  }),
  computed: {},
  created() {
    this.vUpdatable = this.updatable
  },
  methods: {
    ...boardsMapActions(['patchSettingsBoard']),
    changeBoardUpdatable() {
      this.patchSettingsBoard({
        boardId: this.boardId,
        updatable: this.vUpdatable,
      }).then((data) => {
        if (data.updatable) {
          this.$store.commit('ADD_NOTIFICATION', {
            color: 'success',
            text: this.$lang.updatableNotification,
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.settings__switch-label {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
}
</style>
