<template lang="pug">
v-radio-group.members__radio(
  v-model='filterType'
  row
  hide-details
  @change='$emit("toggleChartFilterType", filterType)')
  v-radio.mb-4(
    :label='$lang.aboutActions'
    value='actions'
    :class='[filterType === "actions" ? "active" : ""]')
  v-radio.mb-4(
    :label='$lang.aboutCommits'
    value='commits'
    :class='[filterType === "commits" ? "active" : ""]')
</template>

<script>
export default {
  name: 'RadioButtonsGroup',
  props: {
    radioFilterType: {
      type: String,
      default: 'actions',
    },
  },
  data: () => ({
    filterType: 'actions',
  }),
  watch: {
    radioFilterType(n) {
      this.filterType = n
    },
  },
  created() {
    this.filterType = this.radioFilterType
  },
}
</script>

<style lang="scss" scoped></style>
