<template lang="pug">
v-row(no-gutters)
  v-progress-linear(
    indeterminate
    v-show='!isLoadedDashboard')
  EmptyEntities(v-if='isLoadedDashboard && !dashboard')
  template(v-else-if='isLoadedDashboard && dashboard')
    v-col.dashboard__wrapper.col-12.px-0.d-flex
      .dashboard__user-wrapper.align-start
        .dashboard__user-box
          .dashboard__name-wrapper
            span.dashboard__user-name {{ selectedUser ? `${$lang.userTasks} ${selectUser}` : $lang.myTasks }}
            InternalLink.dashboard__member-link(
              v-if='userMemberId'
              name='member'
              :params='pageParams'
              :text='$lang.toProjectMember'
              :icon='mdiOpenInNew'
              :showCaption='false'
              :isTooltipDisabled='false'
              iconColor='var(--v-main-base)'
              displayStyle='inline-block'
              paddingStyle='0')

          v-dialog(
            v-if='dashboardActors && dashboardActors.length'
            v-model='popupSelectUser'
            max-width='630'
            overlay-color='base'
            content-class='dialog__inner')
            template(v-slot:activator='{on}')
              v-btn.dashboard__button(
                v-on='on'
                depressed) {{ $lang.showTasksOfOtherUser }}
            v-card.backgroundMain
              v-card-title.dialog__title.d-flex.flex-nowrap
                span {{ $lang.selectUserForReport }}
                v-btn.ml-2.ml-sm-auto.grey--text(
                  text
                  @click='popupSelectUser = false'
                  fab
                  small)
                  v-icon(dark) {{ mdiClose }}
              v-card-text.d-flex.flex-column
                button.setting__button-blue.my-4.ml-auto(
                  v-if='dashboardActors && dashboardActors.length'
                  @click='getCurrentUser()'
                  :disabled='!selectedUser') {{ $lang.currentUser }}
                v-autocomplete(
                  v-model='selectedUser'
                  :items='[...sortedDashboardActors]'
                  item-value='id'
                  item-text='full_name'
                  :label='$lang.selectUserForReport'
                  clearable
                  @change='getDashboardData(selectedUser)')
                  template(v-slot:item='{item}')
                    router-link.dashboard__link(:to='`/dashboard/${item.id}`') {{ item.full_name }}
        .dashboard__user-settings.d-flex.flex-column.primary--text
          .dashboard__text-wrapper {{ $lang.totalTasksNumber }} {{ totalTasksNumber || 0 }}
          .d-flex
            v-switch.dashboard__switch.mt-1(
              v-model='isShowFilteredTasks'
              color='switch'
              @change='getFilteredTasks')
              template(v-slot:label)
                span.dashboard__switch-label.primary--text {{ $lang.dashboardActiveSprints }}

    v-tabs.tabs(
      v-model='tab'
      hide-slider
      background-color='backgroundMain'
      active-class='tabs__active')
      v-tab.tabs__tab {{ $lang.ascSorting }}
      v-tab.tabs__tab {{ $lang.descSorting }}
    v-tabs-items.tabs-items(v-model='tab')
      v-tab-item(
        v-if='!emptyUserTasks'
        v-for='(item, name) in sorting'
        :key='name')
        v-col.column__wrapper.col-12.pa-0
          v-list.pa-0.backgroundMain
            v-list-item.column__card.base.mb-10(
              v-for='tasks in item'
              :key='item.id')
              v-list-item-content.base.py-0
                DashboardColumn(:card='tasks')
    .tabs-items(v-if='emptyUserTasks') {{ errorMessage ? errorMessage : $lang.noTasks }}
</template>

<script>
import { mdiClose, mdiOpenInNew } from '@mdi/js'
import { createNamespacedHelpers, mapGetters, mapMutations } from 'vuex'
const { mapState: dashboardMapState, mapActions: dashboardMapActions } =
  createNamespacedHelpers('dashboard')
const { mapGetters: optionsMapGetters } = createNamespacedHelpers('options')

const EmptyEntities = () =>
  import(
    /* webpackChunkName: "EmptyEntities" */ '@/components/EmptyEntities.vue'
  )
const DashboardColumn = () =>
  import(
    /* webpackChunkName: "DashboardColumn" */ /* webpackMode: "eager" */ '@/views/Dashboard/components/DashboardColumn.vue'
  )
const InternalLink = () =>
  import(
    /* webpackChunkName: "InternalLink" */ /* webpackMode: "eager" */ '@/components/InternalLink.vue'
  )

export default {
  name: 'DashboardComponent',
  components: {
    EmptyEntities,
    DashboardColumn,
    InternalLink,
  },
  data() {
    return {
      isLoadedDashboard: false,
      userTasks: {},
      ascUserTasks: null,
      descUserTasks: null,
      tab: null,
      selectedUser: null,
      popupSelectUser: false,
      isShowFilteredTasks: true,
      mdiClose,
      mdiOpenInNew,
      errorMessage: null,
    }
  },
  computed: {
    ...mapGetters(['isMobile', 'isTablet', 'isDark', 'userId', 'userMemberId']),
    ...dashboardMapState(['dashboard', 'dashboardActors']),
    ...optionsMapGetters(['options']),
    sorting() {
      return {
        ascSorting: this.ascUserTasks,
        descSorting: this.descUserTasks,
      }
    },
    totalTasksNumber() {
      return this.isLoadedDashboard && this.filteredDashboard.length
        ? this.filteredDashboard.length
        : null
    },
    selectUser() {
      return (
        this.dashboardActors?.find((user) => user.id === +this.selectedUser)
          ?.full_name || this.$lang.unknownUser
      )
    },
    userName() {
      return this.options?.username
    },
    filteredDashboard() {
      if (this.isShowFilteredTasks) {
        return this.dashboard.filter((task) => task.is_active_sprints)
      }
      return this.dashboard
    },
    sortedDashboardActors() {
      return [...this.dashboardActors].sort((a, b) => b.is_active - a.is_active)
    },
    emptyUserTasks() {
      return !Object.keys(this.userTasks).length
    },
    pageParams() {
      return { id: this.selectedUser ? this.selectedUser : this.userMemberId }
    },
  },
  created() {
    this.selectedUser = +this.$route.params.userId
    Promise.all([this.getDashboardActors(), this.getDashboardData()])
  },
  methods: {
    ...mapMutations(['ADD_NOTIFICATION']),
    ...dashboardMapActions(['getDashboard', 'getDashboardActors']),
    sortObjectAsc(obj) {
      return Object.keys(obj)
        .sort((a, b) => a - b)
        .reduce((res, key) => ((res[key] = obj[key]), res), {})
    },
    sortObjectDesc(obj) {
      return Object.keys(obj)
        .sort((a, b) => b - a)
        .reduce((res, key) => ((res[key] = obj[key]), res), {})
    },
    async getDashboardData() {
      this.isLoadedDashboard = false
      const res = await this.getDashboard(this.$route.params.userId)
      if (res?.data) {
        this.getFilteredTasks()
        this.popupSelectUser = false
        this.isShowFilteredTasks = true
        this.errorMessage = null
      } else if (res.error) {
        this.ADD_NOTIFICATION({
          color: 'error',
          text: res.error.detail,
        })
        this.errorMessage = res.error.detail
      }
      this.isLoadedDashboard = true
    },
    getCurrentUser() {
      this.selectedUser = null
      this.$router.push({ path: '/dashboard' })
      setTimeout(() => {
        this.getDashboardData()
      }, 10)
    },
    getFilteredTasks() {
      this.userTasks = {}
      this.ascUserTasks = []
      this.descUserTasks = []

      this.filteredDashboard.forEach((task) => {
        const totalTime = this.getTotalWorkTime(task.locations)
        this.userTasks[totalTime] = task
      })

      this.ascUserTasks = this.sortObjectAsc(this.userTasks)
      this.descUserTasks = this.sortObjectDesc(this.userTasks)
    },
    getTotalWorkTime(location) {
      if (location.length) {
        const first = location[0]
        const last = location[location.length - 1]
        let end = last.ends_at ? +new Date(last.ends_at) : +new Date()
        let start = first.starts_at
        return (end - start) / 1000
      } else return 0
    },
  },
}
</script>

<style lang="scss" scoped>
.dashboard {
  &__wrapper {
    justify-content: flex-end;

    @media (max-width: 1400px) {
      justify-content: flex-start;
    }
  }

  &__user-wrapper {
    display: flex;
    position: fixed;
    top: 56px;
    z-index: 6;
    font-size: 12px;
    font-weight: 500;

    @media (max-width: 1400px) {
      position: static;
      z-index: auto;
      margin-top: 57px;
      flex-direction: column;
    }

    @media (max-width: 600px) {
      margin-top: 30px;
    }
  }

  &__user-box {
    display: flex;
    align-items: center;

    @media (max-width: 1700px) {
      flex-direction: column;
      margin-bottom: 16px;
    }

    @media (max-width: 1400px) {
      align-items: flex-start;
    }
  }

  &__name-wrapper {
    display: flex;
    align-items: flex-start;
    margin-right: 2rem;

    @media (max-width: 1700px) {
      margin-right: 0;
    }

    @media (max-width: 1400px) {
      margin-bottom: 1rem;
    }
  }

  &__user-name {
    font-family: var(--font-raleway);
    font-size: 16px;
    font-weight: 600;
    color: var(--v-primary-base);
    text-transform: uppercase;
    padding-top: 2px;
  }

  &__button {
    @include btn-settings(false);
    text-transform: none;
  }

  &__link {
    width: 100%;
    align-self: stretch;
    line-height: 3;
  }

  &__user-settings {
    margin-left: 40px;

    @media (max-width: 1400px) {
      margin-top: 12px;
      align-items: flex-start;
      margin-left: 0;
    }
  }

  &__text-wrapper {
    text-align: right;
  }

  &__switch-label {
    font-size: 12px;
    margin-right: 12px;
  }
}

.tabs-items {
  padding-top: 82px;

  @media (max-width: 1400px) {
    padding-top: 40px;
  }
}

.column {
  &__card {
    border-radius: 10px;
    padding: 30px;
  }
}
</style>

<style lang="scss">
.dashboard__switch .v-input__slot {
  flex-direction: row-reverse;
}
</style>
