export const boardSettingsItems = [
  'usersOfBoard',
  'gitRepositories',
  'specialLists',
  'boardHerald',
  'notifications',
  'reportReceivers',
  'notificationUsers',
  'boardPrefix',
  'commonSettings',
]

export const dateFormat = [
  'DD.MM.YY(YY) (-) DD.MM.YY(YY)',
  'DD.Mon(th).YY(YY) (-) DD.Mon(th).YY(YY)',
  'DD/MM/YY(YY) (-) DD/MM/YY(YY)',
  'DD/Mon(th)/YY(YY) (-) DD/Mon(th)/YY(YY)',
  'DD-MM-YY(YY) (-) DD-MM-YY(YY)',
  'DD-Mon(th)-YY(YY) (-) DD-Mon(th)-YY(YY)',
]
