<template lang="pug">
.docs
  EmptyEntities(v-if='!userId')
  v-container.docs__container.title--text.pa-0.pt-lg-15(v-else)
    v-row.flex-column-reverse.flex-md-row
      v-col.col-12.col-lg-4.offset-lg-1.d-flex.align-end
        #needAdmin.docs__subtitle {{ $lang.settingsOfBoard }}
          .docs__text {{ $lang.needAdmin }}
      v-col.col-12.col-md-10.col-lg-7.d-flex
        .img.d-flex.justify-center.align-center
          .img__star-wrapper
            v-img.img__star(
              :src='!isDark ? require("@/assets/img/docs/star.svg") : require("@/assets/img/docs/star-dark.svg")'
              :max-width='isMobile || isTablet ? 28 : 34'
              :max-height='isMobile || isTablet ? 28 : 34')
          .img__avatar.d-flex.align-center
            AvatarGroup(
              :users='users'
              :size='isMobile || isTablet ? 34 : 40'
              :margin='isMobile || isTablet ? "0 0 0 6px" : "0 0 0 8px"')
    v-row.mt-0.mb-16
      v-col.col-12.col-lg-11.offset-lg-1
        v-expansion-panels(flat)
          v-expansion-panel.backgroundMain
            v-expansion-panel-header.docs__hint-header.px-0.title--text {{ $lang.boardSettingsInfo }}
            v-expansion-panel-content
              template(v-for='(hint, idx) in boardSettingsHints')
                v-list-item.px-0(
                  :key='idx'
                  v-if='$lang[`${hint}_text`]')
                  v-list-item-content
                    v-list-item-title.docs__hint-title {{ $lang[hint] }}
                    v-list-item-subtitle.docs__hint-text {{ $lang[`${hint}_text`] }}
    v-row.docs__list
      v-col.col-lg-5
        .screen
          .screen__rect
          .screen__img
            v-img(
              :src='require("@/assets/img/docs/docs-screen.png")'
              position='center center')
      v-col.docs__list-text.col-12.col-md-10.col-lg-5
        #settingsList.docs__subtitle {{ $lang.settingsOfList }}
          .docs__text.d-flex.flex-column
            span(v-html='$lang.listSettingsInfo')
    v-row.pb-8
      v-col.col-12.col-lg-11.offset-lg-1.d-flex.flex-column
        #sprints.docs__subtitle {{ $lang.sprints }}
        span.docs__sprint-subtitle.mt-5.mb-3 {{ $lang.sprintInfoSubtitleRelis }}
        span.docs__text.mb-2(v-html='$lang.sprintInfoTextRelis')
        span.docs__text.mb-2.font-italic(v-html='$lang.sprintInfoFormatNote')
        span.docs__sprint-subtitle.mt-5.mb-3 {{ $lang.sprintInfoSubtitleDate }}
        span.docs__text.mb-2 {{ dateFormat[0] }}
        span.docs__text.mb-2 {{ dateFormat[1] }}
        span.docs__text.mb-2 {{ dateFormat[2] }}
        span.docs__text.mb-2 {{ dateFormat[3] }}
        span.docs__text.mb-2 {{ dateFormat[4] }}
        span.docs__text.mb-2 {{ dateFormat[5] }}
</template>

<script>
import { mapGetters } from 'vuex'
import { boardSettingsItems, dateFormat } from '@/helpers/settingsItems'

const EmptyEntities = () =>
  import(
    /* webpackChunkName: "EmptyEntities" */ '@/components/EmptyEntities.vue'
  )
const AvatarGroup = () =>
  import(
    /* webpackChunkName: "AvatarGroup" */ /* webpackMode: "eager" */ '@/components/AvatarGroup.vue'
  )

export default {
  name: 'DocumentationGeneral',
  components: {
    EmptyEntities,
    AvatarGroup,
  },
  data() {
    return {
      users: [
        { username: 'A', id: 1 },
        { username: 'A', id: 2 },
        { username: 'A', id: 3 },
        { username: 'A', id: 4 },
        { username: 'A', id: 5 },
      ],
    }
  },
  computed: {
    ...mapGetters(['userId', 'isMobile', 'isTablet', 'isDark']),
    boardSettingsHints() {
      return boardSettingsItems
    },
    dateFormat() {
      return dateFormat
    },
  },
}
</script>

<style lang="scss" scoped>
.docs {
  width: 100%;

  @media (max-width: 960px) {
    margin-top: 64px;
  }

  &__subtitle {
    font-size: 30px;
  }

  &__text {
    font-size: 17px;
    max-width: 75%;

    @media (max-width: 1500px) {
      max-width: 100%;
    }
  }

  &__list {
    margin-bottom: 120px;

    @media (max-width: 1500px) {
      justify-content: space-between;
    }

    @media (max-width: 1260px) {
      flex-direction: column;
      margin-bottom: 60px;
    }

    @media (max-width: 960px) {
      margin-bottom: 40px;
    }
  }

  &__list-text {
    margin-top: 64px;

    @media (max-width: 1260px) {
      margin-top: 120px;
    }

    @media (max-width: 960px) {
      margin-top: 72px;
    }
  }

  &__sprint-subtitle {
    font-size: 17px;
    font-weight: 700;
  }

  &__hint-header {
    font-size: 14px;
  }

  &__hint-title,
  &__hint-text {
    font-size: 17px;
  }

  &__hint-text {
    white-space: normal;
  }
}

.img {
  width: 100%;
  max-width: 450px;
  background: var(--v-backgroundReport-base);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 16px 4px;

  &__star-wrapper {
    position: relative;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background: var(--v-backgroundStar-base);
    margin-right: 32px;

    @media (max-width: 960px) {
      width: 60px;
      height: 60px;
      margin-right: 16px;
    }
  }

  &__star {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.screen {
  width: 100%;
  position: relative;

  &__rect {
    width: 235px;
    height: 188px;
    background: #eeeeee;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    @media (max-width: 960px) {
      height: 133px;
    }
  }

  &__img {
    position: absolute;
    top: 40px;
    left: 95px;
    max-width: 316px;

    @media (max-width: 960px) {
      top: 17px;
      left: 20%;
      max-width: 255px;
    }
  }
}
</style>

<style lang="scss">
.docs__hint-header .v-expansion-panel-header__icon {
  margin-left: 2rem !important;
}
</style>
