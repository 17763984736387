<template lang="pug">
div
  h4.title-2.mb-3
    v-tooltip(
      v-model='isShowPopup'
      bottom)
      template(v-slot:activator='{on}')
        v-icon(
          v-if='isCardRelized'
          v-on='on'
          color='success'
          small) {{ mdiStar }}
      span {{ $lang.doneTask }}

  v-row.flex-column-reverse.flex-md-row
    v-col.col-12.col-md-8.col-lg-9
      h3.card__card-title.grey--text {{ card.name }}
      LinkBox(
        :task='card'
        :boardId='$route.params.boardId')
      p.darkGrey--text.mt-2 {{ $lang.listsOfTask }}
        v-icon {{ mdiMenuDown }}
    v-col.col-12.col-md-4.col-lg-3.grey--text.d-flex.flex-column.align-start.align-md-end
      .card__time.text--text.ml-md-4.mb-2.pt-md-2 {{ $lang.timeInSprint }}: {{ card.work_time | dateTime($lang) }}
      .d-flex.justify-end.align-center
        AvatarGroup.justify-end(
          v-if='actualBoardMembers'
          :isTooltip='true'
          :users='membersBoard'
          :removedUsers='card.former_card_members'
          :size='35'
          :margin='"0 0 6px 6px"')
</template>

<script>
import { mdiMenuDown, mdiOpenInNew, mdiStar } from '@mdi/js'
import { createNamespacedHelpers, mapGetters } from 'vuex'
const { mapGetters: boardsMapGetters } = createNamespacedHelpers('boards')

const AvatarGroup = () =>
  import(
    /* webpackChunkName: "AvatarGroup" */ /* webpackMode: "eager" */ '@/components/AvatarGroup.vue'
  )
const LinkBox = () =>
  import(
    /* webpackChunkName: "LinkBox" */ /* webpackMode: "eager" */ '@/components/LinkBox.vue'
  )

export default {
  name: 'CardComponent',
  components: { AvatarGroup, LinkBox },
  props: {
    card: {
      type: Object,
      default: () => {},
    },
    sprint: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isShowPopup: false,
      mdiMenuDown,
      mdiOpenInNew,
      mdiStar,
    }
  },
  computed: {
    ...boardsMapGetters(['actualBoardMembers']),
    ...mapGetters(['isMobile', 'isTablet']),
    isCardRelized() {
      return this.card.work_lists.length
        ? this.sprint.some(
            (board) =>
              board.id ===
                this.card.work_lists[this.card.work_lists.length - 1].id &&
              board.role === 1
          )
        : false
    },
    membersBoard() {
      return (
        this.actualBoardMembers?.filter(
          (member) =>
            this.card.members && this.card.members.includes(member.trello_id)
        ) || []
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.link-wrapper {
  margin-left: 4px;
  text-decoration: none;

  & .link-icon {
    font-size: 18px;
  }
}

.card {
  &__card-title {
    display: inline;
    @include item-title;
    line-height: 1.2;
    margin-right: 8px;
  }

  &__time {
    font-size: 12px;
    font-weight: 700;
    text-align: end;
  }
}
</style>
