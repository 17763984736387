<template lang="pug">
div
  template(v-for='reportDate in reportDates')
    h3.report__title.title--text.d-block.font-weight-bold.py-3.ml-auto(
      :key='reportDate') {{ reportDate | ddMMYY($lang) }}
    v-divider
    v-card.backgroundMain.mb-3(
      v-for='report in reports[reportDate]'
      :key='report.id'
      flat)
      template(v-if='report.report_json.length')
        v-card-title.mt-5.py-2.px-0.py-sm-4.px-sm-0.d-flex.flex-column.align-start
          h4.report__card-title.title--text {{ report.board && report.board.name }}
          v-spacer
          .d-flex.flex-row.flex-wrap
            .report__subtitle.title--text(
              v-for='(specialList, index) in getBoardSpecialLists(report)'
              :key='report.id + "_" + specialList')
              span {{ getSpecialBoardListName(report, specialList) }}
                span {{ getSpecialBoardListCount(report, specialList) }}
                span(v-if='index < getBoardSpecialLists(report).length - 1') &nbsp;|&nbsp;
        v-expansion-panels(flat)
          v-expansion-panel.backgroundMain(
            v-for='task in report.report_json'
            :key='task.idShort')
            v-expansion-panel-header.px-0.py-sm-4.py-2.d-sm-flex
              ReportHeader(
                :task='task'
                :users='getMembersForReport(report.board.members, task.members)'
                :isClient='isClient'
                :membersHidden='report.members_hidden'
                :board='report.board')
            v-expansion-panel-content.report__subcard
              v-list.py-0.py-sm-2.backgroundMain.grey--text(dense)
                ReportText(
                  :task='task'
                  v-for='action in [...task.actions].reverse()'
                  :action='action'
                  :key='action.id')
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
const { mapGetters: boardsMapGetters } = createNamespacedHelpers('boards')

const ReportText = () =>
  import(
    /* webpackChunkName: "ReportText" */ /* webpackMode: "eager" */ '@/components/ReportText.vue'
  )
const ReportHeader = () =>
  import(
    /* webpackChunkName: "ReportHeader" */ /* webpackMode: "eager" */ '@/views/Reports/components/ReportHeader.vue'
  )

export default {
  name: 'ReportsContents',
  props: {
    reports: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ReportText,
    ReportHeader,
  },
  computed: {
    ...mapGetters(['isMobile', 'isTablet', 'isClient', 'isDark']),
    ...boardsMapGetters(['boards']),
    reportDates() {
      return Object.keys(this.reports)
    },
  },
  methods: {
    getMembersForReport(boardMembers, members) {
      return boardMembers
        ? boardMembers.filter(
            (member) => !!members.length && members.includes(member.trello_id)
          )
        : null
    },
    getSpecialBoardListCount(report, specialListId) {
      const count = report.special_counts[specialListId] || 0
      return `${count}`
    },
    getSpecialBoardListName(report, specialListId) {
      const list = report.board.board_lists.find(
        (list) => list.id === +specialListId
      )
      return `${list.name}: `
    },
    getBoardSpecialLists(report) {
      if (report.spesial_counts) {
        return Object.keys(report.special_counts)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.pre-wrap {
  white-space: pre-wrap;
}

.report {
  &__title {
    font-family: var(--font-raleway);
    font-size: 28px;
    font-weight: 700;
  }

  &__subtitle {
    font-size: 14px;
  }

  &__card-title {
    font-family: var(--font-raleway);
    font-size: 32px;
    font-weight: 700;
  }

  &__card-text {
    font-size: 14px;
  }
}
</style>

<style lang="scss">
.report__subcard.v-expansion-panel-content .v-expansion-panel-content__wrap {
  padding-left: 16px;
  padding-right: 16px;

  @media (max-width: 960px) {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
