<template lang="pug">
.flow
  .d-flex.align-center
    ChartRangeDatePicker(
      :minCalendarAvailableDate='minCalendarAvailableDate'
      :isError='isError'
      :initDates='initDates'
      :ttype='ttype'
      @initChartData='initChartData')

  v-chart.chart(
    v-if='!isError'
    :option='option'
    autoresize)
  .chart__empty(v-else) {{$lang.chartBoardError}}
</template>

<script>
import { mdiCalendar } from '@mdi/js'
import { createNamespacedHelpers, mapGetters, mapMutations } from 'vuex'
const { mapState: chartsMapState, mapActions: chartsMapActions } =
  createNamespacedHelpers('charts')
const { mapState: boardsMapState, mapActions: boardsMapActions } =
  createNamespacedHelpers('boards')

import { YMD } from '@/helpers/dateFormatter'
import { light, dark } from '@/helpers/themes'

const ChartRangeDatePicker = () =>
  import(
    /* webpackChunkName: "ChartRangeDatePicker" */ '@/views/Charts/components/ChartRangeDatePicker.vue'
  )

import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { LineChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from 'echarts/components'
import VChart, { INIT_OPTIONS_KEY } from 'vue-echarts'

use([
  TitleComponent,
  TooltipComponent,
  CanvasRenderer,
  LegendComponent,
  GridComponent,
  LineChart,
])

export default {
  name: 'StackedAreaChart',
  components: {
    VChart,
    ChartRangeDatePicker,
  },
  provide: {
    [INIT_OPTIONS_KEY]: {
      // height: '600',
    },
  },
  props: {
    id: {
      type: [String, Number],
    },
  },
  data: () => ({
    option: {},
    xAxisData: new Set(),
    seriesData: [],
    range: {},
    now: new Date(Date.now()).toISOString(),
    mdiCalendar,
    menu: false,
    isError: false,
    initDates: [],
    ttype: 'cards_count',
  }),
  computed: {
    ...mapGetters(['isDark', 'isMobile', 'isTablet', 'lang']),
    ...chartsMapState(['statCards']),
    ...boardsMapState(['currentBoard']),
    minCalendarAvailableDate() {
      let date = new Date()
      let year = date.getFullYear() - 1
      let month = (date.getMonth() + 1).toString()
      let day = (date.getDate() + 1).toString()

      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    colorStyle() {
      return this.isDark ? dark.title : light.title
    },
  },
  created() {
    this.range = this.getInitDateInterval()
    this.getInitData()
  },
  methods: {
    ...mapMutations(['ADD_NOTIFICATION']),
    ...chartsMapActions(['getStatCards']),
    ...boardsMapActions(['getBoard']),
    initChartData() {
      this.getDateRange()
      this.getSeries()
      this.setOption()
    },
    async getInitData() {
      const res = await this.getBoard(this.$route.params.id)
      if (!res?.error) {
        this.isError = false
        await this.getStatCards({
          range: this.range,
          board_id: this.$route.params.id,
          ttype: this.ttype,
        })
        this.initChartData()
      }
      if (res.error) {
        this.isError = true
        if (res.error?.detail) {
          this.ADD_NOTIFICATION({
            color: 'error',
            text: res.error.detail,
          })
        }
      }
    },
    getInitDateInterval() {
      const end = new Date()
      const start = new Date().setDate(new Date().getDate() - 30)
      this.initDates = [YMD(start), YMD(end)]
      return { start: YMD(start), end: YMD(end) }
    },
    getDateRange() {
      this.xAxisData.clear()
      this.statCards.forEach((item) => this.xAxisData.add(item['date']))
    },
    getDataByDate() {
      const obj = {}
      Array.from(this.xAxisData).forEach((date) => {
        const arr = this.statCards.filter((item) => item.date === date)
        obj[date] = arr
      })
      return obj
    },
    getSeries() {
      this.seriesData = []
      const columnNames = new Set()
      let column = {}
      let columnData = []
      this.statCards.forEach((item) => columnNames.add(item.board_name))

      const dataByDate = this.getDataByDate()
      Array.from(columnNames).forEach((name) => {
        for (let value of Object.values(dataByDate)) {
          const a = value.find((el) => el.board_name === name)
          a ? columnData.push(a.card_count) : columnData.push(0)
        }

        column = {
          type: 'line',
          stack: 'Cards',
          name: name,
          areaStyle: {},
          showSymbol: false,
          emphasis: {
            focus: 'series',
          },
          data: columnData,
        }

        this.seriesData.push(column)
        column = {}
        columnData = []
      })
    },
    setOption() {
      this.option = {
        grid: {
          bottom: '15%',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        legend: {
          textStyle: {
            color: this.colorStyle,
          },
          type: 'scroll',
          pageIconColor: this.colorStyle,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: [...this.xAxisData],
            axisLabel: {
              rotate: 90,
              color: this.colorStyle,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              color: this.colorStyle,
            },
          },
        ],
        series: this.seriesData,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.flow {
  width: 100%;
  // height: 100%;
  margin-top: 100px;

  @media (max-width: 600px) {
    margin-top: 20px;
  }
}

.chart {
  width: 100%;
  height: 600px;

  &__empty {
    display: flex;
    justify-content: center;
    padding: 40px 20px;
  }
}
</style>

<style lang="scss">
.chart__picker-input.v-text-field .v-label {
  color: var(--v-inactive-base);
}
</style>
