<template lang="pug">
.link-box
  .link-wrapper
    ExternalLink(
      :href='task.shortUrl'
      target='_blank'
      :text='$lang.goToTrello'
      :icon='mdiOpenInNew'
      iconColor='var(--v-main-base)'
      rel='noopener')
    ExternalLink(
      v-if='isCardLinkVisible'
      :href='`/cards/${task.id}`'
      target='_blank'
      :text='$lang.goToCard'
      :icon='mdiOpenInNew'
      rel='noopener')
  CopyAnimateComponent(
    :name='task.name'
    :actionData='actionData')
</template>

<script>
import { mdiOpenInNew } from '@mdi/js'
const ExternalLink = () =>
  import(
    /* webpackChunkName: "ExternalLink" */ /* webpackMode: "eager" */ '@/components/ExternalLink.vue'
  )
const CopyAnimateComponent = () =>
  import(
    /* webpackChunkName: "CopyAnimateComponent" */ /* webpackMode: "eager" */ '@/components/CopyAnimateComponent.vue'
  )

export default {
  name: 'LinkBox',
  components: {
    ExternalLink,
    CopyAnimateComponent,
  },
  props: {
    task: {
      type: Object,
      default: () => {},
    },
    isCardLinkVisible: {
      type: Boolean,
      default: true,
    },
    actionData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    mdiOpenInNew,
  }),
}
</script>

<style lang="scss" scoped>
.link-box {
  display: inline-block;
}
.link-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  margin: 1.5rem 0;

  /* устройства с мышью и тач-падом */
  @media (hover: hover) and (pointer: fine) {
    display: contents;
  }
}
</style>
