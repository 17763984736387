export const language = [
  { text: 'Русский', value: 'ru' },
  { text: 'English', value: 'en' },
]

export const deltaLabels = [
  '5m',
  '15m',
  '30m',
  '1h',
  '2h',
  '4h',
  '6h',
  '12h',
  '24h',
  '48h',
]

export const langs = ['en', 'ru']

export const localeValues = {
  ru: 'ru',
  en: 'en-US',
}
