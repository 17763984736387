import { render, staticRenderFns } from "./ReportReceiversSettings.vue?vue&type=template&id=74ca5564&scoped=true&lang=pug&"
import script from "./ReportReceiversSettings.vue?vue&type=script&lang=js&"
export * from "./ReportReceiversSettings.vue?vue&type=script&lang=js&"
import style0 from "./ReportReceiversSettings.vue?vue&type=style&index=0&id=74ca5564&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74ca5564",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAutocomplete,VBtn,VForm,VIcon,VList,VListItem,VListItemAction,VListItemContent,VRow})
