<template lang="pug">
.page-wrapper
  router-view
</template>

<script>
import config from '@/../config.json'

export default {
  name: 'HomePageLayout',
  created() {
    window.addEventListener('storage', this.eventHandler)
  },
  beforeDestroy() {
    window.removeEventListener('storage', this.eventHandler)
  },
  methods: {
    eventHandler() {
      if (localStorage.getItem('token-data')) {
        document.location.href = `${config.baseURL}/boards`
      }
    },
  },
}
</script>

<style lang="scss">
*,
::after,
::before {
  box-sizing: border-box;
}

* {
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
  object-fit: cover;
}

button {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

.page-wrapper {
  // colors for home page
  --base: #fff;
  --blue-light: #eaf4ff;
  --blue: #74b6f2;
  --main: #0075ff;
  --main-light: #098beb; // main2
  --grey-light: #f6f6f6;
  --grey: #bdbdbd;
  --grey-dark: #505155; //2dark
  --grey-lightest: #fbfbfb;
  --text: #666666;
  --title: #363636;
}
</style>
