<template lang="pug">
v-row.onboarding(
  no-gutters
  :style='componentStyle')
  v-tabs.onboarding__tabs(
    v-model='tab'
    hide-slider
    center-active
    background-color='backgroundMain'
    active-class='tabs__active')
    v-tab.tabs__tab(
      v-for='(i, idx) in 8'
      :key='i') {{ $lang.onboardingMenu[idx] }}
  v-tabs-items.tabs-items.onboarding__tabs-items.backgroundMain(v-model='tab')
    v-tab-item(
      v-for='(i, idx) in 8'
      :key='i')
      v-col.onboarding__tab-item.col-lg-10.py-0.px-lg-0
        h2.onboarding__title(
          v-if='$lang.onboardingTitle[idx].length'
          v-html='$lang.onboardingTitle[idx]')
        template(v-if='Array.isArray($lang.onboardingSubtitle[idx])')
          span.onboarding__subtitle.onboarding__subtitle--list(
            v-for='(_, index) in $lang.onboardingSubtitle[idx]'
            v-html='$lang.onboardingSubtitle[idx][index]')
        span.onboarding__subtitle.onboarding__subtitle--text(
          v-else
          v-html='$lang.onboardingSubtitle[idx]')
        span.onboarding__text(v-html='$lang.onboardingText[idx]')
        .onboarding__buttons-block
          a.setting__button-blue.onboarding__link(
            v-if='idx === 7'
            :href='`mailto:${email}`') {{ $lang.onboardingButton }}
        v-col.onboarding__image-wrapper
          img.onboarding__image(
            :src='require(`@/assets/img/onboarding/onboarding-${idx}.png`)')
</template>

<script>
import { email } from '@/../config.json'

export default {
  name: 'OnboardingComponent',
  data: () => ({
    tab: null,
    email,
    displayStyle: null,
    prev: null,
  }),
  computed: {
    componentStyle() {
      return {
        '--margin-left':
          this.displayStyle && this.displayStyle === 'none' ? '-12px' : '-52px',
      }
    },
  },
  mounted() {
    this.prev = document.querySelector('.v-slide-group__prev')
    setTimeout(() => {
      this.displayStyle = window
        .getComputedStyle(this.prev)
        .getPropertyValue('display')
    }, 100)

    this.resizeFnc()
  },
  methods: {
    resizeFnc() {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.contentBoxSize) {
            this.displayStyle = window
              .getComputedStyle(entry.target)
              .getPropertyValue('display')
          }
        }
      })

      resizeObserver.observe(this.prev)
    },
  },
}
</script>

<style lang="scss" scoped>
.onboarding {
  --font-size-title: 28px;
  --font-size-text: 22px;
  --margin: 40px;

  @media (max-width: 1500px) {
    --font-size-title: 20px;
    --font-size-text: 20px;
    --margin: 20px;
  }

  color: var(--v-title-base);

  &__tabs {
    margin-left: var(--margin-left);
  }

  &__tabs-items {
    width: 100%;
    margin-top: var(--margin);
  }

  &__tab-item {
    display: flex;
    flex-direction: column;
  }

  &__title,
  &__subtitle {
    font-weight: 400;
    font-size: var(--font-size-title);
  }

  &__title {
    margin-bottom: var(--margin);
  }
  &__text {
    margin-bottom: var(--margin);
  }

  &__subtitle,
  &__text {
    display: block;
  }

  &__subtitle {
    &--text {
      margin-bottom: var(--margin);
    }
  }

  &__text {
    font-size: var(--font-size-text);
    color: var(--v-primary-base);
  }

  &__buttons-block {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 70px;

    @media (max-width: 1500px) {
      margin-bottom: 20px;
    }
  }

  &__link {
    text-decoration: none;
    padding-left: 70px;
    padding-right: 70px;

    @media (max-width: $mobile) {
      padding-left: 55px;
      padding-right: 55px;
    }
  }

  &__image-wrapper {
    display: flex;
    padding: 0;
    justify-content: center;
  }

  &__image {
    max-width: inherit;
    align-self: center;
  }
}
</style>
