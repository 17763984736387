import request from '@/services/request'
import {
  apiToken,
  apiRegistration,
  apiTokenObtain,
  apiTokenRefresh,
  apiPasswordReset,
  apiPasswordResetConfirm,
  apiChangePassword,
  apiVerifyEmail,
  apiConfirmEmail,
  apiLogout,
} from '@/helpers/urls'
import getCookie from '@/helpers/getCookie'

const state = {
  userId: null,
  userMemberId: null,
  accessToken: '',
  refreshToken: '',
  accessExpirationAt: null,
  refreshExpirationAt: null,
  tokenData: null,
  isClient: null,
  lang: 'en',
  isUserStatus: null,
}

const getters = {
  userId: ({ userId }) => userId,
  userMemberId: ({ userMemberId }) => userMemberId,
  accessToken: ({ accessToken }) => accessToken,
  refreshToken: ({ refreshToken }) => refreshToken,
  accessExpirationAt: ({ accessExpirationAt }) => accessExpirationAt,
  refreshExpirationAt: ({ refreshExpirationAt }) => refreshExpirationAt,
  lang: ({ lang }) => lang,
  isClient: ({ isClient }) => isClient,
  isUserStatus: ({ isUserStatus }) => isUserStatus,
}

const actions = {
  login: async ({ commit, getters }, { username, password }) => {
    try {
      const data = await request({
        url: apiToken,
        method: 'POST',
        data: { username, password, settings: { lang: getters.lang } },
        isWithoutToken: true,
      })
      commit('SET_TOKENS', data)
      commit('SET_LANG', data.settings.lang || 'en')
      commit('SET_IS_DARK', data.settings.is_dark || false)
      commit('SET_AUTOSET_IS_DARK', data.settings.autoset_is_dark || false)
      localStorage.setItem('token-data', JSON.stringify(data))
      !data?.user_member_id ? commit('SET_MEMBER_ID_COOKIE') : null
      return data
    } catch (err) {
      console.log(err)
    }
  },
  registration: async (
    { commit },
    { username, email, password1, password2, signing_dumps, settings }
  ) => {
    try {
      return await request({
        url: apiRegistration,
        method: 'POST',
        data: {
          username,
          email,
          password1,
          password2,
          signing_dumps,
          settings,
        },
        isWithoutToken: true,
      })
    } catch (err) {
      commit(
        'ADD_NOTIFICATION',
        {
          color: 'error',
          text: err,
        },
        { root: true }
      )

      return { error: err }
    }
  },
  loginObtain: async ({ commit }) => {
    try {
      const data = await request({ url: apiTokenObtain, method: 'POST' })
      commit('SET_TOKENS', data)
      commit('SET_LANG', data.settings.lang || 'en')
      commit('SET_IS_DARK', data.settings.is_dark)
      commit('SET_AUTOSET_IS_DARK', data.settings.autoset_is_dark || false)
      localStorage.setItem('token-data', JSON.stringify(data))
      return data
    } catch (err) {
      console.log(err)
    }
  },
  logout: async ({ commit, dispatch }) => {
    try {
      await request({ url: apiLogout, method: 'POST' })
      commit('SET_TOKENS', false)
      dispatch('setLang', 'en', { root: true })
      dispatch('setIsDark', false, { root: true })
      commit('SET_AUTOSET_IS_DARK', true, { root: true })
      localStorage.clear()
    } catch (err) {
      console.log(err)
    }
  },
  refreshTokens: async ({ commit, state }) => {
    try {
      const data = {
        refresh: state.refreshToken,
      }
      const response = await request({
        url: apiTokenRefresh,
        method: 'POST',
        isRefresh: true,
        data,
      })
      const tokenData = {
        access: response.access,
        refresh: response.refresh,
        access_expiration_at: response.access_expiration_at,
        refresh_expiration_at: response.refresh_expiration_at,
        id: state.userId,
        user_member_id: state.userMemberId,
      }
      commit('SET_TOKENS', tokenData)
      localStorage.setItem('token-data', JSON.stringify(tokenData))
      return response
    } catch (err) {
      console.log(err)
    }
  },
  resetPassword: async ({ commit }, data) => {
    try {
      return await request({
        url: apiPasswordReset,
        method: 'POST',
        data,
        isWithoutToken: true,
      })
    } catch (err) {
      commit(
        'ADD_NOTIFICATION',
        {
          color: 'error',
          text: err,
        },
        { root: true }
      )

      return { error: err }
    }
  },
  confirmChangePassword: async (_, { password, token }) => {
    try {
      return await request({
        url: apiPasswordResetConfirm,
        method: 'POST',
        data: { password, token },
        isWithoutToken: true,
      })
    } catch (err) {
      console.log(err)
    }
  },
  changePassword: async (_, { password1, password2, oldPassword }) => {
    try {
      return await request({
        url: apiChangePassword,
        method: 'POST',
        data: {
          new_password1: password1,
          new_password2: password2,
          old_password: oldPassword,
        },
      })
    } catch (err) {
      return { error: err }
    }
  },
  setLang: ({ commit }, value) => {
    commit('SET_LANG', value)
    localStorage.setItem('lang', value)
  },
  confirmEmail: async ({ dispatch }) => {
    try {
      const data = await request({ url: apiVerifyEmail, method: 'POST' })
      dispatch('options/getOptions')
      return data
    } catch (err) {
      console.log(err)
    }
  },
  sendConfirmEmail: async (_, token) => {
    try {
      return await request({
        url: `${apiConfirmEmail}${token}/`,
        method: 'POST',
        isWithoutToken: true,
      })
      // добавить запись об подтвержденной почты
    } catch (err) {
      console.log(err)
    }
  },
}

const mutations = {
  SET_TOKENS: (state, data) => {
    if (!data) {
      state.userId = null
      state.userMemberId = null
      state.accessToken = ''
      state.refreshToken = ''
      state.accessExpirationAt = null
      state.refreshExpirationAt = null
      state.tokenData = null
      state.isClient = null
      state.isUserStatus = null
      localStorage.removeItem('token-data')
    } else {
      state.userId = data.id
      state.userMemberId = data.user_member_id
      state.accessToken = data.access
      state.refreshToken = data.refresh
      state.accessExpirationAt = data['access_expiration_at']
      state.refreshExpirationAt = data['refresh_expiration_at']
      state.isClient = data.user_boards_status === 'client' ? true : false
      state.isUserStatus =
        data.user_boards_status === 'not present' ? false : true
    }
  },
  SET_LANG: (state, value) => {
    state.lang = value
  },
  SET_ACCESS_TOKEN: (state, token) => {
    let date = new Date()
    const newDate = new Date(date.getTime() + 5 * 60000).getTime()
    state.accessToken = token
    state.accessExpirationAt = newDate
    state.refreshExpirationAt = newDate
  },
  SET_MEMBER_ID_COOKIE: (state) => {
    state.userMemberId = getCookie('member_id')
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
