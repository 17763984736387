<template lang="pug">
v-container.fill-height(
  v-if='isLoaded'
  fluid)
  v-row.align-self-start(align='start')
    v-col(
      cols='12'
      sm='8'
      md='4')
      v-btn(:to='{ name: "home" }') {{ $lang.toMainPage }}
  v-row.align-self-start(
    align='center'
    justify='center')
    v-col.text-center(
      cols='12'
      sm='8'
      md='4')
      | {{ isConfirmEmail ? $lang.confirmEmail : $lang.notConfirmEmail }}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ConfirmEmail',
  data() {
    return {
      token: undefined,
      isLoaded: false,
    }
  },
  created() {
    this.isLoaded = false
    this.token = this.$route.params.token
    this.$store.dispatch('sendConfirmEmail', this.token).finally(() => {
      this.isLoaded = true
    })
  },
  computed: {
    ...mapGetters(['isConfirmEmail']),
  },
}
</script>

<style scoped></style>
