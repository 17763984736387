<template lang="pug">
.pt-3
  v-form(@submit.prevent='changePrefix')
    v-text-field(
      v-model.trim='vPrefix'
      ref='inputRef'
      :label='$lang.prefixInputLabel'
      :disabled='isDeletePrefix'
      :error-messages='prefixError'
      @focus='touchInput'
      @input='validate')
    v-checkbox(
      v-model='isDeletePrefix'
      :label='$lang.deletePrefix'
      :disabled='!vPrefix')
    .prefix__checkbox-wrapper.mb-8
      v-checkbox(
        v-model='applyToAll'
        :label='$lang.applyToAll')
      span.prefix__hint-prefix.title--text {{ applyToAll && !isDeletePrefix ? $lang.hintPrefix : applyToAll && isDeletePrefix ? $lang.hintDeletePrefix : "" }}
      span.prefix__hint-prefix.title--text {{ applyToAll && !isDeletePrefix ? $lang.hintPrefixTimeout : applyToAll && isDeletePrefix ? $lang.hintDeletePrefixTimeout : "" }}
    button.setting__button-blue.mb-4(
      type='submit'
      :disabled='isButtonDisabled') {{ currentPrefix.length ? $lang.saveChange : $lang.add }}
    button.setting__button-white.ml-3(@click.prevent='clearForm') {{ $lang.clearForm }}
</template>

<script>
import { mapMutations, createNamespacedHelpers } from 'vuex'
const { mapActions: boardMapActions } = createNamespacedHelpers('boards')

export default {
  name: 'PrefixSettings',
  props: {
    boardId: {
      type: Number,
    },
    currentPrefix: {
      type: String,
    },
  },
  data: () => ({
    prefix: null,
    applyToAll: false,
    isDeletePrefix: false,
    prefixError: null,
    isSetNewPrefix: false,
    isValid: true,
    regExp: /^[A-Za-z0-9]{1,10}$/,
    isInputTouched: false,
  }),
  computed: {
    vPrefix: {
      get() {
        return this.currentPrefix
      },
      set(val) {
        this.prefix = val
      },
    },
    isButtonDisabled() {
      return !this.isDeletePrefix && !this.isValid
    },
  },
  created() {
    this.prefix = this.currentPrefix
  },
  methods: {
    ...boardMapActions(['changeBoardPrefix']),
    ...mapMutations(['ADD_NOTIFICATION']),
    async changePrefix() {
      let payload

      if (!this.isDeletePrefix && this.isValid) {
        payload = {
          data: {
            new_prefix: this.prefix,
          },
          method: 'POST',
          boardId: this.boardId,
        }
      } else if (this.isDeletePrefix) {
        payload = {
          data: {},
          method: 'DELETE',
          boardId: this.boardId,
        }
      }

      if (this.applyToAll) {
        payload.data['apply_to_all'] = this.applyToAll
      }

      const res = await this.changeBoardPrefix(payload)
      if (!res) {
        this.clearCheckbox()
        this.prefixError = null
        this.ADD_NOTIFICATION({
          color: 'success',
          text: this.$lang.pefixSavedSuccess,
        })
      } else {
        const text = res?.detail ? res.detail : this.$lang.prefixSaveError
        this.ADD_NOTIFICATION({
          color: 'error',
          text,
        })
      }
    },
    clearForm() {
      this.$refs.inputRef.clearableCallback()
      this.applyToAll = false
      this.isDeletePrefix = false
    },
    clearCheckbox() {
      this.applyToAll = false
      this.isDeletePrefix = false
    },
    validate() {
      if (this.isDeletePrefix) {
        this.prefixError = null
        this.isValid = true
        return
      }

      if (this.prefix && this.regExp.test(this.prefix)) {
        this.isValid = true
        this.prefixError = null
      } else {
        this.isValid = false
        this.prefixError = this.prefix
          ? this.$lang.prefixInputWarnings[1]
          : this.$lang.prefixInputWarnings[0]
      }
    },
    touchInput() {
      this.isInputTouched = true
    },
  },
}
</script>

<style lang="scss" scoped>
.prefix__checkbox-wrapper {
  display: flex;
  flex-direction: column;
}

.prefix__hint-prefix {
  padding-left: 2rem;
  padding-bottom: 1rem;
}
</style>
