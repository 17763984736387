import objectPath from 'object-path'

export default {
  methods: {
    actionInfo(action, task = {}) {
      switch (action.type) {
        case 'createCard':
          return {
            title: this.$lang.createTask,
            actionAuthor: this.getAuthor(action),
          }
        case 'updateCard': {
          const isMove = !!objectPath.get(action.data, 'old.idList')

          if (isMove) {
            return {
              title: this.$lang.moveTask,
              from: objectPath.get(action.data, 'listBefore.name'),
              to: objectPath.get(action.data, 'listAfter.name'),
              actionAuthor: this.getAuthor(action),
            }
          }

          if (task.deleted) {
            return {
              title: this.$lang.deleteTask,
              actionAuthor: this.getAuthor(action),
            }
          }

          if (!task.deleted && task.closed) {
            return {
              title: this.$lang.archiveTask,
              actionAuthor: this.getAuthor(action),
            }
          }

          return {
            title: this.$lang.updateTask,
            actionAuthor: this.getAuthor(action),
          }
        }
        case 'commentCard':
          return {
            title: this.$lang.commentTask,
            actionAuthor: this.getAuthor(action),
          }
        case 'deleteCard':
          return {
            title: this.$lang.deleteTask,
            actionAuthor: this.getAuthor(action),
          }
        case 'addMemberToCard':
          return {
            title: this.$lang.addMemberToTask,
            actionAuthor: this.getAuthor(action),
          }
        case 'removeMemberFromCard':
          return {
            title: this.$lang.removeMemberFromTask,
            actionAuthor: this.getAuthor(action),
          }
        case 'copyCard':
          return {
            title: this.$lang.copyTask,
            actionAuthor: this.getAuthor(action),
          }
        case 'convertToCardFromCheckItem':
          return {
            title: this.$lang.convertTask,
            actionAuthor: this.getAuthor(action),
          }
        default:
          return { title: action.type, text: null }
      }
    },
    getAuthor(action) {
      return objectPath.get(action.member, 'full_name')
        ? objectPath.get(action.member, 'full_name')
        : objectPath.get(action.member, 'username')
        ? objectPath.get(action.member, 'username')
        : null
    },
  },
}
