import request from '@/services/request'
import { apiCards } from '@/helpers/urls'

const state = {
  card: null,
  loading: false,
}

const mutations = {
  setCardData: (state, payload) => {
    state.card = payload
  },
  setLoading: (state, payload) => {
    state.loading = payload
  },
}

const actions = {
  getCardData: async ({ commit }, cardId) => {
    commit('setLoading', true)
    try {
      const data = await request({
        url: `${apiCards}${cardId}/`,
        method: 'GET',
        params: {
          for_detail_page: true,
        },
      })
      commit('setLoading', false)
      commit('setCardData', data)
    } catch (err) {
      commit('setLoading', false)
      console.log(err)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
