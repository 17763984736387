<template lang="pug">
v-tooltip(
  bottom
  v-model='isTooltipVisible'
  z-index='10')
  template.tooltip-text(v-slot:activator='{on, attrs}')
    v-btn.animate__button.animate__hide(
      icon
      width='24'
      height='24'
      @click.stop='copyText(clipBoardText)'
      v-on='on'
      :aria-label='$lang.copyToClipboard')
      IconCopyAnimate(
        :copied='copied'
        width='22'
        height='22')
      transition(name='tooltip')
        .animate__tooltip(v-if='copied') {{ $lang.copied }}
  span {{ $lang.copyToClipboard }}
</template>

<script>
const IconCopyAnimate = () =>
  import(
    /* webpackChunkName: "IconCopyAnimate" */ /* webpackMode: "eager" */ '@/components/IconCopyAnimate.vue'
  )

export default {
  name: 'CopyAnimateComponent',
  components: {
    IconCopyAnimate,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    actionData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isTooltipVisible: false,
    copied: false,
    timeoutID: null,
  }),
  computed: {
    actionDataText() {
      const before = this.actionData?.listBefore?.name || ''
      const after = this.actionData?.listAfter?.name || ''
      return before && after ? `| ${before} -> ${after}` : ''
    },
    clipBoardText() {
      return `${this.name} ${this.actionDataText}`
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeoutID)
  },
  methods: {
    copyText(text) {
      const cb = navigator.clipboard
      cb.writeText(text).then(() => {
        this.isTooltipVisible = false
        this.copied = true

        this.timeoutID = setTimeout(() => {
          this.copied = false
        }, 600)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.animate {
  &__button {
    position: relative;
    margin-bottom: 6px;
  }

  &__tooltip {
    position: absolute;
    z-index: -10;
    top: -130%;
    left: 50%;
    padding: 0;
    border-radius: 15px;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    // background: var(--v-backgroundMain-base);
    transform: translateX(-50%);
    opacity: 0;
    color: var(--v-main-base);
    text-transform: none;
  }
}

/* тач-устройства смартфоны, планшеты */
.animate__hide {
  @media (pointer: coarse) {
    display: none;
  }
}

.tooltip-enter-active {
  transition: all 1600ms;
}

.tooltip-enter {
  z-index: 10;
  top: -50%;
  padding: 4px;
  opacity: 1;
}
</style>
