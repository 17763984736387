<template lang="pug">
.relative
  v-overlay(
    absolute
    :value='!isLoaded')
    v-progress-circular(indeterminate)
  .text-md-center.pa-5(v-show='isLoaded && !columnCards.length')
    span {{ $lang.emptyList }}
  div(v-if='isLoaded && columnCards')
    template(v-for='card in columnCards')
      v-card.card.backgroundMain(
        tile
        :key='card.id'
        elevation='0')
        v-row.card__wrapper(no-gutters)
          .card__title-box
            v-col.card__title-wrapper.col-md-9.px-0.pb-0
              h3.card__title.grey--text
                span.card__title-item {{ card.name || $lang.noTitle }}
                a.link-wrapper(
                  :href='card.shortUrl'
                  target='_blank')
                  v-icon.link-icon {{ mdiOpenInNew }}
            v-col.card__time-wrapper.col-md-3.px-0.pb-0
              p.card__subtext.grey--text.pt-2 {{ $lang.totalWorkTime }}: {{ card.work_time | dateTimeShort($lang) }}
          CardLabels.card__labels.mb-4(:card='card')
          .card__group.card__group--chronology
            .card__subtitle-wrapper.mt-4.pa-0
              .card__subtitle.text--text.py-1.pr-3 {{ $lang.chronology }}
              v-divider
            v-col.col-md-9.pa-0
              BoardColumnTable(:card='card')
          .card__group.card__group--avatar
            v-col.card__subtitle-wrapper.card__subtitle-wrapper--avatar.mt-4.pa-0
              .card__subtitle.text--text.py-1.pr-3 {{ $lang.assignee }}
              v-divider
            v-col.card__avatar.px-0
              AvatarGroup(
                :fontSize='"13px"'
                :size='32'
                :users='getCardMembers(card.members)'
                :removedUsers='card.former_card_members'
                :margin='"0 8px 0 0"')
        v-divider.mb-10
</template>

<script>
import { mdiOpenInNew } from '@mdi/js'
import { createNamespacedHelpers, mapGetters } from 'vuex'
const { mapState: boardsMapState } = createNamespacedHelpers('boards')

const AvatarGroup = () =>
  import(
    /* webpackChunkName: "AvatarGroup" */ /* webpackMode: "eager" */ '@/components/AvatarGroup.vue'
  )
const BoardColumnTable = () =>
  import(
    /* webpackChunkName: "BoardColumnTable" */ /* webpackMode: "eager" */ '@/views/Boards/BoardColumnTable.vue'
  )
const CardLabels = () =>
  import(
    /* webpackChunkName: "CardLabels" */ /* webpackMode: "eager" */ '@/components/CardLabels.vue'
  )

export default {
  name: 'BoardColumn',
  components: {
    AvatarGroup,
    BoardColumnTable,
    CardLabels,
  },
  props: {
    columnId: { type: Number },
    isLoaded: { type: Boolean, default: false },
    isShowArchive: { type: Boolean, default: false },
  },
  data() {
    return {
      reg: /https?:\/\/[\w*.]*.*(?=%[\w|\d]{2})/g,
      mdiOpenInNew,
    }
  },
  computed: {
    ...mapGetters(['isMobile', 'isTablet']),
    ...boardsMapState(['lists', 'currentBoard']),

    column() {
      return this.lists.find((l) => l.id === this.columnId)
    },
    columnCards() {
      let list = []
      if (!this.isShowArchive) {
        list =
          this.column?.cards.filter(
            (card) =>
              !card.deleted &&
              !card.locations[card.locations.length - 1]?.archive
          ) || []
      } else {
        list = this.column?.cards || []
      }
      return list
    },
  },
  methods: {
    findUrls(text) {
      let source = (text || '').toString()
      let urlArray = []
      let matchArray
      // Regular expression to find FTP, HTTP(S) and email URLs.
      // eslint-disable-next-line
      const regexToken = /(((ftp|https?):\/\/)[\-\w@:%_\+.~#?,&\/\/=]+)/g
      // Iterate through any URLs in the text.
      while ((matchArray = regexToken.exec(source)) !== null) {
        let token = matchArray[0]
        urlArray.push(token)
      }
      return urlArray
    },
    decoder(text) {
      try {
        return text.length && this.reg.test(text) ? decodeURI(text) : text
      } catch (e) {
        return text
      }
    },
    getCardMembers(members) {
      return (
        this.currentBoard.all_time_members.filter((user) =>
          members.includes(user.trello_id)
        ) || []
      )
      // return this.currentBoard.members.filter(user => members.includes(user.trello_id));
    },
    getTotalWorkTime(location) {
      let end = location.ends_at ? +new Date(location.ends_at) : +new Date()
      let start = location.starts_at
      return (end - start) / 1000
    },
    getCardWorkTime(card) {
      card.locations.reduce(
        (acc, current) => acc + this.getTotalWorkTime(current),
        0
      )
    },
    getCardDescription(description) {
      if (!description) return '...'
      let urlArray = this.findUrls(description)
      urlArray.forEach((url) => {
        let temp = description.split(url)
        const decodeUrl = this.decoder(url)
        description = temp.join(
          '<a href="' + url + '" target="_blank">' + decodeUrl + '</a>'
        )
      })
      return description
    },
    checkIsArchive(card) {
      const lastLocation = card.locations[card.locations.length - 1]
      return lastLocation ? lastLocation.archive : true
    },
  },
}
</script>

<style lang="scss" scoped>
.description-text {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.link-wrapper {
  margin-left: 4px;
  text-decoration: none;

  & .link-icon {
    font-size: 18px;
  }
}

.card {
  &__wrapper {
    margin-bottom: 24px;
    flex-direction: column;

    @media (max-width: 959px) {
      margin-bottom: 12px;
    }
  }

  &__title-box {
    display: flex;

    @media (max-width: 959px) {
      display: contents;
    }
  }

  &__title {
    overflow-wrap: break-word;
    margin-bottom: 0;
    font-size: 20px;
    line-height: 1.2;
  }

  &__title-item {
    @include item-title;
    line-height: 1.2;
    letter-spacing: 0.38px;
    word-break: break-all;
  }

  &__title-wrapper {
    @media (max-width: 959px) {
      order: 3;
    }
  }

  &__time-wrapper {
    @media (max-width: 959px) {
      order: 1;
      padding-bottom: 0;
    }
  }

  &__subtext,
  &__subtitle {
    font-size: 12px;
    line-height: 1.3;
  }

  &__subtext {
    font-weight: 700;
    text-align: end;

    @media (max-width: 959px) {
      text-align: start;
    }
  }

  &__subtitle-bcg {
    display: flex;
  }

  &__subtitle {
    margin-top: -12px;
    font-weight: 400;
  }

  &__labels {
    @media (max-width: 959px) {
      order: 2;
    }
  }

  &__group {
    flex-direction: column;

    @media (max-width: 959px) {
      &--avatar {
        order: 4;
        margin-top: 0;
      }

      &--chronology {
        order: 5;
      }
    }
  }

  &__subtitle-wrapper {
    display: flex;

    &--avatar {
      @media (max-width: 959px) {
        display: none;
      }
    }
  }

  &__avatar {
    margin-top: 8px;

    @media (max-width: 959px) {
      margin-top: 0;
      margin-bottom: 8px;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}
</style>
