<template lang="pug">
.terms-text(v-html='termsText')
</template>

<script>
import { mapGetters } from 'vuex'
import { termsRu, termsEn } from '@/data/termsText'

export default {
  name: 'TermsPage',
  data: () => ({
    termsRu,
    termsEn,
  }),
  computed: {
    ...mapGetters(['lang']),
    termsText() {
      return this.lang === 'ru'
        ? termsRu
        : termsEn.length === 0
        ? termsRu
        : termsEn
    },
  },
}
</script>

<style lang="scss" scoped>
.terms-text {
  --container-width: 1180px;

  max-width: var(--container-width);
  padding: 60px 12px;
  margin: 0 auto;
}
</style>
