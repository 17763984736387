<template lang="pug">
v-container.auth-wrapper.justify-center.pt-0
  AuthHeader
  v-row.align-end.align-sm-center.justify-sm-end
    v-col.col-12.col-sm-8.col-md-5.col-lg-5.pa-0.mt-14
      v-form.form.d-flex.flex-column(
        v-model='validForm'
        @submit.prevent='submit')
        .d-flex.flex-column(v-if='!isSuccess')
          h3.form__title.title--text.mb-5 {{ $lang.resetPassword }}
          .form__input-wrapper
            v-text-field(
              label='E-mail'
              type='email'
              required
              v-model='email'
              :rules='emailRules'
              :prepend-inner-icon='mdiAccountCircle'
              @focus='error = ""')
            small.form__error {{ error }}
          button.form__btn.primary(
            type='submit'
            :disabled='!validForm') {{ $lang.resetPassword }}
          .form__btn-wrapper.d-flex.flex-wrap.justify-center
            v-btn.form__auth-btn(
              :to='{name: "login"}'
              text
              color='title') {{ $lang.login }}
        .stub(v-else)
          v-img.mb-10.ml-3(
            :src='require("@/assets/img/success.svg")'
            max-width='60'
            max-height='46')
          .stub__text-wrapper.mr-sm-10
            h3.form__title.title--text.mb-5 {{ $lang.mailHasBeenSendedTitle }}
            p.stub__text.text--text {{ $lang.mailHasBeenSended }}
        .mt-14
          v-select.lang-select(
            v-model='vLang'
            :items='language'
            hide-details
            outlined)
</template>

<script>
import { mdiAccountCircle } from '@mdi/js'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { language } from '@/helpers/constants'

const AuthHeader = () =>
  import(
    /* webpackChunkName: "AuthHeader" */ /* webpackMode: "eager" */ '@/views/Auth/components/AuthHeader.vue'
  )

// const locales = {
//   en: require('@/locale/en.json'),
// }

export default {
  name: 'ForgotPassword',
  components: {
    AuthHeader,
  },
  data() {
    return {
      validForm: false,
      emailRules: [
        (v) => !!v || this.$lang.required,
        (v) => /.+@.+/.test(v) || this.$lang.invalidEmail,
      ],
      nonFieldError: null,
      email: '',
      isSuccess: false,
      language,
      mdiAccountCircle,
      error: '',
    }
  },
  computed: {
    ...mapGetters(['lang', 'isMobile']),
    vLang: {
      get() {
        return this.lang
      },
      set(value) {
        this.$store.dispatch('setLang', value)
        this.$setLang(value)
        this.$vuetify.lang.current = value
      },
    },
  },
  methods: {
    ...mapMutations(['ADD_NOTIFICATION']),
    ...mapActions(['resetPassword']),
    async submit() {
      this.nonFieldError = ''
      const res = await this.resetPassword({
        email: this.email,
        settings: { lang: this.lang },
      })
      if (res.status) {
        this.email = ''
        this.isSuccess = true
      } else if (res.error) {
        this.isSuccess = false
        this.error = res.error.email[0]
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.auth-wrapper {
  max-width: 1170px;

  @media (max-width: 600px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.form {
  @include auth-form;

  &__btn-wrapper {
    padding: 0 42px;
  }

  &__btn,
  &__auth-btn {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: normal;
  }

  &__btn {
    @include btn-common(center);
    margin: 21px 0 11px;
  }

  &__auth-btn {
    text-transform: lowercase !important;
    padding: 0 !important;
  }

  &__title {
    font-family: var(--font-raleway);
    font-size: 30px;
    font-weight: 700;
  }

  &__input-wrapper {
    position: relative;
  }

  &__error {
    position: absolute;
    height: 1.2rem;
    color: red;
    bottom: 0;
  }
}

.stub {
  padding: 14px 0 4px;

  &__text {
    font-size: 18px;
    line-height: 1.2;
  }
}
</style>
