export const imagesMapped = {
  desktop: [
    { src: 'desktop.avif', type: 'image/avif' },
    { src: 'desktop.webp', type: 'image/webp' },
    { src: 'desktop.png', type: 'image/png' },
  ],
  features: [
    { src: 'features.avif', type: 'image/avif' },
    { src: 'features.webp', type: 'image/webp' },
    { src: 'features.png', type: 'image/png' },
  ],
  featuresMb: [
    { src: 'features-mb.avif', type: 'image/avif' },
    { src: 'features-mb.webp', type: 'image/webp' },
    { src: 'features-mb.png', type: 'image/png' },
  ],
  qrq: [
    { src: 'qrq.avif', type: 'image/avif' },
    { src: 'qrq.webp', type: 'image/webp' },
    { src: 'qrq.png', type: 'image/png' },
  ],
  screenOne: [
    { src: 'screen-1.avif', type: 'image/avif' },
    { src: 'screen-1.webp', type: 'image/webp' },
    { src: 'screen-1.png', type: 'image/png' },
  ],
  screenTwo: [
    { src: 'screen-2.avif', type: 'image/avif' },
    { src: 'screen-2.webp', type: 'image/webp' },
    { src: 'screen-2.png', type: 'image/png' },
  ],
  screenThree: [
    { src: 'screen-3.avif', type: 'image/avif' },
    { src: 'screen-3.webp', type: 'image/webp' },
    { src: 'screen-3.png', type: 'image/png' },
  ],
  googlePlay: [
    { src: 'google-play.avif', type: 'image/avif' },
    { src: 'google-play.webp', type: 'image/webp' },
    { src: 'google-play.png', type: 'image/png' },
  ],
}
