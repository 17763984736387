export const errorFormatter = (errorResponse) => {
  let errorText = ''

  for (const item in errorResponse) {
    errorText += Array.isArray(errorResponse[item])
      ? errorResponse[item].join(' ')
      : `${errorResponse[item]} `
  }
  return errorText
}
