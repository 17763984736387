export const termsRu = `
  <h1>Условия использования</h1>

  <p>
  1.Текст условий использования
  </p>

  <p>
  2. Текст условий использования
  </p>
  `

export const termsEn = []
