import request from '@/services/request'
import {
  apiBoards,
  apiBoardsProgressList,
  apiBoardsIsAdmin,
  apiBoardsInvite,
  apiLists,
  endGit,
  apiAlertsVariants,
  endProgress,
  endHeralds,
  apiAlerts,
  endUsers,
  apiBoardsUnsubscribeNewsletter,
  endBoardPrefix,
  endTimeTracking,
  endObserverStatus,
  endSyncBoards,
  endSyncBoardsRoughly,
  apiUserBoards,
  apiAvailableTrelloBoards,
} from '@/helpers/urls'

const state = {
  boards: [],
  currentBoard: null,

  lists: [],
  specialLists: [],

  isOpenListSettingsModal: false,

  boardsMembers: [],
  boardUsers: [],
  boardClients: [],
  boardAdmins: [],
  boardObservers: [],

  adminBoards: [],

  boardHeralds: [],
  boardReportReceivers: [],
  boardActiveHerald: null,

  boardGits: [],
  boardSpecialLists: [],
  boardProgress: 0,
  boardsProgress: {},
  boardsNotification: undefined,
  subscribeBoards: [],
  boardSubscribeMembers: undefined,
  actualBoardMembers: null,
  gitTracking: null,

  alertsVariants: undefined,
  alertsBoard: undefined,

  userBoards: [],
  availableTrelloBoards: null,
  isLoadedAvailableTrelloBoards: false,
}

const getters = {
  boards: ({ boards }) => boards,
  boardsMembers: ({ boardsMembers }) => boardsMembers,
  boardUsers: ({ boardUsers }) => boardUsers,
  boardClients: ({ boardClients }) => boardClients,
  boardAdmins: ({ boardAdmins }) => boardAdmins,
  adminBoards: ({ adminBoards }) => adminBoards,
  currentBoard: ({ currentBoard }) => currentBoard,
  lists: ({ lists }) => lists,
  specialLists: ({ specialLists }) => specialLists,
  boardObservers: ({ boardObservers }) => boardObservers,

  //settings boards
  boardHeralds: ({ boardHeralds }) => boardHeralds,
  boardActiveHerald: ({ boardActiveHerald }) => boardActiveHerald,
  boardGits: ({ boardGits }) => boardGits,

  boardSpecialLists: ({ boardSpecialLists }) => boardSpecialLists,
  boardsNotification: ({ boardsNotification }) => boardsNotification,
  boardReportReceivers: ({ boardReportReceivers }) => boardReportReceivers,

  alertsVariants: ({ alertsVariants }) => alertsVariants,
  alertsBoard: ({ alertsBoard }) => alertsBoard,

  isOpenListSettingsModal: ({ isOpenListSettingsModal }) =>
    isOpenListSettingsModal,

  boardProgress: (state) => state.boardProgress,
  boardsProgress: ({ boardsProgress }) => boardsProgress,

  subscribeBoards: ({ subscribeBoards }) => subscribeBoards,
  boardSubscribeMembers: ({ boardSubscribeMembers }) => boardSubscribeMembers,
  actualBoardMembers: ({ actualBoardMembers }) => actualBoardMembers,
}

const actions = {
  // BOARDS
  getBoards: async ({ commit }) => {
    try {
      const data = await request({ url: apiBoards, method: 'GET' })
      commit('SET_BOARDS', data)
      return data
    } catch (err) {
      console.log(err)
    }
  },
  getBoardsProgress: async ({ commit }) => {
    try {
      const data = await request({ url: apiBoardsProgressList, method: 'POST' })
      commit('SET_BOARDS_PROGRESS', data)
      return data
    } catch (err) {
      console.log(err)
    }
  },
  getBoardsAdmin: async ({ commit }) => {
    try {
      const data = await request({ url: apiBoardsIsAdmin, method: 'GET' })
      commit('SET_BOARDS_ADMIN', data)
      return data
    } catch (err) {
      console.log(err)
    }
  },

  // BOARD
  getBoard: async ({ commit }, boardId) => {
    try {
      const data = await request({
        url: `${apiBoards}${boardId}/`,
        method: 'GET',
      })
      commit('SET_BOARD', data)
      commit('SET_BOARD_SPECIAL_LIST', data.special_lists)
      commit('SET_REPORT_RECEIVERS', data.reports_receivers)
      commit('SET_SUBSCRIBE_BOARD_MEMBERS', data.members_emails)
      commit('SET_ALERTS_BOARD', data.alert_times)
      commit('SET_ACTUAL_BOARD_MEMBERS', data.all_time_members)
      commit('SET_GIT_TRACKING', data.settings.git_tracking)
      return data
    } catch (err) {
      return { error: err }
    }
  },
  getBoardProgress: async ({ commit }, boardId) => {
    try {
      const data = await request({
        url: `${apiBoards}${boardId}${endProgress}`,
        method: 'POST',
      })
      commit('SET_BOARD_PROGRESS', data)
      return data
    } catch (error) {
      console.log(error)
    }
  },
  postBoardInvite: async (_, { signingDumps }) => {
    try {
      const data = await request({
        url: apiBoardsInvite,
        method: 'POST',
        isWithoutToken: true,
        data: { signing_dumps: signingDumps },
      })
      return data
    } catch (err) {
      console.log(err)
    }
  },

  // BOARD LISTS
  getBoardList: async ({ commit }, listId) => {
    try {
      const data = await request({
        url: `${apiLists}${listId}/`,
        method: 'GET',
      })
      commit('SET_BOARD_LIST', data)
      return data
    } catch (err) {
      console.log(err)
    }
  },
  setSettingsBoardList: async (
    { dispatch },
    { listId, role, showInProgress, boardId }
  ) => {
    try {
      await request({
        url: `${apiLists}${listId}/`,
        method: 'PATCH',
        data: { role, show_in_progress: showInProgress },
      })
      dispatch('getBoard', boardId)
      return true
    } catch (err) {
      console.log(err)
    }
  },
  setTimeTracking: async (ctx, { listId, count_time }) => {
    try {
      await request({
        url: `${apiLists}${listId}${endTimeTracking}`,
        method: 'POST',
        data: { count_time },
      })
      return true
    } catch (err) {
      console.log(err)
    }
  },

  // BOARDS SETTINGS
  patchSettingsBoard: async ({ commit }, payload) => {
    try {
      const { boardId, ...settings } = payload
      const data = await request({
        url: `${apiBoards}${boardId}/`,
        method: 'PATCH',
        data: { ...settings },
      })
      commit('SET_BOARD', data)
      commit('SET_BOARD_SPECIAL_LIST', data.special_lists)
      commit('SET_REPORT_RECEIVERS', data.reports_receivers)
      commit('SET_SUBSCRIBE_BOARD_MEMBERS', data.members_emails)
      commit('SET_ALERTS_BOARD', data.alert_times)
      commit('SET_GIT_TRACKING', data.settings.git_tracking)
      return data
    } catch (err) {
      console.log(err)
    }
  },
  getBoardHeralds: async ({ commit }, boardId) => {
    try {
      const data = await request({
        url: `${apiBoards}${boardId}${endHeralds}`,
        method: 'GET',
      })
      commit('SET_BOARD_HERALDS', data)
      return data
    } catch (err) {
      console.log(err)
    }
  },

  // BOARDS SETTINGS - Git Settings
  getBoardGits: async ({ commit }, boardId) => {
    try {
      const data = await request({
        url: `${apiBoards}${boardId}${endGit}`,
        method: 'GET',
      })
      commit('SET_BOARD_GITS', data)
      return data
    } catch (err) {
      console.log(err)
    }
  },
  postBoardGit: async (
    { getters, dispatch },
    { gitUrl, gitRegexp, boardId }
  ) => {
    try {
      const data = {
        git_url: gitUrl,
        commit_regexp: gitRegexp,
      }
      await request({
        url: `${apiBoards}${getters.currentBoard.id}${endGit}`,
        method: 'POST',
        data,
      })
      await dispatch('getBoardGits', boardId)
      return true
    } catch (err) {
      console.log(err)
    }
  },
  patchBoardGit: async ({ getters, dispatch }, data) => {
    try {
      const { board_id } = data
      await request({
        url: `${apiBoards}${getters.currentBoard.id}${endGit}`,
        method: 'PATCH',
        data,
      })
      dispatch('getBoardGits', board_id)
      return true
    } catch (err) {
      console.log(err)
    }
  },
  deleteBoardGit: async ({ getters, dispatch }, { gitURL, boardId }) => {
    try {
      const data = {
        git_url: gitURL,
      }
      await request({
        url: `${apiBoards}${getters.currentBoard.id}${endGit}`,
        method: 'DELETE',
        data,
      })
      dispatch('getBoardGits', boardId)
      return true
    } catch (err) {
      console.log(err)
    }
  },

  // BOARDS SETTINGS - Alerts Settings
  getAlertsVariants: async ({ commit }, boardId) => {
    try {
      const data = await request({
        url: apiAlertsVariants,
        method: 'POST',
        data: { board_id: boardId },
      })
      commit('SET_ALERTS_VARIANTS', data)
      return data
    } catch (err) {
      commit('CLOSE_NOTIFICATION'), {}, { root: true }
    }
  },
  getAlertsBoard: async ({ commit }, boardId) => {
    try {
      const data = await request({
        url: `${apiAlerts}?board_id=${boardId}`,
        method: 'GET',
      })
      commit('SET_ALERTS_BOARD', data)
      return data
    } catch (err) {
      commit('CLOSE_NOTIFICATION'), {}, { root: true }
    }
  },
  addListAlert: async (
    { state, dispatch },
    {
      text,
      deltaTime,
      repeat,
      createType,
      deleteType,
      createParams,
      deleteParams,
      alertType,
      boardId,
    }
  ) => {
    try {
      const data = {
        board_id: state.currentBoard.id,
        create_parameters: createParams,
        delete_parameters: deleteParams,
        delta_time: deltaTime,
        repeat,
        alert_type: alertType,
        create_type: createType,
        delete_type: deleteType,
        text,
      }
      await request({ url: apiAlerts, method: 'POST', data })
      dispatch('getAlertsBoard', boardId)
      return 'ok'
    } catch (err) {
      return { error: err }
    }
  },
  changeListAlert: async (
    { state, dispatch },
    {
      id,
      text,
      deltaTime,
      repeat,
      createType,
      deleteType,
      createParams,
      deleteParams,
      alertType,
      boardId,
    }
  ) => {
    try {
      const data = {
        board_id: state.currentBoard.id,
        create_parameters: createParams,
        delete_parameters: deleteParams,
        delta_time: deltaTime,
        repeat,
        alert_type: alertType,
        create_type: createType,
        delete_type: deleteType,
        text,
      }
      await request({ url: `${apiAlerts}${id}/`, method: 'PUT', data })
      dispatch('getAlertsBoard', boardId)
      return 'ok'
    } catch (err) {
      return { error: err }
    }
  },
  deleteListAlert: async ({ dispatch }, { id, boardId }) => {
    try {
      await request({ url: `${apiAlerts}${id}/`, method: 'DELETE' })
      dispatch('getAlertsBoard', boardId)
      return 'ok'
    } catch (err) {
      return { error: err }
    }
  },

  getBoardUsers: async ({ commit }, boardId) => {
    try {
      const data = await request({
        url: `${apiBoards}${boardId}${endUsers}`,
        method: 'GET',
      })
      commit('SET_BOARD_USERS', data)
      return data
    } catch (err) {
      console.log(err)
    }
  },
  addBoardUser: async (
    { commit, dispatch },
    { boardId, userEmail, isClient, mail_lang }
  ) => {
    try {
      const responses = await request({
        url: `${apiBoards}${boardId}${endUsers}`,
        method: 'POST',
        data: { email: userEmail, is_client: isClient, mail_lang },
      })
      commit('SET_NOTIFICATION', responses.detail)
      dispatch('getBoardUsers', boardId)
      return true
    } catch (err) {
      console.log(err)
    }
  },
  deleteBoardUser: async ({ dispatch }, { boardId, userId }) => {
    try {
      await request({
        url: `${apiBoards}${boardId}${endUsers}`,
        method: 'DELETE',
        data: { user_id: userId },
      })
      dispatch('getBoardUsers', boardId)
      return true
    } catch (err) {
      console.log(err)
    }
  },
  getSubscribeBoards: async ({ commit }, signingDumps) => {
    try {
      const data = await request({
        url: `${apiBoardsUnsubscribeNewsletter}?signing_dumps=${signingDumps}`,
        method: 'GET',
      })
      commit('SET_SUBSCRIBE_BOARDS', data)
      return data
    } catch (err) {
      console.log(err)
    }
  },
  postSubscribeBoards: async (_, { signingDumps, boardId }) => {
    try {
      const data = await request({
        url: apiBoardsUnsubscribeNewsletter,
        method: 'POST',
        isWithoutToken: true,
        data: { signing_dumps: signingDumps, board_ids: boardId },
      })
      return data
    } catch (err) {
      console.log(err)
    }
  },
  changeBoardPrefix: async ({ dispatch }, payload) => {
    try {
      const { data, boardId, method } = payload
      await request({
        url: `${apiBoards}${boardId}${endBoardPrefix}`,
        method,
        data,
      })
      dispatch('getBoard', boardId)
    } catch (err) {
      return err
    }
  },
  changeObserverStatus: async ({ commit }, { boardId, user_id }) => {
    try {
      const data = await request({
        url: `${apiBoards}${boardId}${endObserverStatus}`,
        method: 'POST',
        data: {
          user_id,
        },
      })
      commit('UPDATE_OBSERVERS_BOARD', user_id)
      return data
    } catch (err) {
      console.log(err)
    }
  },
  syncBoards: async (ctx, { boardId }) => {
    try {
      await request({
        url: `${apiBoards}${boardId}${endSyncBoards}`,
        method: 'GET',
      })
      return '200'
    } catch (err) {
      console.log(err)
    }
  },
  syncBoardsRoughly: async (ctx, { boardId }) => {
    try {
      await request({
        url: `${apiBoards}${boardId}${endSyncBoardsRoughly}`,
        method: 'GET',
      })
      return '200'
    } catch (err) {
      console.log(err)
    }
  },
  getUserBoards: async ({ commit }) => {
    try {
      const data = await request({
        url: apiUserBoards,
        method: 'GET',
      })
      commit('SET_USER_BOARDS', data)
      return data
    } catch (err) {
      console.log(err)
    }
  },
  getAvailableTrelloBoards: async ({ commit }) => {
    commit('SET_IS_LOADED_AVAILABLE_TRELLO_BOARDS', false)
    try {
      const data = await request({
        url: apiAvailableTrelloBoards,
        method: 'GET',
      })
      commit('SET_AVAILABLE_TRELLO_BOARDS', data)
    } catch (err) {
      return { error: err }
    } finally {
      commit('SET_IS_LOADED_AVAILABLE_TRELLO_BOARDS', true)
    }
  },
  connectAvailableTrelloBoards: async (ctx, data) => {
    try {
      return await request({
        url: apiAvailableTrelloBoards,
        method: 'POST',
        data,
      })
    } catch (err) {
      return { error: err }
    }
  },
}

const mutations = {
  SET_BOARDS: (state, data) => {
    state.boards = data
  },
  SET_TO_ZERO_BOARDS: (state) => {
    state.boards = []
  },
  SET_BOARD: (state, data) => {
    state.currentBoard = data
  },
  SET_TO_ZERO_BOARD: (state) => {
    state.currentBoard = null
    state.boardProgress = 0
  },
  SET_BOARD_LIST: (state, data) => {
    state.lists = [data]
  },
  SET_TO_ZERO_BOARD_LISTS: (state) => {
    state.lists = []
  },

  SET_BOARDS_MEMBERS: (state, data) => {
    state.boardsMembers = data
  },
  SET_TO_ZERO_BOARDS_MEMBERS: (state) => {
    state.boardsMembers = []
  },

  SET_BOARD_USERS: (state, data) => {
    state.boardUsers = data.users
    state.boardAdmins = data.admins
    state.boardClients = data.clients
    state.boardObservers = data.observer_users
  },
  SET_TO_ZERO_BOARD_USERS: (state) => {
    state.boardUsers = []
    state.boardAdmins = []
  },
  SET_BOARD_HERALDS: (state, data) => {
    state.boardHeralds = data.can_be_herald
    state.boardActiveHerald = data.current_herald_id
  },
  SET_TO_ZERO_BOARD_HERALDS: (state) => {
    state.boardHeralds = []
    state.boardActiveHerald = null
  },
  SET_BOARD_GITS: (state, data) => {
    state.boardGits = data.sort((a, b) => a.id - b.id)
  },
  REPLACED_SYNCED_REPO: (state, data) => {
    state.boardGits = state.boardGits.map((git) =>
      git.git_url === data.git_url ? data : git
    )
  },
  SET_TO_ZERO_BOARD_GITS: (state) => {
    state.boardGits = []
  },
  SET_BOARD_SPECIAL_LIST: (state, data) => {
    state.boardSpecialLists = data
  },
  SET_TO_ZERO_BOARD__SPECIAL_LIST: (state) => {
    state.boardSpecialLists = []
  },
  SET_BOARD_PROGRESS: (state, data) => {
    state.boardProgress = data.progress
  },
  SET_BOARDS_PROGRESS: (state, data) => {
    state.boardsProgress = data
  },
  SET_REPORT_RECEIVERS: (state, data) => {
    state.boardReportReceivers = data
  },
  SET_TO_ZERO_REPORT_RECEIVERS: (state) => {
    state.boardReportReceivers = []
  },
  SET_NOTIFICATION: (state, data) => {
    state.boardsNotification = data
  },
  SET_SUBSCRIBE_BOARD_MEMBERS: (state, data) => {
    state.boardSubscribeMembers = data
  },
  SET_SUBSCRIBE_BOARDS: (state, data) => {
    state.subscribeBoards = data
  },
  SET_TO_ZERO_SUBSCRIBE_BOARDS: (state) => {
    state.subscribeBoards = []
  },
  SET_ALERTS_VARIANTS: (state, data) => {
    state.alertsVariants = data
  },
  SET_ALERTS_BOARD: (state, data) => {
    state.alertsBoard = data
  },
  SET_BOARDS_ADMIN: (state, data) => {
    state.adminBoards = data
  },
  UPDATE_OBSERVERS_BOARD: (state, data) => {
    state.boardObservers = state.boardObservers.filter(
      (user) => user.id !== data
    )
  },
  SET_ACTUAL_BOARD_MEMBERS: (state, data) => {
    state.actualBoardMembers = data
  },
  SET_GIT_TRACKING: (state, value) => {
    state.gitTracking = value
  },
  SET_USER_BOARDS: (state, data) => {
    state.userBoards = data
  },
  SET_AVAILABLE_TRELLO_BOARDS: (state, data) => {
    state.availableTrelloBoards = data
  },
  SET_IS_LOADED_AVAILABLE_TRELLO_BOARDS: (state, data) => {
    state.isLoadedAvailableTrelloBoards = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
