<template lang="pug">
v-chart.bar-chart(
  :option='option'
  :style='{ "margin-bottom": marginBottom }'
  autoresize)
</template>

<script>
import { mapGetters } from 'vuex'
import { use } from 'echarts/core'
// import * as echarts from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { HeatmapChart, BarChart } from 'echarts/charts'
import {
  TitleComponent,
  CalendarComponent,
  TooltipComponent,
  VisualMapComponent,
  LegendComponent,
  GridComponent,
} from 'echarts/components'
import VChart, { INIT_OPTIONS_KEY } from 'vue-echarts'

use([
  TitleComponent,
  CalendarComponent,
  TooltipComponent,
  VisualMapComponent,
  HeatmapChart,
  CanvasRenderer,
  BarChart,
  LegendComponent,
  GridComponent,
])

export default {
  name: 'CalendarChart',
  components: {
    VChart,
  },
  provide: {
    [INIT_OPTIONS_KEY]: {
      height: '200',
    },
  },
  inject: ['marginBottom'],
  props: {
    id: {
      type: Number,
      default: null,
    },
    activity: {
      type: Array,
      default: () => [],
    },
    member: {
      type: Object,
      default: () => {},
    },
    chartOptions: {
      type: Object,
      default: () => {},
    },
    selectedBoard: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isLoaded: false,
      option: {
        backgroundColor: this.chartOptions.backgroundColor,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: this.chartOptions.legend,
        grid: {
          left: '10',
          right: '20%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: this.chartOptions.rangeDate,
            axisLabel: this.chartOptions.axisLabel,
          },
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: this.chartOptions.axisLabel,
          },
        ],
        color: this.chartOptions.color,
        series: this.chartOptions.series,
      },
    }
  },
  computed: {
    ...mapGetters(['isDark']),
  },
}
</script>

<style>
.bar-chart {
  height: var(--bar-chart-height);
}
</style>
