<template lang="pug">
div(:id='member.id')
  CalendarChart(
    v-if='this.memberActivity.length'
    :activity='memberActivity'
    :member='member'
    :chartOptions='createChartOptions')
  .chart__stub(v-else)
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
const {
  mapState: membersMapState,
  mapMutations: memberMapMutations,
  mapActions: membersMapActions,
} = createNamespacedHelpers('members')
import { light, dark } from '@/helpers/themes'
import CalendarChart from '@/views/Members/components/CalendarChart.vue'

export default {
  name: 'MemberActivity',
  components: { CalendarChart },
  props: {
    member: {
      type: Object,
      default: () => {},
    },
    visualMapType: {
      type: String,
      default: 'piecewise',
    },
    selectedBoard: {
      type: Number,
      default: 0,
    },
    filterType: {
      type: String,
      default: 'actions',
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters(['isDark', 'isMobile', 'isTablet', 'lang']),
    ...membersMapState(['activity']),
    memberActivity() {
      return (
        this.activity
          .filter((item) => +item.id === this.member.id)
          .map((el) => el.activity) || []
      )
    },
    inRangeColorsPiecewise() {
      return this.isDark
        ? [dark.chartMin, dark.chartMid, dark.chartMax, dark.chartEnd]
        : [light.chartMin, light.chartMid, light.chartMax, light.chartEnd]
    },
    inRangeColorsContinuous() {
      return this.isDark
        ? [dark.chartStart, dark.chartMid, dark.chartEnd]
        : [light.chartStart, light.chartMid, light.chartEnd]
    },
    createChartOptions() {
      return {
        backgroundColor: this.isDark
          ? dark.backgroundMain
          : light.backgroundMain,
        lineStyleColor: 'rgba(80,81,85,0.4)',
        visualMapType: this.visualMapType,
        // visualMapTop: this.visualMapType === 'piecewise' ? '142' : '40',
        itemStyleBorderColor: this.isDark ? dark.inactive : light.inactive,
        itemStyleColor: this.isDark ? dark.base : light.base,
        visualMapOptions:
          this.visualMapType === 'piecewise'
            ? {
                // min: 1,
                // max: 10,
                type: 'piecewise',
                orient: 'horizontal',
                show: false,
                pieces: [
                  { min: 1, max: 3 },
                  { min: 4, max: 6 },
                  { min: 7, max: 9 },
                  { min: 10, max: 1000 },
                  // { min: 8, max: 10, label: '8-10' },
                ],
                inRange: {
                  color: this.inRangeColorsPiecewise,
                },
              }
            : {
                min: 0,
                max: 10,
                range: [1, 10],
                calculable: true,
                type: 'continuous',
                orient: 'vertical',
                left: this.isMobile ? '95%' : this.isTablet ? '85%' : '75%',
                top: '0',
                show: this.isMobile ? false : true,
                textStyle: {
                  color: this.isDark ? dark.title : light.title,
                },
                inRange: {
                  color: this.inRangeColorsContinuous,
                },
              },
        calendarWidth:
          this.visualMapType === 'piecewise'
            ? !this.isMobile && !this.isTablet
              ? '90%'
              : '90%'
            : this.isMobile
            ? '90%'
            : this.isTablet
            ? '80%'
            : '70%',
        calendarTop: this.visualMapType === 'piecewise' ? '40' : '35',
        calendarLabelColor: this.isDark ? dark.title : light.title,
        calendarMonthLabel: this.lang === 'ru' ? this.$lang.monthsList : 'en',
        calendarDayLabel: this.lang === 'ru' ? this.$lang.daysList : 'en',
        calendarMonthLabelShow: this.isMobile
          ? false
          : this.isTablet
          ? false
          : true,
        // calendarRight: this.visualMapType === 'piecewise' ? 'auto' : 30,
        calendarRange: this.getDateRange(),
        chartData: this.getData(),
      }
    },
  },
  watch: {
    filterType(n, o) {
      if (n !== o) {
        this.setToZeroActivity()
        this.getActivity({
          id: this.member.id,
          filter_by_board: this.selectedBoard,
          filter_type: n,
        })
      }
    },
    isDark(n, o) {
      if (n !== o) {
        this.setToZeroActivity()
        this.getActivity({
          id: this.member.id,
          filter_by_board: this.selectedBoard,
          filter_type: this.filterType,
        })
      }
    },
  },
  created() {
    this.getDateRange()
  },
  methods: {
    ...memberMapMutations(['setToZeroActivity']),
    ...membersMapActions(['getActivity']),
    getDateRange() {
      const t = new Date()
      const end = `${t.getFullYear()}-${t.getMonth() + 1}-${t.getDate()}`
      const start = `${t.getFullYear() - 1}-${t.getMonth() + 1}-${
        t.getDate() + 1
      }`
      return [start, end]
    },
    getData() {
      let data = []
      Object.entries(this.memberActivity[0]).map((item) =>
        data.push([item[0], item[1].total_actions])
      )
      return data
    },
  },
}
</script>

<style lang="scss" scoped>
.chart__stub {
  height: var(--chart-height);
  background: var(--v-backgroundMain-base);
}
</style>
