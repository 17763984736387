<template lang="pug">
//- v-form(v-model="validForm" @submit.prevent="submitForm" ref="createNotifacationForm" v-if="isLoaded")
v-form(
  v-model='validForm'
  @submit.prevent='validateForm'
  ref='createNotifacationForm')
  .d-flex.flex-column.align-baseline
    .col-12
      v-select(
        v-model='alertCreateType'
        :items='alerts'
        item-value='alert_id'
        item-text='label'
        :label='$lang.addByEvent'
        :rules='[rules.alertType]'
        validate-on-blur
        @change='handleAlertCreateType("create_type")')
      span.alert-description {{ alertDescription }}
      v-alert(
        color='error'
        outlined
        dense
        v-if='nonFieldError && nonFieldError.create_type') {{ nonFieldError.create_type }}
  .d-flex.flex-column.align-baseline(v-if='alertCreateType !== undefined')
    .col-12(v-for='param in alertsCreateParameters')
      v-select.mr-sm-2(
        v-if='param.type === "select"'
        v-model='alertCreateParams[param.name]'
        :items='param.values'
        attach
        chips
        :label='param.label'
        multiple
        item-value='id'
        item-text='value'
        :rules='[rules.inputRules]'
        @change='getDataForTextTemplate()')
        template(v-slot:selection='{item, index}')
          v-chip(v-if='index < 2')
            span {{ item.value }}

          span.grey--text.caption(v-if='index === 2')
            | (+{{ alertCreateParams[param.name].length - 2 }})
        template(v-slot:prepend-item)
          v-list-item(
            ripple
            @click='toggleCreateType(param.name, param.values), getDataForTextTemplate()')
            v-list-item-action
              v-icon(
                :color='alertCreateParams[param.name] && alertCreateParams[param.name].length > 0 ? "indigo darken-4" : ""') {{ getIconCreateType(param.name, param.values) }}
            v-list-item-content
              v-list-item-title {{ $lang.selectAll }}
          v-divider.mt-2
      v-text-field(
        v-if='param.type === "count"'
        v-model='alertCreateParams[param.name]'
        :label='param.label'
        :hint='$lang.insertNumber'
        :rules='[...rules.count]'
        validate-on-blur
        @change='getDataForTextTemplate()')
      v-select.mr-sm-2(
        v-if='param.type === "single" && param.name !== "recipients_of_letters"'
        v-model='alertCreateParams[param.name]'
        :items='param.values'
        :label='param.label'
        item-value='id'
        item-text='value'
        :rules='[rules.inputRules]'
        @change='getDataForTextTemplate()')
      div(
        v-if='alertCreated && alertCreated.create_parameters.period && param.name === "members"')
        .col-12.px-0
          label {{ $lang.period }}
        .col-12.px-0
          v-slider.mb-6.sliderPeriod(
            ticks
            hide-details
            :max='10'
            :min='1'
            :tick-labels='alertCreated.create_parameters.period'
            v-model='vAlertPeriod'
            @change='getDataForTextTemplate()')

  .d-flex.flex-column.align-baseline(
    v-if='alertCreated && alertCreated.delete_types_for_select')
    .col-12
      v-select(
        v-model='alertDeleteType'
        :items='alertsDelete'
        item-value='alert_id'
        item-text='label'
        :label='$lang.resetByEvent'
        :rules='[rules.alertType]'
        validate-on-blur
        @change='handleChangeError("delete_type")')
      v-alert(
        color='error'
        outlined
        dense
        v-if='nonFieldError && nonFieldError.delete_type') {{ nonFieldError.delete_type }}
  .d-flex.flex-column.align-baseline(v-if='alertDeleteType !== undefined')
    .col-12(v-for='param in alertsDeleteParameters')
      v-select.mr-sm-2(
        v-if='param.type === "select"'
        v-model='alertDeleteParams[param.name]'
        :items='param.values'
        attach
        chips
        :label='param.label'
        multiple
        item-value='id'
        item-text='value')
        template(v-slot:selection='{item, index}')
          v-chip(v-if='index < 2')
            span {{ item.value }}

          span.grey--text.caption(v-if='index === 2')
            | (+{{ alertDeleteParams[param.name].length - 2 }})
        template(v-slot:prepend-item)
          v-list-item(
            ripple
            @click='toggleDeleteType(param.name, param.values)')
            v-list-item-action
              v-icon(
                :color='alertDeleteParams[param.name] && alertDeleteParams[param.name].length > 0 ? "indigo darken-4" : ""') {{ getIconDeleteType(param.name, param.values) }}
            v-list-item-content
              v-list-item-title {{ $lang.selectAll }}
          v-divider.mt-2
      v-text-field(
        v-if='param.type === "count"'
        v-model='alertDeleteParams[param.name]'
        :label='param.label'
        :hint='$lang.insertNumber'
        :rules='[rules.count]'
        validate-on-blur)
      v-select.mr-sm-2(
        v-if='param.type === "single"'
        v-model='alertDeleteParams[param.name]'
        :items='param.values'
        :label='param.label'
        item-value='id'
        item-text='value')
  .d-flex.flex-column.align-baseline.pb-4(v-if='alertCreateType !== undefined')
    .col-12.py-0(
      v-if='alertNotificationTypes.length === 1 && alertNotificationTypes[0].id === "emailAlert"')
      v-text-field(
        readonly
        filled
        :value='alertNotificationTypes[0].value')
    .col-12.py-0(v-else)
      v-select.mr-sm-2(
        v-model='alertNotificationType'
        :items='alertNotificationTypes.reverse()'
        item-value='id'
        item-text='value'
        :rules='[rules.inputRules]'
        :label='alertsParametersAlertType.label'
        @change='handleChangeError("alert_type"), getInitTextTemplate(alertCreateType)')
      v-alert(
        color='error'
        outlined
        dense
        v-if='nonFieldError && nonFieldError.alert_type') {{ nonFieldError.alert_type }}

    .col-12.py-0(v-for='param in alertsCreateParameters')
      v-select.mr-sm-2(
        v-if='param.type === "single" && param.name === "recipients_of_letters" && alertNotificationType === "emailAlert"'
        v-model='alertCreateParams[param.name]'
        :items='param.values'
        :label='param.label'
        item-value='id'
        item-text='value'
        :rules='[rules.inputRules]')
  div(v-if='alertCreated && alertCreated.show_alert_frequency_scale')
    .col-12
      label {{ $lang.timer }}: {{ alertDeltaTime | dateTime($lang) }}
    .col-12
      v-slider.mb-6.sliderTimer(
        ticks
        hide-details
        :max='10'
        :min='1'
        :tick-labels='deltaLabels'
        v-model='vAlertDeltaTime')
  .col-12
    v-switch(
      v-model='alertRepeat'
      :label='alertRepeat ? $lang.shouldRepeat : $lang.dontRepeat'
      hide-details)

  .d-flex.flex-column.align-baseline
    .col-12
      v-textarea(
        v-model='alertNotificationText'
        :label='$lang.notificationText'
        :rules='[rules.comment]'
        clearable
        :clear-icon='mdiCloseCircle'
        validate-on-blur
        auto-grow
        :rows='2'
        @change='handleChangeError("text")'
        @input='(val) => saveUserText(val)'
        ref='alertTextarea')
      v-alert(
        color='error'
        outlined
        dense
        v-if='nonFieldError && nonFieldError.text') {{ nonFieldError.text }}
      v-alert(
        color='error'
        outlined
        dense
        v-else-if='nonFieldError && nonFieldError.create_parameters') {{ $lang.checkFields }}
      .d-flex.align-center
        v-icon.mr-2(
          @click='renewText'
          color='main'
          :class='[isRenew ? "rotate-transition" : ""]'
          :disabled='!isTextTemplate') {{ mdiAutorenew }}
        span(:class='[!isTextTemplate ? "inactive-text" : "", "icon-label"]') {{ $lang.updateText }}
        span.ml-4(
          :class='[!isTextTemplate ? "inactive-text" : "", "caution-text"]') {{ $lang.caution }}
      v-checkbox(
        v-model='isTextTemplate'
        :label='$lang.addText'
        @change='switchVisibilityOfTextTemplate'
        :disabled='isCheckboxDisabled')
  .d-flex.flex-wrap
    .col-12
      button.setting__button-blue.mb-3.mr-3(v-if='isEditing') {{ $lang.saveChange }}
      button.setting__button-blue.mb-3.mr-3(v-else) {{ $lang.createNotification }}
      button.setting__button-white.mb-3(
        v-if='isEditing'
        @click.prevent='closeForm()') {{ $lang.closeForm }}
      button.setting__button-white.mb-3(
        v-else
        @click.prevent='clearForm()') {{ $lang.clearForm }}
</template>

<script>
import {
  mdiCloseBox,
  mdiMinusBox,
  mdiCheckboxBlankOutline,
  mdiAutorenew,
  mdiCloseCircle,
} from '@mdi/js'
import { createNamespacedHelpers, mapMutations } from 'vuex'
const { mapGetters: boardsMapGetters, mapActions: boardsdMapActions } =
  createNamespacedHelpers('boards')
import { deltaLabels } from '@/helpers/constants'

export default {
  name: 'CreateNotificationSettings',
  props: {
    alert: {
      type: Object,
      default: () => {},
    },
    isEditing: {
      type: Boolean,
      default: () => false,
    },
    currentBoard: {
      type: Object,
      default: () => {},
    },
    alerts: {
      type: Array,
      default: () => {},
    },
    alertsParameters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      alertCreateType: undefined,
      alertCreateParams: {},
      alertDeleteType: undefined,
      alertDeleteParams: {},
      alertNotificationType: 'emailAlert',
      alertDeltaTime: 3600,
      alertRepeat: false,
      alertNotificationText: '',
      alertDescription: '',
      deltaLabels,
      nonFieldError: undefined,

      validForm: false,

      rules: {
        comment: (value) => !!value || this.$lang.required,
        alertType: (value) => !!value || value === 0 || this.$lang.required,
        // count: (value) => /(^[0-9]*)$/.test(value) || this.$lang.onlyNumbers,
        count: [
          (value) => /(^[0-9]*)$/.test(value) || this.$lang.onlyNumbers,
          (value) =>
            (!!value && value.toString().length <= 21) ||
            this.$lang.characterLengthError,
        ],
        inputRules: (value) =>
          (!!value && !!value.length) || this.$lang.required,
      },
      isLoaded: false,
      initTextTemplate: '',
      textTemplate: '',
      isTextTemplate: false,
      text: {},
      words: [],
      isCheckboxDisabled: true,
      isRenew: false,
      userText: '',
      timeoutID: null,
      mdiCloseBox,
      mdiAutorenew,
      mdiMinusBox,
      mdiCheckboxBlankOutline,
      mdiCloseCircle,
    }
  },
  created() {
    if (this.alert && Object.keys(this.alert).length) {
      this.alertCreateType = this.alert.create_type
      this.alertCreateParams = this.alert.create_parameters
      this.alertDeleteType = this.alert.delete_type
      this.alertDeleteParams = this.alert.delete_parameters
      this.alertNotificationType = this.alert.alert_type
      this.alertDeltaTime = this.alert.delta_time
      this.alertRepeat = this.alert.repeat
      this.alertNotificationText = this.userText = this.alert.text

      this.isCheckboxDisabled = false
      this.getInitTextTemplate(this.alertCreateType)
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeoutID)
  },
  destroyed() {
    this.resetAlert()
  },
  computed: {
    ...boardsMapGetters(['alertsVariants']),
    vAlertDeltaTime: {
      get() {
        switch (this.alertDeltaTime) {
          case 60 * 5:
            return 1
          case 60 * 15:
            return 2
          case 60 * 30:
            return 3
          case 60 * 60:
            return 4
          case 60 * 60 * 2:
            return 5
          case 60 * 60 * 4:
            return 6
          case 60 * 60 * 6:
            return 7
          case 60 * 60 * 12:
            return 8
          case 60 * 60 * 24:
            return 9
          case 60 * 60 * 48:
            return 10
          default:
            return 0
        }
      },
      set(value) {
        switch (value) {
          case 1: {
            this.alertDeltaTime = 60 * 5
            break
          }
          case 2: {
            this.alertDeltaTime = 60 * 15
            break
          }
          case 3: {
            this.alertDeltaTime = 60 * 30
            break
          }
          case 4: {
            this.alertDeltaTime = 60 * 60
            break
          }
          case 5: {
            this.alertDeltaTime = 60 * 60 * 2
            break
          }
          case 6: {
            this.alertDeltaTime = 60 * 60 * 4
            break
          }
          case 7: {
            this.alertDeltaTime = 60 * 60 * 6
            break
          }
          case 8: {
            this.alertDeltaTime = 60 * 60 * 12
            break
          }
          case 9: {
            this.alertDeltaTime = 60 * 60 * 24
            break
          }
          case 10: {
            this.alertDeltaTime = 60 * 60 * 48
            break
          }
          default: {
            this.alertDeltaTime = 0
            return
          }
        }
      },
    },
    vAlertPeriod: {
      get() {
        switch (this.alertCreateParams['period']) {
          case 1:
            return 1
          case 2:
            return 2
          case 3:
            return 3
          case 4:
            return 4
          case 5:
            return 5
          case 6:
            return 6
          case 7:
            return 7
          case 14:
            return 8
          case 28:
            return 9
          case 56:
            return 10
          default:
            // this.alertCreateParams['period'] = 1
            return this.getDefaultPeriod()
        }
      },
      set(value) {
        switch (value) {
          case 1:
            this.alertCreateParams['period'] = 1
            break
          case 2:
            this.alertCreateParams['period'] = 2
            break
          case 3:
            this.alertCreateParams['period'] = 3
            break
          case 4:
            this.alertCreateParams['period'] = 4
            break
          case 5:
            this.alertCreateParams['period'] = 5
            break
          case 6:
            this.alertCreateParams['period'] = 6
            break
          case 7:
            this.alertCreateParams['period'] = 7
            break
          case 8:
            this.alertCreateParams['period'] = 14
            break
          case 9:
            this.alertCreateParams['period'] = 28
            break
          case 10:
            this.alertCreateParams['period'] = 56
            break
          default:
            this.alertCreateParams['period'] = 1
        }
      },
    },
    alertCreated() {
      return this.alerts.find(
        (alert) => alert.alert_id === this.alertCreateType
      )
    },

    alertsCreateParameters() {
      let params = []

      for (const key in this.alertCreated.create_parameters) {
        const values = this.alertCreated.create_parameters[key].map(
          (param) => ({
            id: param,
            value: this.alertsParameters[key].values[param],
          })
        )
        params.push({
          label: this.alertsParameters[key].label,
          values,
          name: key,
          type: this.alertsParameters[key].type,
        })
      }
      return params
    },

    alertNotificationTypes() {
      return this.alertCreated.alert_type.map((type) => ({
        id: type,
        value: this.alertsParameters.alert_type.values[type],
      }))
    },

    alertsDelete() {
      if (!this.isLoaded) return []
      const deleteTypes = this.alertCreated
        ? this.alertCreated.delete_types
        : []
      const alertsDelete = this.alerts.filter((alert) =>
        deleteTypes.find((type) => type === alert.alert_id)
      )
      return alertsDelete ? alertsDelete : []
    },

    alertDeleted() {
      return this.alertsDelete.find((alert) =>
        this.alertDeleteType ? alert.alert_id === this.alertDeleteType : []
      )
    },

    alertsDeleteParameters() {
      let params = []

      if (this.alertDeleted?.delete_parameters) {
        for (const key in this.alertDeleted.delete_parameters) {
          const values = this.alertDeleted.delete_parameters[key].map(
            (param) => ({
              id: param,
              value: this.alertsParameters[key].values[param],
            })
          )
          params.push({
            label: this.alertsParameters[key].label,
            values,
            name: key,
            type: this.alertsParameters[key].type,
          })
        }
      }
      return params
    },

    alertsParametersAlertType() {
      return this.alertsParameters.alert_type
    },
    alertToSend() {
      let allowedParams

      let filterDeleteParams
      let filterCreateParams

      if (this.alertCreated && this.alertCreated.create_parameters) {
        allowedParams = Object.keys(this.alertCreated.create_parameters)
        filterCreateParams = allowedParams.reduce((param, key) => {
          const value =
            Number.isInteger(+this.alertCreateParams[key]) &&
            this.alertCreateParams[key] !== undefined
              ? +this.alertCreateParams[key]
              : this.alertCreateParams[key]
          const obj = { ...param, [key]: value }
          return obj
        }, {})
      }
      if (this.alertDeleted && this.alertDeleted.delete_parameters) {
        allowedParams = Object.keys(this.alertDeleted.delete_parameters)
        filterDeleteParams = allowedParams.reduce(
          (param, key) => ({
            ...param,
            [key]: this.alertDeleteParams[key],
          }),
          {}
        )
      }

      if (this.alertNotificationTypes.length === 1) {
        this.setAlertType()
        // this.alertNotificationType = this.alertNotificationTypes[0].id
      }

      const body = {
        boardId: this.$route.params.id,
        createType: this.alertCreateType,
        createParams: filterCreateParams,
        deleteParams: filterDeleteParams,
        alertType: this.alertNotificationType,
        deltaTime: this.alertDeltaTime,
        repeat: this.alertRepeat || false,
        text: this.alertNotificationText,
      }

      if (this.alertDeleteType) {
        body.deleteType = this.alertDeleteType
      }

      return body
    },
  },
  methods: {
    ...mapMutations(['ADD_NOTIFICATION']),
    ...boardsdMapActions(['addListAlert', 'changeListAlert']),
    onToggle() {
      this.$emit('toggleAddedNotification')
    },
    async addDeadlineAlert() {
      this.nonFieldError = ''
      const res = await this.addListAlert({
        ...this.alertToSend,
      })
      if (res === 'ok') {
        this.ADD_NOTIFICATION({
          color: 'success',
          text: this.$lang.notificationAdded,
        })
        this.resetAlert()
        this.closeForm()
        this.$vuetify.goTo('#scroll-target')
      } else {
        this.getErrors(res.error)
      }
    },
    async saveAlert() {
      this.nonFieldError = ''
      const res = await this.changeListAlert({
        id: this.alert.id,
        ...this.alertToSend,
      })
      if (res === 'ok') {
        this.ADD_NOTIFICATION({
          color: 'success',
          text: this.$lang.notificationChange,
        })
        this.resetAlert()
        this.closeForm()
      } else {
        this.getErrors(res.error)
      }
    },
    getErrors(error) {
      if (error && typeof error === 'object') {
        this.nonFieldError = {}
        for (let field in error) {
          if (Array.isArray(error[field])) {
            this.nonFieldError[field] = `${error[field].join(' ')} `
          } else {
            this.nonFieldError = error
            this.ADD_NOTIFICATION({
              color: 'error',
              text: this.nonFieldError,
            })
          }
        }
      } else if (error && typeof error !== 'object') {
        this.nonFieldError = error
        this.ADD_NOTIFICATION({
          color: 'error',
          text: this.nonFieldError,
        })
      }
    },
    closeForm() {
      this.$emit('closeFormAlert')
    },
    resetAlert() {
      this.alertCreateType = undefined
      this.alertCreateParams = {}
      this.alertDeleteType = undefined
      this.alertDeleteParams = {}
      this.alertNotificationType = 'commentAlert'
      this.alertDeltaTime = 1
      this.alertRepeat = false
      this.alertNotificationText = ''
      this.alertPeriod = null

      this.onToggle()
    },
    clearForm() {
      this.$refs.createNotifacationForm.reset()
      // this.alertNotificationType = 'commentAlert';
      // this.nonFieldError = undefined;

      this.clearFormFields(true)

      this.$emit('clearAlert')
    },
    handleChangeError(field) {
      if (this.nonFieldError) {
        this.nonFieldError[field] = ''
      }
    },
    getAlertDescription(type) {
      this.alerts.forEach((alert) =>
        alert.alert_id === type
          ? (this.alertDescription = alert.description)
          : ''
      )
    },
    handleAlertCreateType(type) {
      this.clearFormFields()
      this.clearTextTemplate()
      this.getInitTextTemplate(this.alertCreateType)
      this.getAlertDescription(this.alertCreateType)

      if (this.alert && this.alert.create_type !== this.alertCreateType) {
        const findAlertDeleteType = this.alertsDelete.find(
          (alert) => alert.alert_id === this.alertDeleteType
        )

        if (!findAlertDeleteType) {
          this.alertDeleteType = undefined
        }
      }

      this.handleChangeError(type)
    },
    toggleCreateType(param, allParams) {
      if (this.alertCreateParams[param] === undefined) {
        this.$set(this.alertCreateParams, param, [])
      }
      const allIdsParams = allParams.map((param) => param.id)

      this.$nextTick(() => {
        if (
          this.alertCreateParams[param] &&
          this.alertCreateParams[param].length
        ) {
          this.alertCreateParams[param] = []
        } else {
          this.alertCreateParams[param] = allIdsParams.slice()
        }
      })
    },
    toggleDeleteType(param, allParams) {
      if (this.alertDeleteParams[param] === undefined) {
        this.$set(this.alertDeleteParams, param, [])
      }
      const allIdsParams = allParams.map((param) => param.id)

      this.$nextTick(() => {
        if (
          this.alertDeleteParams[param] &&
          this.alertDeleteParams[param].length
        ) {
          this.alertDeleteParams[param] = []
        } else {
          this.alertDeleteParams[param] = allIdsParams.slice()
        }
      })
    },
    validateForm() {
      this.$refs.createNotifacationForm.validate()
      if (this.validForm) {
        this.submitForm()
      }
    },
    submitForm() {
      this.isEditing ? this.saveAlert() : this.addDeadlineAlert()
    },
    clearFormFields(val = false) {
      if (val) {
        this.alertCreateType = undefined
      }
      this.alertCreateParams = {}
      this.alertDeleteType = undefined
      this.alertDeleteParams = {}
      this.alertNotificationType = 'emailAlert'
      this.alertDeltaTime = 1
      this.alertRepeat = false
      this.alertNotificationText = ''

      this.nonFieldError = undefined
    },
    getAlertsText() {
      const regExp = /\{(\w+)\}/gi
      this.textTemplate = this.initTextTemplate

      // console.log([...this.textTemplate.matchAll(regExp)])

      const arr = [...this.textTemplate.matchAll(regExp)]
      arr.map((item) => {
        if (this.text[item[1]]) {
          this.textTemplate = this.textTemplate.replace(
            item[0],
            this.text[item[1]].join()
          )
        }
      })

      // console.log(this.textTemplate)

      if (!regExp.test(this.textTemplate)) {
        this.isCheckboxDisabled = false
      } else {
        this.isCheckboxDisabled = true
        this.isTextTemplate = false
        this.alertNotificationText = this.userText ? this.userText : ''
      }
    },
    getDataForTextTemplate(editingSwitchAlertTypeKey = false) {
      // console.log('editing', this.isEditing, !editingSwitchAlertTypeKey)

      if (this.isEditing && !editingSwitchAlertTypeKey) {
        this.getInitTextTemplate(this.alertCreateType, true)
      }

      this.$nextTick(() => {
        if (this.alertCreated) {
          for (const [key, value] of Object.entries(this.alertCreateParams)) {
            this.$set(this.text, key, [])

            this.alertsCreateParameters
              .filter((item) => key === item.name)
              .map((i) => {
                if (Array.isArray(value)) {
                  value.map((el) => {
                    i.values.map((item, idx) => {
                      if (el === item.id) {
                        idx === 0
                          ? this.text[key].push(`${item.value}`)
                          : this.text[key].push(` ${item.value}`)
                      }
                    })
                  })
                } else {
                  if (i.type !== 'count') {
                    i.values.map((item) => {
                      if (value === item.id) {
                        this.text[key].push(item.value)
                      }
                    })
                  } else {
                    this.text[key].push(value)
                  }
                }
              })

            this.getAlertsText()
          }
        }
      })
    },
    getInitTextTemplate(type) {
      // console.log('editing get', this.isEditing, editingDataKey)

      if (this.isEditing) {
        // this.words = []
        this.getDataForTextTemplate(true)
      }

      const obj = this.alertsVariants.alerts.find(
        (alert) => alert.alert_id === type
      )?.prepared_text_template

      this.initTextTemplate =
        this.alertNotificationType === 'emailAlert'
          ? obj.for_email
          : obj.for_comments

      this.initTextTemplate = this.initTextTemplate.includes('{board_name}')
        ? this.initTextTemplate.replace(
            '{board_name}',
            `"${this.currentBoard.name}"`
          )
        : this.initTextTemplate

      //  console.log(this.initTextTemplate)

      this.textTemplate = [this.initTextTemplate]

      this.getAlertsText(true)
    },
    switchVisibilityOfTextTemplate() {
      this.isTextTemplate ? this.$refs.alertTextarea.focus() : null
      this.alertNotificationText = this.isTextTemplate
        ? this.textTemplate
        : this.userText
    },
    clearTextTemplate() {
      this.text = {}
      this.words = []
      this.isCheckboxDisabled = true
      this.alertNotificationText = ''
    },
    renewText() {
      this.getAnimation()
      this.alertNotificationText = this.textTemplate
    },
    getAnimation() {
      this.isRenew = true

      this.timeoutID = setTimeout(() => {
        this.isRenew = false
      }, 500)
    },
    saveUserText(val) {
      this.userText = val
    },
    getSelectedAllParams(param, allParams, type) {
      if (type === 'create') {
        return (
          this.alertCreateParams[param] &&
          this.alertCreateParams[param].length === allParams.length
        )
      }
      return (
        this.alertDeleteParams[param] &&
        this.alertDeleteParams[param].length === allParams.length
      )
    },
    getSelectedSomeParams(param, allParams, type) {
      if (type === 'create') {
        return (
          this.alertCreateParams[param] &&
          this.alertCreateParams[param].length > 0 &&
          this.alertCreateParams[param].length < allParams.length
        )
      }
      return (
        this.alertDeleteParams[param] &&
        this.alertDeleteParams[param].length > 0 &&
        this.alertDeleteParams[param].length < allParams.length
      )
    },
    getIconCreateType(param, allParams) {
      if (this.getSelectedAllParams(param, allParams, 'create')) {
        return mdiCloseBox
      }
      if (this.getSelectedSomeParams(param, allParams, 'create')) {
        return mdiMinusBox
      }
      return mdiCheckboxBlankOutline
    },
    getIconDeleteType(param, allParams) {
      if (this.getSelectedAllParams(param, allParams, 'delete')) {
        return 'mdiCloseBox'
      }
      if (this.getSelectedSomeParams(param, allParams, 'delete')) {
        return 'mdiMinusBox'
      }
      return 'mdiCheckboxBlankOutline'
    },
    setAlertType() {
      this.alertNotificationType = this.alertNotificationTypes[0].id
    },
    getDefaultPeriod() {
      this.alertCreateParams['period'] = 1
      return 1
    },
  },
}
</script>

<style lang="scss">
.sliderTimer {
  margin-right: 20px;

  & .v-slider__tick-label {
    transform: rotate(45deg) !important;
  }
}

.sliderPeriod {
  margin-right: 20px;
}

.setting {
  &__button-white {
    @include btn-settings($color: false);
  }

  &__button-blue {
    @include btn-settings($color: true);
  }
}

.icon-label,
.caution-text {
  font-size: 16px;
}

.caution-text {
  color: var(--v-main-base);
}

.inactive-text {
  color: var(--v-inactive-base);
}

.rotate-transition {
  animation: cssload-spin 500ms linear;
  -o-animation: cssload-spin 500s linear;
  -ms-animation: cssload-spin 500ms linear;
  -webkit-animation: cssload-spin 500ms linear;
  -moz-animation: cssload-spin 500ms linear;
}

@keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
  }
}

@-o-keyframes cssload-spin {
  100% {
    -o-transform: rotate(360deg);
  }
}

@-ms-keyframes cssload-spin {
  100% {
    -ms-transform: rotate(360deg);
  }
}

@-webkit-keyframes cssload-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes cssload-spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
</style>

<style scoped>
.alert-description {
  color: var(--v-inactive-base);
  font-size: 14px;
}
</style>
