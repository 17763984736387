<template lang="pug">
.fullpage-wrapper
  v-main(
    app
    style='height: 100%')
    router-view
</template>

<script>
import config from '@/../config.json'

export default {
  name: 'FullPage',
  created() {
    window.addEventListener('storage', this.eventHandler)
  },
  beforeDestroy() {
    window.removeEventListener('storage', this.eventHandler)
  },
  methods: {
    eventHandler() {
      if (localStorage.getItem('token-data')) {
        document.location.href = `${config.baseURL}/boards`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.fullpage-wrapper {
  height: 100%;
}
</style>
